import clsx from "clsx";
import deepEqual from "deep-equal";
import Select, { SelectItemRenderer, SelectRenderer } from "react-dropdown-select";
import styles from "./DropdownSelect.module.css";
import "./react-dropdown-select-overrides.css";

export type DropdownSelectOption = {
  id: string;
  name: string;
  disabled?: boolean;
};

interface Props {
  className?: string;
  selectClassName?: string;
  placeholder: string;
  options: DropdownSelectOption[];
  multi?: boolean;
  searchable?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  selected: DropdownSelectOption[];
  onSelect: (select: DropdownSelectOption[]) => unknown;
  onOpen?: () => unknown;
}

export const DropdownSelect = ({
  placeholder,
  options,
  multi,
  onSelect,
  selected,
  clearable,
  className,
  selectClassName,
  searchable = false,
  disabled = false,
  onOpen,
}: Props) => {
  const onSearch = ({ state, methods }: SelectRenderer<DropdownSelectOption>) => {
    const regexp = new RegExp(methods.safeString(state.search), "i");
    return methods.sortBy().filter((item) => regexp.test(item.name));
  };

  const noDataRenderer = () => {
    return <p style={{ textAlign: "center" }}>Keine Ergebnisse gefunden</p>;
  };

  const itemRenderer = (args: SelectItemRenderer<DropdownSelectOption>) => {
    const { item, methods } = args;

    const handleClick = () => {
      if (item.disabled) {
        return;
      }
      methods.addItem(item);
    };

    return (
      <div
        title={item.name}
        key={item.id}
        onClick={handleClick}
        className={clsx(styles.item, { [styles.disabled]: item.disabled })}
      >
        {multi ? (
          <input
            className={styles.itemCheckbox}
            type="checkbox"
            onChange={() => methods.addItem(item)}
            checked={methods.isSelected(item)}
            disabled={item.disabled}
          />
        ) : null}
        <div className={styles.itemText}>{item.name}</div>
      </div>
    );
  };

  const handleChange = (args: any) => {
    if (deepEqual(args, selected)) {
      // ignore unchanged. prevent unnecessary trigger
      return;
    }
    onSelect(args);
  };

  return (
    <div className={clsx(styles.root, className)}>
      <Select
        className={clsx(styles.select, selectClassName, {
          [styles.selectHasItems]: multi && selected.length > 0,
        })}
        values={selected}
        placeholder={placeholder}
        multi={multi}
        searchable={searchable}
        clearable={clearable}
        disabled={disabled}
        color="var(--theme-color-primary)"
        options={options}
        onDropdownOpen={onOpen}
        onChange={handleChange}
        noDataRenderer={noDataRenderer}
        searchFn={onSearch}
        itemRenderer={itemRenderer}
        labelField={"name"}
        valueField={"id"}
      />
    </div>
  );
};
