import { ScoreCategory } from "app/models";

export function getScoreCategoryByScore(score: number): ScoreCategory {
  if (score > 100_000_000_000_000) {
    return ScoreCategory.ID;
  }

  if (score > 100_000_000_000) {
    return ScoreCategory.EXACT;
  }

  if (score > 100_000) {
    return ScoreCategory.SIMILAR;
  }

  if (score > 2000) {
    return ScoreCategory.FUZZY;
  }

  return ScoreCategory.RELATED;
}
