import { Button } from "app/components/Button/Button";
import { Card, CardActions, CardContentSection, CardTitle } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { Checkbox } from "app/components/Checkbox/Checkbox";
import { Notifications } from "app/models/user.model";
import { updateUserNotifications } from "app/redux/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { handleUnknownError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import deepEqual from "deep-equal";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from "./SettingsPageNotifications.module.css";

export const SettingsPageNotifications = () => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [notifcations, setNotifications] = useState<Notifications>(user?.notifications || {});
  const [loading, setLoading] = useState(false);

  const isUntouched = deepEqual(notifcations, user?.notifications);

  if (!user) {
    return null;
  }

  const toggleNotification = (key: keyof Notifications) => () => {
    const currentValue = notifcations[key] ?? true;
    setNotifications({ ...notifcations, [key]: !currentValue });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      trackEvent(AppEvent.UPDATE_NOTIFICATION_PREFERENCE, {
        notifcations,
      });
      await dispatch(updateUserNotifications(notifcations)).unwrap();
      toast.success("Wir haben die Einstellung für Benachrichtigungen angepasst.");
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CardContainer className={styles.firstCard}>
      <Card>
        <CardTitle
          title="Benachrichtigungen"
          subtitle="Wählen Sie aus, welche Benachrichtigungen Sie erhalten möchten."
        />
        <CardContentSection
          title="Workspace"
          description="Wir benachrichtigen Sie, wenn etwas in Ihrem Workspace passiert."
        >
          <div className={styles.section}>
            <Checkbox
              onToggle={toggleNotification("comments")}
              name="comments"
              checked={notifcations?.comments ?? true}
              className={styles.checkbox}
              label="Neue Kommentare"
            />
          </div>
        </CardContentSection>
        <CardContentSection
          title="Markenüberwachung"
          description="Wir benachrichtigen Sie, wenn wir eine potenzielle Kollision entdecken oder halten Sie mit aktuellen Statistiken auf dem Laufenden."
        >
          <div className={styles.section}>
            <Checkbox
              name="collisions"
              checked={notifcations?.collisions ?? true}
              className={styles.checkbox}
              onToggle={toggleNotification("collisions")}
              label="Kollisionsmail"
            />

            <Checkbox
              name="no-collisions"
              checked={notifcations.statistics ?? true}
              className={styles.checkbox}
              onToggle={toggleNotification("statistics")}
              label={"Aktuelle Statistiken"}
            />
          </div>
        </CardContentSection>
        <CardContentSection
          title="Neuigkeiten"
          description="Wir informieren Sie über neue Features und wie Sie Oktomark noch effizienter Nutzen können."
        >
          <div className={styles.section}>
            <Checkbox
              name="new-features"
              checked={notifcations.newFeatures ?? true}
              className={styles.checkbox}
              onToggle={toggleNotification("newFeatures")}
              label="Neue Features"
            />

            <Checkbox
              name="tipsAndTricks"
              checked={notifcations.tipsAndTricks ?? true}
              className={styles.checkbox}
              label="Tipps und Tricks"
              onToggle={toggleNotification("tipsAndTricks")}
            />
          </div>
        </CardContentSection>
        <CardActions>
          <Button onClick={handleSubmit} loading={loading} disabled={isUntouched}>
            Einstellungen speichern
          </Button>
        </CardActions>
      </Card>
    </CardContainer>
  );
};
