import { FlaggedTrademark, FlaggedTrademarkResolved } from "app/models/flagged-trademark.model";
import { Trademark } from "app/models/trademark.model";
import { QueryType, TrademarkReferenceWithCreatedAt } from ".";
import { TrademarkComment } from "./trademark-comment.model";

export enum NotificationInterval {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

export type Monitoring = {
  uid: string;
  userId: string;
  interval: NotificationInterval;
  sensitivity: QueryType[];
  note: string;
  comments?: TrademarkComment[];
  trademark: Trademark;
  flaggedTrademarks: FlaggedTrademark[];
  collisions: TrademarkReferenceWithCreatedAt[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type MonitoringResolved = {
  uid: string;
  userId: string;
  interval: NotificationInterval;
  note: string;
  comments?: TrademarkComment[];
  trademark: Trademark;
  flaggedTrademarks: FlaggedTrademarkResolved[];
  collisions: Trademark[];
  createdAt?: Date;
  updatedAt?: Date;
};

export enum CollisonFlag {
  PROBLEMATIC = "PROBLEMATIC",
  UNPROBLEMATIC = "UNPROBLEMATIC",
  UNKNOWN = "UNKNOWN",
}

export type MonitoringReport = {
  id: string;
  trademark: Trademark;
  unflaggedTrademarks: Trademark[];
  flaggedTrademarks: FlaggedTrademark[];
};

export type MonitoringWithReport = {
  monitoring: Monitoring;
  report?: MonitoringReport;
  reportLoading: boolean;
};
