import { ICONS } from "app/assets/icons/icons";
import { NiceClass } from "app/models/trademark.model";
import { useAppSelector } from "app/redux/store.hooks";
import clsx from "clsx";
import styles from "./NiceClassDescription.module.css";

type NiceClassDescriptionProps = {
  className?: string;
  niceClass: NiceClass;
};

export const NiceClassDescription = ({ className, niceClass }: NiceClassDescriptionProps) => {
  const systemClass = useAppSelector((state) => state.nizza.map[niceClass.id]?.heading);
  const hasDescription = niceClass.description && niceClass.description !== "";

  const description = hasDescription ? niceClass.description : systemClass;
  return (
    <div className={clsx(styles.root, className)}>
      <b className={styles.id}>{niceClass.id}</b>
      <p
        className={clsx(styles.text, {
          [styles.textPre]: !hasDescription,
        })}
      >
        {description}
      </p>
      {hasDescription ? null : (
        <div className={styles.note}>
          <ICONS.ALERT_CIRCLE className={styles.icon} />
          Der tatsächlich eingetragene Text wurde nicht vom Markenamt bereitgestellt.
        </div>
      )}
    </div>
  );
};
