import { APP_CONST } from "app/assets/constants";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Popover, PopOverTheme } from "app/components/Popover/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { UserFlag } from "app/models";
import { AppModal, logoutSaga, showModal, useAppDispatch, useAppSelector } from "app/redux";
import { NO_AUTH_ROUTES, ROUTES } from "app/routes";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import clsx from "clsx";
import { useMemo } from "react";
import { IconType } from "react-icons";
import { NavLink, useNavigate } from "react-router-dom";
import styles from "./AppNavigation.module.scss";

export type NavHandlerFunction = () => unknown;

export type NavHandle = string | NavHandlerFunction;
export interface NavItem {
  to?: string;
  title: string;
  menuOnly?: boolean;
  allowedUserFlags?: UserFlag[];
  Icon: IconType;
}

type NavigationItemProps = {
  slim?: boolean;
  className?: string;
  item: NavItem;
  highlights?: number;
  onClick?: () => unknown;
};

const NavigationItem = ({ slim, item, highlights, onClick, className }: NavigationItemProps) => {
  const navElement = (
    <NavLink
      key={item.title}
      className={({ isActive }) =>
        clsx(
          styles.menuLink,
          {
            [styles.menuLinkActive]: isActive && item.to,
            [styles.menuLinkSlim]: slim,
            [styles.isBeta]: item?.allowedUserFlags?.includes(UserFlag.BETA_FEATURES),
            [styles.isTestAccount]: item?.allowedUserFlags?.includes(UserFlag.TEST_ACCOUNT),
            [styles.isAdmin]: item?.allowedUserFlags?.includes(UserFlag.PLATFORM_ADMIN),
          },
          className,
        )
      }
      end={item.to === "/"}
      to={item.to ? item.to : "#"}
      onClick={onClick}
    >
      {item.Icon ? (
        <span className={styles.linkIcon}>
          <item.Icon size={ICON_SIZE.DEFAULT} />
        </span>
      ) : null}
      {slim ? null : (
        <>
          <span>{item.title}</span>
          {highlights ? <span className={styles.highlights}>{highlights > 100 ? `99+` : highlights}</span> : null}
        </>
      )}
    </NavLink>
  );

  return slim ? (
    <Popover
      closeOnOutSideClick={false}
      buttonElement={() => navElement}
      showOnHover={true}
      hoverOnly={true}
      placement="right"
      theme={PopOverTheme.PRIMARY}
    >
      <PopoverContent className={styles.popoverContent}>
          <span>{item.title}</span>
          {highlights ? <span className={styles.highlights}>{highlights > 100 ? `99+` : highlights}</span> : null}
      </PopoverContent>
    </Popover>
  ) : (
    navElement
  );
};

interface AppNavigationContentProps {
  navItems: NavItem[];
  slim?: boolean;
}

export const AppNavigationContent = ({ navItems, slim }: AppNavigationContentProps) => {
  const collisions = useAppSelector((state) =>
    state.monitoring.items.reduce((acc, tm) => acc + tm.collisions.length, 0),
  );
  const user = useAppSelector((state) => state.auth.user);
  const appLinks = useMemo(
    () =>
      navItems.filter(
        ({ allowedUserFlags = [] }) =>
          allowedUserFlags.length === 0 || user?.flags?.some((f) => allowedUserFlags.includes(f)),
      ),
    [navItems, user?.flags],
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openFeedbackModal = () =>
    showModal(dispatch)({
      type: AppModal.FEEDBACK,
    });

  const logout = async () => {
    trackEvent(AppEvent.LOGOUT_VIA_MAIN_NAVIGATION);
    await logoutSaga({
      hardRefresh: false,
    });
    navigate(NO_AUTH_ROUTES.LOGOUT_SUCCESS);
  };

  const logoutNav = {
    title: "Logout",
    Icon: ICONS.LOGOUT,
  };

  const feedbackNav = {
    title: "Feedback",
    Icon: ICONS.FEEDBACK,
  };

  return (
    <>
      {appLinks.map((item) => {
        let highlights = undefined;

        if (typeof item.to === "string" && item.to === ROUTES.MONITORING.path) {
          highlights = collisions;
        }
        return <NavigationItem slim={slim} key={item.title} item={item} highlights={highlights} />;
      })}
      <div className={styles.menuSeparator} />
      <NavigationItem slim={slim} item={logoutNav} onClick={logout} />
      <div className={styles.menuSeparator} />
      <NavigationItem
        slim={slim}
        item={feedbackNav}
        onClick={openFeedbackModal}
        className={clsx("tutorial-feedback", styles.feedbackNavItem)}
      />
    </>
  );
};

type Props = {
  navItems: NavItem[];
};

export const AppNavigation = ({ navItems }: Props) => {
  return (
    <Popover
      placement="bottom-end"
      theme={PopOverTheme.PRIMARY}
      offset={8}
      onOpen={() => trackEvent(AppEvent.OPEN_MAIN_NAVIGATION)}
      buttonElement={(open) => (
        <button className={clsx("tutorial-navigation", styles.button)}>
          <div>
            <img className={styles.brandLogo} src={APP_CONST.IMAGES.OKTO} alt="" />
          </div>
          <ICONS.APP_MENU className={styles.menuIcon} size={"24px"} />
        </button>
      )}
    >
      <div className={styles.menu}>
        <AppNavigationContent navItems={navItems} />
      </div>
    </Popover>
  );
};
