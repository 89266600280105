import { emoji } from "app/util/emoji.util";
import { isDevelopmentEnvironment } from "app/util/env.util";

class CustomLogger {
  private icon;
  private enabled = false;

  constructor(icon: any) {
    this.icon = icon;
    this.enabled = isDevelopmentEnvironment();
  }

  log(message?: any, ...optionalParams: any[]) {
    if (this.enabled) {
      console.log(this.icon, message, ...optionalParams);
    }
  }

  error(message?: any, ...optionalParams: any[]) {
    if (this.enabled) {
      console.error(this.icon, message, ...optionalParams);
    }
  }

  warn(message?: any, ...optionalParams: any[]) {
    if (this.enabled) {
      console.warn(this.icon, message, ...optionalParams);
    }
  }
}

export const appLogger = new CustomLogger(emoji.OKTOPUS);
export const websocketLogger = new CustomLogger(emoji.GREEN_CIRCLE);
