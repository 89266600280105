import { AiOutlineCrown } from "react-icons/ai";
import { BiCheckbox, BiCheckboxChecked, BiGridSmall } from "react-icons/bi";
import { FaPencilAlt, FaRegFolderOpen } from "react-icons/fa";
import { GiPaintBucket } from "react-icons/gi";
import { IoMdHelpCircleOutline } from "react-icons/io";
import {
  IoAdd,
  IoAlertCircleOutline,
  IoAlertOutline,
  IoArrowDownCircleOutline,
  IoBookmarkOutline,
  IoBug,
  IoBuildOutline,
  IoBulb,
  IoBusinessOutline,
  IoCameraOutline,
  IoCheckmarkOutline,
  IoChevronBack,
  IoChevronDown,
  IoChevronForward,
  IoChevronUp,
  IoCloseOutline,
  IoColorPaletteOutline,
  IoCrop,
  IoEllipsisVertical,
  IoEyeOff,
  IoFileTrayFullOutline,
  IoFilterOutline,
  IoFlask,
  IoGrid,
  IoGridOutline,
  IoHelpOutline,
  IoHomeOutline,
  IoImageOutline,
  IoJournalOutline,
  IoLinkOutline,
  IoLogOutOutline,
  IoMegaphoneOutline,
  IoMenu,
  IoMoonOutline,
  IoNewspaperOutline,
  IoNotifications,
  IoOpenOutline,
  IoOptions,
  IoPeople,
  IoPerson,
  IoPersonOutline,
  IoPrint,
  IoReorderFourOutline,
  IoSaveOutline,
  IoSearchOutline,
  IoShareOutline,
  IoShieldCheckmarkOutline,
  IoStatsChart,
  IoStorefrontOutline,
  IoSunnyOutline,
  IoTelescopeOutline,
  IoTerminal,
  IoTrashOutline,
  IoUnlinkOutline,
} from "react-icons/io5";
import { MdOutlineComment, MdOutlinePersonSearch, MdOutlineSortByAlpha } from "react-icons/md";
import { TiSortNumerically } from "react-icons/ti";
import { VscChromeMaximize, VscChromeMinimize } from "react-icons/vsc";

export const ICON_SIZE = {
  SMALL: 16,
  DEFAULT: 20,
  LARGE: 32,
};

export const ICONS = {
  ACCOUNT: IoPerson,
  ADD: IoAdd,
  ADMIN: IoTerminal,
  ALERT: IoAlertOutline,
  ALERT_CIRCLE: IoAlertCircleOutline,
  APP_MENU: IoMenu,
  BOOKMARK: IoBookmarkOutline,
  BULB: IoBulb,
  CAMERA: IoCameraOutline,
  CHART: IoStatsChart,
  CHECKBOX: BiCheckboxChecked,
  CHECKMARK: IoCheckmarkOutline,
  CHEVRON_DOWN: IoChevronDown,
  CHEVRON_LEFT: IoChevronBack,
  CHEVRON_RIGHT: IoChevronForward,
  CHEVRON_UP: IoChevronUp,
  CLASSIFICATION: IoReorderFourOutline,
  CLOSE: IoCloseOutline,
  COLLAPSE: IoChevronDown,
  COLLECTION: FaRegFolderOpen,
  COLLECTIONS: FaRegFolderOpen,
  COLOR: GiPaintBucket,
  COMPANY: IoBusinessOutline,
  CROP: IoCrop,
  DARK_THEME: IoMoonOutline,
  DASHBOARD: IoGrid,
  DEBUG: IoBug,
  DELETE: IoTrashOutline,
  DOWNLOAD: IoArrowDownCircleOutline,
  DRAG: BiGridSmall,
  EDIT: FaPencilAlt,
  EMPTY_CHECKBOX: BiCheckbox,
  EXPAND: IoChevronUp,
  EXPERIMENT: IoFlask,
  EXPORT: IoPrint,
  EXTERNAL_LINK: IoOpenOutline,
  FEEDBACK: IoMegaphoneOutline,
  FILE_TRAY: IoFileTrayFullOutline,
  FILTER: IoFilterOutline,
  GRID: IoGridOutline,
  HELP: IoMdHelpCircleOutline,
  HOME: IoHomeOutline,
  IGNORE: IoEyeOff,
  IMAGE: IoImageOutline,
  INTERFACE: IoColorPaletteOutline,
  LIGHT_THEME: IoSunnyOutline,
  LINK: IoLinkOutline,
  LOGOUT: IoLogOutOutline,
  MAXIMIZE: VscChromeMaximize,
  MENU: IoEllipsisVertical,
  MINIMIZE: VscChromeMinimize,
  MONITORING: IoShieldCheckmarkOutline,
  MONITORINGS: IoShieldCheckmarkOutline,
  NOTE: MdOutlineComment,
  NOTIFICATION: IoNotifications,
  NUMBERS: TiSortNumerically,
  OPTIONS: IoOptions,
  PARTNER: IoStorefrontOutline,
  PAYMENT: IoJournalOutline,
  PERSON: IoPersonOutline,
  PORTFOLIO: MdOutlinePersonSearch,
  PORTFOLIOS: MdOutlinePersonSearch,
  RESEARCH: IoTelescopeOutline,
  RESEARCHES: IoTelescopeOutline,
  SAVED_SEARCHES: IoSaveOutline,
  SEARCH: IoSearchOutline,
  SETTINGS: IoBuildOutline,
  SHARE: IoShareOutline,
  SORT: MdOutlineSortByAlpha,
  TRADEMARK: AiOutlineCrown,
  UNKNOWN: IoHelpOutline,
  UNLINK: IoUnlinkOutline,
  UPDATES: IoNewspaperOutline,
  WORKSPACE: IoPeople,
};
