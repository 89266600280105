import { ICONS } from "app/assets/icons/icons";
import { CardWithActions } from "app/components/CardWithActions/CardWithActions";
import { AddTrademarkToCollectionModalView } from "app/components/Modals/AddTrademarkToCollectionModal/AddTrademarkToCollectionModal";
import { NiceToolTipButton } from "app/components/TrademarkCard/ToolTipButton/NiceToolTipButton";
import { ViennaToolTipButton } from "app/components/TrademarkCard/ToolTipButton/ViennaToolTipButton";
import {
  createActionMapWithOverrides,
  TrademarkActionConfig,
  TrademarkActionConfigMap,
  TrademarkActions,
  TrademarkActionType,
} from "app/components/TrademarkCard/TrademarkActions/TrademarkActions";
import { TrademarkBadges } from "app/components/TrademarkCard/TrademarkBadges/TrademarkBadges";
import { useActiveContext, useDebounce, useIsAdmin } from "app/hooks";
import { useAppDarkMode } from "app/hooks/use-app-dark-mode";
import { useQuickSearch } from "app/hooks/use-quick-search.hook";
import { ContextType, TrademarkReference } from "app/models";
import { Trademark } from "app/models/trademark.model";
import {
  addTrademarksToCollection,
  addTrademarksToPortfolio,
  addTrademarksToResearch,
  removeTrademarksFromCollection,
  removeTrademarksFromPortfolio,
  removeTrademarksFromResearch,
} from "app/redux";
import { AppModal, showModal } from "app/redux/slices/modal.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { trademarkDetailRoute } from "app/routes";
import { ellipsify } from "app/util/string.util";
import { matchesTrademark } from "app/util/trademark-reference.util";
import { hasVisual, shareTrademark } from "app/util/trademark.util";
import clsx from "clsx";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { isMobile } from "react-device-detect";
import { NavLink } from "react-router-dom";
import { Checkbox } from "../Checkbox/Checkbox";
import { AddTrademarkToPortfolioModalView } from "../Modals/AddTrademarkToPortfolioModal/AddTrademarkToPortfolioModal";
import { AddTrademarkToResearchModalView } from "../Modals/AddTrademarkToResearchModal/AddTrademarkToResearchModal";
import { TrademarkImage } from "../TrademarkImage/TrademarkImage";
import styles from "./TrademarkCard.module.scss";

export enum TrademarkCardDisplayType {
  CARD = "CARD",
  COMPACT = "COMPACT",
  IMAGE = "IMAGE",
}

type TrademarkCardProps = {
  className?: string;
  trademark: Trademark;
  ignored?: boolean;
  onToggleIgnore?: () => void;
  hideLink?: boolean;
  hideActions?: boolean;
  noBg?: boolean;
  displayType?: TrademarkCardDisplayType;
  actions?: TrademarkActionConfigMap;
  children?: React.ReactNode;

  // Select
  isSelectable?: boolean;
  onToggleSelect?: (selected: boolean) => void;

  // Drag & drop
  isDraggable?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

type ControlsProps = {
  className?: string;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

const Controls = ({ children, className, dragHandleProps }: PropsWithChildren<ControlsProps>) => {
  return (
    <div className={clsx(styles.controlsWrapper, className)} {...dragHandleProps}>
      {children}
    </div>
  );
};

export const TrademarkCard = ({
  className,
  children,
  trademark,
  ignored,
  hideLink,
  onToggleIgnore,
  actions = new Map(),
  hideActions,
  displayType = TrademarkCardDisplayType.CARD,
  noBg,
  isSelectable,
  onToggleSelect,
  isDraggable,
  dragHandleProps,
}: TrademarkCardProps) => {
  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();
  const darkMode = useAppDarkMode();
  const monitorings = useAppSelector((state) => state.monitoring.items);
  const context = useActiveContext();
  const { searchByTrademarkName, searchByTrademarkOwner, searchByTrademarkRepresentative } = useQuickSearch();

  const activeResearch = context?.type === ContextType.RESEARCH ? context.data.research : undefined;
  const activeCollection = context?.type === ContextType.COLLECTION ? context.data.collection : undefined;
  const activePortfolio = context?.type === ContextType.PORTFOLIO ? context.data.portfolio : undefined;
  const showSelect = isSelectable && context;

  const contextTrademarks = useMemo(() => {
    switch (context?.type) {
      case ContextType.RESEARCH:
        return activeResearch?.trademarks || [];
      case ContextType.COLLECTION:
        return activeCollection?.trademarks || [];
      case ContextType.PORTFOLIO:
        return activePortfolio?.trademarks || [];
      default:
        return [];
    }
  }, [activeCollection?.trademarks, activePortfolio?.trademarks, activeResearch?.trademarks, context?.type]);

  // XXX: Keep own selected state for optimistic updates and snappy UI ...
  const [isSelected, setSelected] = useState(containsTrademark(contextTrademarks, trademark));

  // XXX: ... but reset selected state on websocket update
  useEffect(() => {
    setSelected(containsTrademark(contextTrademarks, trademark));
  }, [trademark, contextTrademarks]);

  const disableBuyButton = monitorings.some((item) => matchesTrademark(trademark)(item.trademark));

  const handleSelect = async (selected: boolean) => {
    if (onToggleSelect) {
      onToggleSelect(selected);
    }

    if (activeCollection) {
      const payload = {
        id: activeCollection.uid,
        trademarks: [
          {
            trademarkId: trademark.id,
            trademarkOffice: trademark.office,
          },
        ],
      };

      if (selected) {
        await dispatch(addTrademarksToCollection(payload)).unwrap();
      } else {
        await dispatch(removeTrademarksFromCollection(payload)).unwrap();
      }

      return;
    }

    if (activeResearch) {
      const payload = {
        id: activeResearch.uid,
        trademarks: [
          {
            trademarkId: trademark.id,
            trademarkOffice: trademark.office,
          },
        ],
      };

      if (selected) {
        await dispatch(addTrademarksToResearch(payload)).unwrap();
      } else {
        await dispatch(removeTrademarksFromResearch(payload)).unwrap();
      }

      return;
    }

    if (activePortfolio) {
      const payload = {
        id: activePortfolio.uid,
        trademarks: [
          {
            trademarkId: trademark.id,
            trademarkOffice: trademark.office,
          },
        ],
      };
      if (selected) {
        await dispatch(addTrademarksToPortfolio(payload)).unwrap();
      } else {
        await dispatch(removeTrademarksFromPortfolio(payload)).unwrap();
      }

      return;
    }
  };

  const debouncedhandleSelect = useDebounce(handleSelect, 300);

  const handleSelectToggle = () => {
    const newValue = !isSelected;
    setSelected(newValue);
    debouncedhandleSelect(newValue);
  };

  const showNiceClassModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    showModal(dispatch)({
      type: AppModal.NICE_CLASS,
      trademark,
    });
  };

  const showViennaClassModal = (e: React.MouseEvent) => {
    e.stopPropagation();
    showModal(dispatch)({
      type: AppModal.VIENNA_CLASS,
      trademark,
    });
  };

  const showAddToCollectionModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_COLLECTION,
      props: {
        defaultView: AddTrademarkToCollectionModalView.ADD,
        trademarks: [trademark],
      },
    });
  };

  const showAddToResearchModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_RESEARCH,
      props: {
        defaultView: AddTrademarkToResearchModalView.ADD,
        trademarks: [trademark],
      },
    });
  };

  const showAddToPortfolioModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_PORTFOLIO,
      props: {
        defaultView: AddTrademarkToPortfolioModalView.ADD,
        trademarks: [trademark],
      },
    });
  };

  const openCreateMonitoringModal = () => {
    showModal(dispatch)({
      type: AppModal.CREATE_MONITORING,
      props: {
        trademark,
      },
    });
  };

  const showDebugModal = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: trademark,
      },
    });
  };

  const detailLink = () => {
    const path = trademarkDetailRoute({
      id: trademark.id,
      office: trademark.office,
    });
    const name = trademark.name || "-";

    const heading = (
      <h4
        className={clsx(styles.name, {
          [styles.nameCompact]: isCompactDisplay,
        })}
      >
        {name}
      </h4>
    );

    if (hideLink) {
      return heading;
    }

    return (
      <NavLink
        className={clsx(styles.nameLink, {
          [styles.nameCompact]: isCompactDisplay,
        })}
        to={path}
      >
        {heading}
      </NavLink>
    );
  };

  const getText = () => {
    const defaultNiceClass = trademark.niceClasses.find((niceClass) => niceClass.default);
    const firstNiceClass = trademark.niceClasses.find(Boolean);

    const text = defaultNiceClass ? defaultNiceClass.description : firstNiceClass ? firstNiceClass.description : "";

    if (!text) {
      return null;
    }

    return (
      <div className={styles.text}>
        {text.length > 140 ? text.substr(0, 140 - 1) : text}
        {text.length > 140 && <span>&hellip;</span>}
      </div>
    );
  };

  const getOwnerLinks = () => {
    return (
      <div className={styles.owner}>
        {trademark.owners.map((o, i) => (
          <button key={`${o.name}_${i}`} className={styles.applicant} onClick={searchByTrademarkOwner(o.name)}>
            {o.name}
          </button>
        ))}
        {trademark.representatives.map((r, i) => (
          <button
            className={styles.representative}
            key={`${r.name}_${i}`}
            onClick={searchByTrademarkRepresentative(r.name)}
          >
            {r.name}
          </button>
        ))}
      </div>
    );
  };

  const defaultActions = new Map<TrademarkActionType, TrademarkActionConfig>([
    [
      TrademarkActionType.CREATE_MONITORING,
      {
        onClick: openCreateMonitoringModal,
        enabled: !disableBuyButton,
        ...actions.get(TrademarkActionType.CREATE_MONITORING),
      },
    ],
    [
      TrademarkActionType.ADD_NOTE,
      {
        enabled: false,
        ...actions.get(TrademarkActionType.ADD_NOTE),
      },
    ],
    [
      TrademarkActionType.SEARCH_BY_NAME,
      {
        onClick: searchByTrademarkName(trademark),
        title: `Nach ${ellipsify(trademark.name, 10)} suchen`,
        ...actions.get(TrademarkActionType.SEARCH_BY_NAME),
      },
    ],
    [
      TrademarkActionType.ADD_TO_THIS_COLLECTION,
      {
        enabled: false,
        ...actions.get(TrademarkActionType.ADD_TO_THIS_COLLECTION),
      },
    ],
    [
      TrademarkActionType.ADD_TO_THIS_RESEARCH,
      {
        enabled: false,
        ...actions.get(TrademarkActionType.ADD_TO_THIS_RESEARCH),
      },
    ],
    [
      TrademarkActionType.ADD_TO_THIS_PORTFOLIO,
      {
        enabled: false,
        ...actions.get(TrademarkActionType.ADD_TO_THIS_PORTFOLIO),
      },
    ],
    [
      TrademarkActionType.ADD_TO_COLLECTION,
      {
        onClick: showAddToCollectionModal,
        ...actions.get(TrademarkActionType.ADD_TO_COLLECTION),
      },
    ],
    [
      TrademarkActionType.ADD_TO_RESEARCH,
      {
        onClick: showAddToResearchModal,
        ...actions.get(TrademarkActionType.ADD_TO_RESEARCH),
      },
    ],
    [
      TrademarkActionType.ADD_TO_PORTFOLIO,
      {
        onClick: showAddToPortfolioModal,
        ...actions.get(TrademarkActionType.ADD_TO_PORTFOLIO),
      },
    ],
    [
      TrademarkActionType.IGNORE_TRADEMARK,
      {
        onClick: onToggleIgnore,
        enabled: Boolean(onToggleIgnore),
        ...actions.get(TrademarkActionType.IGNORE_TRADEMARK),
      },
    ],
    [
      TrademarkActionType.SHARE,
      {
        onClick: () => shareTrademark(trademark),
      },
    ],
    [
      TrademarkActionType.EXPORT,
      {
        //TODO: make export possible everywhere
        enabled: false,
      },
    ],
    [
      TrademarkActionType.DEBUG,
      {
        onClick: showDebugModal,
        enabled: isAdmin,
      },
    ],
  ]);

  const actionsConfig = createActionMapWithOverrides(defaultActions, actions);

  const viennaClasses = useMemo(
    () => (trademark.viennaClasses || []).map((vc) => <ViennaToolTipButton key={vc} vc={vc} />),
    [trademark.viennaClasses],
  );

  const hasViennaClasses = trademark.viennaClasses.length > 0;
  const hasNiceClasses = trademark.niceClasses.length > 0;

  const niceClasses = useMemo(
    () => trademark.niceClasses.map((nc) => <NiceToolTipButton key={nc.id} nc={nc} />),
    [trademark.niceClasses],
  );

  const isCardDisplay = displayType === TrademarkCardDisplayType.CARD;
  const isCompactDisplay = displayType === TrademarkCardDisplayType.COMPACT;
  const isImageDisplay = displayType === TrademarkCardDisplayType.IMAGE;

  const maybeActions = hideActions ? null : (
    <TrademarkActions config={actionsConfig} visibleLimit={isCompactDisplay ? 1 : 5} horizontal={isImageDisplay} />
  );

  const badges = <TrademarkBadges trademark={trademark} compact={isCompactDisplay} />;

  const nizzaClassesElement = (
    <>
      {hasNiceClasses ? (
        <div
          className={clsx(styles.goodsClassWrapper, {
            [styles.goodsClassWrapperCompact]: isCompactDisplay,
          })}
          onClick={showNiceClassModal}
        >
          {niceClasses}
        </div>
      ) : null}
    </>
  );

  const viennaClassesElement = (
    <>
      {hasViennaClasses ? (
        <div
          className={clsx(styles.goodsClassWrapper, {
            [styles.goodsClassWrapperCompact]: isCompactDisplay,
          })}
          onClick={showViennaClassModal}
        >
          {viennaClasses}
        </div>
      ) : null}
    </>
  );

  const image = <TrademarkImage trademark={trademark} thumbnail />;

  const maybeChildren = children ? <div className={styles.children}> {children}</div> : null;

  const controls = showSelect ? (
    <Controls
      className={clsx({
        [styles.controlsWrapperCompact]: isCompactDisplay,
        [styles.controlsWrapperImage]: isImageDisplay,
      })}
    >
      <Checkbox checked={isSelected} onToggle={handleSelectToggle} />
    </Controls>
  ) : isDraggable && dragHandleProps ? (
    <Controls
      dragHandleProps={dragHandleProps}
      className={clsx({
        [styles.controlsWrapperCompact]: isCompactDisplay,
        [styles.controlsWrapperImage]: isImageDisplay,
      })}
    >
      <ICONS.DRAG />
    </Controls>
  ) : null;

  const content = (
    <>
      {controls}
      <div className={styles.detailWrapper}>
        {detailLink()}
        {getOwnerLinks()}
        {badges}
        <div className={styles.mainContent}>
          {hasVisual(trademark.type) ? (
            <div
              className={clsx(styles.imageWrapper, {
                [styles.imageDarkmode]: darkMode.value,
              })}
            >
              {image}
            </div>
          ) : null}
          <div
            className={clsx(styles.contentRight, {
              [styles.contentRightPadded]: !isMobile && hasVisual(trademark.type),
            })}
          >
            {getText()}
            {nizzaClassesElement}
            {viennaClassesElement}
          </div>
        </div>
        {maybeChildren}
      </div>
    </>
  );

  const contentCompact = (
    <>
      {controls}
      {hasVisual(trademark.type) ? (
        <div
          className={clsx(styles.imageWrapperCompact, {
            [styles.imageDarkmode]: darkMode.value,
          })}
        >
          {image}
        </div>
      ) : null}
      <div className={styles.descriptionCompact}>
        {detailLink()}
        {/* {getOwnerLinks()} */}
        {nizzaClassesElement}
        {badges}
        {maybeChildren}
      </div>
    </>
  );

  const contentImage = (
    <>
      <div
        className={clsx(styles.imageWrapperImage, {
          [styles.imageDarkmode]: darkMode.value,
        })}
      >
        {image}
      </div>
      {controls}
      <div className={styles.descriptionImage}>
        {detailLink()}
        {badges}
        {/* {getOwnerLinks()} */}
        {nizzaClassesElement}
        {viennaClassesElement}
        {maybeChildren}
      </div>
    </>
  );

  return (
    <CardWithActions
      className={clsx(
        styles.root,
        {
          [styles.rootCompact]: isCompactDisplay,
          [styles.rootImage]: isImageDisplay,
          [styles.ignored]: ignored,
          [styles.noBg]: noBg,
          [styles.isSelected]: isSelected,
          [styles.hasControls]: showSelect || isDraggable,
          [styles.canSelect]: showSelect,
        },
        className,
      )}
      contentClassName={clsx(styles.content, {
        [styles.contentCompact]: isCompactDisplay,
        [styles.contentImage]: isImageDisplay,
      })}
      actionsClassName={clsx({
        [styles.actionsImage]: isImageDisplay,
      })}
      actions={maybeActions}
      onClick={showSelect ? handleSelectToggle : undefined}
      title={showSelect ? (isSelected ? "Marke entfernen" : "Marke hinzufügen") : undefined}
    >
      {isCardDisplay ? content : null}
      {isCompactDisplay ? contentCompact : null}
      {isImageDisplay ? contentImage : null}
    </CardWithActions>
  );
};

const containsTrademark = (trademarks: TrademarkReference[], trademark: Trademark) =>
  trademarks.some(matchesTrademark(trademark));
