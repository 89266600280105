import { User } from "app/models";
import { emoji } from "app/util/emoji.util";
import { isDevelopmentEnvironment, isDevOrDebug } from "app/util/env.util";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const loggingEnabled = true;

type AuthResponse = {
  user: User;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
};

export const apiUrl = () => {
  const prod = "https://api.oktomark.de";
  const dev = `http://${window.location.hostname}:8080`;

  // if (process && process.env.REACT_APP_API_ENV && process.env.REACT_APP_API_ENV === "production") {
  //   return prod;
  // }

  // console.log(process);

  if (isDevelopmentEnvironment()) {
    return dev;
  }

  return prod;
};

type ClientOptions = { preventRefresh?: boolean; basePath?: string; withCredentials?: boolean };

const requestStart = new Map<string, number>();

export const refreshTokens = async () => {
  const client = getClient({
    withCredentials: true,
    preventRefresh: true,
  });
  const { data } = await client.post<AuthResponse>(
    `/auth/refresh`,
    {},
    {
      withCredentials: true,
    },
  );
  return data;
};

const TokenRefreshResponseInterceptor: ((error: any) => any) | undefined = async function (error) {
  if (!error || !error.response || !error.config) {
    return Promise.reject(error);
  }

  // console.log("API Response not 2xx", error.response, error.config);
  const url = error.response.config.url;
  const status = error.response.status;
  const isRetry = error.config._retry;
  if (status === 401 && !url.includes("/auth/refresh") && !isRetry) {
    error.config._retry = true;
    try {
      await refreshTokens();
      return axios(error.config);
    } catch (error) {
      console.error("Failed to refresh access token on demand", error);
    }
  }
  return Promise.reject(error);
};

const RequestInterceptor = (request: AxiosRequestConfig) => {
  if (loggingEnabled) {
    const uri = axios.getUri(request);
    const path = getPath(request.baseURL);

    const url = uri || path;
    requestStart.set(url, Date.now());
  }
  return request;
};

const getPath = (baseUrl?: string) => {
  return baseUrl?.replace(apiUrl(), "") || "";
};

const ResponseInterceptor = (response: AxiosResponse) => {
  const {
    status,
    config: { method },
  } = response;
  if (loggingEnabled) {
    const uri = axios.getUri(response.config);
    const path = getPath(response.config.baseURL);
    const url = uri || path;
    const reqTime = Date.now() - (requestStart.get(url) || 0);
    console.groupCollapsed(emoji.GLOBE, method?.toUpperCase(), url, status, reqTime, "ms");
    console.log(response);
    console.groupEnd();
  }
  return response;
};

const defaultClientOptions = { preventRefresh: false, basePath: "", withCredentials: true };

export const getClient = (options?: ClientOptions) => {
  const { preventRefresh, basePath, withCredentials } = { ...defaultClientOptions, ...options };

  const axiosInstance = axios.create({
    baseURL: apiUrl() + basePath,
    withCredentials,
  });

  if (!preventRefresh) {
    axiosInstance.interceptors.response.use(undefined, TokenRefreshResponseInterceptor);
  }

  if (isDevOrDebug()) {
    axiosInstance.interceptors.request.use(RequestInterceptor);
    axiosInstance.interceptors.response.use(ResponseInterceptor);
  }
  return axiosInstance;
};
