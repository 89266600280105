import { createAnimatedRoute } from "app/animated-route";
import { AppNavigationWrapper } from "app/components/AppNavigationWrapper/AppNavigationWrapper";
import { BreadCrumbs } from "app/components/BreadCrumbs/BreadCrumbs";
import { ImageSearchOverlay } from "app/components/ImageSearchOverlay/ImageSearchOverlay";
import { ModalsContainer } from "app/components/Modals/ModalsContainer";
import { useAppSearchParams, useHasUserFlag, useInvitePendingModal, useIsAdmin } from "app/hooks";
import { useAppDarkMode } from "app/hooks/use-app-dark-mode";
import { NoAuthLayout } from "app/layouts/NoAuthLayout/NoAuthLayout";
import { UserFlag } from "app/models/user.model";
import { AppModal, restoreSession, showModal, useAppDispatch, useAppSelector } from "app/redux";
import { ROUTES, withNestedRoutes } from "app/routes";
import { AppLoadingView } from "app/views/AppLoadingView/AppLoadingView";
import { ViennaClassesView } from "app/views/ClassesView/ViennaClassesView";
import { CollectionsView } from "app/views/CollectionsView/CollectionsView";
import { DashboardView } from "app/views/DashboardView/DashboardView";
import { MonitoringView } from "app/views/MonitoringView/MonitoringView";
import { NoAuthView } from "app/views/NoAuthView/NoAuthView";
import { SignupPendingCard } from "app/views/NoAuthView/SignupPendingCard/SignupPendingCard";
import { PartnerView } from "app/views/PartnerView/PartnerView";
import { SearchView } from "app/views/SearchView/SearchView";
import { SettingsView } from "app/views/SettingsView/SettingsView";
import { TrademarkBuilderView } from "app/views/TrademarkBuilderView/TrademarkBuilderView";
import { TrademarkDetailView } from "app/views/TrademarkDetailView/TrademarkDetailView";
import { UpdatesView } from "app/views/UpdatesView/UpdatesView";
import { AnimatePresence } from "framer-motion";
import { lazy, Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ContextFooter } from "./components/ContextFooter/ContextFooter";
import { LoadingIndicator } from "./components/LoadingIndicator/LoadingIndicator";
import styles from "./RootView.module.scss";
import { UpsellingBanner } from "./views/DashboardView/UpsellingBanner/UpsellingBanner";
import { PortfoliosView } from "./views/PortfoliosView/PortfoliosView";
import { ResearchDetail } from "./views/ResearchesView/ResearchDetail/ResearchDetail";
import { SettingsPageNotifications } from "./views/SettingsView/SettingsPageNotifications/SettingsPageNotifications";
import { SettingsPagePayment } from "./views/SettingsView/SettingsPagePayment/SettingsPagePayment";
const AdminView = lazy(() => import("./views/AdminView/AdminView"));

const Paywall = () => {
  return (
    <div className={styles.paywall}>
      <UpsellingBanner />
      <SettingsPagePayment />
      <SettingsPageNotifications />
    </div>
  );
};

export const RootView = () => {
  const { isInTrial, needsSubscription } = useAppSelector((state) => state.payment);
  const isAdmin = useIsAdmin();
  const hasPendingSignup = useHasUserFlag(UserFlag.PENDING_SIGNUP);
  //needed to initialize dark mode handlers
  const { value: darkMode } = useAppDarkMode();
  const { user, loading } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [{ redirect }] = useAppSearchParams({
    redirect: "",
  });

  useEffect(() => {
    const themeTags = ['meta[name="msapplication-TileColor"]', 'meta[name="theme-color"]'];

    for (const tag of themeTags) {
      const current = document.querySelector(tag);
      current?.setAttribute("content", darkMode ? "#121212" : "#f6f7f8");
    }
  }, [darkMode]);

  useInvitePendingModal((pendingInvite) => {
    if (!pendingInvite) {
      return;
    }

    return showModal(dispatch)({
      type: AppModal.PENDING_INVITE,
      props: {
        pendingInvite,
      },
    });
  });

  useEffect(() => {
    restoreSession()(dispatch);
  }, [dispatch]);

  if (loading) {
    return <AppLoadingView />;
  }

  if (!user) {
    return <NoAuthView />;
  }

  if (hasPendingSignup) {
    return (
      <NoAuthLayout>
        <SignupPendingCard />
      </NoAuthLayout>
    );
  }

  const r = [
    {
      path: withNestedRoutes(ROUTES.VIENNA_CLASSES.path),
      element: <ViennaClassesView />,
    },
    {
      path: withNestedRoutes(ROUTES.TRADEMARK_BUILDER.path),
      element: <TrademarkBuilderView />,
    },
    {
      path: withNestedRoutes(ROUTES.SEARCH.path),
      element: <SearchView />,
    },
    {
      path: withNestedRoutes(ROUTES.TRADEMARK_DETAIL.path),
      element: <TrademarkDetailView />,
    },
    {
      path: withNestedRoutes(ROUTES.COLLECTION.path),
      element: <CollectionsView />,
    },
    {
      path: withNestedRoutes(ROUTES.PORTFOLIO.path),
      element: <PortfoliosView />,
    },
    {
      path: withNestedRoutes(ROUTES.MONITORING.path),
      element: <MonitoringView />,
    },
    {
      path: withNestedRoutes(ROUTES.PARTNER.path),
      element: <PartnerView />,
    },
    {
      path: withNestedRoutes(ROUTES.SETTINGS.path),
      element: <SettingsView />,
    },
    {
      path: withNestedRoutes(ROUTES.UPDATES.path),
      element: <UpdatesView />,
    },
    {
      path: withNestedRoutes(`${ROUTES.PORTFOLIO.path}/:portfolioId/research/:researchId`),
      element: <ResearchDetail />,
    },
    {
      path: ROUTES.DASHBOARD.path,
      element: <DashboardView />,
    },
  ];

  // XXX: Paywall logic (aka. workspace needs a subscription and user is not in trial phase)
  //
  // We do not use an overlay to avoid developer tools hacks.
  // Also we have to make sure to keep the same ModalContainer rendered all the time
  // to avoid the unmounting of the UpsellingModal during the upselling process.
  return (
    <>
      <AppNavigationWrapper>
        <div className={styles.content}>
          {needsSubscription && !isInTrial ? (
            <Paywall />
          ) : (
            <>
              <BreadCrumbs />
              <AnimatePresence>
                <Routes key={location.pathname}>
                  {isAdmin ? (
                    <Route
                      path={withNestedRoutes(ROUTES.ADMIN.path)}
                      element={
                        <Suspense fallback={<LoadingIndicator />}>
                          <AdminView />
                        </Suspense>
                      }
                    />
                  ) : null}
                  {r.map(createAnimatedRoute)}
                  {/* redirect is not animated */}
                  <Route path="*" element={<Navigate to={redirect || ROUTES.DASHBOARD.path} />} />
                </Routes>
              </AnimatePresence>
              <ContextFooter />
            </>
          )}
        </div>
      </AppNavigationWrapper>
      <ImageSearchOverlay />
      <ModalsContainer />
    </>
  );
};

export default RootView;
