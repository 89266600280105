import { getClient } from "app/api";
import { TrademarkOffice } from "app/models/trademark.model";

export const getStatisticCharts = async () => {
  const client = await getClient();
  const { data } = await client.get<{ title: string; office: TrademarkOffice; description: string }[]>("/statistics");
  const images = await Promise.all(data.map((_, i) => getStatisticChart(i)));

  return data.map((d, i) => ({
    ...d,
    image: images[i],
  }));
};

const getStatisticChart = async (index: number) => {
  const client = await getClient();
  const { data } = await client.get(`/statistics/${index}`, {
    responseType: "arraybuffer",
  });
  return Buffer.from(data, "binary").toString("base64");
};
