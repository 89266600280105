import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { WorkspaceClient } from "app/api";
import { PublicUser } from "app/models";
import { PendingInvite, Workspace } from "app/models/workspace.model";

const STATE_KEY = "workspace";

type WorkspaceState = {
  loading: boolean;
  workspace: Workspace | null;
  users: PublicUser[];
  pendingInvites: PendingInvite[];
};

export const fetchWorkspace = createAsyncThunk(`${STATE_KEY}/fetch`, WorkspaceClient.fetch);
export const fetchWorkspaceUsers = createAsyncThunk(`${STATE_KEY}/users/fetch`, WorkspaceClient.fetchUsers);
export const fetchPendingInvites = createAsyncThunk(
  `${STATE_KEY}/pending-invites/fetch`,
  WorkspaceClient.fetchPendingInvites,
);
export const updateWorkspace = createAsyncThunk(`${STATE_KEY}/update`, WorkspaceClient.update);

export const updateWorkspaceContact = createAsyncThunk(`${STATE_KEY}/updateContact`, WorkspaceClient.updateContact);

export const updateWorkspaceBranding = createAsyncThunk(`${STATE_KEY}/updateBranding`, WorkspaceClient.updateBranding);

export const updateWorkspaceBrandingLogo = createAsyncThunk(
  `${STATE_KEY}/updateBranding`,
  WorkspaceClient.updateBrandingLogo,
);

export const deleteWorkspace = createAsyncThunk(`${STATE_KEY}/delete`, WorkspaceClient.delete);

const initialState: WorkspaceState = {
  loading: false,
  workspace: null,
  users: [],
  pendingInvites: [],
};

export const workspaceSlice = createSlice({
  name: STATE_KEY,
  initialState: initialState,
  reducers: {
    update: (state, action: PayloadAction<Workspace>) => {
      state.workspace = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    destroy: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchWorkspace.fulfilled, (state, action) => {
        state.workspace = action.payload;
        state.loading = false;
      })
      .addCase(fetchWorkspaceUsers.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(fetchPendingInvites.fulfilled, (state, action) => {
        state.pendingInvites = action.payload;
      })
      .addCase(deleteWorkspace.fulfilled, (state) => {
        state = initialState;
      })

      // Pending
      .addCase(fetchWorkspace.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchWorkspace.rejected, (state) => {
        state.loading = false;
      })

      // Matcher
      .addMatcher(
        isAnyOf(
          updateWorkspace.fulfilled,
          updateWorkspaceContact.fulfilled,
          updateWorkspaceBranding.fulfilled,
          updateWorkspaceBrandingLogo.fulfilled,
        ),
        (state, { payload }) => {
          state.workspace = payload;
        },
      );
  },
});

export const workspaceReducer = workspaceSlice.reducer;
