import i18nLanguages from "@cospired/i18n-iso-languages";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { DropdownSelect, DropdownSelectOption } from "app/components/DropdownSelect/DropdownSelect";
import { Input } from "app/components/Input/Input";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import clsx from "clsx";
import i18nCountries from "i18n-iso-countries";
import { ChangeEvent } from "react";
import { BillingInformation } from "../../../../models/payment-information.model";
import styles from "./BillingInformationForm.module.css";

i18nCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
i18nCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));
i18nLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/en.json"));
i18nLanguages.registerLocale(require("@cospired/i18n-iso-languages/langs/de.json"));

const SUPPORTED_LANGUAGES = ["da", "de", "en", "es", "fi", "fr", "it", "ja", "nl", "he", "ar", "no", "sv"];

const COUNTRIES: DropdownSelectOption[] = Object.entries(i18nCountries.getNames("de"))
  .map((entry) => ({ name: entry[1], id: entry[0] }))
  .sort((a, b) => a.name.localeCompare(b.name));

const LANGUAGES: DropdownSelectOption[] = Object.entries(i18nLanguages.getNames("de"))
  .map((entry) => ({ name: entry[1], id: entry[0] }))
  .filter((language) => SUPPORTED_LANGUAGES.includes(language.id))
  .sort((a, b) => a.name.localeCompare(b.name));

interface BillingInformationFormProps {
  billingInformation: BillingInformation;
  onChange: (billingInformation: BillingInformation) => void;
}

export const BillingInformationForm = ({ billingInformation, onChange }: BillingInformationFormProps) => {
  const handleChange =
    (key: keyof BillingInformation) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      onChange({
        ...billingInformation,
        [key]: value,
      });

  const handleAddressChange =
    (key: keyof BillingInformation["address"]) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) =>
      onChange({
        ...billingInformation,
        address: {
          ...billingInformation.address,
          [key]: value,
        },
      });

  const handleTestData = () => {
    const random = (Math.random() + 1).toString(36).substring(7);
    onChange({
      name: `Hanna Musterfrau (${random})`,
      email: `test+${random}@example.com`,
      address: {
        line1: "Musterstraße 1",
        line2: "",
        city: "Musterstadt",
        zip: "12345",
        state: "",
        country: "DE",
      },
      locale: "de",
    });
  };

  const isGermany = billingInformation.address.country === "DE";
  return (
    <div className={styles.form}>
      <div className={styles.row}>
        <div className={styles.column}>
          <Input
            id="name"
            name="name"
            type="text"
            value={billingInformation.name}
            onChange={handleChange("name")}
            label="Name"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Input
            id="email"
            value={billingInformation.email}
            onChange={handleChange("email")}
            type="email"
            label="E-Mail"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <Input
            id="address1"
            value={billingInformation.address.line1}
            onChange={handleAddressChange("line1")}
            label={isGermany ? "Straße / Hausnummer" : "Adresse Zeile 1"}
          />
        </div>
      </div>
      {!isGermany && (
        <div className={styles.row}>
          <div className={styles.column}>
            <Input
              id="address2"
              value={billingInformation.address.line2}
              onChange={handleAddressChange("line2")}
              label="Adresse Zeile 2"
            />
          </div>
        </div>
      )}
      <div className={styles.row}>
        {isGermany && (
          <div className={styles.column}>
            <Input
              id="zip"
              value={billingInformation.address.zip}
              onChange={handleAddressChange("zip")}
              name="zip"
              label="Postleitzahl"
            />
          </div>
        )}
        <div className={styles.column}>
          <Input
            id="city"
            value={billingInformation.address.city}
            onChange={handleAddressChange("city")}
            name="city"
            label="Stadt"
          />
        </div>
        {!isGermany && (
          <div className={styles.column}>
            <Input
              id="zip"
              name="zip"
              value={billingInformation.address.zip}
              onChange={handleAddressChange("zip")}
              label="Zip-Code"
            />
          </div>
        )}
        {!isGermany && (
          <div className={styles.column}>
            <Input
              id="state"
              name="state"
              value={billingInformation.address.state}
              onChange={handleAddressChange("state")}
              label="Staat / Territorium"
            />
          </div>
        )}
      </div>
      <div className={styles.row}>
        <div className={styles.column}>
          <div className={styles.select}>
            <label htmlFor="country" className={styles.selectLabel}>
              Land
            </label>
            {/* <Select
            id="country"
            value={billingInformation.address.country}
            options={COUNTRIES}
            onChange={handleAddressChange("country")}
          /> */}
            <DropdownSelect
              selected={[
                {
                  id: billingInformation.address.country,
                  name:
                    COUNTRIES.find((c) => c.id === billingInformation.address.country)?.name ||
                    billingInformation.address.country,
                },
              ]}
              placeholder="Deutschland"
              searchable
              options={COUNTRIES}
              onSelect={([{ id }]) =>
                handleAddressChange("country")({ target: { value: id } } as ChangeEvent<
                  HTMLInputElement | HTMLSelectElement
                >)
              }
            />
          </div>
        </div>
      </div>
      <div className={clsx(styles.row, styles.invoiceLocaleWrapper)}>
        <div className={styles.column}>
          <div className={styles.select}>
            <label htmlFor="locale" className={styles.selectLabel}>
              Wählen Sie die Sprache Ihrer Rechnung
            </label>
            <DropdownSelect
              selected={[
                {
                  id: billingInformation.locale,
                  name: LANGUAGES.find((c) => c.id === billingInformation.locale)?.name || billingInformation.locale,
                },
              ]}
              placeholder="Deutsch"
              searchable
              options={LANGUAGES}
              onSelect={([{ id }]) =>
                handleChange("locale")({ target: { value: id } } as ChangeEvent<HTMLInputElement | HTMLSelectElement>)
              }
            />
          </div>
        </div>
      </div>
      <RenderIfTestAccount padding={true} center={true}>
        <Button onClick={handleTestData} theme={ButtonTheme.LINK}>
          Testdaten einfügen
        </Button>
      </RenderIfTestAccount>
    </div>
  );
};
