import { fetchNiceClasses_V2 } from "app/api";
import { Button } from "app/components/Button/Button";
import { Card } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { TmClass, TmClassParent } from "app/models/tmclass.model";
import { useEffect, useState } from "react";
import { MagicNiceClassGenerator } from "./MagicNiceClassGenerator/MagicNiceClassGenerator";
import { UserNiceClassDefinition } from "./model";
import styles from "./TrademarkBuilderView.module.scss";
import { UserClassDefinitionCard } from "./UserClassDefinitionCard/UserClassDefinitionCard";

const flatten = (children: TmClassParent[]): TmClass[] =>
  children.flatMap((c) => c.classes.concat(flatten(c.children)));

export const TrademarkBuilderView = () => {
  const [items, setItems] = useState<TmClass[]>([]);
  const [classSelectorVisible, setClassSelectorVisible] = useState<boolean>(false);
  const [textFile, setTextFile] = useState<string | null>(null);

  // TODO: persistieren
  const [userClassesDef, setUserClassesDef] = useState<UserNiceClassDefinition[]>([]);

  useEffect(() => {
    fetchNiceClasses_V2().then((result) => {
      const flatResult = flatten(result);
      setItems(flatResult);
    });
  }, []);

  useEffect(() => {
    console.log("Generate textfile download");
    const text = userClassesDef
      .map((ucd) => `${ucd.classNumber}: ${ucd.classes.map((c) => c.Begriff).join("; ")}`)
      .join("\n\n");

    const data = new Blob([text], { type: "text/plain" });

    // If we are replacing a previously generated file we need to
    // manually revoke the object URL to avoid memory leaks.
    if (textFile !== null) {
      window.URL.revokeObjectURL(textFile);
    }

    const file = window.URL.createObjectURL(data);

    setTextFile(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userClassesDef]);

  const handleAddUserClass =
    (c: number, words: TmClass[] = []) =>
    () => {
      if (userClassesDef.find((u) => u.classNumber === c)) {
        setUserClassesDef((u) =>
          u.map((uc) => ({
            ...uc,
            words: [...uc.classes, ...words],
          })),
        );
      } else {
        setUserClassesDef((u) => [
          ...u,
          {
            classNumber: c,
            classes: words,
          },
        ]);
      }
      setClassSelectorVisible(false);
    };

  //   TODO: fix any
  const handleMagicWordSelect = (w: TmClass) => {
    console.log("magic word selected", w);
    handleAddUserClass(Number(w.Klasse), [w])();
  };

  //   TODO: fix any
  const handleMagicClassSelect = (c: number) => {
    console.log("magic class selected", c);
    handleAddUserClass(c)();
  };

  const handleToggle = (ucd: UserNiceClassDefinition) => (c: TmClass) => {
    console.log("Toggle", { ucd, c });
    setUserClassesDef((u) =>
      u.map((us) => {
        if (us.classNumber === ucd.classNumber) {
          const hasClassAlready = ucd.classes.find((w) => w.id === c.id);
          if (hasClassAlready) {
            return {
              ...us,
              classes: us.classes.filter((i) => i.id !== c.id),
            };
          } else {
            return {
              ...us,
              classes: [...us.classes, c],
            };
          }
        } else {
          return us;
        }
      }),
    );
  };

  const handleRemove = (ucd: UserNiceClassDefinition) => () => {
    setUserClassesDef((u) => u.filter((c) => c.classNumber !== ucd.classNumber));
  };

  return (
    <CardContainer
      header={{
        title: "Trademark Builder",
      }}
    >
      <MagicNiceClassGenerator
        tmClasses={items}
        onSelectClass={handleMagicClassSelect}
        onSelectWord={handleMagicWordSelect}
      />
      {userClassesDef.map((c) => (
        <UserClassDefinitionCard
          c={c}
          allClassItems={items.filter((i) => Number(i.Klasse) === c.classNumber)}
          onToggle={handleToggle(c)}
          onRemoveClass={handleRemove(c)}
          key={c.classNumber}
        />
      ))}
      <Card item>
        {classSelectorVisible ? (
          <div className={styles.classPicker}>
            {Array(46)
              .fill(0)
              .map((_, i) => i)
              .filter((i) => i > 0 && !userClassesDef.map((d) => d.classNumber).includes(i))
              .map((i) => (
                <Button key={i} onClick={handleAddUserClass(i)}>
                  {i}
                </Button>
              ))}
          </div>
        ) : (
          <Button onClick={() => setClassSelectorVisible(true)}>Klasse hinzufügen</Button>
        )}
      </Card>
      {/* TODO: nizza klassen vorschlagen basierend auf den ausgewählten */}
      {/* TODO: killer feature: gib uns ein paar begriffe und wir geben dir ein ganzes WDL das du dann anpassen kannst */}
      {/* TODO export oder direkt eintragen beim dpma */}
      {textFile ? (
        <Card>
          <a download="wdl.txt" href={textFile}>
            Download
          </a>
        </Card>
      ) : null}
    </CardContainer>
  );
};
