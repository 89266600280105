import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { PortfolioClient } from "app/api";
import { Portfolio, QueryResult } from "app/models";

const STATE_KEY = "portfolios";

type Portfolios = Record<
  string,
  {
    portfolio: Portfolio;
    trademarks: QueryResult | null;
  }
>;

export interface PortfoliosState {
  allPortfolios: Portfolios;
  items: Portfolio[];
  loading: boolean;
}

const initialState: PortfoliosState = {
  loading: false,
  items: [],
  allPortfolios: {},
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchSystemInfo())
//
export const fetchPortfolios = createAsyncThunk(`${STATE_KEY}/fetch`, PortfolioClient.fetch);

export const createPortfolio = createAsyncThunk(`${STATE_KEY}/create`, PortfolioClient.create);

export const updatePortfolio = createAsyncThunk(`${STATE_KEY}/update`, PortfolioClient.update);

export const deletePortfolio = createAsyncThunk(`${STATE_KEY}/delete`, PortfolioClient.delete);

export const addTrademarksToPortfolio = createAsyncThunk(`${STATE_KEY}/addTrademarks`, PortfolioClient.addTrademarks);

export const sortTrademarksInPortfolio = createAsyncThunk(
  `${STATE_KEY}/sortTrademarks`,
  PortfolioClient.sortTrademarks,
);

export const removeTrademarksFromPortfolio = createAsyncThunk(
  `${STATE_KEY}/removeTrademarks`,
  PortfolioClient.removeTrademarks,
);
export const addResearchesToPortfolio = createAsyncThunk(`${STATE_KEY}/addResearches`, PortfolioClient.addResearches);
export const removeResearchesFromPortfolio = createAsyncThunk(
  `${STATE_KEY}/removeResearches`,
  PortfolioClient.removeResearches,
);

export const updateTrademarkPortfolioNotes = createAsyncThunk(
  `${STATE_KEY}/updateTrademarkNotes`,
  PortfolioClient.updateTrademarkNotes,
);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const portfolioSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updatePortfolio: (state, { payload }: PayloadAction<Portfolio>) => {
      state.allPortfolios[payload.uid] = {
        portfolio: payload,
        trademarks: null,
      };
      if (state.items.some((i) => i.uid === payload.uid)) {
        state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.items = state.items.concat(payload);
      }
    },
    deletePortfolio: (state, action: PayloadAction<{ portfolioId: string }>) => {
      state.items = state.items.filter((i) => i.uid !== action.payload.portfolioId);
      delete state.allPortfolios[action.payload.portfolioId];
    },
    destroy: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchPortfolios.fulfilled, (state, { payload }) => {
        for (const portfolio of payload) {
          state.allPortfolios[portfolio.uid] = {
            portfolio: portfolio,
            trademarks: null,
          };
        }
        state.items = payload;
        state.loading = false;
      })
      .addCase(createPortfolio.fulfilled, (state, { payload }) => {
        state.allPortfolios[payload.uid] = {
          portfolio: payload,
          trademarks: null,
        };
        if (state.items.some((i) => i.uid === payload.uid)) {
          state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.items = state.items.concat(payload);
        }
      })
      .addCase(deletePortfolio.fulfilled, (state, action) => {
        delete state.allPortfolios[action.meta.arg];
        state.items = state.items.filter((i) => i.uid !== action.meta.arg);
      })

      // Pending
      .addCase(fetchPortfolios.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchPortfolios.rejected, (state) => {
        state.loading = false;
      })

      // Matcher
      .addMatcher(
        isAnyOf(
          updatePortfolio.fulfilled,
          addTrademarksToPortfolio.fulfilled,
          sortTrademarksInPortfolio.fulfilled,
          removeTrademarksFromPortfolio.fulfilled,
          addResearchesToPortfolio.fulfilled,
          removeResearchesFromPortfolio.fulfilled,
          updateTrademarkPortfolioNotes.fulfilled,
        ),
        (state, { payload }) => {
          state.allPortfolios[payload.uid] = {
            portfolio: payload,
            trademarks: null,
          };
          if (state.items.some((i) => i.uid === payload.uid)) {
            state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
          } else {
            state.items = state.items.concat(payload);
          }
        },
      );
  },
});

export const portfolioReducer = portfolioSlice.reducer;
