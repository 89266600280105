import { Contact } from "app/models/trademark.model";
import styles from "./GoogleMaps.module.css";

type GoogleMapsProps = {
  query?: string;
  contact?: Contact;
};

export const GoogleMaps = ({ query, contact }: GoogleMapsProps) => {
  if (!query && !contact) {
    return null;
  }

  const q = contact
    ? `${contact.name}, ${contact.address.street}, ${contact.address.postcode}, ${contact.address.city}`
    : query
    ? query
    : "";

  return (
    <iframe
      title="Google Maps"
      src={`https://maps.google.com/maps?q=${encodeURIComponent(q)}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
      frameBorder="0"
      aria-hidden="false"
      tabIndex={0}
      scrolling="no"
      className={styles.googleMaps}
    ></iframe>
  );
};
