import {
  AppNavigation,
  AppNavigationContent,
  NavItem,
} from "app/components/AppNavigationWrapper/AppNavigation/AppNavigation";
import { AppSearchBar } from "app/components/AppNavigationWrapper/SearchBar/AppSearchBar";
import { appRoutes } from "app/routes";
import styles from "./AppNavigationWrapper.module.scss";

interface Props {
  children?: React.ReactNode;
}
export const AppNavigationWrapper = ({ children }: Props) => {
  const authRoutes = appRoutes().map<NavItem>((r) => ({
    title: r.title,
    to: r.path,
    Icon: r.Icon,
    allowedUserFlags: r.allowedUserFlags || [],
  }));
  return (
    <>
      <header className={styles.header}>
        <AppNavigation navItems={authRoutes} />
        <AppSearchBar />
      </header>
      <aside className={styles.aside}>
        <nav className={styles.nav}>
          <AppNavigationContent navItems={authRoutes} slim={true} />
        </nav>
      </aside>
      <div className={styles.headerSpacer} />
      <div className={styles.wrapper}>
        <div className={styles.asideSpacer} />
        <main className={styles.main}>{children}</main>
      </div>
    </>
  );
};
