import { FutziClient, FutziImageSearchQuery, FutziSearchQuery } from "app/api";
import { SearchInputData } from "app/models";
import { handleError } from "app/util/error-handler";
import { blobToBase64 } from "app/util/image.util";
import { appLogger } from "app/util/logger.util";
import { copyObject } from "app/util/object.util";
import deepEqual from "deep-equal";
import { AppDispatch, AppMiddleware, DEFAULT_LIMIT, searchSlice } from "..";

const execSearch = async (dispatch: AppDispatch, data: SearchInputData) => {
  const {
    query,
    owner,
    representative,
    queryType,
    limit = DEFAULT_LIMIT,
    offset = 0,
    filters,
    sortBy,
    sortOrder,
  } = data;
  const queryParams: FutziSearchQuery = {
    query,
    owner,
    representative,
    queryType,
    limit,
    offset,
    sortBy,
    sortOrder,
    filters: {
      office: filters?.office,
      status: filters?.status,
      type: filters?.type,
      owner: filters?.owner,
      representative: filters?.representative,
      niceClass: filters?.niceClass,
      niceClassOperator: filters?.niceClassOperator,
      viennaClass: filters?.viennaClass,
      viennaClassOperator: filters?.viennaClassOperator,
    },
  };

  try {
    appLogger.log("Run search", { queryParams });

    dispatch(searchSlice.actions.setSearchLoading(true));
    const result = await FutziClient.search(queryParams);
    appLogger.log("Search result", result);
    dispatch(searchSlice.actions.updateResult(result));
  } catch (error) {
    handleError("Suche konnte nicht ausgeführt werden", error);
  } finally {
    dispatch(searchSlice.actions.setSearchLoading(false));
  }
};

const execImageSearch = async (dispatch: AppDispatch, image: Blob, data: SearchInputData) => {
  try {
    const base64 = await blobToBase64(image);

    const query: FutziImageSearchQuery = {
      image: base64,
      limit: data.limit,
      offset: data.offset,
      sortBy: data.sortBy,
      sortOrder: data.sortOrder,
      filters: {
        office: data.filters?.office,
        status: data.filters?.status,
        type: data.filters?.type,
        owner: data.filters?.owner,
        representative: data.filters?.representative,
        niceClass: data.filters?.niceClass,
        niceClassOperator: data.filters?.niceClassOperator,
        viennaClass: data.filters?.viennaClass,
        viennaClassOperator: data.filters?.viennaClassOperator,
      },
    };

    appLogger.log("Run image search", {
      query,
      imageBase64: base64,
    });

    dispatch(searchSlice.actions.setSearchLoading(true));
    const result = await FutziClient.searchImage(query);
    dispatch(searchSlice.actions.updateResult(result));
  } catch (error) {
    handleError("Bildsuche konnte nicht ausgeführt werden", error);
  } finally {
    dispatch(searchSlice.actions.setSearchLoading(false));
  }
};

let lastSearch: any = null;
let lastImage: any = null;
let lastQueryKey: string = "";
let lastOffset: number = 0;

const triggerActions = ["search/startNewSearch", "search/updateOffset"];
const resetOffsetActions = ["search/startNewSearch"];

export const searchMiddleware: AppMiddleware = (store) => (next) => (action) => {
  let result = next(action);
  if (triggerActions.includes(action.type)) {
    const state = store.getState();
    const dispatch = store.dispatch;
    const current = state.search.current;
    const offset = state.search.offset;

    const queryKey = `${current.query}_${current.owner}_${current.representative}`;
    const isQueryEmpty = !current.query && !current.owner && !current.representative;
    const currentChanged = !deepEqual(lastSearch, current, { strict: true });
    const queryKeyChanged = queryKey !== lastQueryKey;
    const offsetChanged = state.search.offset !== lastOffset;

    const image = state.search.image;
    const imageChanged = lastImage !== image;

    const resetOffset = resetOffsetActions.includes(action.type);

    // update diff state
    lastQueryKey = queryKey;
    lastSearch = copyObject(current);
    lastImage = image;
    lastOffset = offset;

    appLogger.log("Search Middleware", {
      offset,
      queryKeyChanged,
      queryKey,
      resetOffset,
      currentChanged,
      image,
      imageChanged,
      result,
      current,
      offsetChanged,
    });

    let query: SearchInputData = {
      ...current,
      // reset offset for new search results or take modified from current
      offset: resetOffset ? 0 : offset,
      limit: DEFAULT_LIMIT,
    };

    if (queryKeyChanged) {
      dispatch(searchSlice.actions.resetResult());
    }

    if (currentChanged || imageChanged || offsetChanged) {
      if (image) {
        execImageSearch(dispatch, image, query);
      }
      if (!isQueryEmpty) {
        execSearch(dispatch, query);
      }
    }
  }

  return result;
};
