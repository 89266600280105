import { createAnimatedRoute } from "app/animated-route";
import collectionImage from "app/assets/collections.svg";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { Card } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { AddTrademarkToCollectionModalView } from "app/components/Modals/AddTrademarkToCollectionModal/AddTrademarkToCollectionModal";
import { k, l } from "app/i18n";
import { pluralize } from "app/i18n/i18n.util";
import { GridNavigationItemConfig, GridNavigationLayout } from "app/layouts/GridLayout/GridNavigationLayout";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { ContextType } from "app/models";
import { AppModal, showModal, updateUserContext } from "app/redux";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { collectionDetailRoute, withNestedRoutes } from "app/routes";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { CollectionHelp } from "app/views/CollectionsView/CollectionSidebar/CollectionHelp";
import clsx from "clsx";
import { Route, Routes, useNavigate } from "react-router-dom";
import { CollectionDetail } from "./CollectionDetail/CollectionDetail";
import styles from "./CollectionsView.module.scss";

export const CollectionsView = () => {
  const { items, loading } = useAppSelector((state) => state.collection);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const collections: GridNavigationItemConfig[] = items
    .map((l) => ({
      path: l.uid,
      title: l.name,
      Icon: ICONS.COLLECTION,
      subtitle: `${pluralize("trademarks", l.trademarks?.length || 0)}`,
      createdAt: l.createdAt,
      children: null,
    }))
    .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

  const showCreateCollectionModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_COLLECTION,
      props: {
        trademarks: [],
        defaultView: AddTrademarkToCollectionModalView.NEW,
        onCreateNewCollection: async (collectionId: string) => {
          trackEvent(AppEvent.CREATE_COLLECTION);
          navigate(collectionDetailRoute(collectionId));
          dispatch(
            updateUserContext({
              type: ContextType.COLLECTION,
              collectionId,
            }),
          );
        },
      },
    });
  };

  const indexRouteElement =
    collections.length > 0 ? (
      <>
        <GridNavigationLayout title={l(k.MY_COLLECTIONS)} titleIcon={ICONS.COLLECTION} items={collections}>
          <Card className={clsx("tutorial-create-collection", styles.addCard)} onClick={showCreateCollectionModal}>
            <ICONS.ADD size={ICON_SIZE.LARGE} />
            <span>Sammlung erstellen</span>
          </Card>
        </GridNavigationLayout>
        <CardContainer>
          <CollectionHelp />
        </CardContainer>
      </>
    ) : (
      <CardContainer>
        <CollectionsEmptyState onNewCollection={showCreateCollectionModal} />
        <CollectionHelp />
      </CardContainer>
    );

  const animatedRoutes = [
    {
      path: withNestedRoutes(":collectionId"),
      element: <CollectionDetail />,
    },
  ];

  return (
    <OneColumnLayout>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Routes>
          {/* Index Route is animated by parent router */}
          <Route path="" element={indexRouteElement} />
          {animatedRoutes.map(createAnimatedRoute)}
        </Routes>
      )}
    </OneColumnLayout>
  );
};

const CollectionsEmptyState = ({ onNewCollection }: { onNewCollection: () => void }) => {
  return (
    <EmptyState
      className={styles.empty}
      title={l(k.MY_COLLECTIONS)}
      text={l(k.COLLECTION_EMPTYSTATE_TEXT)}
      image={collectionImage}
      fullWidth
    >
      <Button shadow className={clsx("tutorial-create-collection", styles.addButton)} onClick={onNewCollection}>
        Neue Sammlung erstellen
      </Button>
    </EmptyState>
  );
};
