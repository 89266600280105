import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllViennaClasses } from "app/api";
import { ViennaClass, ViennaClassIdType } from "app/models";
import { AppState } from "app/redux/store.model";

type ViennaClassMap = {
  [key: string]: ViennaClass;
};

export interface ViennaState {
  items: ViennaClass[];
  loading: boolean;
  map: ViennaClassMap;
}

const initialState: ViennaState = {
  items: [],
  map: {},
  loading: false,
};

export const fetchVienna = createAsyncThunk("vienna/fetch", fetchAllViennaClasses);

const viennaSlice = createSlice({
  name: "vienna",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchVienna.fulfilled, (state, action) => {
        state.items = action.payload;
        state.map = action.payload.reduce((prev: ViennaClassMap, curr) => {
          prev[curr.viennaId] = curr;
          return prev;
        }, {});
        state.loading = false;
      })

      // Pending
      .addCase(fetchVienna.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchVienna.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const selectViennaClasses =
  (classes: string[]) =>
  ({ vienna }: AppState) => {
    const classInfos = classes.map((v) =>
      vienna.map[v]
        ? vienna.map[v]
        : {
            viennaId: v,
            notes: [],
            type: ViennaClassIdType.CATEGORY,
            text: "Zu dieser Klasse gibt es leider aktuell keinen Text.",
          },
    );
    return classInfos;
  };

export const viennaReducer = viennaSlice.reducer;
