export const DEFAULT_LANGUAGE = "DE";
export const DEFAULT_LOCALE = "de-DE";

const AVAILABLE_LANGUAGES = [
  DEFAULT_LANGUAGE,
  // "DE"
];

const getBrowserLanguage = (): string => {
  const languageGuess = Array.isArray(window.navigator.languages)
    ? window.navigator.languages.find(Boolean)
    : window.navigator.language;

  if (languageGuess) {
    return languageGuess.split("-")[0].toUpperCase();
  }

  return DEFAULT_LANGUAGE;
};

const isInAvailableLanguages = (language: string) => {
  return AVAILABLE_LANGUAGES.includes(language.toUpperCase());
};

export const getLanguage = () => {
  const browserLanguage = getBrowserLanguage();
  return isInAvailableLanguages(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;
};
