import { APP_CONST } from "app/assets/constants";
import { ICONS } from "app/assets/icons/icons";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { useUserSavedSearches } from "app/hooks/use-user-saved-searches.hook";
import { SavedSearchCard } from "./SavedSearchCard/SavedSearchCard";
import styles from "./SavedSearches.module.scss";

export const SavedSearches = () => {
  const { items: userVisibleSavedSearches, activateSavedSearch } = useUserSavedSearches();
  return (
    <CardContainer
      header={{
        title: "Gespeicherte Suchen",
        Icon: ICONS.SAVED_SEARCHES,
      }}
    >
      <div className={styles.wrapper}>
        {userVisibleSavedSearches.length > 0 ? (
          userVisibleSavedSearches.map((item) => (
            <SavedSearchCard key={item.uid} savedSearch={item} onActivate={activateSavedSearch} />
          ))
        ) : (
          <EmptyState
            image={APP_CONST.IMAGES.SAVE_SEARCH}
            text="Hier können Sie Ihre Suchen für später speichern und schnell zwischen ihnen wechseln. Oktomark speichert automatisch sämtliche Filter und die Sortierung für Sie."
            smallImage
          ></EmptyState>
        )}
      </div>
    </CardContainer>
  );
};
