import { AtLeastOne } from "app/util/type.util";
import { useSearchParams } from "react-router-dom";

export type AppSearchParams = Partial<{
  ref: string;
  email: string;
  id: string;
  office: string;
  secret: string;
  redirect: string;
  workspaceId: string;
}>;

export function useAppSearchParams<T extends AppSearchParams>(conf: T): [T, (update: AtLeastOne<T>) => void] {
  const [params, setParams] = useSearchParams();
  const defaults = { ...conf };
  const resultWithDefaults = Object.entries(conf).reduce<T>(
    (prev, [key, value]) => ({
      ...prev,
      [key]: params.get(key) || value || null,
    }),
    defaults,
  );

  const updateParams = (update: AtLeastOne<T>, navOptions?: any) => {
    //   TODO: fix any
    setParams(update as any, navOptions);
  };

  return [resultWithDefaults, updateParams];
}
