import { ICONS } from "app/assets/icons/icons";
import { UserFlag } from "app/models";
import { TrademarkOffice } from "app/models/trademark.model";

export type RouteConfig = {
  path: string;
  title: string;
  hidden?: boolean;
  Icon?: any;
  allowedUserFlags?: UserFlag[];
  children?: { [key: string]: RouteConfig };
};

export const ROOT_PATH = "/";

export const ROUTES = {
  DASHBOARD: {
    path: ROOT_PATH,
    title: "Mein Dashboard",
    Icon: ICONS.DASHBOARD,
  },
  SEARCH: {
    path: "/search",
    title: "Suche",
    Icon: ICONS.SEARCH,
    // hidden: true
  },
  PORTFOLIO: {
    path: "/portfolio",
    title: "Mandanten",
    Icon: ICONS.PORTFOLIOS,
  },
  MONITORING: {
    path: "/monitoring",
    title: "Überwachungen",
    Icon: ICONS.MONITORINGS,
  },
  COLLECTION: {
    path: "/collection",
    title: "Sammlungen",
    Icon: ICONS.COLLECTIONS,
  },
  SETTINGS: {
    path: "/settings",
    title: "Einstellungen",
    Icon: ICONS.SETTINGS,
    children: {
      WORKSPACE: {
        path: "workspace",
        title: "Workspace",
        Icon: ICONS.WORKSPACE,
        subtitle: "Erstellen Sie jetzt einen Workspace und laden Sie weitere Mitglieder ein",
      },
      ACCOUNT: {
        path: "account",
        title: "Account",
        subtitle: "Hier können Sie Ihren persönlichen Account verwalten.",
        Icon: ICONS.ACCOUNT,
      },
      PAYMENT: {
        path: "payment",
        title: "Zahlung",
        subtitle: "Hier können Sie Ihre Zahlungsmethoden verwalten und Rechnungen einsehen.",
        Icon: ICONS.PAYMENT,
      },
      INTERFACE: {
        path: "interface",
        title: "Benutzeroberfläche",
        subtitle: "Passen Sie die App auf ihre Bedürfnisse an",
        Icon: ICONS.INTERFACE,
      },
      NOTIFICATIONS: {
        path: "notifications",
        title: "Benachrichtigungen",
        subtitle: "Wählen Sie aus, welche Benachrichtigungen Sie erhalten möchten.",
        Icon: ICONS.NOTIFICATION,
      },
    },
  },
  TRADEMARK_DETAIL: {
    path: "/trademark",
    title: "Markendetail",
    hidden: true,
    Icon: ICONS.SEARCH,
  },
  ADMIN: {
    title: "Admin (root)",
    path: "/admin",
    allowedUserFlags: [UserFlag.PLATFORM_ADMIN],
    Icon: ICONS.ADMIN,
  },
  VIENNA_CLASSES: {
    path: "/vienna",
    title: "Wiener Klassifikation",
    Icon: ICONS.CLASSIFICATION,
    allowedUserFlags: [UserFlag.PLATFORM_ADMIN],
    hidden: true,
  },
  UPDATES: {
    path: "/updates",
    title: "Updates",
    Icon: ICONS.UPDATES,
    allowedUserFlags: [UserFlag.PLATFORM_ADMIN],
    hidden: true,
  },
  PARTNER: {
    path: "/partner",
    title: "Partner",
    allowedUserFlags: [UserFlag.TEST_ACCOUNT],
    Icon: ICONS.PARTNER,
    // hidden: true,
  },
  TRADEMARK_BUILDER: {
    path: "/trademark-builder",
    title: "Trademark Builder",
    Icon: ICONS.NUMBERS,
    allowedUserFlags: [UserFlag.TEST_ACCOUNT],
    // hidden: true,
  },
};

export const NO_AUTH_ROUTES = {
  LOGIN: "/login",
  LOGOUT_SUCCESS: "/logout-success",
  SIGNUP: "/signup",
  SIGNUP_PENDING: "/signup-pending",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  RESET_EMAIL: "/reset-email",
  ACCEPT_INVITE: "/accept-invite",
};

export enum AdminPage {
  ACCOUNTS = "accounts",
  TRACKING = "tracking",
  REPRESENTATIVES = "representatives",
  FEEDBACK = "feedback",
  DPMA = "dpma",
  EUIPO = "euipo",
  STATISTICS = "statistics",
  PDF = "pdf",
  VIENNA = "vienna",
  DEBUG = "debug",
  QUEUE = "queue",
}
export const savedSearchRoute = (id: string) => `${ROUTES.SEARCH.path}/${id}`;

export const trademarkDetailRoute = ({ id, office }: { id: string; office: TrademarkOffice }) =>
  `${ROUTES.TRADEMARK_DETAIL.path}?id=${id}&office=${(office || "").toLowerCase()}`;

export const monitoringDetailRoute = (id: string) => `${ROUTES.MONITORING.path}/${id}`;

export const collectionDetailRoute = (id: string) => `${ROUTES.COLLECTION.path}/${id}`;

export const portfolioDetailRoute = (id: string) => `${ROUTES.PORTFOLIO.path}/${id}`;

export const researchDetailRoute = (portfolioId: string, researchId: string) =>
  `${ROUTES.PORTFOLIO.path}/${portfolioId}/research/${researchId}`;

export const searchDetailRoute = (id: string) => `${ROUTES.SEARCH.path}/${id}`;

export const representativeSearchRoute = (representative: string) =>
  `${ROUTES.SEARCH.path}?representative=${encodeURIComponent(representative)}`;

export const settingsRoute = (page: keyof typeof ROUTES.SETTINGS.children) => {
  const subPage = ROUTES.SETTINGS.children[page].path || "";
  return `${ROUTES.SETTINGS.path}/${subPage}`;
};

export const adminRoute = (page: AdminPage) => `${ROUTES.ADMIN.path}/${page}`;

export const userDetailRoute = (userId: string) => `${adminRoute(AdminPage.ACCOUNTS)}/${userId}`;

export const appRoutes = () => {
  return Object.values(ROUTES)
    .map((r) => r as RouteConfig)
    .filter((r) => !r.hidden);
};

export const withNestedRoutes = (basePath: string) => {
  return `${basePath}/*`;
};

export function isNoAuthRoute(path?: string) {
  const currentPath = path || window.location.pathname;
  return Object.values(NO_AUTH_ROUTES).includes(currentPath);
}
