// Taken from https://stackoverflow.com/a/62017005
import { debounce } from "lodash-es";
import { useCallback, useEffect, useRef } from "react";

export function useDebounce<A extends any[]>(cb: (...args: A) => unknown, delay: number) {
  const options = {
    leading: false,
    trailing: true,
  };
  const inputsRef = useRef({ cb, delay });
  const isMounted = useIsMounted();

  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      (...args: A) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted()) inputsRef.current.cb(...args);
      },
      delay,
      options,
    ),
    [delay, debounce],
  );
}

function useIsMounted() {
  const isMountedRef = useRef(true);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return () => isMountedRef.current;
}
