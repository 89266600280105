import { Button, ButtonTheme } from "app/components/Button/Button";
import { CardContentSection } from "app/components/Card/Card";
import { DropdownSelect, DropdownSelectOption } from "app/components/DropdownSelect/DropdownSelect";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { useAppForm } from "app/hooks";
import { ContextType, NiceClass, Operator, Portfolio } from "app/models";
import { createResearch, createSavedSearch, updateUserContext, useAppDispatch, useAppSelector } from "app/redux";
import { savedSearchRoute } from "app/routes";
import { handleError } from "app/util/error-handler";
import { NizzaDialer } from "app/views/SearchView/SearchFilters/NizzaDialer/NizzaDialer";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CreateResearchModal.module.scss";

interface CreateResearchModalProps {
  open: boolean;
  onClose: () => void;
  portfolio?: Portfolio;
}

const toNiceFilterOption = (available: number[]) => (i: NiceClass) => ({
  id: String(i.classNumber),
  name: `${i.classNumber} (${i.heading})`,
  disabled: available.length > 0 && !available.includes(i.classNumber),
});

type FormData = { name: string };

export const CreateResearchModal = ({ open, onClose, portfolio }: CreateResearchModalProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [operator, setOperator] = useState<Operator>(Operator.OR);
  const [selectedNiceClasses, setSelectedNiceClasses] = useState<string[]>([]);

  const nizzaClasses = useAppSelector((state) => state.nizza.items);

  const { handleSubmit, register } = useAppForm<FormData>({
    defaultValues: {
      name: "",
    },
  });

  const niceTagOptions = useMemo(
    () => nizzaClasses.map(toNiceFilterOption(nizzaClasses.map((n) => n.classNumber))),
    [nizzaClasses],
  );

  const dropdownSelected = nizzaClasses
    .filter((n) => selectedNiceClasses.includes(String(n.classNumber)))
    .map(toNiceFilterOption(nizzaClasses.map((n) => n.classNumber)));

  if (!portfolio) {
    //TODO: fix no portfolio handling
    return null;
  }

  const handleAddNewResearch = async (formData: FormData) => {
    try {
      const research = await dispatch(
        createResearch({
          name: formData.name,
          portfolioId: portfolio.uid,
          trademarks: [],
        }),
      ).unwrap();

      if (!research) {
        return;
      }

      const savedSearch = await dispatch(
        createSavedSearch({
          query: formData.name,
          filters: {
            niceClass: selectedNiceClasses,
            niceClassOperator: operator,
          },
          name: "",
          researchId: research?.uid,
        }),
      ).unwrap();

      await dispatch(
        updateUserContext({
          type: ContextType.RESEARCH,
          researchId: research.uid,
        }),
      ).unwrap();

      navigate(savedSearchRoute(savedSearch.uid));

      onClose();
    } catch (error) {
      handleError("Recherche konnte nicht erstellt werden.", error);
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await handleAddNewResearch(data);
      setLoading(false);
      onClose();
    } catch {
      setLoading(false);
    }
  };

  const handleNiceClassChange = (selected: DropdownSelectOption[]) => {
    setSelectedNiceClasses(selected.map((s) => s.id));
  };

  const handleToggleNizza = (id: string) => {
    if (selectedNiceClasses.includes(id)) {
      setSelectedNiceClasses(selectedNiceClasses.filter((c) => c !== id));
    } else {
      setSelectedNiceClasses([...selectedNiceClasses, id]);
    }
  };

  const handleResetNizza = () => {
    setSelectedNiceClasses([]);
  };

  return (
    <Modal open={open} onClose={onClose} title={"Recherche starten"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <CardContentSection title="Markenname oder Name der Recherche">
            <Input autoFocus placeholder="Markenname" {...register("name")} />
          </CardContentSection>

          <CardContentSection title="Waren und Dienstleistungen (optional)">
            <div className={styles.nizza}>
              <DropdownSelect
                selected={dropdownSelected}
                placeholder="WDL Suche"
                multi
                searchable
                options={niceTagOptions}
                onSelect={handleNiceClassChange}
              />
              <div className={styles.dialer}>
                <p>Schnellwahl</p>
                <NizzaDialer
                  selected={selectedNiceClasses}
                  operator={operator}
                  onToggle={handleToggleNizza}
                  onReset={handleResetNizza}
                  onOperatorChange={setOperator}
                />
              </div>
            </div>
          </CardContentSection>
        </ModalContent>
        <ModalActions>
          <Button type="reset" theme={ButtonTheme.OUTLINE} onClick={onClose} disabled={loading}>
            Abbrechen
          </Button>
          <Button type="submit" loading={loading}>
            Recherche starten
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
