import { getClient } from "app/api";
import { NiceClass, NiceClassLocale, NiceClassSuggestions } from "app/models/nice-class.model";
import { TmClassParent } from "app/models/tmclass.model";
import { ViennaClass } from "app/models/vienna-class.model";

export const fetchNiceClasses = async (year: number, classNumbers: string[]) => {
  const client = await getClient();

  const { data } = await client.get("/classes/nice", {
    params: {
      year: year,
      classes: classNumbers.map(Number),
    },
  });

  return data;
};

export const fetchNiceClasses_V2 = async () => {
  const client = await getClient();

  const { data } = await client.get<TmClassParent[]>("/classes/nice/full_v2");

  return data;
};

export const fetchNiceClassesSuggestions = async ({
  classes,
  descriptions,
}: {
  classes: string[];
  descriptions: string[];
}) => {
  const client = await getClient();

  const { data } = await client.post<NiceClassSuggestions>("/classes/nice/suggestions", { classes, descriptions });

  return data;
};

export const fetchViennaClasses = async (viennaIds: string[]) => {
  const client = await getClient();

  const { data } = await client.get("/classes/vienna", {
    params: {
      viennaIds,
    },
  });

  return data;
};

export const fetchAllViennaClasses = async () => {
  const client = await getClient();
  const { data } = await client.get<ViennaClass[]>("/classes/vienna/full", {});
  return data;
};

export const fetchAllNiceClasses = async (year: number, locale: NiceClassLocale) => {
  const client = await getClient();
  const { data } = await client.get<NiceClass[]>("/classes/nice/full", {
    params: {
      year,
      locale,
    },
  });
  return data;
};
