import { ICONS } from "app/assets/icons/icons";
import { Card, CardContentSection } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EditableTextField } from "app/components/EditableTextField/EditableTextField";
import { PopoverListItem } from "app/components/Popover";
import { useIsAdmin } from "app/hooks";
import { Portfolio } from "app/models";
import { useAppSelector } from "app/redux/store.hooks";
import { localizedTimeDistance } from "app/util/date.util";
import { parseUrl } from "app/util/validate.util";
import styles from "./PortfolioHeader.module.scss";

type Props = {
  portfolio: Portfolio;
  onExport: () => void;
  onRename: () => void;
  onDelete: () => void;
  onDebug: () => void;
  onUpdateNote: (note: string) => Promise<unknown>;
  onEditContact: () => unknown;
};

export const PortfolioHeader = ({
  portfolio,
  onExport,
  onRename,
  onDelete,
  onDebug,
  onUpdateNote,

  onEditContact,
}: Props) => {
  const isAdmin = useIsAdmin();
  const portfolioUser = useAppSelector((state) =>
    state.auth.user?.userId === portfolio.userId
      ? state.auth.user
      : state.workspace.users.find((u) => u.userId === portfolio?.userId),
  );

  let menuItems: PopoverListItem[] = [
    {
      Icon: ICONS.DOWNLOAD,
      title: `Mandant exportieren`,
      onClick: onExport,
    },
    {
      Icon: ICONS.EDIT,
      title: "Umbenennen",
      onClick: onRename,
    },
    {
      Icon: ICONS.DELETE,
      title: `Löschen`,
      onClick: onDelete,
    },
    {
      title: "Debug",
      onClick: onDebug,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
  ];

  const renderContactDetail = (value?: string) => {
    if (!value) {
      return null;
    }
    return <p className={styles.contactLine}>{value}</p>;
  };

  const renderContactEmail = (value?: string) => {
    if (!value) {
      return null;
    }
    return (
      <p className={styles.contactLine}>
        <a href={`mailto:${value}`} rel="noreferrer noopener" target="_blank">
          {value}
        </a>
      </p>
    );
  };

  const renderContactPhone = (value?: string) => {
    if (!value) {
      return null;
    }
    return (
      <p className={styles.contactLine}>
        <a href={`tel:${value}`} rel="noreferrer noopener" target="_blank">
          {value}
        </a>
      </p>
    );
  };

  const renderContactReference = (value?: string) => {
    if (!value) {
      return null;
    }

    const url = parseUrl(value);

    if (url) {
      return (
        <p className={styles.contactLine}>
          <a href={url} rel="noreferrer noopener" target="_blank">
            {value}
          </a>
        </p>
      );
    }

    return renderContactDetail(value);
  };

  return (
    <>
      <CardContainer
        header={{
          title: portfolio.name,
          subtitle: `Erstellt ${localizedTimeDistance(portfolio.createdAt || new Date())} von ${
            portfolioUser?.name || "Unbekannt"
          }`,
          Icon: ICONS.PORTFOLIO,
          actions: menuItems,
        }}
        className={styles.largeTitle}
      ></CardContainer>
      <CardContainer className="tutorial-portfolio-info">
        <div className={styles.wrapper}>
          <Card>
            <CardContentSection
              title={"Mandanteninfo"}
              actions={
                <div className={styles.editIcon} onClick={onEditContact}>
                  <ICONS.EDIT size={14} />
                </div>
              }
            >
              {renderContactDetail(portfolio?.contact?.name)}
              {renderContactDetail(portfolio?.contact?.position)}
              {renderContactDetail(portfolio?.contact?.address1)}
              {renderContactDetail(portfolio?.contact?.address2)}
              {renderContactPhone(portfolio?.contact?.phone)}
              {renderContactEmail(portfolio?.contact?.email)}
            </CardContentSection>
            {portfolio.contact?.reference ? (
              <CardContentSection title={"Referenz"}>
                {renderContactReference(portfolio.contact.reference)}
              </CardContentSection>
            ) : null}
          </Card>
          <Card>
            <CardContentSection title={"Interne Notizen"}>
              <EditableTextField text={portfolio.note} onSave={onUpdateNote} />
            </CardContentSection>
          </Card>
        </div>
      </CardContainer>
    </>
  );
};
