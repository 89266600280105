import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ListItem } from "app/components/List/List";
import { AddTrademarkToCollectionModalView } from "app/components/Modals/AddTrademarkToCollectionModal/AddTrademarkToCollectionModal";
import { AddTrademarkToPortfolioModalView } from "app/components/Modals/AddTrademarkToPortfolioModal/AddTrademarkToPortfolioModal";
import { AddTrademarkToResearchModalView } from "app/components/Modals/AddTrademarkToResearchModal/AddTrademarkToResearchModal";
import { ExportType } from "app/components/Modals/ExportModal/ExportModal";
import { Portfolio, PortfolioContact } from "app/models";
import { Trademark } from "app/models/trademark.model";
import { PendingInvite } from "app/models/workspace.model";
import { AppDispatch } from "app/redux/store.model";
import { AppEvent, trackEvent } from "app/util/tracking.util";

export enum AppModal {
  UPSELLING = "UPSELLING",
  FEEDBACK = "FEEDBACK",
  NICE_CLASS = "NICE_CLASS",
  VIENNA_CLASS = "VIENNA_CLASS",
  CREATE_PORTFOLIO = "CREATE_PORTFOLIO",
  CREATE_RESEARCH = "CREATE_RESEARCH",
  EDIT_CONTACT = "EDIT_CONTACT",
  ADD_TRADEMARK_TO_COLLECTION = "ADD_TRADEMARK_TO_COLLECTION",
  ADD_TRADEMARK_TO_PORTFOLIO = "ADD_TRADEMARK_TO_PORTFOLIO",
  ADD_TRADEMARK_TO_RESEARCH = "ADD_TRADEMARK_TO_RESEARCH",
  SELECT = "SELECT",
  CREATE_MONITORING = "CREATE_MONITORING",
  CHANGE_VALUE = "CHANGE_VALUE",
  CONFIRM_DELETE = "CONFIRM_DELETE",
  DEBUG = "DEBUG",
  IMAGE = "IMAGE",
  EXPORT = "EXPORT",
  PENDING_INVITE = "PENDING_INVITE",
}

interface ModalBaseData {
  type: AppModal;
}

interface NiceClassModalData extends ModalBaseData {
  type: AppModal.NICE_CLASS;
  trademark: Trademark;
}

interface ViennaClassModalData extends ModalBaseData {
  type: AppModal.VIENNA_CLASS;
  trademark: Trademark;
}

interface CreateMonitoringModalData extends ModalBaseData {
  type: AppModal.CREATE_MONITORING;
  props: {
    trademark: Trademark;
  };
}
interface UpsellingModalData extends ModalBaseData {
  type: AppModal.UPSELLING;
}
interface FeedbackModalData extends ModalBaseData {
  type: AppModal.FEEDBACK;
}
interface AddTrademarkToCollectionModalData extends ModalBaseData {
  type: AppModal.ADD_TRADEMARK_TO_COLLECTION;
  props: {
    defaultView: AddTrademarkToCollectionModalView;
    onCreateNewCollection?: (collectionId: string) => void;
    trademarks: Trademark[];
  };
}

interface AddTrademarkToPortfolioModalData extends ModalBaseData {
  type: AppModal.ADD_TRADEMARK_TO_PORTFOLIO;
  props: {
    defaultView: AddTrademarkToPortfolioModalView;
    onCreateNewPortfolio?: (portfolioId: string) => void;
    trademarks: Trademark[];
  };
}

interface AddTrademarkToResearchModalData extends ModalBaseData {
  type: AppModal.ADD_TRADEMARK_TO_RESEARCH;
  props: {
    defaultView: AddTrademarkToResearchModalView;
    onCreateNewResearch?: (researchId: string) => void;
    trademarks: Trademark[];
  };
}

interface SelectModalData extends ModalBaseData {
  type: AppModal.SELECT;
  props: {
    title: string;
    subtitle: string;
    items: ListItem[];
  };
}

interface ExportModalData extends ModalBaseData {
  type: AppModal.EXPORT;
  props: {
    title?: string;
    subtitle?: string;
    buttonText?: string;
    disabledTypes?: ExportType[];
    onExport: (type: ExportType) => Promise<void>;
  };
}

interface ChangeValueModalData extends ModalBaseData {
  type: AppModal.CHANGE_VALUE;
  props: {
    defaultValue: string;
    onSubmit: (value: string) => Promise<void>;
    title: string;
    description?: string;
  };
}

interface ConfirmDeleteData extends ModalBaseData {
  type: AppModal.CONFIRM_DELETE;
  props: {
    title: string;
    text: string;
    onConfirm: () => Promise<void>;
  };
}

interface DebugModalData extends ModalBaseData {
  type: AppModal.DEBUG;
  props: {
    json?: object | null;
    xml?: string | null;
  };
}

interface ImageModalData extends ModalBaseData {
  type: AppModal.IMAGE;
  props: {
    trademark?: Trademark | null;
  };
}

interface InvitePendingModal extends ModalBaseData {
  type: AppModal.PENDING_INVITE;
  props: {
    pendingInvite?: PendingInvite;
  };
}

interface CreatePortfolioModal extends ModalBaseData {
  type: AppModal.CREATE_PORTFOLIO;
  props: {};
}
interface CreateResearchModal extends ModalBaseData {
  type: AppModal.CREATE_RESEARCH;
  props: {
    portfolio: Portfolio;
  };
}
interface EditContactModalModal extends ModalBaseData {
  type: AppModal.EDIT_CONTACT;
  props: {
    title: string;
    description: string;
    contact?: Partial<PortfolioContact>;
    onSubmit?: (value: PortfolioContact) => Promise<void>;
  };
}

type ModalData =
  | AddTrademarkToCollectionModalData
  | AddTrademarkToPortfolioModalData
  | AddTrademarkToResearchModalData
  | ChangeValueModalData
  | ConfirmDeleteData
  | DebugModalData
  | ExportModalData
  | FeedbackModalData
  | ImageModalData
  | InvitePendingModal
  | CreateMonitoringModalData
  | NiceClassModalData
  | EditContactModalModal
  | SelectModalData
  | UpsellingModalData
  | CreatePortfolioModal
  | CreateResearchModal
  | ViennaClassModalData;

export interface ModalState {
  openModal: AppModal | null;
  modal: ModalData | null;
}

const initialState: ModalState = {
  openModal: null,
  modal: null,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal: (state, { payload }: PayloadAction<ModalData>) => {
      trackEvent(AppEvent.SHOW_MODAL, {
        type: payload.type,
      });
      state.modal = payload;
      state.openModal = payload.type;
    },
    close: (state) => {
      state.openModal = null;
    },
    clear: (state) => {
      state.modal = null;
    },
  },
});

export const showModal = (dispatch: AppDispatch) => (modal: ModalData) => {
  dispatch(modalSlice.actions.showModal(modal));
};

export const closeModal = (dispatch: AppDispatch) => () => {
  dispatch(modalSlice.actions.close());
  // wait a bit before clearing so the close animation has time to finish
  setTimeout(() => {
    dispatch(modalSlice.actions.clear());
  }, 500);
};

export const modalReducer = modalSlice.reducer;
