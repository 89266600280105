// export const isDevelopmentEnvironment = () =>
// window.process && window.process.env.NODE_ENV && window.process.env.NODE_ENV === "development";
export const isDevelopmentEnvironment = () => window.location.origin === "http://localhost:3000";

export const isProductionEnvironment = () => !isDevelopmentEnvironment();

export const isDebugEnabled = () => {
  try {
    const flag = localStorage.getItem("oktomark-debug");
    return Boolean(flag);
  } catch (error) {
    console.error("Error reading debug setting");
    return false;
  }
};
export const isDevOrDebug = () => {
  return isDevelopmentEnvironment() || isDebugEnabled();
};
