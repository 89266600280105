import { AuthClient } from "app/api/auth.api";
import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { Card, CardActions, CardContent } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { useAppForm } from "app/hooks";
import { handleError } from "app/util/error-handler";
import clsx from "clsx";
import { useState } from "react";
import sharedStyles from "./../SharedNoAuthViewStyles.module.css";
import styles from "./ForgotPasswordCard.module.css";

type FormData = {
  email: string;
};

export const ForgotPasswordCard = () => {
  const [loading, setLoading] = useState(false);

  const { registerEmailField, handleSubmit, errors } = useAppForm<FormData>();

  const [successVisible, setSuccessVisible] = useState(false);

  const onSubmit = async (data: FormData) => {
    if (!data.email) {
      return;
    }

    try {
      setLoading(true);
      await AuthClient.sendPasswordResetEmail(data.email);
      // toast.success("Hilfe ist auf dem Weg. Wir haben Ihnen eine E-Mail geschickt.");
      // history.push(NO_AUTH_ROUTES.LOGIN)
      setSuccessVisible(true);
    } catch (error) {
      handleError("Da ist etwas schief gelaufen. Bitte überprüfen Sie Ihre E-Mail Adresse.", error);
    } finally {
      setLoading(false);
    }
  };

  const successFragment = (
    <div className={styles.success}>
      <div className={clsx(sharedStyles.headline, styles.headline)}>Hilfe ist auf dem Weg.</div>
      <p className={clsx(sharedStyles.subheadline, styles.subheadline)}>
        Wir haben Ihnen eine E-Mail mit einem Link zum zurücksetzen Ihres Passwortes geschickt.
      </p>
      <img className={sharedStyles.image} src={APP_CONST.IMAGES.MAIL_SENT} alt="" />
    </div>
  );

  const emailHasErrors = Boolean(errors.email);

  return (
    <Card className={sharedStyles.root}>
      <CardContent>
        {successVisible ? (
          successFragment
        ) : (
          <>
            <div className={sharedStyles.headline}>Passwort vergessen?</div>
            <p className={sharedStyles.subheadline}>
              Geben Sie einfach Ihre E-Mail Adresse ein und wir erledigen den Rest.
            </p>
            <form className={sharedStyles.form} onSubmit={handleSubmit(onSubmit)}>
              <Input
                {...registerEmailField()}
                valid={!emailHasErrors}
                type="email"
                name="email"
                placeholder="hanna@example.com"
                label={emailHasErrors ? "Bitte geben Sie eine vollständige E-Mail Adresse ein" : "E-Mail"}
              />
              <CardActions>
                <Button type="submit" loading={loading} className={sharedStyles.button}>
                  Neues Passwort anfordern
                </Button>
              </CardActions>
            </form>
          </>
        )}
      </CardContent>
    </Card>
  );
};
