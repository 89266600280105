import { PartnerClient } from "app/api";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardTitle } from "app/components/Card/Card";
import { DebugButton } from "app/components/DebugButton/DebugButton";
import { Partner } from "app/models";

type Props = {
  partner: Partner;
};

export const PartnerDebugSettings = ({ partner }: Props) => {
  const handlePartnerDelete = async () => {
    if (!partner?.uid) {
      return;
    }
    await PartnerClient.delete(partner.uid);
  };

  return (
    <Card>
      <CardTitle title={`Debug`} />
      <CardContent>
        <p>Todo</p>
        <ul>
          <li>Bug wenn USP nicht gespeichert und eine anderer gespeichert wird</li>
          <li>Slug validieren (URL Konform)</li>
          <li>Workspace anzapfen und URL anhand von Workspace Name vorschlagen und vorausfüllen</li>
          <li>
            Formatierungs Buttons im Intro TextEditor extra anpassen, damit du was geiles bauen kannst und wir dich an
            die Hand nehmen.
          </li>
        </ul>
        <p>Ideen</p>
        <ul>
          <li>Statement vom Oktomark Team, wenn wir Kontakt hatten oder zu Besuch waren.</li>
        </ul>
      </CardContent>
      <CardActions>
        <Button onClick={handlePartnerDelete} theme={ButtonTheme.DANGER}>
          Partner Webseite löschen
        </Button>
        <DebugButton json={partner} />
      </CardActions>
    </Card>
  );
};
