import { ResearchClient } from "app/api";
import { Button } from "app/components/Button/Button";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EditableTrademarkList } from "app/components/EditableTrademarkList/EditableTrademarkList";
import { ExportType } from "app/components/Modals/ExportModal/ExportModal";
import { Tutorial, TutorialStep } from "app/components/Tutorial/Tutorial";
import { useActiveContext } from "app/hooks";
import { pluralize } from "app/i18n";
import { ContextType, TrademarkReference, TrademarkReferenceWithNote, UserFlag } from "app/models";
import { TrademarkWithNote } from "app/models/trademark.model";
import {
  AppModal,
  deleteResearch,
  removeTrademarksFromResearch,
  searchSlice,
  showModal,
  sortTrademarksInResearch,
  updateResearch,
  updateTrademarkResearchNotes,
  updateUserContext,
  useAppDispatch,
  useAppSelector,
} from "app/redux";
import { portfolioDetailRoute, ROUTES, savedSearchRoute } from "app/routes";
import { handleError, handleUnknownError } from "app/util/error-handler";
import { sanitizeForFileName } from "app/util/string.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { matchesTrademark } from "app/util/trademark-reference.util";
import clsx from "clsx";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./ResearchDetail.module.scss";
import { ResearchHeader } from "./ResearcheHeader/ResearchHeader";
import { ResearchEmptyState } from "./ResearchEmptyState/ResearchEmptyState";
import { ResearchSavedSearches } from "./ResearchSavedSearches/ResearchSavedSearches";
import { ResearchSuggestions } from "./ResearchSuggestions/ResearchSuggestions";

const tutorialSteps: TutorialStep[] = [
  {
    title: "Notizen",
    target: ".tutorial-research-notes",
    content: "Hinterlegen Sie Notizen für Ihren Mandanten. Diese werden dann im Recherbericht berücksichtigt.",
    disableBeacon: true,
  },
  {
    title: "Recherchebericht",
    target: ".tutorial-research-export",
    content:
      "Hier können Sie den Recherchebericht für Ihren Mandanten herunterladen, sobald Sie Ihre Recherche abgeschlossen haben.",
    disableBeacon: true,
  },
];

export const ResearchDetail = () => {
  const context = useActiveContext();
  const { portfolioId, researchId } = useParams<{ portfolioId: string; researchId: string }>();
  const research = useAppSelector((state) => state.research.items.find((r) => r.uid === researchId));
  const researchPortfolio = useAppSelector((state) => state.portfolio.items.find((p) => p.uid === portfolioId));

  const [trademarks, setTrademarks] = useState<TrademarkWithNote[]>([]);
  const [loading, setLoading] = useState(false);

  const savedSearches = useAppSelector((state) => state.search.saved);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (research && research.trademarks && research.trademarks.length > 0) {
      if (trademarks.length === 0) {
        setLoading(true);
      }
      // TODO: Move this to redux to get websocket updates aka. real time collaboration
      ResearchClient.fetchResolved(research.uid).then((res) => {
        setTrademarks(
          res.trademarks.trademarks.map((t) => ({
            ...t,
            note: research.trademarks?.find(matchesTrademark(t))?.note,
          })),
        );
        setLoading(false);
      });
    } else {
      // Reset
      setTrademarks([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [research]);

  if (!research) {
    return null;
  }

  const { name, uid } = research;

  const showRenameModal = () => {
    showModal(dispatch)({
      type: AppModal.CHANGE_VALUE,
      props: {
        title: `Recherche ${name} umbenennen`,
        description: `Wie möchten Sie diese Recherche nennen?`,
        defaultValue: name,
        onSubmit: async (name: string) => {
          trackEvent(AppEvent.RENAME_RESEARCH, {
            uid,
            name,
          });
          try {
            await dispatch(updateResearch({ id: uid, research: { name } }));
          } catch (error) {
            handleError("Recherche konnte nicht umbenannt werden", error);
          }
        },
      },
    });
  };

  const showDeleteModal = () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Recherche ${name} wirklich löschen?`,
        text: `Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          trackEvent(AppEvent.DELETE_RESEARCH, {
            uid,
          });

          try {
            await dispatch(deleteResearch(uid));
            if (researchPortfolio) {
              navigate(portfolioDetailRoute(researchPortfolio.uid));
            } else {
              navigate(ROUTES.DASHBOARD.path);
            }
          } catch (error) {
            handleError("Recherche konnte nicht gelöscht werden", error);
          }
        },
      },
    });
  };

  const showExportModal = () => {
    showModal(dispatch)({
      type: AppModal.EXPORT,
      props: {
        title: `Recherchebericht generieren`,
        buttonText: "Jetzt generieren",
        onExport: (type: ExportType) => handleDownload(uid, type)(),
      },
    });
  };

  const showDebugModal = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: {
          research,
          trademarks,
        },
      },
    });
  };

  const activateResearchContext = async () => {
    const researchSearches = research.savedSearches?.map((s) => s.savedSearchId) || [];
    const lastSavedSearch = savedSearches
      .filter((s) => researchSearches.includes(s.uid))
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .find(Boolean);

    if (lastSavedSearch) {
      navigate(savedSearchRoute(lastSavedSearch.uid));
    } else {
      navigate(ROUTES.SEARCH.path);
      dispatch(
        searchSlice.actions.startNewSearch({
          query: research.name,
        }),
      );
    }

    dispatch(
      updateUserContext({
        type: ContextType.RESEARCH,
        researchId: uid,
      }),
    );
  };

  const handleSaveNote = async (note: string) => {
    trackEvent(AppEvent.UPDATE_RESEARCH_NOTE, {
      uid,
    });
    try {
      await dispatch(updateResearch({ id: uid, research: { note } }));
    } catch (error) {
      handleError("Notiz konnte nicht gespeichert werden", error);
    }
  };

  const handleDownload = (researchId: string, type: ExportType) => () => {
    trackEvent(AppEvent.DOWNLOAD_RESEARCH, {
      uid: researchId,
      type,
    });
    return ResearchClient.download(researchId, type)
      .then((response) => {
        const date = format(new Date(), "dd-MM-yyyy");

        const filename = `Recherche-${sanitizeForFileName(research.name)}-${date}.${type}`;

        // FIXME: Don't know if this works on all devices and browsers
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => handleUnknownError(error));
  };

  const handleSaveSortOrder = async (trademarkReferences: TrademarkReference[]) => {
    const sortedTrademarkReferences = [...(research.trademarks || [])].sort(
      (a, b) => trademarkReferences.findIndex(matchesTrademark(a)) - trademarkReferences.findIndex(matchesTrademark(b)),
    );

    await dispatch(
      sortTrademarksInResearch({
        id: research.uid,
        trademarks: sortedTrademarkReferences,
      }),
    ).unwrap();
  };

  const handleRemoveTrademarks = async (trademarkReferences: TrademarkReference[]) => {
    await dispatch(
      removeTrademarksFromResearch({
        id: research.uid,
        trademarks: trademarkReferences,
      }),
    ).unwrap();
  };

  const handleTrademarkNoteUpdate = async (trademarkReference: TrademarkReferenceWithNote) => {
    await dispatch(
      updateTrademarkResearchNotes({
        id: research.uid,
        trademarks: [trademarkReference],
      }),
    ).unwrap();
  };

  return (
    <>
      <Tutorial flag={UserFlag.TUTORIAL_RESEARCH} steps={tutorialSteps} />
      <ResearchHeader
        research={research}
        // onActivateContext={activateResearchContext}
        onRename={showRenameModal}
        onDelete={showDeleteModal}
        onExport={showExportModal}
        onSaveNote={handleSaveNote}
        onDebug={showDebugModal}
      />
      <CardContainer>
        <div className={styles.largeCtaContainer}>
          <Button onClick={showExportModal} className={clsx("tutorial-research-export", styles.largeCta)}>
            Recherchebericht generieren
          </Button>
          {context?.type === ContextType.RESEARCH && context.data.research.uid !== researchId ? (
            <Button onClick={activateResearchContext} className={styles.largeCta}>
              Recherche fortsetzen
            </Button>
          ) : null}
        </div>
      </CardContainer>
      <ResearchSavedSearches research={research} />
      <EditableTrademarkList
        loading={loading}
        trademarks={trademarks}
        loadingMessage={`Recherche ${research.name} wird geladen`}
        emptyState={<ResearchEmptyState research={research} />}
        title={`${pluralize("trademarks", trademarks.length)} in dieser Recherche`}
        onSaveSortOrder={handleSaveSortOrder}
        onAddTrademarks={activateResearchContext}
        onRemoveTrademarks={handleRemoveTrademarks}
        onTrademarkNoteUpdate={handleTrademarkNoteUpdate}
      />
      {!loading ? <ResearchSuggestions researchId={uid} /> : null}
    </>
  );
};
