import { ICONS } from "app/assets/icons/icons";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { List, ListItem } from "app/components/List/List";
import { Modal as ModalElement, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { TextArea } from "app/components/TextArea/TextArea";
import { k, l } from "app/i18n";
import { pluralize } from "app/i18n/i18n.util";
import { Portfolio } from "app/models";
import { Trademark } from "app/models/trademark.model";
import { useAppDispatch, useAppSelector } from "app/redux";
import { portfolioDetailRoute } from "app/routes";
import { handleError } from "app/util/error-handler";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addTrademarksToPortfolio, createPortfolio } from "../../../redux/slices/portfolio.slice";
import styles from "./AddTrademarkToPortfolioModal.module.scss";

export enum AddTrademarkToPortfolioModalView {
  ADD = "add",
  NEW = "new",
}

type AddTrademarkToPortfolioModalProps = {
  open: boolean;
  onClose: () => void;
  onCreateNewPortfolio?: (portfolioId: string) => void;
  trademarks?: Trademark[];
  defaultView?: AddTrademarkToPortfolioModalView;
};

export const AddTrademarkToPortfolioModal = ({
  open,
  onClose,
  trademarks = [],
  defaultView = AddTrademarkToPortfolioModalView.ADD,
  onCreateNewPortfolio = () => null,
}: AddTrademarkToPortfolioModalProps) => {
  const items = useAppSelector((state) => state.portfolio.items);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [view, setView] = useState<AddTrademarkToPortfolioModalView>(defaultView);
  const [newPortfolioName, setNewPortfolioName] = useState<string>("");
  const [newPortfolioNote, setNewPortfolioNote] = useState<string>("");

  useEffect(() => {
    if (trademarks.length === 0) {
      setView(AddTrademarkToPortfolioModalView.NEW);
    } else {
      setView(AddTrademarkToPortfolioModalView.ADD);
    }
  }, [trademarks]);

  const goToPortfolio = (id: string) => {
    navigate(portfolioDetailRoute(id));
  };

  const handleAddNewPortfolio = async () => {
    try {
      const res = await dispatch(
        createPortfolio({
          name: newPortfolioName,
          note: newPortfolioNote,
          trademarks: trademarks.map((trademark) => ({
            trademarkId: trademark.id,
            trademarkOffice: trademark.office,
          })),
        }),
      ).unwrap();

      if (!res) {
        return;
      }

      const portfolioId = res.uid;

      const withMarks = trademarks?.length ? ` mit ${pluralize("trademarks", trademarks?.length)}` : "";
      toast.success(`Mandant ${newPortfolioName}${withMarks} angelegt`, {
        onClick: () => goToPortfolio(portfolioId),
      });
      onCreateNewPortfolio(portfolioId);
      onClose();
    } catch (error) {
      handleError("Mandant konnte nicht angelegt werden.", error);
    }
  };

  const handleShowView = (v: AddTrademarkToPortfolioModalView) => () => {
    setView(v);
  };

  const addTrademarkToPortfolio = (portfolio: Portfolio) => async () => {
    if (!trademarks) {
      return;
    }
    try {
      await dispatch(
        addTrademarksToPortfolio({
          id: portfolio.uid,
          trademarks: trademarks.map(({ id, office }) => ({
            trademarkId: id,
            trademarkOffice: office,
          })),
        }),
      ).unwrap();
      if (trademarks.length > 1) {
        toast.success(`${pluralize("trademarks", trademarks?.length)} Marken zu ${portfolio.name} hinzugefügt.`, {
          onClick: () => goToPortfolio(portfolio.uid),
        });
      } else {
        toast.success(`${trademarks.map((trademark) => trademark.name).join(", ")} zu ${portfolio.name} hinzugefügt.`, {
          onClick: () => goToPortfolio(portfolio.uid),
        });
      }
      onClose();
    } catch (error) {
      handleError(`Marke konnte nicht zum Mandanten hinzugefügt werden.`, error);
    }
  };

  const listItems: ListItem[] = items
    .map((l) => ({
      id: l.uid,
      title: l.name,
      active: false,
      subtitle: `${pluralize("trademarks", l.trademarks?.length || 0)}`,
      onClick: addTrademarkToPortfolio(l),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  const newConfig = {
    title: l(k.CREATE_NEW_PORTFOLIO),
    subtitle: l(k.CREATE_NEW_PORTFOLIO_SUBTITLE),
    content: (
      <>
        {trademarks.length > 0 ? (
          <Button
            theme={ButtonTheme.LINK}
            onClick={handleShowView(AddTrademarkToPortfolioModalView.ADD)}
            className={styles.seeAll}
          >
            <ICONS.CHEVRON_LEFT /> Alle Mandanten
          </Button>
        ) : null}
        <Input placeholder={`Name des Mandanten`} onTextChange={setNewPortfolioName} onEnter={handleAddNewPortfolio} />
        <TextArea placeholder="Notiz" value={newPortfolioNote} onTextChange={setNewPortfolioNote} />
      </>
    ),
    actions: (
      <Button onClickPromise={handleAddNewPortfolio} disabled={!newPortfolioName}>
        Mandant anlegen
      </Button>
    ),
  };
  const listConfig = {
    title: l(k.ADD_TO_PORTFOLIO),
    subtitle: `Fügen Sie ${
      trademarks.length === 1 ? `${trademarks[0]?.name || "diese Marke"}` : `${trademarks.length} Marken`
    } zu einem Mandanten hinzu.`,
    content: <List items={listItems} />,
    actions: (
      <>
        <Button theme={ButtonTheme.TRANSPARENT} onClick={handleShowView(AddTrademarkToPortfolioModalView.NEW)}>
          {l(k.CREATE_NEW_PORTFOLIO)}
        </Button>
        <Button theme={ButtonTheme.OUTLINE} onClick={onClose}>
          Schließen
        </Button>
      </>
    ),
  };

  const config = view === AddTrademarkToPortfolioModalView.NEW ? newConfig : listConfig;

  return (
    <ModalElement open={open} onClose={onClose} title={config.title} subtitle={config.subtitle}>
      <ModalContent>{config.content}</ModalContent>
      <ModalActions>{config.actions}</ModalActions>
    </ModalElement>
  );
};
