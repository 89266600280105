import { AddTrademarkToCollectionModal } from "app/components/Modals/AddTrademarkToCollectionModal/AddTrademarkToCollectionModal";
import { ChangeValueModal } from "app/components/Modals/ChangeValueModal/ChangeValueModal";
import { ConfirmDeleteModal } from "app/components/Modals/ConfirmDeleteModal/ConfirmDeleteModal";
import { CreateMonitoringModal } from "app/components/Modals/CreateMonitoringModal/CreateMonitoringModal";
import { CreatePortfolioModal } from "app/components/Modals/CreatePortfolioModal/CreatePortfolioModal";
import { CreateResearchModal } from "app/components/Modals/CreateResearchModal/CreateResearchModal";
import { DebugModal } from "app/components/Modals/DebugModal/DebugModal";
import { EditContactModal } from "app/components/Modals/EditContactModal/EditContactModal";
import { ExportModal } from "app/components/Modals/ExportModal/ExportModal";
import { FeedbackModal } from "app/components/Modals/FeedbackModal/FeedbackModal";
import { NiceClassModal } from "app/components/Modals/NiceClassModal/NiceClassModal";
import { TrademarkImageModal } from "app/components/Modals/TrademarkImageModal/TrademarkImageModal";
import { UpsellingModal } from "app/components/Modals/UpsellingModal/UpsellingModal";
import { ViennaClassModal } from "app/components/Modals/ViennaClassModal/ViennaClassModal";
import { AppModal, closeModal, useAppDispatch, useAppSelector } from "app/redux";
import { useCallback } from "react";
import { AddTrademarkToPortfolioModal } from "./AddTrademarkToPortfolioModal/AddTrademarkToPortfolioModal";
import { AddTrademarkToResearchModal } from "./AddTrademarkToResearchModal/AddTrademarkToResearchModal";
import { PendingInviteModal } from "./PendingInviteModal/PendingInviteModal";
import { SelectModal } from "./SelectModal/SelectModal";

export const ModalsContainer = () => {
  const { modal, openModal } = useAppSelector((state) => state.modal);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const isOpen = useCallback((m: AppModal) => openModal === m, [openModal]);

  const close = useCallback(() => {
    closeModal(dispatch)();
  }, [dispatch]);

  if (!user) {
    return null;
  }

  return (
    <>
      <UpsellingModal open={isOpen(AppModal.UPSELLING)} onClose={close} />
      <FeedbackModal open={isOpen(AppModal.FEEDBACK)} onClose={close} />
      <NiceClassModal
        open={isOpen(AppModal.NICE_CLASS)}
        onClose={close}
        trademark={modal?.type === AppModal.NICE_CLASS ? modal.trademark : null}
      />
      <ViennaClassModal
        open={isOpen(AppModal.VIENNA_CLASS)}
        onClose={close}
        trademark={modal?.type === AppModal.VIENNA_CLASS ? modal.trademark : null}
      />
      <AddTrademarkToCollectionModal
        open={isOpen(AppModal.ADD_TRADEMARK_TO_COLLECTION)}
        onClose={close}
        {...(modal?.type === AppModal.ADD_TRADEMARK_TO_COLLECTION ? modal.props : {})}
      />
      <AddTrademarkToResearchModal
        open={isOpen(AppModal.ADD_TRADEMARK_TO_RESEARCH)}
        onClose={close}
        {...(modal?.type === AppModal.ADD_TRADEMARK_TO_RESEARCH ? modal.props : {})}
      />
      <AddTrademarkToPortfolioModal
        open={isOpen(AppModal.ADD_TRADEMARK_TO_PORTFOLIO)}
        onClose={close}
        {...(modal?.type === AppModal.ADD_TRADEMARK_TO_PORTFOLIO ? modal.props : {})}
      />
      <SelectModal
        open={isOpen(AppModal.SELECT)}
        onClose={close}
        {...(modal?.type === AppModal.SELECT ? modal.props : {})}
      />
      <CreateMonitoringModal
        open={isOpen(AppModal.CREATE_MONITORING)}
        onClose={close}
        {...(modal?.type === AppModal.CREATE_MONITORING ? modal.props : {})}
      />
      <ChangeValueModal
        open={isOpen(AppModal.CHANGE_VALUE)}
        onClose={close}
        {...(modal?.type === AppModal.CHANGE_VALUE ? modal.props : {})}
      />
      <ConfirmDeleteModal
        open={isOpen(AppModal.CONFIRM_DELETE)}
        onClose={close}
        {...(modal?.type === AppModal.CONFIRM_DELETE ? modal.props : {})}
      />
      <DebugModal
        open={isOpen(AppModal.DEBUG)}
        onClose={close}
        {...(modal?.type === AppModal.DEBUG ? modal.props : {})}
      />
      <ExportModal
        open={isOpen(AppModal.EXPORT)}
        onClose={close}
        {...(modal?.type === AppModal.EXPORT ? modal.props : {})}
      />
      <TrademarkImageModal
        open={isOpen(AppModal.IMAGE)}
        onClose={close}
        {...(modal?.type === AppModal.IMAGE ? modal.props : {})}
      />
      <PendingInviteModal
        open={isOpen(AppModal.PENDING_INVITE)}
        onClose={close}
        {...(modal?.type === AppModal.PENDING_INVITE ? modal.props : {})}
      />
      <CreatePortfolioModal
        open={isOpen(AppModal.CREATE_PORTFOLIO)}
        onClose={close}
        {...(modal?.type === AppModal.CREATE_PORTFOLIO ? modal.props : {})}
      />
      <CreateResearchModal
        open={isOpen(AppModal.CREATE_RESEARCH)}
        onClose={close}
        {...(modal?.type === AppModal.CREATE_RESEARCH ? modal.props : {})}
      />
      <EditContactModal
        open={isOpen(AppModal.EDIT_CONTACT)}
        onClose={close}
        {...(modal?.type === AppModal.EDIT_CONTACT ? modal.props : {})}
      />
    </>
  );
};
