import { AuthClient } from "app/api";
import {
  authSlice,
  collectionSlice,
  monitoringSlice,
  paymentSlice,
  searchSlice,
  store,
  workspaceSlice,
} from "app/redux";
import { appLogger } from "app/util/logger.util";
import { closeSocket } from "app/websocket/websocket";
import { notificationsSlice } from "../slices";

type LogoutSagaOptions = {
  hardRefresh?: boolean;
};

export const logoutSaga = async ({ hardRefresh }: LogoutSagaOptions) => {
  try {
    await AuthClient.logoutSession();
  } catch (error) {
    appLogger.warn("Cannot logout current session", error);
  }

  store.dispatch(authSlice.actions.destroy());
  store.dispatch(collectionSlice.actions.destroy());
  store.dispatch(workspaceSlice.actions.destroy());
  store.dispatch(paymentSlice.actions.destroy());
  store.dispatch(monitoringSlice.actions.destroy());
  store.dispatch(notificationsSlice.actions.destroy());
  store.dispatch(searchSlice.actions.reset());

  closeSocket();

  // do not refresh here by default, this is handled on the logout page
  if (hardRefresh) {
    window.location.reload();
  }
};
