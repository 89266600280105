import clsx from "clsx";
import styles from "./List.module.scss";

export interface ListItem {
  id: string;
  title: string;
  subtitle?: string | null;
  active?: boolean;
  onClick?: () => void;
  icon?: React.ReactElement | null;
  children?: any;
}

interface ListProps {
  title?: string;
  items: ListItem[];
  className?: string;
}

export const List = ({ title, items, className }: ListProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      {title && <div className={styles.listTitle}>{title}</div>}
      {items.map(({ id, onClick, active, icon, title, subtitle, children }) => (
        <div
          onClick={onClick}
          className={clsx(styles.listItem, { [styles.active]: active, [styles.hasAction]: Boolean(onClick) })}
          key={`list_item_${id}`}
        >
          <div className={styles.icon}>{icon ? icon : null}</div>
          <div className={styles.textWrapper}>
            <div className={styles.title}>{title}</div>
            {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
          </div>
          {children}
        </div>
      ))}
    </div>
  );
};
