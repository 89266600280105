import { Button, ButtonTheme } from "app/components/Button/Button";
import { ImageUploadArea } from "app/components/ImageUploadArea/ImageUploadArea";
import { useAppSelector } from "app/redux";
import { handleError } from "app/util/error-handler";
import { appLogger } from "app/util/logger.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useEffect, useRef, useState } from "react";
import ReactCrop, { convertToPixelCrop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./ImageSearchArea.module.scss";

interface ImageSearchAreaProps {
  onSearch: (blob: Blob) => Promise<void>;
}

type ViewType = "upload" | "crop" | "search";

export const ImageSearchArea = ({ onSearch }: ImageSearchAreaProps) => {
  const [previewImage, setPreviewImage] = useState<string>();
  const previewImageRef = useRef<HTMLImageElement>(null);
  const imageCanvasRef = useRef<HTMLCanvasElement>(null);
  const [currentCrop, setCurrentCrop] = useState<PixelCrop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const currentImage = useAppSelector((state) => state.search.image);

  const [view, setView] = useState<ViewType>(currentImage ? "search" : "upload");

  useEffect(() => {
    const canvas = imageCanvasRef.current;
    const context = canvas?.getContext("2d");
    const preview = previewImageRef.current;

    if (!completedCrop || !canvas || !context || !preview) {
      return;
    }

    const scaleX = preview.naturalWidth / preview.width;
    const scaleY = preview.naturalHeight / preview.height;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = completedCrop.width * pixelRatio;
    canvas.height = completedCrop.height * pixelRatio;

    context.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    context.imageSmoothingQuality = "high";

    context.drawImage(
      preview,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    );
  }, [completedCrop]);

  const handleImageChange = (files: File[]) => {
    if (files.length > 0) {
      // Set local preview image
      appLogger.log("ImageSearchArea", "handleImageChange", files);
      const reader: any = new FileReader();
      reader.addEventListener("load", async () => setPreviewImage(reader.result));
      reader.readAsDataURL(files[0]);

      // Reset to crop view
      setCurrentCrop(undefined);
      setView("crop");
    }
  };

  const handleSearch = async () => {
    setView("search");
    trackEvent(AppEvent.IMAGE_SEARCH);

    // Use current canvas ref image
    return new Promise<void>((resolve) => {
      imageCanvasRef.current?.toBlob(async (blob) => {
        if (blob) {
          try {
            console.log("Starting image search...");
            await onSearch(blob);
          } catch (error) {
            handleError("Bildersuche fehlgeschlagen. Bitte versuche es erneut", error);
          } finally {
            resolve();
          }
        }
      });
    });
  };

  const onPreviewImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    // HACK: Run useEffect on preview image load to populate preview canvas
    setCompletedCrop(convertToPixelCrop({ width, height }, width, height));
  };

  const reset = () => {
    setPreviewImage(undefined);
    setCurrentCrop(undefined);
    setCompletedCrop(undefined);
    setView("upload");
  };

  return (
    <div className={styles.root}>
      <p>
        Die Oktomark Bildersuche sucht basierend auf dem bereitgestellten Bild ähnliche Bilder. Um die Suche möglichst
        präzise auszuführen können Sie den Suchbereich einschränken.
      </p>

      {view === "search" && currentImage ? (
        <div className={styles.wrapperSearch}>
          <ImageUploadArea className={styles.searchUpload} onUpload={handleImageChange} />
          <img className={styles.searchImage} src={URL.createObjectURL(currentImage)} alt=""></img>
        </div>
      ) : null}

      {view === "upload" ? <ImageUploadArea onUpload={handleImageChange} /> : null}
      {previewImage && view === "crop" ? (
        <div className={styles.wrapper} style={{ display: view === "crop" ? "block" : "none" }}>
          <ReactCrop
            crop={currentCrop}
            onChange={(c: any) => setCurrentCrop(c)}
            onComplete={(c: any) => setCompletedCrop(c)}
          >
            <img
              src={previewImage}
              alt=""
              ref={previewImageRef}
              style={{ height: "100%", maxHeight: "300px" }}
              onLoad={onPreviewImageLoad}
            />
          </ReactCrop>
        </div>
      ) : null}
      {previewImage ? (
        <div className={styles.actions}>
          <Button theme={ButtonTheme.TRANSPARENT} title="" onClick={reset}>
            Zurücksetzen
          </Button>
          <Button onClickPromise={handleSearch}>Jetzt Suchen</Button>
        </div>
      ) : null}
      <div className={styles.wrapper} style={{ display: "none" }}>
        <canvas
          ref={imageCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            width: Math.round(completedCrop?.width ?? 0),
            height: "auto",
          }}
        />
      </div>
    </div>
  );
};
