import { Card } from "app/components/Card/Card";
import clsx from "clsx";
import styles from "./CardWithActions.module.css";

interface Props {
  className?: string;
  contentClassName?: string;
  actionsClassName?: string;
  actions?: React.ReactNode;
  children: React.ReactNode;
  onClick?: () => void;
  title?: string;
}

export const CardWithActions = ({
  className,
  contentClassName,
  actionsClassName,
  children,
  actions,
  onClick,
  title,
}: Props) => {
  return (
    <Card className={clsx(styles.root, className)}>
      <div className={clsx(styles.content, contentClassName)} onClick={onClick} title={title}>
        {children}
      </div>
      <div className={clsx(styles.actions, actionsClassName)}>{actions}</div>
    </Card>
  );
};
