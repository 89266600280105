import Fuse from "fuse.js";
import uniqBy from "lodash-es/uniqBy";
import { useState } from "react";
import { Input } from "../../../components/Input/Input";
import { useFuse } from "../../../hooks";
import { TmClass } from "../../../models/tmclass.model";
import styles from "./TmClassCloud.module.scss";

type TitledItem = {
  item: TmClass;
  title: string;
};

type TmClassCloudProps = {
  items: TitledItem[];
  hideFilter?: boolean;
  onSelect: (item: TmClass) => unknown;
};

const fuseOptions: Fuse.IFuseOptions<TitledItem> = {
  keys: ["title"],
  threshold: 0.6,
};

export const TmClassCloud = ({ items, onSelect, hideFilter }: TmClassCloudProps) => {
  const [filter, setFilter] = useState("");

  const filteredList = useFuse<TitledItem>(items, filter, { limit: 20 }, fuseOptions);

  const itemsToRender = filter ? filteredList.map((fr) => fr.item) : items;

  const handleSelect = (i: any) => () => {
    onSelect(i);
  };

  return (
    <>
      {itemsToRender.length > 0 && !hideFilter ? <Input placeholder="Filter" onTextChange={setFilter} /> : null}
      <div className={styles.root}>
        {uniqBy(itemsToRender, "title").map(({ title, item }) => (
          <button onClick={handleSelect(item)} key={title}>
            {title}
          </button>
        ))}
      </div>
    </>
  );
};
