import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { ImagePicker } from "app/components/ImagePicker/ImagePicker";
import { Input } from "app/components/Input/Input";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { useAppForm } from "app/hooks";
import { Partner } from "app/models";
import { updatePartner, updatePartnerImage, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { omit } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

type Props = {
  partner: Partner;
  title: string;
  subtitle: string;
  imageKey: "usp1Image" | "usp2Image" | "usp3Image";
  headlineKey: "usp1Headline" | "usp2Headline" | "usp3Headline";
  descriptionKey: "usp1Description" | "usp2Description" | "usp3Description";
};

type FormData = Partner & { file?: File };

export const PartnerUspSettings = ({ partner, title, subtitle, imageKey, headlineKey, descriptionKey }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const defaultFormValues = getDefaultFormData({ partner, imageKey, headlineKey, descriptionKey });

  const { handleSubmit, register, isValid, isDirty, setValue, reset, control } = useAppForm<FormData>({
    defaultValues: defaultFormValues,
  });

  const handleResetForm = useCallback(() => {
    reset(getDefaultFormData({ partner, imageKey, headlineKey, descriptionKey }));
  }, [partner, reset, descriptionKey, headlineKey, imageKey]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm, partner]);

  const handlePartnerUpdate = async (data: FormData) => {
    if (!partner?.uid) {
      return;
    }
    if (!isValid || !isDirty) {
      return;
    }
    setLoading(true);
    try {
      trackEvent(AppEvent.UPDATE_PARTNER, data);
      if (data.file) {
        await dispatch(
          updatePartnerImage({
            id: partner.uid,
            blob: data.file,
            key: imageKey,
          }),
        ).unwrap();
      }

      // XXX: When new file has been uploaded we omit some fields to avoid saving old values
      const omits = ["file"];
      if (data.file) {
        omits.push(imageKey);
      }
      await dispatch(updatePartner({ id: partner.uid, partner: omit(data, omits) })).unwrap();
      setValue("file", undefined);
    } catch (error) {
      handleError("Partnerseite konnte nicht aktualisiert werden.", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTestData = async () => {
    await reset(getTestData({ partner, imageKey, headlineKey, descriptionKey }));
    setValue(headlineKey, "Lorem ipsum dolor sit", { shouldDirty: true });
  };

  return (
    <Card item>
      <CardTitle title={title} subtitle={subtitle} />
      <form onSubmit={handleSubmit(handlePartnerUpdate)}>
        <CardContent>
          <CardContentSection title="Titelbild" description="Hinterlegen Sie ein Bild für diesen Block.">
            <Controller
              name={imageKey}
              control={control}
              render={({ field }) => (
                <Controller
                  name="file"
                  control={control}
                  render={({ field: field2 }) => (
                    <ImagePicker url={field.value} file={field2.value} onUpload={field2.onChange} />
                  )}
                />
              )}
            />
            <Controller
              name={imageKey}
              control={control}
              render={({ field }) =>
                field.value ? (
                  <Button theme={ButtonTheme.LINK} onClick={() => field.onChange("")}>
                    Löschen
                  </Button>
                ) : (
                  <div></div>
                )
              }
            />
          </CardContentSection>
          <CardContentSection
            title="Überschrift und Text"
            description="Wählen Sie eine kurze Überschrift und schreiben sie zwei oder drei Sätze."
          >
            <Input type="text" label="Überschrift" placeholder="Überschrift" {...register(headlineKey)} />
            <Input
              type="text"
              label="Text"
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vitae dapibus metus."
              {...register(descriptionKey)}
            />
          </CardContentSection>
        </CardContent>
        <CardActions>
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty}>
            Block aktualieren
          </Button>
          <Button type="button" onClick={handleResetForm} theme={ButtonTheme.LINK} disabled={loading || !isDirty}>
            Abbrechen
          </Button>
          <RenderIfTestAccount>
            <Button type="button" onClick={handleTestData} theme={ButtonTheme.LINK} disabled={loading}>
              Vorausfüllen
            </Button>
          </RenderIfTestAccount>
        </CardActions>
      </form>
    </Card>
  );
};

function getDefaultFormData({
  partner,
  imageKey,
  headlineKey,
  descriptionKey,
}: {
  partner: Partner;
  imageKey: "usp1Image" | "usp2Image" | "usp3Image";
  headlineKey: "usp1Headline" | "usp2Headline" | "usp3Headline";
  descriptionKey: "usp1Description" | "usp2Description" | "usp3Description";
}) {
  return {
    [imageKey]: partner[imageKey],
    [headlineKey]: partner[headlineKey],
    [descriptionKey]: partner[descriptionKey],
    file: undefined,
  };
}

function getTestData({
  partner,
  imageKey,
  headlineKey,
  descriptionKey,
}: {
  partner: Partner;
  imageKey: "usp1Image" | "usp2Image" | "usp3Image";
  headlineKey: "usp1Headline" | "usp2Headline" | "usp3Headline";
  descriptionKey: "usp1Description" | "usp2Description" | "usp3Description";
}) {
  return {
    ...getDefaultFormData({ partner, imageKey, headlineKey, descriptionKey }),
    [imageKey]: partner[imageKey],
    [headlineKey]: partner[headlineKey] || "Lorem ipsum dolor sit",
    [descriptionKey]:
      partner[descriptionKey] ||
      "Vivamus lorem eros, facilisis fringilla odio quis, pellentesque pretium nisl. Ut vel pharetra nisl. Sed ut sollicitudin ligula, eget pharetra eros.",
  };
}
