import { Placement } from "@popperjs/core";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { IconButton } from "app/components/IconButton/IconButton";
import { useHover } from "app/hooks";
import { useCloseEffect } from "app/hooks/use-close-effect.hook";
import clsx from "clsx";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import styles from "./Popover.module.scss";

export enum PopOverTheme {
  PRIMARY,
  DEFAULT,
}

type DefaultPopoverButtonProps = {
  active: boolean;
  className?: string;
};

export const DefaultPopoverButton = ({ active, className }: DefaultPopoverButtonProps) => {
  return (
    <IconButton
      title="Menü"
      className={clsx(
        {
          [styles.buttonActive]: active,
        },
        className,
      )}
    >
      <ICONS.MENU size={ICON_SIZE.DEFAULT} />
    </IconButton>
  );
};

interface Props {
  children?: React.ReactNode;
  buttonElement?: (active: boolean) => React.ReactNode;
  placement?: Placement;
  theme?: PopOverTheme;
  closeOnInteraction?: boolean;
  showOnHover?: boolean;
  hoverOnly?: boolean;
  closeOnOutSideClick?: boolean;
  // Popover can be a controlled component
  open?: boolean;
  onOpen?: () => unknown;
  onClose?: () => unknown;
  offset?: number;
  className?: string;
  buttonClassName?: string;
}

const HOVER_CLOSE_TIMEOUT = 1000;
const CLICK_CLOSE_TIMEOUT = 300;

export const Popover = ({
  children,
  buttonElement = (active) => <DefaultPopoverButton active={active} />,
  placement = "auto",
  theme = PopOverTheme.DEFAULT,
  closeOnInteraction = true,
  closeOnOutSideClick = true,
  showOnHover = false,
  hoverOnly = false,
  offset = 10,
  open,
  onOpen = () => {},
  onClose = () => {},
  className,
  buttonClassName,
}: Props) => {
  const [visible, setVisibility] = useState(false);
  // if is hover only overlay can close immediately
  const [closeable, setCloseable] = useState(hoverOnly);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const isOpen = open !== undefined ? open : visible;

  const hover = useHover(wrapperRef.current);

  const { styles: popperStyles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement,
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, offset],
        },
      },
    ],
  });

  const handleOutsideClick = useCallback(() => {
    if (isOpen && closeable && closeOnOutSideClick) {
      setVisibility(false);
      onClose();
    }
  }, [isOpen, closeable, closeOnOutSideClick, onClose]);

  const handleInsideClick = useCallback(() => {
    if (isOpen && closeOnInteraction && closeable) {
      setVisibility(false);
      onClose();
    }
  }, [isOpen, closeOnInteraction, closeable, onClose]);

  useCloseEffect(tooltipRef, handleOutsideClick, handleInsideClick);

  // allow outside clicks only after menu has fully opened
  useEffect(() => {
    let timeout: number | null = null;
    if (hoverOnly) {
      return;
    }

    if (isOpen) {
      timeout = window.setTimeout(
        () => {
          setCloseable(true);
        },
        // allow longer mouse movement when hover is used
        showOnHover ? HOVER_CLOSE_TIMEOUT : CLICK_CLOSE_TIMEOUT,
      );
    } else {
      setCloseable(false);
    }
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [isOpen, showOnHover, hoverOnly]);

  const toggleVisibility = () => {
    if (!isOpen) {
      onOpen();
    } else {
      onClose();
    }
    setVisibility(!isOpen);
  };

  useEffect(() => {
    if (hover && showOnHover) {
      setVisibility(true);
      onOpen();
    }
    if (showOnHover && !hover && isOpen && closeable) {
      setVisibility(false);
      onClose();
    }
  }, [hover, showOnHover, closeable, isOpen, onOpen, onClose]);

  return (
    <div ref={wrapperRef} className={className}>
      <div ref={setReferenceElement} onClick={toggleVisibility} className={clsx(styles.button, buttonClassName)}>
        {buttonElement(isOpen)}
      </div>
      {isOpen ? (
        <div className={styles.popper} ref={setPopperElement} style={popperStyles.popper} {...attributes.popper}>
          <div className={styles.tooltip} style={popperStyles.offset} ref={tooltipRef}>
            <div
              className={clsx(styles.arrow, {
                [styles.arrowThemeDefault]: theme === PopOverTheme.DEFAULT,
                [styles.arrowThemePrimary]: theme === PopOverTheme.PRIMARY,
              })}
              ref={setArrowElement}
              style={popperStyles.arrow}
            />
            <div
              className={clsx(styles.contentWrapper, {
                [styles.contentThemeDefault]: theme === PopOverTheme.DEFAULT,
                [styles.contentThemePrimary]: theme === PopOverTheme.PRIMARY,
              })}
            >
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
