import { Card } from "app/models/payment-information.model";

export const TRIAL_DAYS = 14;

export const formatCardNumber = (card: Card) => {
  switch (card?.brand) {
    case "amex":
      return card?.last4.padStart(15, "*") || "";

    default:
      return card?.last4.padStart(16, "*") || "";
  }
};
