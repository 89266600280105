import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Card } from "app/components/Card/Card";
import { Popover, PopoverList } from "app/components/Popover";
import { TrademarkImage } from "app/components/TrademarkImage/TrademarkImage";
import { useCloseEffect } from "app/hooks/use-close-effect.hook";
import { k, l } from "app/i18n";
import { pluralize } from "app/i18n/i18n.util";
import { searchSlice } from "app/redux/slices/search.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import {
  collectionDetailRoute,
  monitoringDetailRoute,
  portfolioDetailRoute,
  researchDetailRoute,
  ROUTES,
  trademarkDetailRoute,
} from "app/routes";
import { countFilters } from "app/util/saved-search.util";
import { hasVisual } from "app/util/trademark.util";
import clsx from "clsx";
import { compareDesc } from "date-fns";
import { isEqual } from "lodash-es";
import objectHash from "object-hash";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Key } from "ts-key-enum";
import styles from "./SearchInput.module.scss";

export enum SearchBy {
  TRADEMARK = "TRADEMARK",
  OWNER = "OWNER",
  REPRESENTATIVE = "REPRESENTATIVE",
}

interface SearchInputProps {
  className?: string;
  onImageSearch?: (blob: Blob) => Promise<void>;
  onSubmit: (query: string, searchBy: SearchBy) => void;
}

export const SearchInput = ({ className, onSubmit }: SearchInputProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchBy, setSearchBy] = useState<SearchBy>(SearchBy.TRADEMARK);
  const current = useAppSelector((state) => state.search.current);
  const currentImage = useAppSelector((state) => state.search.image);
  const [value, setValue] = useState<string>(current.query || current.owner || current.representative || "");
  const [focused, setFocused] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [quickNavOpen, setQuickNavOpen] = useState(false);
  const [quickNavIndex, setQuickNavIndex] = useState(-1);
  const quickNavItems = useQuickNavItems(value);
  const quickNavLength = quickNavItems.flatMap((item) => item.items).length;

  useCloseEffect(rootRef, () => setQuickNavOpen(false));

  useEffect(() => {
    setValue(current.query || current.owner || current.representative || "");
    if (current.query) {
      setSearchBy(SearchBy.TRADEMARK);
    } else if (current.owner) {
      setSearchBy(SearchBy.OWNER);
    } else if (current.representative) {
      setSearchBy(SearchBy.REPRESENTATIVE);
    } else {
      setSearchBy(SearchBy.TRADEMARK);
    }
  }, [current]);

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(value, searchBy);
      navigate(ROUTES.SEARCH.path);
      // blur on mobile to hide keyboard
      if (isMobile && inputRef.current) {
        inputRef.current.blur();
      }
    }

    setQuickNavOpen(false);
  };

  const handleSearchByChange = (val: SearchBy) => {
    setSearchBy(val);
    inputRef.current?.select();
  };

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);

    setQuickNavOpen(true);
    setQuickNavIndex(-1);
  };

  const handleFocusChange = (newState: boolean) => () => {
    if (!focused) {
      inputRef.current?.select();
    }

    setFocused(newState);
    if (newState) {
      setQuickNavOpen(true);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.Enter) {
      if (quickNavIndex > -1) {
        const onClick = quickNavItems.flatMap((o) => o.items)[quickNavIndex]?.onClick;
        if (onClick) {
          onClick();
        }
      } else {
        handleSubmit();
      }
      setQuickNavOpen(false);
      setQuickNavIndex(-1);
    }

    if (e.key === Key.Escape || e.key === Key.Tab) {
      setQuickNavOpen(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Key.ArrowDown) {
      e.preventDefault();
      if (quickNavIndex === -1) {
        setQuickNavOpen(true);
      }
      setQuickNavIndex(quickNavIndex < quickNavLength - 1 ? quickNavIndex + 1 : -1);
    } else if (e.key === Key.ArrowUp) {
      e.preventDefault();
      if (quickNavIndex === -1) {
        setQuickNavOpen(true);
      }
      setQuickNavIndex(quickNavIndex > -1 ? quickNavIndex - 1 : quickNavLength - 1);
    }
  };

  const handleClearSearch = () => {
    setValue("");
    inputRef.current?.select();
    dispatch(searchSlice.actions.reset());
    navigate(ROUTES.SEARCH.path);
  };

  const handleOpenQuickNav = () => {
    setQuickNavOpen(true);
  };

  const searchByOptions = [
    {
      title: "Marken",
      value: SearchBy.TRADEMARK,
      icon: ICONS.TRADEMARK,
    },
    {
      title: l(k.OWNER),
      value: SearchBy.OWNER,
      icon: ICONS.PERSON,
    },
    {
      title: l(k.REPRESENTATIVE),
      value: SearchBy.REPRESENTATIVE,
      icon: ICONS.COMPANY,
    },
  ];

  const iconForSearchBy = (sb: SearchBy) => {
    switch (sb) {
      case SearchBy.OWNER:
        return <ICONS.PERSON size={ICON_SIZE.DEFAULT} />;
      case SearchBy.REPRESENTATIVE:
        return <ICONS.COMPANY size={ICON_SIZE.DEFAULT} />;
      default:
        return <ICONS.TRADEMARK size={ICON_SIZE.DEFAULT} />;
    }
  };

  const isSearchDisabled = !value || value === "";
  const canClear = value || currentImage;

  return (
    <div
      ref={rootRef}
      className={clsx(styles.root, "tutorial-search", className, {
        [styles.focused]: focused,
      })}
    >
      <div className={styles.wrapper}>
        <Popover
        buttonClassName={styles.searchByPopoverButton}
          buttonElement={(active) => (
            <button
              className={clsx(styles.searchByButton, {
                [styles.searchByButtonActive]: active,
              })}
              title="Suche nach..."
            >
              {iconForSearchBy(searchBy)}
            </button>
          )}
        >
          <PopoverList
            items={searchByOptions.map((option) => ({
              onClick: () => handleSearchByChange(option.value),
              title: option.title,
              Icon: option.icon,
            }))}
          />
        </Popover>
        <div className={styles.inputWrapper}>
          <input
            value={value}
            ref={inputRef}
            type="text"
            onClick={handleOpenQuickNav}
            onKeyUp={handleKeyUp}
            onKeyDown={handleKeyDown}
            onChange={handleQueryChange}
            onFocus={handleFocusChange(true)}
            onBlur={handleFocusChange(false)}
            placeholder={"Suche"}
            className={clsx("hotkey-search-input", styles.input)}
          />
          <button
            style={{ display: canClear ? "initial" : "none" }}
            className={styles.clearSearchButton}
            title="Suchfeld leeren"
            onClick={handleClearSearch}
          >
            <ICONS.CLOSE size={ICON_SIZE.DEFAULT} />
          </button>
        </div>
        <button disabled={isSearchDisabled} className={styles.searchButton} title="Jetzt suchen" onClick={handleSubmit}>
          <ICONS.SEARCH size={ICON_SIZE.DEFAULT} />
        </button>
      </div>
      <QuickNav items={quickNavItems} activeItem={quickNavIndex} open={quickNavOpen} setOpen={setQuickNavOpen} />
    </div>
  );
};

interface QuickNavProps {
  items: {
    headline: string;
    Icon: any;
    items: {
      id: string;
      title: string;
      onClick: () => void;
      Image?: any;
    }[];
  }[];
  activeItem: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const QuickNav = ({ items, activeItem, open, setOpen }: QuickNavProps) => {
  const itemsLength = items.flatMap((o) => o.items).length;

  const handleClick = (onClick: () => void) => () => {
    onClick();
    setOpen(false);
  };

  if (!open || itemsLength <= 0) {
    return null;
  }
  return (
    <Card className={styles.quickNav}>
      {items
        .filter((item) => item.items.length > 0)
        .map(({ headline, Icon, items: menuItems }) => (
          <div key={headline} className={styles.quickNavList}>
            <div className={styles.quickNavTitle}>{headline}</div>
            {menuItems.map((i) => (
              <div
                key={i.id}
                className={clsx(styles.quickNavDetailLink, {
                  [styles.active]: items.flatMap((item) => item.items).findIndex((a) => a.id === i.id) === activeItem,
                })}
                onClick={handleClick(i.onClick)}
              >
                {i.Image ? i.Image : <Icon className={styles.quickNavIcon} />} {i.title}
              </div>
            ))}
          </div>
        ))}
    </Card>
  );
};

const useQuickNavItems = (query: string) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const LIMIT = 3;
  const { search, monitoring, collection, research, portfolio } = useAppSelector((state) => state);

  // Collect matching stuff from Redux store
  return useMemo(() => {
    const { lastSearches, trademarks, monitorings, collections, researches, portfolios, savedSearches } = {
      // Last searches
      lastSearches: search.last
        // Only unique
        .filter((t, index, self) => self.findIndex((a) => isEqual(a, t)) === index)
        .filter((savedSearch) =>
          query
            ? savedSearch.query?.toLowerCase().includes(query.toLowerCase()) ||
              savedSearch.owner?.toLowerCase().includes(query.toLowerCase()) ||
              savedSearch.representative?.toLowerCase().includes(query.toLowerCase())
            : true,
        )
        .slice(0, query ? LIMIT : 10),

      // Trademarks
      trademarks: monitoring.items
        .filter((m) => query && m.trademark.name.toLowerCase().includes(query.toLowerCase()))
        .sort((a, b) => compareDesc(a.updatedAt || 0, b.updatedAt || 0))
        .map((m) => m.trademark)
        .concat(
          Object.values(collection.allCollections).flatMap(
            (c) =>
              c.trademarks?.trademarks.filter((t) => t.name.toLocaleLowerCase().includes(query.toLowerCase())) || [],
          ),
        )
        // Only unique
        .filter((t, index, self) => self.findIndex((a) => isEqual(a, t)) === index)
        .slice(0, LIMIT),

      // Monitorings
      monitorings: monitoring.items
        .filter((m) => query && m.trademark.name.toLowerCase().includes(query.toLowerCase()))
        .sort((a, b) => compareDesc(a.updatedAt || 0, b.updatedAt || 0))
        .slice(0, LIMIT),

      // Collections
      collections: collection.items
        .filter((c) => query && c.name.toLowerCase().includes(query.toLowerCase()))
        .sort((a, b) => compareDesc(a.updatedAt || 0, b.updatedAt || 0))
        .slice(0, LIMIT),

      // Researches
      researches: research.items
        .filter((r) => query && r.name.toLowerCase().includes(query.toLowerCase()))
        .sort((a, b) => compareDesc(a.updatedAt || 0, b.updatedAt || 0))
        .map((r) => ({
          ...r,
          researchPortfolioId: portfolio.items.find((p) => p.researches?.some((pr) => pr.researchId === r.uid))?.uid,
        }))
        .filter((r) => r.researchPortfolioId)
        .slice(0, LIMIT),

      // Portfolios
      portfolios: portfolio.items
        .filter((p) => query && p.name.toLowerCase().includes(query.toLowerCase()))
        .sort((a, b) => compareDesc(a.updatedAt || 0, b.updatedAt || 0))
        .slice(0, LIMIT),

      // Saved searches
      savedSearches: search.saved
        .filter(
          (s) =>
            query &&
            (s.name?.toLowerCase().includes(query.toLowerCase()) ||
              s.query?.toLowerCase().includes(query.toLowerCase()) ||
              s.owner?.toLowerCase().includes(query.toLowerCase()) ||
              s.representative?.toLowerCase().includes(query.toLowerCase())),
        )
        .sort((a, b) => compareDesc(a.updatedAt || 0, b.updatedAt || 0))
        .slice(0, LIMIT),
    };

    // Build menu
    return [
      // Last searches
      {
        headline: l(k.LAST_SEARCHES),
        Icon: ICONS.SEARCH,
        items: lastSearches.map((s) => ({
          id: objectHash(s),
          title: `${s.query || s.owner || s.representative || ""}${
            countFilters(s.filters) > 0 ? ` (${countFilters(s.filters)} Filter)` : ""
          }`,
          onClick: () => {
            dispatch(searchSlice.actions.startNewSearch(s));
            navigate(ROUTES.SEARCH.path);
          },
        })),
      },
      // Trademarks
      {
        headline: l(k.TRADEMARKS),
        Icon: ICONS.TRADEMARK,
        items: trademarks.map((t) => ({
          id: objectHash(t),
          title: t.name,
          onClick: () => navigate(trademarkDetailRoute({ id: t.id, office: t.office })),
          Image: hasVisual(t.type) ? <TrademarkImage trademark={t} className={styles.quickNavImage} /> : null,
        })),
      },
      // Saved searches
      {
        headline: l(k.SAVED_SEARCHES),
        Icon: ICONS.SAVED_SEARCHES,
        items: savedSearches.map((s) => ({
          id: objectHash(s),
          title: `${s.name || s.query || s.owner || s.representative || ""}${
            countFilters(s.filters) > 0 ? ` (${countFilters(s.filters)} Filter)` : ""
          }`,
          onClick: () => {
            dispatch(searchSlice.actions.startNewSearch(s));
            navigate(ROUTES.SEARCH.path);
          },
        })),
      },
      // Collections
      {
        headline: l(k.COLLECTIONS),
        Icon: ICONS.COLLECTIONS,
        items: collections.map((c) => ({
          id: objectHash(c),
          title: `${c.name} (${pluralize("trademarks", c.trademarks?.length || 0)})`,
          onClick: () => navigate(collectionDetailRoute(c.uid)),
        })),
      },
      // Researches
      {
        headline: l(k.RESEARCHES),
        Icon: ICONS.RESEARCHES,
        items: researches.map((r) => ({
          id: objectHash(r),
          title: `${r.name} (${pluralize("trademarks", r.trademarks?.length || 0)})`,
          // XXX: We only have researches attached to a portfolio here because we have a filter in place
          // So we can safely use || "" here
          onClick: () => navigate(researchDetailRoute(r.researchPortfolioId || "", r.uid)),
        })),
      },
      // Portfolios
      {
        headline: l(k.PORTFOLIOS),
        Icon: ICONS.PORTFOLIOS,
        items: portfolios.map((p) => ({
          id: objectHash(p),
          title: p.name,
          onClick: () => navigate(portfolioDetailRoute(p.uid)),
        })),
      },
      // monitorings
      {
        headline: l(k.TRADEMARK_MONITORINGS),
        Icon: ICONS.MONITORING,
        items: monitorings.map((m) => ({
          id: objectHash(m),
          title: `${m.trademark.name} (${pluralize("collisions", m.collisions?.length || 0)})`,
          onClick: () => navigate(monitoringDetailRoute(m.uid)),
        })),
      },
    ];
  }, [
    collection.allCollections,
    collection.items,
    dispatch,
    monitoring.items,
    navigate,
    portfolio.items,
    query,
    research.items,
    search.last,
    search.saved,
  ]);
};
