import { UserFlag } from "app/models/user.model";
import { useAppSelector } from "app/redux/store.hooks";
import { mustContainAll } from "app/util/array.util";

export const useIsAdmin = () => {
  const allowedFlags = [UserFlag.PLATFORM_ADMIN];
  const user = useAppSelector((state) => state.auth.user);
  return user?.flags?.some((flag) => allowedFlags.includes(flag)) || false;
};

export const useIsTestAccount = () => {
  const allowedFlags = [UserFlag.PLATFORM_ADMIN, UserFlag.TEST_ACCOUNT];
  const user = useAppSelector((state) => state.auth.user);
  return user?.flags?.some((flag) => allowedFlags.includes(flag)) || false;
};

export const useIsBeta = () => {
  const allowedFlags = [UserFlag.PLATFORM_ADMIN, UserFlag.TEST_ACCOUNT, UserFlag.BETA_FEATURES];
  const user = useAppSelector((state) => state.auth.user);
  return user?.flags?.some((flag) => allowedFlags.includes(flag)) || false;
};

export const useHasUserFlag = (flag: UserFlag) => {
  const user = useAppSelector((state) => state.auth.user);
  return user?.flags?.includes(flag) || false;
};

export const useHasSomeUserFlag = (flags: UserFlag[]) => {
  const user = useAppSelector((state) => state.auth.user);
  return user?.flags?.some((flag) => flags.includes(flag)) || false;
};

export const useHasAllUserFlags = (flags: UserFlag[]) => {
  const user = useAppSelector((state) => state.auth.user);
  return mustContainAll(flags, user?.flags || []);
};
