import { SavedSearch } from "app/models";
import { Trademark, TrademarkOffice } from "app/models/trademark.model";

const resultsPageBaseUrl = "https://www.tmdn.org/tmview/#/tmview/results";
const detailPageBaseUrl = "https://www.tmdn.org/tmview/#/tmview/detail";

const euipoDetailId = (tmId: string) => {
  return `EM5${tmId.padStart(14, "0")}`;
};

const dpmaDetailId = (tmId: string) => {
  return `DE5${tmId.padStart(14, "0")}`;
};

export const buildTmViewDetailUrl = (tm: Trademark) => {
  const id = tm.office === TrademarkOffice.EUIPO ? euipoDetailId(tm.id) : dpmaDetailId(tm.id);

  return `${detailPageBaseUrl}/${id}`;
};

const optionalRawQueryParam = (p?: string | null) => {
  if (!p || p === "") {
    return "";
  }

  return `&${p}`;
};

export const buildTmViewSearchUrl = (search: Partial<SavedSearch>) => {
  const q = search.query;
  if (!q || q === "") {
    return null;
  }

  const n = search.filters?.niceClass || [];
  const v = search.filters?.viennaClass || [];

  // class filters sould not be url encoded
  let niceClassFilter = "";
  if (n && n.length > 0) {
    niceClassFilter = `fNiceClass=${n.join(",")}`;
  }

  // class filters sould not be url encoded
  // TODO: use tmview style vienna notation
  let viennaClassFilter = "";
  if (v && v.length > 0) {
    viennaClassFilter = `fViennaCodes=${v.join(",")}`;
  }

  const params = new URLSearchParams();

  params.set("basicSearch", q);
  params.set("page", "1");
  params.set("pageSize", "30");
  params.set("criteria", "C");
  params.set("fTMStatus", "Registered");
  //TODO: allow user to select this in oktomark?
  //   params.set("fOffices", "WO");

  const u = `${resultsPageBaseUrl}?${params}${optionalRawQueryParam(niceClassFilter)}${optionalRawQueryParam(
    viennaClassFilter,
  )}`;

  // console.log("buildTmViewSearchUrl", {
  //   search,
  //   // result,
  //   // searchLoading,
  //   u,
  //   params: params.toString(),
  // });
  return u;
};
