import { TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { ContextType } from "./context.model";

export enum UserFlag {
  /**
   * Permission flags
   */
  // Ben and Peter only
  PLATFORM_ADMIN = "PLATFORM_ADMIN",
  // For internal test accounts only
  TEST_ACCOUNT = "TEST_ACCOUNT",
  // Used by real beta customers
  BETA_FEATURES = "BETA_FEATURES",

  /**
   * Payment flags
   */
  // Used by test accounts to test stripe in testing mode
  PAYMENT_TEST = "PAYMENT_TEST",
  // Used by platform admins to test stripe in production mode
  PAYMENT_BETA = "PAYMENT_BETA",
  // Skips all payment related content because custom pricing is in place
  PAYMENT_CUSTOM = "PAYMENT_CUSTOM",

  /**
   * Feature flags
   */
  ONBOARDING_MAIL_ORGANIZE = "ONBOARDING_MAIL_ORGANIZE",
  ONBOARDING_MAIL_MOBILE_DEVICES = "ONBOARDING_MAIL_MOBILE_DEVICES",
  ONBOARDING_MAIL_SEARCH = "ONBOARDING_MAIL_SEARCH",
  ONBOARDING_MAIL_TRADEMARK_MONITORINGS = "ONBOARDING_MAIL_TRADEMARK_MONITORINGS",
  PENDING_SIGNUP = "PENDING_SIGNUP",
  TUTORIAL = "TUTORIAL",
  TUTORIAL_PORTFOLIO = "TUTORIAL_PORTFOLIO",
  TUTORIAL_CONTEXT = "TUTORIAL_CONTEXT",
  TUTORIAL_RESEARCH = "TUTORIAL_RESEARCH",
  TUTORIAL_COLLECTION = "TUTORIAL_COLLECTION",
  TUTORIAL_SEARCH = "TUTORIAL_SEARCH",
  TUTORIAL_TRADEMARK_MONITORING = "TUTORIAL_TRADEMARK_MONITORING",
  TUTORIAL_UPDATES = "TUTORIAL_UPDATES",
}

export enum AuthProvider {
  GOOGLE = "GOOGLE",
  EMAIL_PASSWORD = "EMAIL_PASSWORD",
  UNKNOWN = "UNKNOWN",
}

export interface CustomBranding {
  name?: string;
  companyName?: string;
  primaryColor?: string;
  secondaryColor?: string;
  companyLogoUrl?: string;
}

export interface Notifications {
  comments?: boolean;
  collisions?: boolean;
  statistics?: boolean;
  newFeatures?: boolean;
  tipsAndTricks?: boolean;
  feedback?: boolean;
}

export type InterfaceDefaults = {
  darkMode: boolean;
  trademarkDisplayType: TrademarkCardDisplayType;
};

export type Context = {
  type?: ContextType;
  collectionId?: string;
  portfolioId?: string;
  researchId?: string;
};

export interface PublicUser {
  userId: string;
  uid: string;
  name: string;
  email: string;
  photoUrl?: string;
  photoPngUrl?: string;
  // FIXME: Use something like mapToUser() and convert to Date object
  createdAt: string;
  updatedAt: string;
}

export interface User extends PublicUser {
  authProvider: AuthProvider;
  flags?: UserFlag[];
  notifications?: Notifications;
  interfaceDefaults?: InterfaceDefaults;
  context?: Context;
}
