import { Button, ButtonTheme } from "app/components/Button/Button";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { handleUnknownError } from "app/util/error-handler";

interface ConfirmDeleteModalProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  text?: string;
  onConfirm?: () => Promise<void>;
}

export const ConfirmDeleteModal = ({ open, onClose, title, text, onConfirm }: ConfirmDeleteModalProps) => {
  const handleConfirm = async () => {
    if (onConfirm) {
      try {
        await onConfirm();
        onClose();
      } catch (error) {
        handleUnknownError(error);
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <ModalContent>{text}</ModalContent>
      <ModalActions>
        <Button theme={ButtonTheme.OUTLINE} onClick={onClose}>
          Abbrechen
        </Button>
        <Button theme={ButtonTheme.DANGER} onClickPromise={handleConfirm}>
          Löschen
        </Button>
      </ModalActions>
    </Modal>
  );
};
