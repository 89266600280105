import { Card, CardContent, CardTitle } from "app/components/Card/Card";
import { CountryFlag } from "app/components/CountryFlag/CountryFlag";
import { GoogleMaps } from "app/components/GoogleMaps/GoogleMaps";
import { k, l } from "app/i18n";
import { Contact, Trademark } from "app/models/trademark.model";
import { searchSlice } from "app/redux/slices/search.slice";
import { useAppDispatch } from "app/redux/store.hooks";
import { ROUTES } from "app/routes";
import { useNavigate } from "react-router-dom";
import styles from "./TrademarkSidebar.module.css";

interface ContactInfoProps {
  contact: Contact;
  action?: () => unknown;
  linkTitle?: string;
}

export const ContactInfo = ({
  contact: {
    name,
    address: { street, city, postcode, country },
  },
  action,
  linkTitle,
}: ContactInfoProps) => (
  <>
    <div className={styles.contactName} title={linkTitle}>
      {action ? (
        <button className={styles.button} onClick={action}>
          {name}
        </button>
      ) : (
        name
      )}
    </div>
    {street && street !== "x" && <div className={styles.contactAddress}>{street}</div>}
    <div className={styles.contactAddress}>
      {postcode && postcode !== "x" && postcode} {city && city !== "x" && city}
      {country && country.toLowerCase() !== "de" && country.toLowerCase() !== "zz" && (
        <CountryFlag className={styles.countryFlag} code={country} />
      )}
    </div>
  </>
);

interface TrademarkSidebarProps {
  trademark: Trademark;
}

export const TrademarkSidebar = ({ trademark }: TrademarkSidebarProps) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const searchByTrademarkOwner = (owner?: string) => () => {
    if (!owner) {
      return;
    }
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        owner: owner,
      }),
    );
  };

  const searchByTrademarkRepresentative = (representative?: string) => () => {
    if (!representative) {
      return;
    }
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        representative: representative,
      }),
    );
  };

  const noContent = trademark.owners.length === 0 && trademark.representatives.length === 0 && !trademark.contact?.name;

  return (
    <div className={styles.root}>
      {trademark.owners.length > 0 && (
        <Card item={trademark.representatives.length > 0 || Boolean(trademark.contact?.name)}>
          <CardTitle title={l(k.OWNER)} compact />
          <CardContent>
            {trademark.owners.map((o, i) => (
              <div key={`owner_${o.name}_${i}`}>
                <ContactInfo
                  contact={o}
                  action={searchByTrademarkOwner(o.name)}
                  linkTitle="Nach diesem Inhaber suchen"
                />
                <div className={styles.googleMaps}>
                  <GoogleMaps key={`owner_${o.name}_maps`} contact={o} />
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
      )}
      {trademark.representatives.length > 0 && (
        <Card item={Boolean(trademark.contact?.name)}>
          <CardTitle title={l(k.REPRESENTATIVE)} compact />
          <CardContent>
            {trademark.representatives.map((r, i) => (
              <div key={`rep_${r.name}_${i}`}>
                <ContactInfo
                  contact={r}
                  action={searchByTrademarkRepresentative(r.name)}
                  linkTitle="Nach diesem Vertreter suchen"
                />
                <div className={styles.googleMaps}>
                  <GoogleMaps key={`rep_${r.name}_maps`} contact={r} />
                </div>
              </div>
            ))}
          </CardContent>
        </Card>
      )}
      {trademark.contact?.name && (
        <Card>
          <CardTitle title="Kontakt" compact />
          <CardContent>
            <ContactInfo contact={trademark.contact} />
            <div className={styles.googleMaps}>
              <GoogleMaps contact={trademark.contact} />
            </div>
          </CardContent>
        </Card>
      )}
      {noContent ? <Card>Zu dieser Marke wurden bisher keine Personeninformationen veröffentlicht.</Card> : null}
    </div>
  );
};
