import getUnicodeFlagIcon from "country-flag-icons/unicode";
import countries from "i18n-iso-countries";

countries.registerLocale(require("i18n-iso-countries/langs/de.json"));

const localizedCountry = (code: string) => {
  return countries.getName(code, "de");
};

const localizedCountryFlag = (code: string) => {
  return getUnicodeFlagIcon(code);
};

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> & {
  code: string;
};

export const CountryFlag = ({ code, ...props }: Props) => (
  <span {...props} title={localizedCountry(code)}>
    {localizedCountryFlag(code)}
  </span>
);
