import { Button, ButtonTheme } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { useAppForm } from "app/hooks";
import { useEffect, useState } from "react";

interface ChangeValueModalProps {
  open: boolean;
  onClose: () => void;
  defaultValue?: string;
  onSubmit?: (value: string) => Promise<void>;
  title?: string | null;
  description?: string | null;
}

type FormData = { value: string };

export const ChangeValueModal = ({
  open,
  onClose,
  defaultValue = "",
  onSubmit,
  title = "",
  description = "",
}: ChangeValueModalProps) => {
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, errors, isValid, setValue } = useAppForm<FormData>({
    defaultValues: {
      value: defaultValue,
    },
  });

  useEffect(() => {
    setValue("value", defaultValue);
  }, [defaultValue, setValue]);

  const onSubmitValue = async ({ value }: FormData) => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
      if (onSubmit && value) {
        await onSubmit(value);
      }
      setLoading(false);
      onClose();
    } catch {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose} title={title} subtitle={description || ""}>
      <form onSubmit={handleSubmit(onSubmitValue)}>
        <ModalContent>
          <Input
            autoFocus
            type="text"
            valid={!Boolean(errors.value)}
            {...register("value", { minLength: 1, required: true })}
          />
        </ModalContent>
        <ModalActions>
          <Button theme={ButtonTheme.OUTLINE} onClick={onClose} disabled={loading}>
            Abbrechen
          </Button>
          <Button type="submit" loading={loading}>
            Speichern
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
