import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { useAppForm } from "app/hooks";
import { Workspace, WorkspaceContact } from "app/models/workspace.model";
import { updateWorkspace, updateWorkspaceContact, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useCallback, useEffect, useState } from "react";
import styles from "./SettingsPageWorkspaceSettings.module.scss";

type Props = {
  workspace: Workspace;
};

type FormData = {
  name: string;
  contact: WorkspaceContact;
};

export const SettingsPageWorkspaceSettings = ({ workspace }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { handleSubmit, register, isValid, isDirty, setValue, reset } = useAppForm<FormData>({
    defaultValues: getDefaultFormData(workspace),
  });

  const handleResetForm = useCallback(() => {
    reset(getDefaultFormData(workspace));
  }, [workspace, reset]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm, workspace?.contact, workspace?.name]);

  const handleContactUpdate = async (data: FormData) => {
    if (!isValid || !isDirty) {
      return;
    }
    setLoading(true);
    try {
      if (data.name !== workspace?.name) {
        trackEvent(AppEvent.RENAME_WORKSPACE, {
          name: data.name,
        });
        await dispatch(updateWorkspace(data.name)).unwrap();
      }

      trackEvent(AppEvent.UPDATE_WORKSPACE_CONTACT, data);
      await dispatch(updateWorkspaceContact(data.contact)).unwrap();
    } catch (error) {
      handleError("Workspace konnte nicht aktualisiert werden.", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTestData = async () => {
    await reset(getTestData(workspace));
    setValue("contact.email", "test@example.com", { shouldDirty: true });
  };

  return (
    <Card item>
      <CardTitle
        title={`Workspace`}
        subtitle="Hier können Sie Ihren Workspace umbenennen oder Ihre Anschrift ändern."
      />
      <form onSubmit={handleSubmit(handleContactUpdate)}>
        <CardContent>
          <CardContentSection title="Workspace Name" description="Wie möchten Sie Ihren Workspace nennen?">
            <Input type="text" placeholder="Name" {...register("name")} className={styles.workspaceName} />
          </CardContentSection>
          <CardContentSection title="Anschrift" description="Ihre Anschrift wird zum Beispiel in Berichten benutzt.">
            <Input type="text" label="Name" placeholder="Name" {...register("contact.name")} />
            <Input
              type="address"
              label="Addresszeile 1"
              placeholder="Musterstraße 1"
              {...register("contact.address1")}
            />
            <Input
              type="address"
              label="Addresszeile 2"
              placeholder="12345 Musterstadt"
              {...register("contact.address2")}
            />
            <Input type="tel" label="Telefon" placeholder="+491523123247" {...register("contact.phone")} />
            <Input type="email" label="E-Mail" placeholder="hanna@example.com" {...register("contact.email")} />
          </CardContentSection>
        </CardContent>
        <CardActions>
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty}>
            Workspace aktualisieren
          </Button>
          <Button type="button" onClick={handleResetForm} theme={ButtonTheme.LINK} disabled={loading || !isDirty}>
            Abbrechen
          </Button>
          <RenderIfTestAccount>
            <Button type="button" onClick={handleTestData} theme={ButtonTheme.LINK} disabled={loading}>
              Vorausfüllen
            </Button>
          </RenderIfTestAccount>
        </CardActions>
      </form>
    </Card>
  );
};

function getDefaultFormData(workspace: Workspace | null) {
  return {
    name: workspace?.name ?? "",
    contact: {
      name: workspace?.contact?.name ?? "",
      address1: workspace?.contact?.address1 ?? "",
      address2: workspace?.contact?.address2 ?? "",
      phone: workspace?.contact?.phone ?? "",
      email: workspace?.contact?.email ?? "",
    },
  };
}

function getTestData(workspace: Workspace | null) {
  return {
    name: workspace?.name ?? "",
    contact: {
      email: "test@example.com",
      address1: "Musterstraße 1",
      address2: "12345 Musterstadt",
      phone: "+49123456789",
    },
  };
}
