import { createAnimatedRoute } from "app/animated-route";
import { APP_CONST } from "app/assets/constants";
import { ICONS } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState, EmptyStateTutorial } from "app/components/EmptyState/EmptyState";
import { k, l } from "app/i18n";
import { GridNavigationItemConfig, GridNavigationLayout } from "app/layouts/GridLayout/GridNavigationLayout";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { useAppSelector } from "app/redux/store.hooks";
import { ROUTES, withNestedRoutes } from "app/routes";
import { htmlToString } from "app/util/html";
import { MonitoringDetail } from "app/views/MonitoringView/MonitoringDetail/MonitoringDetail";
import { MonitoringHelp } from "app/views/MonitoringView/MonitoringHelp/MonitoringHelp";
import { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import styles from "./MonitoringView.module.scss";

const EmptyStateCard = () => {
  const navigate = useNavigate();

  const goToSearch = () => {
    navigate(ROUTES.SEARCH.path);
  };
  return (
    <CardContainer>
      <div className={styles.emptyState}>
        <EmptyState title={l(k.TRADEMARK_MONITORING_EMPTY_STATE_TITLE)} fullWidth>
          <EmptyStateTutorial
            text={l(k.TRADEMARK_MONITORING_EMPTY_STATE_TEXT)}
            image={APP_CONST.IMAGES.TUTORIAL.MONITOR}
          />
          <Button onClick={goToSearch} style={{ width: "100%" }}>
            Jetzt Marken suchen
          </Button>
        </EmptyState>
        <MonitoringHelp />
      </div>
    </CardContainer>
  );
};

export const MonitoringView = () => {
  const { items, loading } = useAppSelector((state) => state.monitoring);
  const hasNoMonitorings = items.length === 0 && !loading;

  const monitorings: GridNavigationItemConfig[] = useMemo(
    () =>
      items
        .map((m) => {
          const unflagged = m.collisions.length || 0;
          return {
            path: m.uid,
            title: m.trademark.name,
            highlight: unflagged > 0,
            subtitle: htmlToString(m.note),
            Icon: ICONS.MONITORING,
            createdAt: m.createdAt,
            children:
              m.collisions.length > 0 ? (
                <div className={styles.collisionPreview}>
                  <span>{m.collisions.length}</span> Kollisionen
                </div>
              ) : null,
          };
        })
        .sort((a, b) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())),
    [items],
  );

  if (hasNoMonitorings) {
    return (
      <OneColumnLayout>
        <EmptyStateCard />
      </OneColumnLayout>
    );
  }

  const animatedRoutes = [
    {
      path: withNestedRoutes(":monitoringId"),
      element: <MonitoringDetail />,
    },
  ];

  return (
    <>
      <Routes>
         {/* Index Route is animated by parent router */}
        <Route
          path=""
          element={
            <>
              <GridNavigationLayout title={l(k.MY_MONITORINGS)} titleIcon={ICONS.MONITORINGS} items={monitorings} />
              <CardContainer>
                <MonitoringHelp />
              </CardContainer>
            </>
          }
        ></Route>
        {animatedRoutes.map(createAnimatedRoute)}
      </Routes>
    </>
  );
};
