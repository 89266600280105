import { Popover } from "app/components/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { useAppSelector } from "app/redux/store.hooks";
import { isMobile } from "react-device-detect";
import styles from "./ToolTipButton.module.css";

interface ViennaToolTipButtonProps {
  vc: string;
}

export const ViennaToolTipButton = ({ vc }: ViennaToolTipButtonProps) => {
  const loading = useAppSelector((state) => state.vienna.loading);
  const classInfo = useAppSelector((state) => state.vienna.map[vc]);

  if (loading) {
    return null;
  }

  if (!classInfo) {
    console.log("NO VIENNA CLASS FOUND", vc);
  }

  const text = classInfo?.text || "Zu dieser Klasse gibt es leider aktuell keinen Text.";

  const button = <span className={styles.text}>{vc}</span>;

  if (isMobile) {
    return button;
  }

  return (
    <Popover placement="bottom" hoverOnly={true} showOnHover={true} buttonElement={(active) => button}>
      <PopoverContent className={styles.tooltipContent}>{text}</PopoverContent>
    </Popover>
  );
};
