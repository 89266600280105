import { getClient } from "app/api";
import { Portfolio, ResolvedPortfolio, TrademarkReferenceWithNote } from "app/models";
import { CommentVisibility } from "app/models/trademark-comment.model";
import { TrademarkReference } from "app/models/trademark-reference.model";
import { Trademark } from "app/models/trademark.model";
import { mapPortfolio } from "app/util/portfolio.util";
import { mapResearch } from "app/util/research.util";
import { mapQueryResult, mapTrademark } from "app/util/trademark.util";

const client = getClient({ basePath: "/portfolio" });

export const PortfolioClient = {
  fetch: async () => {
    const { data } = await client.get<Portfolio[]>("/");
    return data.map(mapPortfolio);
  },
  fetchResolved: async (id: string) => {
    const { data } = await client.get<ResolvedPortfolio>(`/${id}`);
    return {
      ...data,
      portfolio: mapPortfolio(data.portfolio),
      trademarks: mapQueryResult(data.trademarks),
      researches: data.researches.map(mapResearch),
    };
  },
  create: async (portfolio: Pick<Portfolio, "name" | "trademarks" | "note" | "contact">) => {
    const { data } = await client.post<Portfolio>("/", portfolio);
    return mapPortfolio(data);
  },
  update: async ({
    id,
    portfolio,
  }: {
    id: string;
    portfolio: Partial<Pick<Portfolio, "name" | "note" | "contact">>;
  }) => {
    const { data } = await client.put<Portfolio>(`/${id}`, portfolio);
    return mapPortfolio(data);
  },
  delete: async (id: string) => {
    const { data } = await client.delete(`/${id}`);
    return data;
  },
  addTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/trademarks/add`, {
      trademarks,
    });
    return mapPortfolio(data);
  },
  sortTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/trademarks/sort`, {
      trademarks,
    });
    return mapPortfolio(data);
  },
  removeTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/trademarks/remove`, {
      trademarks,
    });
    return mapPortfolio(data);
  },
  addCollections: async ({ id, collections }: { id: string; collections: string[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/collections/add`, {
      collections,
    });
    return mapPortfolio(data);
  },
  removeCollections: async ({ id, collections }: { id: string; collections: string[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/collections/remove`, {
      collections,
    });
    return mapPortfolio(data);
  },
  addResearches: async ({ id, researches }: { id: string; researches: string[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/researches/add`, {
      researches,
    });
    return mapPortfolio(data);
  },
  removeResearches: async ({ id, researches }: { id: string; researches: string[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/researches/remove`, {
      researches,
    });
    return mapPortfolio(data);
  },
  addMonitorings: async ({ id, monitorings }: { id: string; monitorings: string[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/monitorings/add`, {
      monitorings,
    });
    return mapPortfolio(data);
  },
  removeMonitorings: async ({ id, monitorings }: { id: string; monitorings: string[] }) => {
    const { data } = await client.post<Portfolio>(`/${id}/monitorings/remove`, {
      monitorings,
    });
    return mapPortfolio(data);
  },
  updateTrademarkNotes: async ({ id, trademarks }: { id: string; trademarks: TrademarkReferenceWithNote[] }) => {
    const { data } = await client.put<Portfolio>(`/${id}/notes`, {
      trademarks,
    });
    return mapPortfolio(data);
  },
  addComment: async ({ id, comment, visibility }: { id: string; comment: string; visibility: CommentVisibility }) => {
    const { data } = await client.post<Portfolio>(`/${id}/comments`, {
      comment,
      visibility,
    });
    return mapPortfolio(data);
  },
  updateComment: async ({
    id,
    commentId,
    comment,
    visibility,
  }: {
    id: string;
    commentId: string;
    comment: string;
    visibility: CommentVisibility;
  }) => {
    const { data } = await client.put<Portfolio>(`/${id}/comments/${commentId}`, {
      comment,
      visibility,
    });
    return mapPortfolio(data);
  },
  deleteComment: async ({ id, commentId }: { id: string; commentId: string }) => {
    const { data } = await client.delete<Portfolio>(`/${id}/comments/${commentId}`);
    return mapPortfolio(data);
  },
  getSuggestions: async (portfolioId: string) => {
    const { data } = await client.get<Trademark[]>(`/${portfolioId}/suggestions`);
    return data.map(mapTrademark);
  },
  download: async (portfolioId: string, type: "pdf" | "csv" | "xlsx") => {
    return client.get(`/${portfolioId}/${type}`, {
      responseType: "arraybuffer",
    });
  },
};
