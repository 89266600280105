// import { captureException } from "@sentry/react";
import { toast, ToastContent } from "react-toastify";
import { isProductionEnvironment } from "./env.util";

export function handleWarning(message: ToastContent, warning: any) {
  console.warn(warning);
  toast.warn(message);
}

export function handleError(message: ToastContent, error: any) {
  if (isProductionEnvironment()) {
    // captureException(error);
  }
  console.error(error);
  toast.error(message);
}

export function handleUnknownError(error: any) {
  if (isProductionEnvironment()) {
    // captureException(error);
  }
  console.error(error);

  const email = "info@oktomark.de";

  const mailto = `mailto:${email}`;

  toast.error(
    <span>
      Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder kontaktieren Sie unseren{" "}
      <a
        href={mailto}
        style={{ color: "white", textDecoration: "underline" }}
        target="_blank"
        rel="noreferrer nofollow noopener"
      >
        Kundenservice
      </a>
      .
    </span>,
  );
}

export function handleUnknownAdminError(error: any) {
  if (isProductionEnvironment()) {
    // captureException(error);
  }
  console.error(error);
  toast.error(`Es ist ein Fehler aufgetreten: ${JSON.stringify(error)}`);
}
