import { TrademarkClient } from "app/api";
import { Card, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { DisplayTypeSwitch } from "app/components/DisplayTypeSwitch/DisplayTypeSwitch";
import { ThemeToggle } from "app/components/ThemeToggle/ThemeToggle";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { useAppDarkMode } from "app/hooks";
import { Trademark, TrademarkOffice } from "app/models/trademark.model";
import { updateUserInterfaceDefaults } from "app/redux";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useEffect, useState } from "react";
import styles from "./SettingsPageInterface.module.scss";

export const SettingsPageInterface = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [displayType, setDisplayType] = useState(
    user?.interfaceDefaults?.trademarkDisplayType || TrademarkCardDisplayType.CARD,
  );
  const darkMode = useAppDarkMode();
  const [trademark, setTrademark] = useState<Trademark>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    TrademarkClient.fetch({
      id: "3020212476208",
      office: TrademarkOffice.DPMA,
    }).then(setTrademark);
  }, []);

  const handleThemeToggle = async (enabled: boolean) => {
    handleSave({
      darkMode: enabled,
      trademarkDisplayType: displayType,
    });
  };

  const handleDisplayTypeChange = (dt: TrademarkCardDisplayType) => {
    setDisplayType(dt);
    handleSave({ darkMode: darkMode.value, trademarkDisplayType: dt });
  };

  const handleSave = async (interfaceDefaults: {
    darkMode: boolean;
    trademarkDisplayType: TrademarkCardDisplayType;
  }) => {
    trackEvent(AppEvent.SAVE_INTERFACE_DEFAULTS, interfaceDefaults);
    try {
      await dispatch(updateUserInterfaceDefaults(interfaceDefaults)).unwrap();
    } catch (error) {
      handleError("Einstellungen konnten nicht gespeichert werden.", error);
    }
  };

  const descriptions: Record<string, string> = {
    [TrademarkCardDisplayType.CARD]: "Kartenansicht mit mehr Infos",
    [TrademarkCardDisplayType.COMPACT]: "Kompakte Darstellung",
    [TrademarkCardDisplayType.IMAGE]: "Bildraster",
  };

  return (
    <CardContainer className={styles.firstCard}>
      <Card className={styles.card}>
        <CardTitle title="Benutzeroberfläche" subtitle="Passen Sie die App auf ihre Bedürfnisse an" />
        <CardContent>
          <CardContentSection title="Design" description="Wählen Sie zwichen einem hellen und einem dunkelen Design.">
            <div className={styles.row}>
              <ThemeToggle onChange={handleThemeToggle} />
              <p>{darkMode ? "Dunkles Design" : "Helles Design"}</p>
            </div>
          </CardContentSection>
          <CardContentSection title="Standard Ansicht" description="Legen Sie die Standard Ansicht für Marken fest.">
            <div className={styles.row}>
              <DisplayTypeSwitch type={displayType} onChange={handleDisplayTypeChange} />
              <p>{descriptions[displayType]}</p>
            </div>
            {trademark && (
              <CardContainer className={styles.preview}>
                <TrademarkCard trademark={trademark} displayType={displayType} hideActions hideLink />
              </CardContainer>
            )}
          </CardContentSection>
        </CardContent>
      </Card>
    </CardContainer>
  );
};
