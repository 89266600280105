import { CollapsibleCard } from "app/components/CollapsibleCard/CollapsibleCard";

export const MonitoringHelp = () => {
  return (
    <CollapsibleCard title="Wie funktioniert die Markenüberwachung?">
      <p>Wenn Sie eine neue Überwachung anlegen, beginnt Oktomark damit, die Marke für Sie zu beobachten.</p>
      <p>Wir halten Sie auf dem Laufenden und benachrichtigen Sie z. B. bei den folgenden Ereignissen:</p>
      <ul>
        <li>
          Eine <strong>mögliche Kollision</strong> wurde entdeckt.
        </li>
        <li>Die überwachte Marke ändert sich.</li>
        <li>
          Eine Marke, die als <strong>problematisch</strong> eingestuft wurde, ändert sich.
        </li>
      </ul>
      <p>
        Um Ihre Überwachung zu verbessern, brauchen wir Ihre Hilfe. Markieren Sie potenzielle Kollisionen, die Oktomark
        gefunden hat, als <strong>problematisch</strong> oder <strong>unproblematisch</strong>.
      </p>
      <p>So lernt Oktomark automatisch dazu und kann Sie in Zukunft noch besser unterstützen.</p>
    </CollapsibleCard>
  );
};
