import { FeedbackForm } from "app/components/FeedbackForm/FeedbackForm";
import { Modal, ModalContent } from "app/components/Modal/Modal";

interface FeedbackModalProps {
  open: boolean;
  onClose: () => void;
}

export const FeedbackModal = ({ open, onClose }: FeedbackModalProps) => {
  return (
    <Modal open={open} onClose={onClose} title={"Feedback"} subtitle="Sagen Sie uns, was Sie denken">
      <ModalContent>
        <FeedbackForm onSuccess={onClose} />
      </ModalContent>
    </Modal>
  );
};
