import { sendFeedback } from "app/api";
import { Button } from "app/components/Button/Button";
import { TextArea } from "app/components/TextArea/TextArea";
import { useAppForm } from "app/hooks";
import { useAppSelector } from "app/redux";
import { handleUnknownError } from "app/util/error-handler";
import { getCurrentFingerPrint } from "app/util/tracking.util";
import { useState } from "react";
import { toast } from "react-toastify";
import styles from "./FeedbackForm.module.scss";

interface Props {
  onSuccess: () => any;
}

const templates = [
  "Oktomark hat mein Problem nicht gelöst",
  "Oktomark hat schlechte Ergebnisse geliefert",
  "Mir fehlt ein Feature",
  "Mir gefällt",
];

type FeedBackFormData = {
  text: string;
};

export const FeedbackForm = ({ onSuccess }: Props) => {
  const [loading, setLoading] = useState(false);
  const token = useAppSelector((state) => state.auth.token);

  const { handleSubmit, register, setValue, setFocus } = useAppForm<FeedBackFormData>({
    defaultValues: {
      text: "",
    },
  });

  const onSendFeedback = async (data: FeedBackFormData) => {
    if (!data.text) return;

    try {
      setLoading(true);
      const metaInfo = getCurrentFingerPrint();
      await sendFeedback(data.text, metaInfo, token);
      toast.success("Danke für Ihr Feedback!");
      onSuccess();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSetTemplate = (t: string) => () => {
    setValue("text", `${t} `);
    setFocus("text");
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSendFeedback)}>
      <p>
        Sie haben eine Idee für eine neue Funktion oder etwas funktioniert nicht so, wie Sie es erwartet hätten? Wir
        freuen uns auf Ihre Nachricht.
      </p>
      <div className={styles.templates}>
        {templates.map((t) => (
          <button type="button" key={t} onClick={handleSetTemplate(t)}>
            {t} &hellip;
          </button>
        ))}
      </div>
      <TextArea
        rows={5}
        className={styles.textarea}
        autoFocus={true}
        placeholder="Ihre Nachricht ..."
        {...register("text")}
      />
      <Button type="submit" className={styles.button} loading={loading}>
        Feedback senden
      </Button>
    </form>
  );
};
