import { PageContextProvider } from "app/context";
import { AppInterfaceTheme } from "app/models/app-interface-theme.enum";
import { store } from "app/redux";
import { RootView } from "app/RootView";
//global styles
import { setThemeBodyClass } from "app/style";
import "app/style/fonts/dmsans/dmsans.css";
import "app/style/index.css";
import "app/style/ReactCollapse.css";
import "app/style/scrollbar.css";
import { StyledToastContainer } from "app/style/StyledToastContainer";
import "app/style/theme.css";
import { ScrollToTop } from "app/util/ScrollToTop";
import { initTracking } from "app/util/tracking.util";
import { TrackPageView } from "app/util/TrackPageView";
import "normalize.css";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

function init() {
  setThemeBodyClass(AppInterfaceTheme.LIGHT);

  initTracking();

  const container = document.getElementById("root");
  if (!container) {
    return;
  }
  const root = createRoot(container);

  //TODO: re-enable StrictMode when https://github.com/atlassian/react-beautiful-dnd/issues/2399 is resolved
  root.render(
    <>
      <BrowserRouter>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <PageContextProvider>
              <ScrollToTop />
              <TrackPageView />
              <RootView />
              <StyledToastContainer hideProgressBar={true} icon={false} position="bottom-right" />
            </PageContextProvider>
          </DndProvider>
        </Provider>
      </BrowserRouter>
    </>,
  );
}

init();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
