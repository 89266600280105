import { FutziSearchQuery } from "app/api";
import { l } from "app/i18n";
import { k } from "app/i18n/translations";
import { SavedSearch } from "app/models";
import { Trademark } from "app/models/trademark.model";
import deepEqual from "deep-equal";
import { pick } from "lodash-es";
import { toDate } from "./date.util";

export const mapSavedSearch = (savedSearch: SavedSearch): SavedSearch => ({
  ...savedSearch,
  createdAt: toDate(savedSearch.createdAt) || new Date(),
  updatedAt: toDate(savedSearch.updatedAt) || new Date(),
});

export function isTrademarkIgnored(
  savedSearch: SavedSearch | null | undefined,
  result: Trademark,
): boolean | undefined {
  return savedSearch?.ignored?.map(({ trademarkId }) => trademarkId).includes(result.id);
}

export const guessSavedSearchTitle = (savedSearch: SavedSearch) => {
  if (savedSearch.name) {
    return savedSearch.name;
  }

  if (savedSearch.query) {
    return savedSearch.query;
  }

  if (savedSearch.owner) {
    return `${l(k.OWNER)} ${savedSearch.owner}`;
  }

  if (savedSearch.representative) {
    return `${l(k.REPRESENTATIVE)}: ${savedSearch.representative}`;
  }

  return "-";
};

export const countFilters = (filters: FutziSearchQuery["filters"]) => {
  return (
    (filters?.office?.length || 0) +
    (filters?.type?.length || 0) +
    (filters?.status?.length || 0) +
    (filters?.niceClass?.length || 0) +
    (filters?.viennaClass?.length || 0) +
    (filters?.owner?.length || 0) +
    (filters?.representative?.length || 0)
  );
};

export const cleanSavedSearch = (savedSearch: SavedSearch) =>
  pick(savedSearch, ["filters", "ignored", "owner", "query", "representative", "sortBy", "sortOrder"]);

export const matchSavedSearch = (savedSearch: SavedSearch) => (s: SavedSearch) =>
  deepEqual(cleanSavedSearch(savedSearch), cleanSavedSearch(s), { strict: true });
