import { PortfolioClient } from "app/api";
import { EditableTrademarkList } from "app/components/EditableTrademarkList/EditableTrademarkList";
import { ExportType } from "app/components/Modals/ExportModal/ExportModal";
import { Tutorial, TutorialStep } from "app/components/Tutorial/Tutorial";
import { pluralize } from "app/i18n";
import { ContextType, PortfolioContact, TrademarkReference, TrademarkReferenceWithNote, UserFlag } from "app/models";
import { Research } from "app/models/research.model";
import { Trademark } from "app/models/trademark.model";
import { searchSlice, updateUserContext } from "app/redux";
import { AppModal, showModal } from "app/redux/slices/modal.slice";
import {
  deletePortfolio,
  removeTrademarksFromPortfolio,
  sortTrademarksInPortfolio,
  updatePortfolio,
  updateTrademarkPortfolioNotes,
} from "app/redux/slices/portfolio.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { ROUTES } from "app/routes";
import { handleError, handleUnknownError } from "app/util/error-handler";
import { sanitizeForFileName } from "app/util/string.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { matchesTrademark } from "app/util/trademark-reference.util";
import { PortfolioHeader } from "app/views/PortfoliosView/PortfolioDetail/PortfolioHeader/PortfolioHeader";
import { PortfolioResearches } from "app/views/PortfoliosView/PortfolioDetail/PortfolioResearches/PortfolioResearches";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PortfolioEmptyState } from "./PortfolioEmptyState/PortfolioEmptyState";

const tutorialSteps: TutorialStep[] = [
  {
    title: "Mandanteninfo",
    target: ".tutorial-portfolio-info",
    content:
      "Hier können Sie die Kontaktdaten für Ihren Mandanten und interne Notizen hinterlegen. Die Kontaktdaten werden z. B. für Berichte benutzt.",
    disableBeacon: true,
  },
  {
    title: "Recherchen",
    target: ".tutorial-portfolio-research-empty-state",
    content: "Hier sind alle Recherchen zu diesem Mandanten aufgeführt. Starten Sie jetzt Ihre erste Recherche.",
    disableBeacon: true,
  },
];

export const PortfolioDetail = () => {
  const { portfolioId } = useParams<{ portfolioId: string }>();
  const portfolio = useAppSelector((state) => state.portfolio.items.find((m) => m.uid === portfolioId));

  const [trademarks, setTrademarks] = useState<Trademark[]>([]);
  const [researches, setResearches] = useState<Research[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      portfolio &&
      ((portfolio.trademarks && portfolio.trademarks.length > 0) ||
        (portfolio.researches && portfolio.researches.length > 0))
    ) {
      if (trademarks.length === 0 && researches.length === 0) {
        setLoading(true);
      }
      // TODO: Move this to redux to get websocket updates aka. real time collaboration
      PortfolioClient.fetchResolved(portfolio.uid).then((res) => {
        setTrademarks(
          res.trademarks.trademarks.map((t) => ({
            ...t,
            note: portfolio.trademarks?.find(matchesTrademark(t))?.note,
          })),
        );
        setResearches(res.researches);
        setLoading(false);
      });
    } else {
      // Reset
      setTrademarks([]);
      setResearches([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolio]);

  if (!portfolio) {
    return null;
  }

  const { name, uid, note } = portfolio;

  const handleRename = () => {
    showModal(dispatch)({
      type: AppModal.CHANGE_VALUE,
      props: {
        title: `Portfolio ${name} umbenennen`,
        description: `Wie möchten Sie diesen Mandant nennen?`,
        defaultValue: name,
        onSubmit: async (name: string) => {
          trackEvent(AppEvent.RENAME_PORTFOLIO, {
            uid,
            name,
          });
          try {
            await dispatch(updatePortfolio({ id: uid, portfolio: { name, note: note || "" } }));
          } catch (error) {
            handleError("Mandant konnte nicht umbenannt werden", error);
          }
        },
      },
    });
  };

  const handleEditContact = () => {
    showModal(dispatch)({
      type: AppModal.EDIT_CONTACT,
      props: {
        title: `Mandanteninfo`,
        description: `Hinterlegen Sie Kontakinformationen für diesen Mandanten`,
        contact: portfolio.contact,
        onSubmit: async (newContact: PortfolioContact) => {
          trackEvent(AppEvent.UPDATE_PORTFOLIO_NOTE, {
            uid,
          });
          try {
            await dispatch(updatePortfolio({ id: uid, portfolio: { contact: newContact } }));
          } catch (error) {
            handleError("Mandant konnte nicht gespeichert werden", error);
          }
        },
      },
    });
  };

  const handleDelete = () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Mandant ${name} wirklich löschen?`,
        text: `Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          trackEvent(AppEvent.DELETE_PORTFOLIO, {
            uid,
          });

          try {
            await dispatch(deletePortfolio(uid)).unwrap();
            navigate(ROUTES.PORTFOLIO.path);
          } catch (error) {
            handleError("Mandant konnte nicht gelöscht werden", error);
          }
        },
      },
    });
  };

  const handleExport = () => {
    showModal(dispatch)({
      type: AppModal.EXPORT,
      props: {
        title: `Mandant exportieren`,
        onExport: (type: ExportType) => handleDownload(uid, type)(),
      },
    });
  };

  const handleDebug = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: {
          portfolio,
          trademarks,
        },
      },
    });
  };

  const activatePortfolioContext = async () => {
    await dispatch(
      updateUserContext({
        type: ContextType.PORTFOLIO,
        portfolioId: portfolio.uid,
      }),
    ).unwrap();
    dispatch(
      searchSlice.actions.startNewSearch({
        owner: portfolio.name,
      }),
    );
    navigate(ROUTES.SEARCH.path);
  };

  const handleNoteChange = async (newNote: string) => {
    trackEvent(AppEvent.UPDATE_PORTFOLIO_NOTE, {
      uid,
    });
    try {
      await dispatch(updatePortfolio({ id: uid, portfolio: { note: newNote } }));
    } catch (error) {
      handleError("Notiz konnte nicht gespeichert werden", error);
    }
  };

  const handleDownload = (portfolioId: string, type: ExportType) => () => {
    trackEvent(AppEvent.DOWNLOAD_PORTFOLIO, {
      uid: portfolioId,
      type,
    });
    return PortfolioClient.download(portfolioId, type)
      .then((response) => {
        const date = format(new Date(), "dd-MM-yyyy");

        const filename = `Mandantenportfolio - ${sanitizeForFileName(portfolio.name)} - ${date}.${type}`;

        // FIXME: Don't know if this works on all devices and browsers
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => handleUnknownError(error));
  };

  const handleSaveSortOrder = async (trademarkReferences: TrademarkReference[]) => {
    const sortedTrademarkReferences = [...(portfolio.trademarks || [])].sort(
      (a, b) => trademarkReferences.findIndex(matchesTrademark(a)) - trademarkReferences.findIndex(matchesTrademark(b)),
    );

    await dispatch(
      sortTrademarksInPortfolio({
        id: portfolio.uid,
        trademarks: sortedTrademarkReferences,
      }),
    ).unwrap();
  };

  const handleRemoveTrademarks = async (trademarkReferences: TrademarkReference[]) => {
    await dispatch(
      removeTrademarksFromPortfolio({
        id: portfolio.uid,
        trademarks: trademarkReferences,
      }),
    ).unwrap();
  };

  const handleTrademarkNoteUpdate = async (trademarkReference: TrademarkReferenceWithNote) => {
    await dispatch(
      updateTrademarkPortfolioNotes({
        id: portfolio.uid,
        trademarks: [trademarkReference],
      }),
    ).unwrap();
  };

  return (
    <>
      <Tutorial flag={UserFlag.TUTORIAL_PORTFOLIO} steps={tutorialSteps} />
      <PortfolioHeader
        portfolio={portfolio}
        onRename={handleRename}
        onDelete={handleDelete}
        onExport={handleExport}
        onUpdateNote={handleNoteChange}
        onEditContact={handleEditContact}
        onDebug={handleDebug}
      />
      <PortfolioResearches portfolio={portfolio} researches={researches} />
      <EditableTrademarkList
        loading={loading}
        trademarks={trademarks}
        loadingMessage={`Portfolio ${portfolio.name} wird geladen`}
        emptyState={<PortfolioEmptyState portfolio={portfolio} />}
        title={`${pluralize("trademarks", trademarks.length)} in diesem Portfolio`}
        onSaveSortOrder={handleSaveSortOrder}
        onAddTrademarks={activatePortfolioContext}
        onRemoveTrademarks={handleRemoveTrademarks}
        onTrademarkNoteUpdate={handleTrademarkNoteUpdate}
      />
      {/* {!loading ? <PortfolioSuggestions portfolioId={uid} excludes={trademarks} /> : null} */}
    </>
  );
};
