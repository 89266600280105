import { useHasUserFlag } from "app/hooks";
import { useAppDarkMode } from "app/hooks/use-app-dark-mode";
import { UserFlag } from "app/models/user.model";
import { removeUserFlag } from "app/redux";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";

export type TutorialStep = Step;

type TutorialProps = {
  flag: UserFlag;
  steps: TutorialStep[];
  lastButtonText?: string;
};

export const Tutorial = ({ flag, steps, lastButtonText }: TutorialProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const darkmode = useAppDarkMode();
  const dispatch = useAppDispatch();

  const hasTutorialFlag = useHasUserFlag(flag);

  if (!user) {
    return null;
  }

  const callback = ({ status }: CallBackProps) => {
    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      dispatch(removeUserFlag(flag));
    }
  };

  return (
    <Joyride
      run={hasTutorialFlag}
      steps={steps}
      callback={callback}
      continuous={true}
      disableScrolling
      disableOverlayClose
      disableCloseOnEsc
      showSkipButton
      locale={{
        back: "Zurück",
        close: "Schließen",
        last: lastButtonText || "Okay",
        next: "Weiter",
        skip: "Tutorial überspringen",
      }}
      styles={{
        options: {
          // Copied from theme-dark/theme-light
          primaryColor: "#008ffb",
          textColor: darkmode.value ? "rgb(241, 241, 241)" : "rgb(47, 47, 47)",
          arrowColor: darkmode.value ? "rgb(45, 45, 45)" : "#FFFFFF",
          backgroundColor: darkmode.value ? "rgb(45, 45, 45)" : "#FFFFFF",

          zIndex: 1000000,
        },
      }}
    />
  );
};
