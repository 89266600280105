import { getClient } from "app/api";
import { SavedSearch } from "app/models";
import { TrademarkOffice } from "app/models/trademark.model";
import { mapSavedSearch } from "app/util/saved-search.util";

const client = getClient({ basePath: "/saved-search" });

export const SavedSearchClient = {
  fetch: async () => {
    const { data } = await client.get<SavedSearch[]>("/");
    return data.map(mapSavedSearch);
  },

  create: async (savedSearch: Partial<SavedSearch> & { researchId?: string }) => {
    const req = sanitizeRequest(savedSearch);
    const { data } = await client.post<SavedSearch>("/", req);
    return mapSavedSearch(data);
  },

  update: async (savedSearch: Partial<SavedSearch>) => {
    const req = sanitizeRequest(savedSearch);
    const { data } = await client.put<SavedSearch>(`/${savedSearch.uid}`, req);
    return mapSavedSearch(data);
  },

  delete: async (id: string) => {
    const { data } = await client.delete<any>(`/${id}`);
    return data;
  },

  addIgnoredTrademark: async (id: string, trademarkId: string, trademarkOffice: TrademarkOffice) => {
    const { data } = await client.post<SavedSearch>(`/${id}/ignored`, {
      trademarkId,
      trademarkOffice,
    });
    return mapSavedSearch(data);
  },

  removeIgnoredTrademark: async (id: string, trademarkId: string, trademarkOffice: TrademarkOffice) => {
    const { data } = await client.delete<SavedSearch>(`/${id}/ignored/${trademarkOffice}/${trademarkId}`);
    return mapSavedSearch(data);
  },
};

// Delete unwanted properties
// TODO: find a better way
const sanitizeRequest = (savedSearch: Partial<SavedSearch>) => {
  let req: any = {
    ...savedSearch,
  };

  delete req.createdAt;
  delete req.updatedAt;
  delete req.id;
  delete req.userId;
  delete req.uid;
  delete req.ignored;
  delete req.limit;
  delete req.offset;

  return req;
};
