import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { searchMiddleware } from "./middleware";
import {
  authReducer,
  collectionReducer,
  feedReducer,
  modalReducer,
  monitoringReducer,
  nizzaReducer,
  notificationReducer,
  partnerReducer,
  paymentReducer,
  portfolioReducer,
  researchReducer,
  searchReducer,
  systemInfoReducer,
  viennaReducer,
  workspaceReducer,
} from "./slices";

export const rootReducer = combineReducers({
  auth: authReducer,
  collection: collectionReducer,
  feed: feedReducer,
  modal: modalReducer,
  monitoring: monitoringReducer,
  nizza: nizzaReducer,
  notification: notificationReducer,
  partner: partnerReducer,
  payment: paymentReducer,
  portfolio: portfolioReducer,
  research: researchReducer,
  search: searchReducer,
  systemInfo: systemInfoReducer,
  vienna: viennaReducer,
  workspace: workspaceReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(searchMiddleware),
});
