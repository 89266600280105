import { ICONS } from "app/assets/icons/icons";
import { Card } from "app/components/Card/Card";
import { IconButton } from "app/components/IconButton/IconButton";
import { useInterfaceWidth } from "app/hooks";
import { searchSlice, useAppDispatch, useAppSelector } from "app/redux";
import clsx from "clsx";
import { useCallback, useState } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import styles from "./ImageSearchOverlay.module.scss";
interface Props {
  className?: string;
  children?: React.ReactNode;
}

type BoxPosition = {
  bottom: number;
  left: number;
};

export type DragItem = BoxPosition & {
  type: string;
  id: string;
};

const DRAG_TYPE = "image-search-drag";
const INITIAL_POSITION = { bottom: 16, left: 80 };

const SIZE_LARGE = 250;
const SIZE_SMALL = 80;

export const ImageSearchOverlay = ({ className, children, ...props }: Props) => {
  const image = useAppSelector((state) => state.search.image);
  const [box, setBox] = useState<BoxPosition>(INITIAL_POSITION);
  const [lastUserPosition, setLastUserPosition] = useState<BoxPosition>(INITIAL_POSITION);
  const [minimized, setMinimized] = useState(false);
  const [width, setWidth] = useState(SIZE_LARGE);
  const { isXs, isSm } = useInterfaceWidth();
  const dispatch = useAppDispatch();
  const moveBox = useCallback(
    (left: number, bottom: number) => {
      setBox({ left, bottom });
      setLastUserPosition({ left, bottom });
    },
    [setBox],
  );

  const [, dropParent] = useDrop(
    () => ({
      accept: DRAG_TYPE,
      drop(item: DragItem, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.bottom - delta.y);
        // console.log("ImageSearchOverlay", "move", item, delta, { left, top });
        moveBox(left, top);
        return undefined;
      },
    }),
    [moveBox],
  );

  const [{ isDragging }, dragChild] = useDrag(
    () => ({
      type: DRAG_TYPE,
      item: box,
      collect: (monitor) => {
        return {
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [box],
  );

  const handleClose = async () => {
    // TODO: maybe we should only remove the image and leave all filters untouched
    // await dispatch(searchSlice.actions.updateImage(null));

    await dispatch(searchSlice.actions.reset());
  };

  const toggleMinimized = () => {
    if (minimized) {
      setMinimized(false);
      setBox(lastUserPosition);
      setWidth(SIZE_LARGE);
    } else {
      setMinimized(true);
      setBox(INITIAL_POSITION);
      setWidth(SIZE_SMALL);
    }
  };

  const isSmallScreen = isXs && !isSm;

  if (!image || isSmallScreen) {
    return null;
  }

  return (
    <div
      className={clsx(styles.root, {
        [styles.capturePointer]: isDragging,
      })}
    >
      <div
        ref={dropParent}
        className={clsx(styles.dropParent, {
          [styles.capturePointer]: isDragging,
        })}
      >
        <div ref={dragChild} style={box} className={styles.dragChild}>
          <Card
            className={clsx(styles.card, {
              [styles.dragging]: isDragging,
            })}
          >
            <div className={styles.header}>
              <IconButton onClick={handleClose} title="Schließen" className={styles.closeButton}>
                <ICONS.CLOSE />
              </IconButton>
              <IconButton
                onClick={toggleMinimized}
                title={minimized ? "Maximieren" : "Minimieren"}
                className={styles.closeButton}
              >
                {/* {minimized ? <ICONS.EXPAND /> : <ICONS.COLLAPSE />} */}
                {minimized ? <ICONS.MAXIMIZE /> : <ICONS.MINIMIZE />}
              </IconButton>
            </div>
            <div className={styles.imageWrapper} style={{ width }}>
              <img className={styles.image} style={{ width }} src={URL.createObjectURL(image)} alt="Aktuelles Bild" />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};
