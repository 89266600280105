import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { Card, CardContent } from "app/components/Card/Card";
import { FeedbackForm } from "app/components/FeedbackForm/FeedbackForm";
import { useTimeout } from "app/hooks/use-timeout.hook";
import { useAppSelector } from "app/redux/store.hooks";
import { NO_AUTH_ROUTES } from "app/routes";
import clsx from "clsx";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sharedStyles from "./../SharedNoAuthViewStyles.module.css";
import styles from "./LogoutCard.module.scss";

export const LogoutCard = () => {
  const navigate = useNavigate();
  const token = useAppSelector((state) => state.auth.token);
  const [showSuccess, setShowSuccess] = useState(false);

  const goToLoginAndFinishLogout = useCallback(() => {
    navigate(NO_AUTH_ROUTES.LOGIN);
    // HACK: Reload the whole page
    // This drops the current state and forces the browser to load the latest version of the app
    // https://stackoverflow.com/a/50263511
    window.location.reload();
  }, [navigate]);

  const handleFeedbackSent = () => {
    setShowSuccess(true);
    window.setTimeout(goToLoginAndFinishLogout, 5000);
  };

  useEffect(() => {
    if (!token) {
      goToLoginAndFinishLogout();
    }
  }, [token, goToLoginAndFinishLogout]);

  // reload page after some time to clear states
  useTimeout(goToLoginAndFinishLogout, 300000);

  return (
    <Card className={sharedStyles.root}>
      <div className={styles.success}>
        <div className={clsx(sharedStyles.headline, styles.headline)}>Sie haben sich erfolgreich ausgeloggt.</div>
        <p className={clsx(sharedStyles.subheadline, styles.subheadline)}>Danke, dass Sie Oktomark benutzten! </p>
        <img className={sharedStyles.image} src={APP_CONST.IMAGES.LOGOUT} alt="" />
      </div>
      <CardContent>
        {showSuccess ? (
          <div className={styles.feedbackSent}>
            <h2>Vielen Dank!</h2>
            <Button onClick={goToLoginAndFinishLogout}>Zum Login</Button>
          </div>
        ) : (
          <FeedbackForm onSuccess={handleFeedbackSent} />
        )}
      </CardContent>
    </Card>
  );
};
