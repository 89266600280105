import { TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import cardViewImage from "../../assets/icons/card_view.svg";
import imageViewImage from "../../assets/icons/image_view.svg";
import tableViewImage from "../../assets/icons/table_view.svg";
import { ButtonGroup } from "../ButtonGroup/ButtonGroup";

interface Props {
  onChange: (type: TrademarkCardDisplayType) => unknown;
  type: TrademarkCardDisplayType;
}

export const DisplayTypeSwitch = ({ type, onChange }: Props) => {
  const handleClick = (dt: TrademarkCardDisplayType) => () => {
    trackEvent(AppEvent.CHANGE_DEFAULT_DISPLAY_TYPE, {
      dt,
    });
    onChange(dt);
  };

  return (
    <ButtonGroup
      options={[
        {
          id: TrademarkCardDisplayType.COMPACT,
          title: <img src={tableViewImage} alt="" />,
          active: type === TrademarkCardDisplayType.COMPACT,
          onClick: handleClick(TrademarkCardDisplayType.COMPACT),
        },
        {
          id: TrademarkCardDisplayType.CARD,
          title: <img src={cardViewImage} alt="" />,
          active: type === TrademarkCardDisplayType.CARD,
          onClick: handleClick(TrademarkCardDisplayType.CARD),
        },
        {
          id: TrademarkCardDisplayType.IMAGE,
          title: <img src={imageViewImage} alt="" />,
          active: type === TrademarkCardDisplayType.IMAGE,
          onClick: handleClick(TrademarkCardDisplayType.IMAGE),
        },
      ]}
    />
  );
};
