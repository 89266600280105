import { Popover } from "app/components/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { NiceClass } from "app/models/trademark.model";
import { useAppSelector } from "app/redux/store.hooks";
import { ellipsify } from "app/util/string.util";
import { isMobile } from "react-device-detect";
import styles from "./ToolTipButton.module.css";

interface NiceToolTipButtonProps {
  nc: NiceClass;
}

export const NiceToolTipButton = ({ nc }: NiceToolTipButtonProps) => {
  const systemClass = useAppSelector((state) => state.nizza.map[nc.id]?.heading);
  const text = ellipsify(
    nc.description || `(Vorläufig) - ${systemClass}` || "Zu dieser Klasse gibt es leider aktuell keinen Text.",
    200,
  );
  const button = <span className={styles.text}>{nc.id}</span>;

  if (isMobile) {
    return button;
  }

  return (
    <Popover placement="bottom" hoverOnly={true} showOnHover={true} buttonElement={(active) => button}>
      <PopoverContent className={styles.tooltipContent}>{text}</PopoverContent>
    </Popover>
  );
};
