import { Card } from "app/components/Card/Card";
import { localizedDate } from "app/util/date.util";
import clsx from "clsx";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";
import styles from "./PreviewCardNavItem.module.scss";

interface Props {
  path: string;
  title: string;
  subtitle?: string;
  Icon: IconType;
  children?: React.ReactNode;
  createdAt?: Date;
  actions?: React.ReactNode;
  onClick?: () => unknown;
}

export const PreviewCardNavItem = ({ path, Icon, children, title, subtitle, createdAt, actions, onClick }: Props) => {
  return (
    <Card className={clsx(styles.card, { [styles.hasActions]: Boolean(actions) })} onClick={onClick}>
      <NavLink className={styles.link} to={path} end={path === ""}>
        <div className={styles.title}>
          <div className={styles.iconWrapper}>
            <Icon size={20} className={styles.titleIcon} />
          </div>
          <h4>{title}</h4>
        </div>
        {subtitle ? <p>{subtitle}</p> : null}
      </NavLink>
      <div className={styles.children}>{children}</div>
      {createdAt ? <div className={styles.bottom}>Erstellt am {localizedDate(createdAt)}</div> : null}
      {actions ? <div className={styles.actions}>{actions}</div> : null}
    </Card>
  );
};
