// https://unicode.org/emoji/charts/full-emoji-list.html?utm_source=thenewstack&utm_medium=website&utm_campaign=platform
export const emoji = {
  GLOBE: String.fromCodePoint(0x1f310),
  DISK: String.fromCodePoint(0x1f4be),
  ORANGE: String.fromCodePoint(0x1f7e7),
  BLUE_CIRCLE: String.fromCodePoint(0x1f535),
  GREEN_CIRCLE: String.fromCodePoint(0x1f7e2),
  MAGNIFIER: String.fromCodePoint(0x1f50e),
  OKTOPUS: String.fromCodePoint(0x1f991),
};
