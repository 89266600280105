import { AdminSecrets } from "app/hooks";
import {
  Collection,
  Monitoring,
  Portfolio,
  Research,
  SavedSearch,
  SessionInfo,
  User,
  UserFeedback,
  UserFlag,
} from "app/models";
import { TrackingAggregations } from "app/models/admin/tracking-aggregations.model";
import { UserEvent, UserMetric } from "app/models/user-event-log.model";
import { Workspace } from "app/models/workspace.model";
import { mapMetric, mapUserEvent } from "app/util/notification.util";
import { getClient } from "./client";

const client = getClient({ basePath: "/admin" });

export type AdminSendMailRequest = {
  userId: string;
  subject: string;
  template: string;
  variables: object;
};

export const AdminClient = {
  getUsers: async () => {
    const { data } = await client.get<User[]>("/users");
    return data;
  },
  sendMail: async (reqData: AdminSendMailRequest) => {
    const { data } = await client.post("/send-mail", reqData);
    return data;
  },
  getUserMails: async (userId: string) => {
    const { data } = await client.get<any>(`/users/${userId}/mails`);
    return data;
  },
  getWorkspaces: async () => {
    const { data } = await client.get<Workspace[]>("/workspaces");
    return data;
  },
  updateUserFlags: async (userId: string, flags: UserFlag[]) => {
    const {
      data: { items },
    } = await client.post(`/users/${userId}/flags`, {
      flags,
    });
    return items;
  },
  getUserNote: async (userId: string) => {
    const { data } = await client.get<{ note: string }>(`/users/${userId}/note`);

    return data.note;
  },
  updateUserNote: async (userId: string, note: string) => {
    const { data } = await client.put<{ note: string }>(`/users/${userId}/note`, {
      note,
    });
    return data.note;
  },
  getMonitorings: async (userId: string) => {
    const { data } = await client.get<Monitoring[]>(`/users/${userId}/monitorings`);
    return data;
  },
  getSavedSearches: async (userId: string) => {
    const { data } = await client.get<SavedSearch[]>(`/users/${userId}/saved-searches`);
    return data;
  },
  getCollections: async (userId: string) => {
    const { data } = await client.get<Collection[]>(`/users/${userId}/collections`);
    return data;
  },
  getPortfolios: async (userId: string) => {
    const { data } = await client.get<Portfolio[]>(`/users/${userId}/portfolios`);
    return data;
  },
  getResearches: async (userId: string) => {
    const { data } = await client.get<Research[]>(`/users/${userId}/researches`);
    return data;
  },
  deleteUser: async (userId: string) => {
    const { data } = await client.post(`/users/queue-delete`, {
      userId,
    });
    return data;
  },
  approveUser: async (userId: string) => {
    const { data } = await client.post(`/users/${userId}/approve`);
    return data;
  },
  getUserEventLog: async (userId: string) => {
    const { data } = await client.get<UserEvent[]>(`/users/${userId}/event-log`);
    return data.map(mapUserEvent);
  },
  getUserMetrics: async (userId: string) => {
    const { data } = await client.get<UserMetric[]>(`/users/${userId}/metrics`);
    return data.map(mapMetric);
  },
  deleteUserEventLog: async (userId: string) => {
    const { data } = await client.delete(`/users/${userId}/event-log`);
    return data;
  },
  deleteUserMetrics: async (userId: string) => {
    const { data } = await client.delete(`/users/${userId}/metrics`);
    return data;
  },
  getFeedbackEntries: async () => {
    const { data } = await client.get<UserFeedback[]>(`/feedback`);
    return data;
  },
  getLatestSessions: async () => {
    const { data } = await client.get<Array<[string, SessionInfo]>>(`/users/sessions`);
    return data;
  },
  addViennaClass: async (newViennaClass: any) => {
    const { data } = await client.post<unknown>(`/vienna`, newViennaClass);
    return data;
  },
  getSecrets: async () => {
    const { data } = await client.get<AdminSecrets>(`/secrets`);
    return data;
  },
  getTrackingAggregations: async () => {
    const { data } = await client.get<TrackingAggregations>(`/aggregations`);
    return data;
  },
};
