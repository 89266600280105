import {
  BillingInformation,
  PaymentInformation,
  PaymentSetup,
  Subscription,
} from "app/models/payment-information.model";
import { getClient } from "./client";

const client = getClient({ basePath: "/payment" });

export const PaymentClient = {
  getPaymentInformation: async () => {
    const { data } = await client.get<PaymentInformation>("/");
    return data;
  },

  setupPayment: async (billingInformation: BillingInformation) => {
    const { data } = await client.put<PaymentSetup>(`/setup`, billingInformation);
    return data;
  },

  bookPremium: async (paymentMethodId: string) => {
    const { data } = await client.put<PaymentInformation>("/premium", {
      paymentMethodId,
    });
    return data;
  },

  cancelPremium: async () => {
    const { data } = await client.delete<PaymentInformation>(`/premium`);
    return data;
  },

  updateBillingInformation: async (billingInformation: BillingInformation) => {
    const { data } = await client.put<PaymentInformation>("/billing-information", billingInformation);
    return data;
  },

  addPaymentMethod: async (paymentMethodId: string) => {
    const { data } = await client.post<PaymentInformation>("/payment-methods", {
      paymentMethodId,
    });
    return data;
  },

  setDefaultPaymentMethod: async (paymentMethodId: string) => {
    const { data } = await client.put<PaymentInformation>(`/payment-methods/${paymentMethodId}/default`);
    return data;
  },

  deletePaymentMethod: async (paymentMethodId: string) => {
    const { data } = await client.delete<PaymentInformation>(`/payment-methods/${paymentMethodId}`);
    return data;
  },

  getSubscriptionPreview: async () => {
    const { data } = await client.get<Subscription[]>("/subscriptions/preview");
    return data;
  },
};
