import { ICONS } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { PortfolioEmptyState } from "app/components/PortfolioEmptyState/PortfolioEmptyState";
import { PreviewCardNavItem } from "app/components/PreviewCardNavItem/PreviewCardNavItem";
import { Tutorial, TutorialStep } from "app/components/Tutorial/Tutorial";
import { useUserSavedSearches } from "app/hooks/use-user-saved-searches.hook";
import { k, l } from "app/i18n";
import { GridNavigationItemConfig } from "app/layouts/GridLayout/GridNavigationLayout";
import { UserFlag } from "app/models";
import { AppModal, showModal } from "app/redux";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { collectionDetailRoute, monitoringDetailRoute, portfolioDetailRoute, ROUTES } from "app/routes";
import { sortByDateDesc } from "app/util/date.util";
import { useMemo } from "react";
import { SavedSearchCard } from "../SearchView/SavedSearches/SavedSearchCard/SavedSearchCard";
import styles from "./DashboardView.module.scss";
import { UpsellingBanner } from "./UpsellingBanner/UpsellingBanner";

const tutorialSteps: TutorialStep[] = [
  {
    title: "Willkommen bei Oktomark",
    target: ".tutorial-search-bar",
    content:
      "Wir wünschen Ihnen viel Spaß mit Ihrem Oktomark Account und um Ihnen den Einstieg zu erleichtern, haben wir ein kurzes Tutorial vorbereitet.",
    disableBeacon: true,
    placement: "center",
  },
  {
    title: "Suche",
    target: ".tutorial-search-bar",
    content: "Über die Suchleiste können Sie eine neue Suche starten oder z. B. nach Mandanten oder Recherchen suchen.",
    disableBeacon: true,
  },
  {
    title: "Bleiben Sie organisiert",
    target: ".tutorial-portfolio-empty-state",
    content: "Legen Sie jetzt Ihren ersten Mandanten an und starten Sie Ihre erste Recherche.",
    disableBeacon: true,
  },
  {
    title: "Ihre Meinung",
    target: ".tutorial-feedback",
    content:
      "Sagen Sie uns, was Sie denken! Wir sind bestrebt Oktomark immer weiter zu verbessern und auf Ihre Bedürfnisse abzustimmen.",
    disableBeacon: true,
  },
];

export const DashboardView = () => {
  const { items: portfolios, loading: portfoliosLoading } = useAppSelector((state) => state.portfolio);
  const { items: collections } = useAppSelector((state) => state.collection);
  const { items: monitorings } = useAppSelector((state) => state.monitoring);
  const totalCollisions = monitorings.reduce((acc, i) => acc + i.collisions.length, 0);
  const { items: savedSearches, activateSavedSearch } = useUserSavedSearches();
  const dispatch = useAppDispatch();

  const collectionQuickItems: GridNavigationItemConfig[] = useMemo(
    () =>
      [...collections]
        .sort((a, b) => sortByDateDesc(b.updatedAt, a.updatedAt))
        .slice(0, 3)
        .map((l) => ({
          path: collectionDetailRoute(l.uid),
          title: l.name,
          Icon: ICONS.COLLECTION,
          createdAt: l.createdAt,
          children: null,
        })),
    [collections],
  );

  const monitoringQuickItems: GridNavigationItemConfig[] = useMemo(
    () =>
      [...monitorings]
        .sort((a, b) => sortByDateDesc(b.updatedAt, a.updatedAt))
        .sort((a, b) => b.collisions.length - a.collisions.length)
        .slice(0, 3)
        .map((m) => {
          const unflagged = m.collisions.length || 0;
          return {
            path: monitoringDetailRoute(m.uid),
            title: m.trademark.name,
            highlight: unflagged > 0,
            Icon: ICONS.MONITORING,
            createdAt: m.createdAt,
            children:
              m.collisions.length > 0 ? (
                <div className={styles.collisionPreview}>
                  <span>{m.collisions.length}</span> Kollisionen
                </div>
              ) : null,
          };
        }),
    [monitorings],
  );

  const savedSearchesQuickItems = useMemo(
    () => [...savedSearches].sort((a, b) => sortByDateDesc(b.updatedAt, a.updatedAt)).slice(0, 3),
    [savedSearches],
  );

  const savedSearchesOverview = useMemo(
    () => (
      <div className={styles.previewLinkWrapper}>
        <CardContainer
          className={styles.previewBg}
          header={{
            title: l(k.SAVED_SEARCHES),
            Icon: ICONS.SAVED_SEARCHES,
            link: ROUTES.SEARCH.path,
          }}
        >
          <div className={styles.previewLinks}>
            {savedSearchesQuickItems.map((i) => (
              <SavedSearchCard key={i.uid} savedSearch={i} onActivate={activateSavedSearch} noActions={true} />
            ))}
          </div>
        </CardContainer>
      </div>
    ),
    [activateSavedSearch, savedSearchesQuickItems],
  );

  const collectionOverview = useMemo(
    () => (
      <div className={styles.previewLinkWrapper}>
        <CardContainer
          className={styles.previewBg}
          header={{
            title: l(k.COLLECTIONS),
            Icon: ICONS.COLLECTIONS,
            link: ROUTES.COLLECTION.path,
          }}
        >
          <div className={styles.previewLinks}>
            {collectionQuickItems.map((i) => (
              <PreviewCardNavItem
                key={i.path}
                path={i.path}
                title={i.title}
                Icon={i.Icon}
                subtitle={i.subtitle}
                children={i.children}
                createdAt={i.createdAt}
              />
            ))}
          </div>
        </CardContainer>
      </div>
    ),
    [collectionQuickItems],
  );

  const monitoringOverview = useMemo(
    () => (
      <div className={styles.previewLinkWrapper}>
        <CardContainer
          className={styles.previewBg}
          header={{
            title: `${l(k.MONITORINGS)}${totalCollisions > 0 ? ` (${totalCollisions})` : ""}`,
            Icon: ICONS.MONITORINGS,
            link: ROUTES.MONITORING.path,
          }}
        >
          <div className={styles.previewLinks}>
            {monitoringQuickItems.map((i) => (
              <PreviewCardNavItem
                key={i.path}
                path={i.path}
                title={i.title}
                Icon={i.Icon}
                subtitle={i.subtitle}
                children={i.children}
                createdAt={i.createdAt}
              />
            ))}
          </div>
        </CardContainer>
      </div>
    ),
    [monitoringQuickItems, totalCollisions],
  );

  const portfolioQuickItems: GridNavigationItemConfig[] = useMemo(
    () =>
      [...portfolios]
        .sort((a, b) => sortByDateDesc(b.updatedAt, a.updatedAt))
        .slice(0, 3)
        .map((l) => ({
          path: portfolioDetailRoute(l.uid),
          title: l.name,
          Icon: ICONS.PORTFOLIO,
          createdAt: l.createdAt,
          children: null,
        })),
    [portfolios],
  );

  const showCreatePortfolioModal = () => {
    showModal(dispatch)({
      type: AppModal.CREATE_PORTFOLIO,
      props: {},
    });
  };

  const hasPortfolio = !portfoliosLoading && portfolioQuickItems.length > 0;

  return (
    <>
      <UpsellingBanner />
      <CardContainer
        header={{
          title: l(k.PORTFOLIOS),
          Icon: ICONS.PORTFOLIOS,
          link: ROUTES.PORTFOLIO.path,
        }}
      >
        {portfoliosLoading ? (
          <LoadingIndicator />
        ) : portfolioQuickItems.length > 0 ? (
          <div className={styles.previewLinks}>
            {portfolioQuickItems.map((i) => (
              <PreviewCardNavItem
                key={i.path}
                path={i.path}
                title={i.title}
                Icon={i.Icon}
                subtitle={i.subtitle}
                children={i.children}
                createdAt={i.createdAt}
              />
            ))}
            {hasPortfolio ? (
              <div className={styles.portfolioCtaWrapper}>
                <Button onClick={showCreatePortfolioModal}>{l(k.CREATE_NEW_PORTFOLIO)}</Button>
              </div>
            ) : null}
          </div>
        ) : (
          <PortfolioEmptyState />
        )}
      </CardContainer>

      {/* TODO: hide monitorings if no collisions found or sort lower */}
      {monitorings.length > 0 ? monitoringOverview : null}
      {savedSearches.length > 0 ? savedSearchesOverview : null}
      {collections.length > 0 ? collectionOverview : null}
      <Tutorial flag={UserFlag.TUTORIAL} steps={tutorialSteps} />
    </>
  );
};
