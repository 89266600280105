import clsx from "clsx";
import { Button, ButtonTheme } from "../Button/Button";
import { Input } from "../Input/Input";
import styles from "./InviteUser.module.scss";

interface InviteUserProps {
  emails: string[];
  className?: string;
  onChange: (emails: string[]) => void;
}

export const InviteUser = ({ className, emails, onChange }: InviteUserProps) => {
  const handleChangeEmail = (index: number) => (email: string) => {
    onChange(emails.map((row, i) => (i === index ? email : row)));
  };
  const handleAddRow = () => {
    onChange([...emails, ""]);
  };

  return (
    <div className={clsx(styles.root, className)}>
      {emails.map((email, i) => (
        <Input key={i} placeholder="hanna@example.com" value={email} onTextChange={handleChangeEmail(i)} />
      ))}
      <Button className={styles.addMore} theme={ButtonTheme.TRANSPARENT} onClick={handleAddRow}>
        Zeile hinzufügen
      </Button>
    </div>
  );
};
