import clsx from "clsx";
import { default as ReactToggle, ToggleIcons } from "react-toggle";
import "react-toggle/style.css";
import styles from "./Toggle.module.css";

type ToggleProps = {
  className?: string;
  checked: boolean;
  label?: string;
  onChange: (checked: boolean) => unknown;
  icons?: ToggleIcons;
};

export const Toggle = ({ checked, label, onChange, icons, className }: ToggleProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };

  const toggle = <ReactToggle icons={icons} checked={checked} onChange={handleChange} />;

  return (
    <div className={clsx(styles.root, className)}>
      {label ? (
        <label>
          {toggle}
          <span>{label}</span>
        </label>
      ) : (
        toggle
      )}
    </div>
  );
};
