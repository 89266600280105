import clsx from "clsx";
import { ChangeEvent, useRef } from "react";
import styles from "./Checkbox.module.scss";
type Props = {
  className?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: any;
  onToggle?: (checked: boolean) => unknown;
};

export const Checkbox = ({ className, disabled, checked, onChange, onToggle, name, label }: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e);
    }
    if (onToggle) {
      onToggle(e.target.checked);
    }
  };

  const toggle = () => {
    const newCheckedValue = !checked;
    if (onToggle) {
      onToggle(newCheckedValue);
    }
    if (inputRef.current) {
      inputRef.current.checked = newCheckedValue;
    }
  };

  return (
    <div className={className}>
      <label
        onClick={toggle}
        className={clsx(styles.formControl, {
          [styles.noLabel]: !Boolean(label),
        })}
        htmlFor={name}
      >
        <input
          ref={inputRef}
          type="checkbox"
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  );
};
