import { PendingInvite } from "app/models/workspace.model";
import { useAppSelector } from "app/redux/store.hooks";
import { useEffect } from "react";

export const useInvitePendingModal = (openInvitePendingModal: (pendingInvite: PendingInvite) => void) => {
  const { pendingInvites } = useAppSelector((state) => state.workspace);
  useEffect(() => {
    if (pendingInvites.length > 0) {
      openInvitePendingModal(pendingInvites[0]);
    }
  }, [openInvitePendingModal, pendingInvites]);
};
