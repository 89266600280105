import { Button, ButtonTheme } from "app/components/Button/Button";
import { ImageUploadArea } from "app/components/ImageUploadArea/ImageUploadArea";
import { User } from "app/models";
import { appLogger } from "app/util/logger.util";
import { useEffect, useState } from "react";
import styles from "./AvatarUpload.module.scss";

interface Props {
  user: User;
  onDelete: () => Promise<unknown>;
  onChange: (photo: File | null) => unknown;
  uploadedImage?: File | null;
}

export const AvatarUpload = ({ user, uploadedImage, onDelete, onChange }: Props) => {
  const [uploadedPhotoUrl, setUploadedPhotoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!uploadedImage) {
      setUploadedPhotoUrl(null);
      return;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const result = reader.result;
      if (typeof result === "string") {
        setUploadedPhotoUrl(result);
      } else {
        appLogger.log("AvatarUpload", "User uploaded non string image", result);
      }
    });
    reader.readAsDataURL(uploadedImage);
  }, [uploadedImage]);

  const handleImageChange = (files: File[]) => {
    if (files.length > 0) {
      appLogger.log("AvatarUpload", "handleImageChange", files);
      const uploaded = files[0];
      onChange(uploaded);

      // const reader = new FileReader();
      // reader.addEventListener("load", () => {
      //   const result = reader.result;
      //   if (typeof result === "string") {
      //     setUploadedPhotoUrl(result);
      //   } else {
      //     appLogger.log("AvatarUpload", "User uploaded non string image", result);
      //   }
      // });
      // reader.readAsDataURL(uploaded);
    }
  };

  const handleImageDelete = async () => {
    await onDelete();
  };

  const handleReset = async () => {
    onChange(null);
    setUploadedPhotoUrl(null);
  };

  const text = user.photoUrl ? "Profilbild ändern" : "Profilbild hochladen";
  const imageUrl = uploadedPhotoUrl || user.photoUrl;
  return (
    <div className={styles.root}>
      <div
        className={styles.upload}
        style={{
          backgroundColor: "var(--theme-color-layer)",
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        {/* {user.photoUrl && <img className={styles.userImage} src={user.photoUrl} alt="" height="100" width="auto" />}
        {uploadedPhotoUrl ? (
          <div className={styles.uploadOverlay}>
            <img className={styles.uploadedImage} src={uploadedPhotoUrl} alt="" height="100" width="auto" />
          </div>
        ) : null} */}
        <ImageUploadArea className={styles.uploadArea} onUpload={handleImageChange} text={text} />
      </div>
      <div className={styles.actions}>
        {user.photoUrl ? (
          <Button theme={ButtonTheme.LINK} onClick={handleImageDelete}>
            Profilbild löschen
          </Button>
        ) : null}
        {uploadedPhotoUrl ? (
          <Button theme={ButtonTheme.LINK} onClick={handleReset}>
            Zurücksetzen
          </Button>
        ) : null}
      </div>
    </div>
  );
};
