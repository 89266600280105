import { apiUrl, FutziSearchQuery } from "app/api";
import { Collection, Monitoring, Partner, PaymentInformation, Portfolio, Research, SavedSearch } from "app/models";
import { UserEvent } from "app/models/user-event-log.model";
import { WebsocktMessageType } from "app/models/websocket-message-type.enum";
import { PendingInvite, Workspace } from "app/models/workspace.model";
import {
  authSlice,
  collectionSlice,
  fetchPendingInvites,
  monitoringSlice,
  notificationsSlice,
  partnerSlice,
  paymentSlice,
  portfolioSlice,
  researchSlice,
  searchSlice,
  store,
  workspaceSlice,
} from "app/redux";
import { mapCollection } from "app/util/collection.util";
import { websocketLogger } from "app/util/logger.util";
import { mapMonitoring } from "app/util/monitoring.util";
import { mapUserEvent } from "app/util/notification.util";
import { mapPartner } from "app/util/partner.util";
import { mapPortfolio } from "app/util/portfolio.util";
import { mapResearch } from "app/util/research.util";
import { mapSavedSearch } from "app/util/saved-search.util";
import io, { Socket } from "socket.io-client";

let socket: Socket | null = null;
const METRICS_NAMESPACE = "metrics";

export function closeSocket() {
  if (socket) {
    socket.close();
    socket = null;
  }
}

let metricsBuffer: Array<any> = [];

function retryMetricsMessageBuffer() {
  // console.log("retryMetricsMessageBuffer", metricsBuffer);
  if (metricsBuffer.length > 0 && socket) {
    for (const message of metricsBuffer) {
      socket.emit(METRICS_NAMESPACE, JSON.stringify(message));
    }
    metricsBuffer = [];
  }
}

export function sendMetrics(data: any) {
  const timestampedData = {
    timestamp: Date.now(),
    ...data,
  };
  // console.log("Metrics", timestampedData);
  if (!socket || !socket.connected) {
    metricsBuffer.push(timestampedData);
    return;
  }
  socket?.emit(METRICS_NAMESPACE, JSON.stringify(timestampedData));
}

function createSocket() {
  const url = apiUrl();
  // log("Connecting websocket at", url);
  return io(url, {
    // use cookie auth
    withCredentials: true,
    //or
    // auth: {
    //   token: "xxx"
    // }
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionAttempts: 1000,
    autoConnect: true,
  });
}

export function initSocket() {
  socket = createSocket();

  // Refresh token on reconnect
  socket.on("reconnect_attempt", async () => {
    websocketLogger.log("Websocket reconnect_attempt");
  });

  socket.io.on("reconnect", () => {
    websocketLogger.log("Websocket reconnect");
    retryMetricsMessageBuffer();
  });

  socket.on("connect", () => {
    websocketLogger.log("Websocket connected");
    // send metrics as soon as the websocket is connected
    retryMetricsMessageBuffer();
  });

  socket.on("disconnect", () => {
    websocketLogger.log("Websocket disconnected");
    // reconnect();
  });

  socket.on("connect_error", () => {
    // reconnect();
  });

  socket.on(WebsocktMessageType.USER_UPDATE, (data: any) => {
    websocketLogger.log("Websocket", WebsocktMessageType.USER_UPDATE, data);
    store.dispatch(authSlice.actions.setUser(data));
  });

  socket.on(WebsocktMessageType.SAVED_SEARCH_UPDATE, (data: SavedSearch) => {
    websocketLogger.log("Websocket", WebsocktMessageType.SAVED_SEARCH_UPDATE, data);
    store.dispatch(searchSlice.actions.updateSavedSearch(mapSavedSearch(data)));
  });

  socket.on(WebsocktMessageType.SAVED_SEARCH_DELETE, (data: { savedSearchId: string }) => {
    websocketLogger.log("Websocket", WebsocktMessageType.SAVED_SEARCH_DELETE, data);
    store.dispatch(searchSlice.actions.deleteSavedSearch(data));
  });

  socket.on(WebsocktMessageType.LAST_SEARCH_UPDATE, (data: FutziSearchQuery) => {
    websocketLogger.log("Websocket", WebsocktMessageType.LAST_SEARCH_UPDATE, data);
    store.dispatch(searchSlice.actions.updateLast(data));
  });

  socket.on(WebsocktMessageType.COLLECTION_UPDATE, (data: Collection) => {
    websocketLogger.log("Websocket", WebsocktMessageType.COLLECTION_UPDATE, data);
    store.dispatch(collectionSlice.actions.updateCollection(mapCollection(data)));
  });

  socket.on(WebsocktMessageType.COLLECTION_DELETE, (data: { collectionId: string }) => {
    websocketLogger.log("Websocket", WebsocktMessageType.COLLECTION_DELETE, data);
    store.dispatch(collectionSlice.actions.deleteCollection(data));
  });

  socket.on(WebsocktMessageType.PORTFOLIO_UPDATE, (data: Portfolio) => {
    websocketLogger.log("Websocket", WebsocktMessageType.PORTFOLIO_UPDATE, data);
    store.dispatch(portfolioSlice.actions.updatePortfolio(mapPortfolio(data)));
  });

  socket.on(WebsocktMessageType.PORTFOLIO_DELETE, (data: { portfolioId: string }) => {
    websocketLogger.log("Websocket", WebsocktMessageType.PORTFOLIO_DELETE, data);
    store.dispatch(portfolioSlice.actions.deletePortfolio(data));
  });
  socket.on(WebsocktMessageType.RESEARCH_UPDATE, (data: Research) => {
    websocketLogger.log("Websocket", WebsocktMessageType.RESEARCH_UPDATE, data);
    store.dispatch(researchSlice.actions.updateResearch(mapResearch(data)));
  });

  socket.on(WebsocktMessageType.RESEARCH_DELETE, (data: { researchId: string }) => {
    websocketLogger.log("Websocket", WebsocktMessageType.RESEARCH_DELETE, data);
    store.dispatch(researchSlice.actions.deleteResearch(data));
  });

  socket.on(WebsocktMessageType.TRADEMARK_MONITORING_UPDATE, (data: Monitoring) => {
    websocketLogger.log("Websocket", WebsocktMessageType.TRADEMARK_MONITORING_UPDATE, data);
    store.dispatch(monitoringSlice.actions.updateMonitoring(mapMonitoring(data)));
  });

  socket.on(WebsocktMessageType.TRADEMARK_MONITORING_DELETE, (data: { monitoringId: string }) => {
    websocketLogger.log("Websocket", WebsocktMessageType.TRADEMARK_MONITORING_DELETE, data);
    store.dispatch(monitoringSlice.actions.deleteMonitoring(data));
  });

  socket.on(WebsocktMessageType.WORKSPACE_UPDATE, (data: Workspace) => {
    websocketLogger.log("Websocket", WebsocktMessageType.WORKSPACE_UPDATE, data);
    store.dispatch(workspaceSlice.actions.update(data));
  });

  socket.on(WebsocktMessageType.WORKSPACE_DELETE, () => {
    websocketLogger.log("Websocket", WebsocktMessageType.WORKSPACE_DELETE);
    store.dispatch(workspaceSlice.actions.destroy());
  });

  socket.on(WebsocktMessageType.WORKSPACE_PENDING_INVITE, (data: PendingInvite) => {
    websocketLogger.log("Websocket", WebsocktMessageType.WORKSPACE_PENDING_INVITE, data);
    store.dispatch(fetchPendingInvites());
  });

  socket.on(WebsocktMessageType.WORKSPACE_USER_REMOVED, () => {
    websocketLogger.log("Websocket", WebsocktMessageType.WORKSPACE_USER_REMOVED);
    // HACK: Reload the whole page to rehydrate store
    window.location.reload();
  });

  socket.on(WebsocktMessageType.PAYMENT_UPDATE, (data: PaymentInformation) => {
    websocketLogger.log("Websocket", WebsocktMessageType.PAYMENT_UPDATE, data);
    store.dispatch(paymentSlice.actions.update(data));
  });

  socket.on(WebsocktMessageType.NOTIFICATION_UPDATE, (data: UserEvent) => {
    websocketLogger.log("Websocket", WebsocktMessageType.NOTIFICATION_UPDATE, data);
    store.dispatch(notificationsSlice.actions.updateNotification(mapUserEvent(data)));
  });

  socket.on(WebsocktMessageType.PARTNER_UPDATE, (data: Partner) => {
    websocketLogger.log("Websocket", WebsocktMessageType.PARTNER_UPDATE, data);
    store.dispatch(partnerSlice.actions.updatePartner(mapPartner(data)));
  });

  socket.on(WebsocktMessageType.PARTNER_DELETE, (data: { partnerId: string }) => {
    websocketLogger.log("Websocket", WebsocktMessageType.PARTNER_DELETE, data);
    store.dispatch(partnerSlice.actions.deletePartner(data));
  });
}
