import { ICONS } from "app/assets/icons/icons";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { TrademarkResultList } from "app/components/TrademarkResultList/TrademarkResultList";
import { fetchMore, useAppDispatch, useAppSelector } from "app/redux";
import { uniqueTrademarks } from "app/util/filter.util";
import { appLogger } from "app/util/logger.util";
import { uniqWith } from "lodash-es";
import { useMemo } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";

export const LatestApplications = () => {
  const { result, loading, moreLoading } = useAppSelector((state) => state.feed);
  const dispatch = useAppDispatch();

  useBottomScrollListener(async () => {
    if (!moreLoading) {
      appLogger.log("Loading more feed items");
      dispatch(fetchMore({ offset: result.trademarks.length }));
    }
  });

  // TODO: fix duplicate trademarks coming from backend
  const trademarksToRender = useMemo(() => uniqWith(result.trademarks, uniqueTrademarks), [result.trademarks]);

  return (
    <CardContainer
      header={{
        title: "Neuste Eintragungen",
        Icon: ICONS.UPDATES,
      }}
    >
      <TrademarkResultList
        trademarks={trademarksToRender}
        hideHeaderActions={true}
        renderItem={(displayType, trademark) => (
          <TrademarkCard displayType={TrademarkCardDisplayType.COMPACT} trademark={trademark} isSelectable />
        )}
      />
      {loading || moreLoading ? <LoadingIndicator /> : null}
    </CardContainer>
  );
};
