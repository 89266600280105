import { Portfolio } from "app/models";
import { toDate } from "./date.util";

export const mapPortfolio = (portfolio: Portfolio): Portfolio => ({
  ...portfolio,
  createdAt: toDate(portfolio.createdAt),
  updatedAt: toDate(portfolio.updatedAt),
  comments:
    portfolio.comments?.map((comment) => ({
      ...comment,
      createdAt: toDate(comment.createdAt),
      updatedAt: toDate(comment.updatedAt),
    })) || [],
});
