import { BillingInformation, Card } from "app/models/payment-information.model";
import { formatCardNumber } from "app/util/payment.util";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

interface CreditCardProps {
  billingInformation: BillingInformation;
  card: Card;
}

export const CreditCard = ({ billingInformation: { name }, card }: CreditCardProps) => {
  return (
    <Cards
      cvc={""}
      expiry={`${card?.expMonth.toString().padStart(2, "0")}/${card?.expYear}`}
      name={name}
      number={formatCardNumber(card)}
      issuer={card?.brand}
      preview
    />
  );
};
