import { ResearchClient } from "app/api";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { SimilarTrademarksHeader } from "app/components/SimilarTrademarksHeader/SimilarTrademarksHeader";
import {
  TrademarkActionConfig,
  TrademarkActionType,
} from "app/components/TrademarkCard/TrademarkActions/TrademarkActions";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { k, l } from "app/i18n";
import { Trademark } from "app/models/trademark.model";
import { addTrademarksToResearch, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { useEffect, useState } from "react";

type ResearchSuggestionsProps = {
  researchId: string;
};

export const ResearchSuggestions = ({ researchId }: ResearchSuggestionsProps) => {
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Trademark[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    setSuggestions([]);
    ResearchClient.getSuggestions(researchId)
      .then(setSuggestions)
      .finally(() => setLoading(false));
  }, [researchId]);

  if (loading) {
    return <LoadingIndicator message={l(k.LOADING_TRADEMARK_LIST_SUGGESTIONS)} />;
  }

  return (
    <>
      <SimilarTrademarksHeader />
      {suggestions.map((suggestion) => {
        const handleAddToThisResearch = async () => {
          try {
            await dispatch(
              addTrademarksToResearch({
                id: researchId,
                trademarks: [
                  {
                    trademarkId: suggestion.id,
                    trademarkOffice: suggestion.office,
                  },
                ],
              }),
            ).unwrap();
          } catch (error) {
            handleError(`Marke konnte nicht zur Recherche hinzugefügt werden.`, error);
          }
        };
        const actions = new Map<TrademarkActionType, TrademarkActionConfig>([
          [
            TrademarkActionType.ADD_TO_THIS_RESEARCH,
            {
              enabled: true,
              onClick: handleAddToThisResearch,
            },
          ],
        ]);
        return (
          <TrademarkCard
            actions={actions}
            displayType={TrademarkCardDisplayType.COMPACT}
            key={suggestion.id}
            trademark={suggestion}
            noBg
            isSelectable
          />
        );
      })}
    </>
  );
};
