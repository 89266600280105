import { Button, ButtonTheme } from "app/components/Button/Button";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { ContextType, Portfolio } from "app/models";
import { searchSlice, updateUserContext, useAppDispatch } from "app/redux";
import { ROUTES } from "app/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  portfolio: Portfolio;
};

export const PortfolioEmptyState = ({ portfolio }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goToSearch = () => {
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        owner: portfolio.name,
      }),
    );

    dispatch(
      updateUserContext({
        type: ContextType.PORTFOLIO,
        portfolioId: portfolio.uid,
      }),
    );
  };

  return (
    <EmptyState
      title="Marken hinzufügen"
      fullWidth
      noBg
      text="Fügen Sie alle Marken des Mandanten hinzu und aktivieren Sie die Überwachung."
    >
      <Button onClick={goToSearch} theme={ButtonTheme.TRANSPARENT}>
        Jetzt Marken suchen
      </Button>
    </EmptyState>
  );
};
