import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationClient } from "app/api/notification.api";
import { UserEvent } from "app/models/user-event-log.model";

const STATE_KEY = "notification";

interface NotificationState {
  items: UserEvent[];
  loading: boolean;
}

const initialState: NotificationState = {
  items: [],
  loading: false,
};

export const fetchNotifications = createAsyncThunk(`${STATE_KEY}/fetch`, NotificationClient.fetch);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const notificationsSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateNotification: (state, { payload }: PayloadAction<UserEvent>) => {
      if (state.items.some((i) => i.uid === payload.uid)) {
        state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.items = [payload].concat(state.items);
      }
    },
    destroy: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = false;
      })

      // Pending
      .addCase(fetchNotifications.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchNotifications.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const notificationReducer = notificationsSlice.reducer;
