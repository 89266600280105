import { APP_CONST } from "app/assets/constants";
import { pluralize } from "app/i18n";
import { AppModal, showModal, useAppDispatch, useAppSelector } from "app/redux";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import styles from "./UpsellingBanner.module.scss";

export const UpsellingBanner = () => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const { needsSubscription, isInTrial, remainingTrialDays } = useAppSelector((state) => state.payment);

  if (!user) {
    return null;
  }

  if (!needsSubscription) {
    return null;
  }

  const showUpsellingModal = () => {
    trackEvent(AppEvent.CLICK_UPSELLING_BANNER);
    showModal(dispatch)({ type: AppModal.UPSELLING });
  };

  const pretext = isInTrial
    ? `Ihre Testphase läuft noch ${pluralize("days", remainingTrialDays)}.`
    : "Ihre Testphase ist abgelaufen.";

  const text = isInTrial ? "Hier können Sie Ihr Oktomark Abonnement aktivieren" : "Aktivieren Sie jetzt ihr Abonnement";

  return (
    <div className={styles.banner}>
      <div className={styles.icon}>
        <img src={APP_CONST.IMAGES.OKTO_WHITE} className={styles.okto} alt="" />
      </div>
      <span className={styles.pretext}>{pretext}</span>
      <span className={styles.bannerLink} onClick={showUpsellingModal}>
        {text}
      </span>
    </div>
  );
};
