import { getClient } from "app/api";
import { Collection, ResolvedCollection, TrademarkReferenceWithNote } from "app/models";
import { CommentVisibility } from "app/models/trademark-comment.model";
import { TrademarkReference } from "app/models/trademark-reference.model";
import { Trademark } from "app/models/trademark.model";
import { mapCollection } from "app/util/collection.util";
import { mapQueryResult, mapTrademark } from "app/util/trademark.util";

const client = getClient({ basePath: "/collection" });

export const CollectionClient = {
  fetch: async () => {
    const { data } = await client.get<Collection[]>("/");
    return data.map(mapCollection);
  },
  fetchResolved: async (id: string) => {
    const { data } = await client.get<ResolvedCollection>(`/${id}`);
    return {
      ...data,
      collection: mapCollection(data.collection),
      trademarks: mapQueryResult(data.trademarks),
    };
  },
  create: async (collection: Pick<Collection, "name" | "trademarks" | "note">) => {
    const { data } = await client.post<Collection>("/", collection);
    return mapCollection(data);
  },
  update: async ({ id, collection }: { id: string; collection: Partial<Pick<Collection, "name" | "note">> }) => {
    const { data } = await client.put<Collection>(`/${id}`, collection);
    return mapCollection(data);
  },
  delete: async (id: string) => {
    const { data } = await client.delete(`/${id}`);
    return data;
  },
  addTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Collection>(`/${id}/trademarks/add`, {
      trademarks,
    });
    return mapCollection(data);
  },
  sortTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Collection>(`/${id}/trademarks/sort`, {
      trademarks,
    });
    return mapCollection(data);
  },
  removeTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Collection>(`/${id}/trademarks/remove`, {
      trademarks,
    });
    return mapCollection(data);
  },
  updateTrademarkNotes: async ({ id, trademarks }: { id: string; trademarks: TrademarkReferenceWithNote[] }) => {
    const { data } = await client.put<Collection>(`/${id}/notes`, {
      trademarks,
    });
    return mapCollection(data);
  },
  addComment: async ({ id, comment, visibility }: { id: string; comment: string; visibility: CommentVisibility }) => {
    const { data } = await client.post<Collection>(`/${id}/comments`, {
      comment,
      visibility,
    });
    return mapCollection(data);
  },
  updateComment: async ({
    id,
    commentId,
    comment,
    visibility,
  }: {
    id: string;
    commentId: string;
    comment: string;
    visibility: CommentVisibility;
  }) => {
    const { data } = await client.put<Collection>(`/${id}/comments/${commentId}`, {
      comment,
      visibility,
    });
    return mapCollection(data);
  },
  deleteComment: async ({ id, commentId }: { id: string; commentId: string }) => {
    const { data } = await client.delete<Collection>(`/${id}/comments/${commentId}`);
    return mapCollection(data);
  },
  getSuggestions: async (collectionId: string) => {
    const { data } = await client.get<Trademark[]>(`/${collectionId}/suggestions`);
    return data.map(mapTrademark);
  },
  download: async (collectionId: string, type: "pdf" | "csv" | "xlsx") => {
    return client.get<Buffer>(`/${collectionId}/${type}`, {
      responseType: "arraybuffer",
    });
  },
};
