import { useAppDarkMode } from "app/hooks/use-app-dark-mode";
import clsx from "clsx";
import { lazy, Suspense } from "react";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import styles from "./JsonDebug.module.css";
const ReactJson = lazy(() => import("react-json-view"));

interface JsonDebugProps {
  className?: string;
  json: any;
  collapsed?: boolean | number;
}

export const JsonDebug = ({ className, json, collapsed = true }: JsonDebugProps) => {
  const darkMode = useAppDarkMode();
  if (!json) {
    return null;
  }
  return (
    <div className={clsx(styles.root, className)}>
      <Suspense fallback={<LoadingIndicator />}>
        <ReactJson
          src={JSON.parse(JSON.stringify(json))}
          displayDataTypes={false}
          theme={darkMode.value ? "hopscotch" : "rjv-default"}
          enableClipboard={false}
          collapsed={collapsed}
        />
      </Suspense>
    </div>
  );
};
