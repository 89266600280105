export const preloadImages = async (images: string[]) => {
  await Promise.all(
    images.map(
      (url) =>
        new Promise<void>((resolve) => {
          //   console.log("Preload", url);
          const img = new Image();
          img.src = url;
          img.onload = () => resolve();
          img.onerror = () => {
            console.log("Image", url, "failed to preload");
          };
        }),
    ),
  );
};

export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
};
