import styles from "./OneColumnLayout.module.scss";

type OneColumnLayoutProps = {
  children: React.ReactNode;
};

export const OneColumnLayout = ({ children }: OneColumnLayoutProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
