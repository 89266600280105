import { getClient } from "app/api";
import { QueryResult } from "app/models";
import { TrademarkReference } from "app/models/trademark-reference.model";
import { Trademark } from "app/models/trademark.model";
import { mapQueryResult, mapTrademark } from "app/util/trademark.util";

const client = getClient({ basePath: "/futzi" });

export const TrademarkClient = {
  fetch: async ({ id, office }: { id: string; office: string }) => {
    const { data } = await client.get<Trademark>("/trademark", {
      params: { id, office },
    });
    return mapTrademark(data);
  },

  fetchCount: async () => {
    const {
      data: { total },
    } = await client.get<{
      total: number;
    }>("/count");

    return total;
  },

  resolve: async (trademarks: TrademarkReference[]) => {
    const { data } = await client.post<QueryResult>("/trademarks", {
      trademarks,
    });
    return mapQueryResult(data);
  },

  fetchSuggestions: async ({ id, office }: { id: string; office: string }) => {
    const { data } = await client.get<Trademark[]>("/suggestions", {
      params: { id, office },
    });
    return data.map(mapTrademark);
  },

  download: async ({ id, office, type }: { id: string; office: string; type: "pdf" | "csv" | "xlsx" }) => {
    return client.get(`/trademark/${type}`, {
      params: { id, office },
      responseType: "arraybuffer",
    });
  },
};
