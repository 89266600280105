import alienNotFound from "app/assets/alien_not_found.svg";
import buyOktomark from "app/assets/BuyOktomark.svg";
import eatingIceCream from "app/assets/eating_ice-cream.svg";
import feedback from "app/assets/feedback.svg";
import filter from "app/assets/filter.svg";
import folder from "app/assets/folder_files.svg";
import lawyer from "app/assets/lawyer.svg";
import logout from "app/assets/logout.svg";
import mailSent from "app/assets/mail-sent.svg";
import noData from "app/assets/no-data.svg";
import notFound from "app/assets/not_found.svg";
import okto from "app/assets/okto.svg";
import appLogo from "app/assets/oktomark_logo.svg";
import appLogoText from "app/assets/oktomark_logo_text.svg";
import oktoWhite from "app/assets/okto_white.svg";
import productManager from "app/assets/product_manager.svg";
import research from "app/assets/research.svg";
import saveSearch from "app/assets/save-search.svg";
import signuppending from "app/assets/sign-up-pending.svg";
import theSearch from "app/assets/the-search.svg";
import monitorTrademarkClick from "app/assets/tutorial/monitor_trademark_click.png";
import saveTrademarkToCollection from "app/assets/tutorial/save-trademark-tutorial.png";
import updatesIcon from "app/assets/updates.svg";
import { TrademarkOffice } from "app/models/trademark.model";

export const APP_CONST = {
  NAME: "Oktomark",
  LINKS: {
    DASHBOARD: "https://app.oktomark.de",
    TRADEMARK: (id: string, office: TrademarkOffice) => `https://app.oktomark.de/trademark?id=${id}&office=${office}`,
    WEBSITE: "https://oktomark.de",
    AGB: "https://oktomark.de/agb",
    PRIVACY_POLICY: "https://oktomark.de/datenschutz",
  },
  IMAGES: {
    OKTO: okto,
    OKTO_WHITE: oktoWhite,
    LOGO: appLogo,
    LOGO_WITH_TEXT: appLogoText,
    EATING_ICE_CREAM: eatingIceCream,
    FEEDBACK: feedback,
    PORTFOLIO: folder,
    RESEARCH: research,
    BUY_OKTOMARK: buyOktomark,
    FILTER: filter,
    UPDATES: updatesIcon,
    ALIEN_NOT_FOUND: alienNotFound,
    NOT_FOUND: notFound,
    LAWYER: lawyer,
    MAIL_SENT: mailSent,
    LOGOUT: logout,
    SIGNUP_PENDING: signuppending,
    THE_SEARCH: theSearch,
    NO_DATA: noData,
    PRODUCT_MANAGER: productManager,
    SAVE_SEARCH: saveSearch,
    TUTORIAL: {
      MONITOR: monitorTrademarkClick,
      COLLECTION: saveTrademarkToCollection,
      PORTFOLIO: saveTrademarkToCollection,
      RESEARCH: saveTrademarkToCollection,
    },
  },
};
