import { getClient } from "app/api";
import { Partner } from "app/models";
import { mapPartner } from "app/util/partner.util";

const client = getClient({ basePath: "/partner" });

export const PartnerClient = {
  fetch: async () => {
    const { data } = await client.get<Partner[]>("/");
    return data.map(mapPartner);
  },
  create: async (partner: Partial<Omit<Partner, "uid" | "userId" | "createdAt" | "updatedAt">>) => {
    const { data } = await client.post<Partner>("/", partner);
    return mapPartner(data);
  },
  update: async ({
    id,
    partner,
  }: {
    id: string;
    partner: Partial<Omit<Partner, "uid" | "userId" | "createdAt" | "updatedAt">>;
  }) => {
    const { data } = await client.put<Partner>(`/${id}`, partner);
    return mapPartner(data);
  },
  delete: async (id: string) => {
    const { data } = await client.delete(`/${id}`);
    return data;
  },

  updateImage: async ({
    id,
    blob,
    key,
  }: {
    id: string;
    blob: Blob;
    key: "logo" | "image" | "usp1Image" | "usp2Image" | "usp3Image";
  }) => {
    const formData = new FormData();
    formData.append("image", blob);
    const { data } = await client.put<Partner>(`/${id}/${key}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  },
};
