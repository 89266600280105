import { sanitize } from "dompurify";

const anchorToExternalRegex = new RegExp(/(<a.*?https?:\/\/.*?[^a]>)+?/g);

function addAnchorTargetBlank(htmlText: string) {
  var match;
  while ((match = anchorToExternalRegex.exec(htmlText)) !== null) {
    // With only one capture group in the RegExp, we can safely take the first index from the match.
    var linkString = match[0];

    if (linkString.indexOf("target=") === -1) {
      var fixedLinkString = linkString.replace(/>$/, ' target="_blank" rel="noreferrer noopener">');
      htmlText = htmlText.replace(linkString, fixedLinkString);
    }
  }
  return htmlText;
}

export const rawHtmlToSafeHtml = (rawHtml: string) => {
  return sanitize(addAnchorTargetBlank(rawHtml));
};

export const htmlToString = (rawHtml?: string) => {
  if (!rawHtml) {
    return "";
  }
  let tmp = document.createElement("div");
  tmp.innerHTML = rawHtmlToSafeHtml(rawHtml);
  return tmp.textContent || tmp.innerText || "";
};
