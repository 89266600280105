import {
  FaCcAmex,
  FaCcDinersClub,
  FaCcDiscover,
  FaCcJcb,
  FaCcMastercard,
  FaCcVisa,
  FaCreditCard,
} from "react-icons/fa";

interface Props {
  brand?: "amex" | "diners" | "discover" | "jcb" | "mastercard" | "unionpay" | "visa" | "unknown" | string;
}

export const CreditCardIcon = ({ brand }: Props) => {
  switch (brand) {
    case "amex":
      return <FaCcAmex />;
    case "diners":
      return <FaCcDinersClub />;
    case "discover":
      return <FaCcDiscover />;
    case "jcb":
      return <FaCcJcb />;
    case "mastercard":
      return <FaCcMastercard />;
    case "visa":
      return <FaCcVisa />;
    default:
      return <FaCreditCard />;
  }
};
