import { useWindowWidth } from "@react-hook/window-size";

// $width_xs: 0px;
// $width_sm: 600px;
// $width_md: 900px;
// $width_lg: 1200px;
// $width_xl: 1536px;

enum Width {
  XS = 0,
  SM = 600,
  MD = 900,
  LG = 1200,
  XL = 1536,
}

export function useInterfaceWidth() {
  const width = useWindowWidth();

  const isMin = (w: Width) => {
    return width >= w;
  };

  const isXs = isMin(Width.XS);
  const isSm = isMin(Width.SM);
  const isMd = isMin(Width.MD);
  const isLg = isMin(Width.LG);
  const isXl = isMin(Width.XL);

  return { isXs, isSm, isMd, isLg, isXl, width };
}
