import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { DisplayTypeSwitch } from "app/components/DisplayTypeSwitch/DisplayTypeSwitch";
import { Divider } from "app/components/Divider/Divider";
import { IconButton } from "app/components/IconButton/IconButton";
import { TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { Trademark } from "app/models/trademark.model";
import { useAppSelector } from "app/redux/store.hooks";
import { notEmpty } from "app/util/filter.util";
import { hasVisual } from "app/util/trademark.util";
import { default as React, useState } from "react";
import styles from "./TrademarkResultList.module.scss";

export enum ResultListActionType {
  ADD_TO_COLLECTION = "ADD_TO_COLLECTION",
  FLAG_ALL_AS_IGNORED = "FLAG_ALL_AS_IGNORED",
  FLAG_ALL_AS_PROBLEMATIC = "FLAG_ALL_AS_PROBLEMATIC",
  REMOVE_FLAGS = "REMOVE_FLAGS",
}

export interface ResultListActionConfig {
  title?: string;
  Icon?: any;
  onClick?: () => Promise<void> | (() => void);
  enabled?: boolean;
}

export type ResultListActionConfigMap = Map<ResultListActionType, ResultListActionConfig>;

type ResultListActionsProps = { config?: ResultListActionConfigMap };

const ResultListActions = ({ config = new Map() }: ResultListActionsProps) => {
  const buildAction = (
    type: ResultListActionType,
    a: ResultListActionConfig,
  ): Required<ResultListActionConfig> | null => {
    const baseProps = {
      className: "",
      enabled: true,
      onClick: async () => console.log("onClick", type),
    };
    switch (type) {
      case ResultListActionType.ADD_TO_COLLECTION:
        return {
          Icon: ICONS.BOOKMARK,
          title: "Zu Sammlung hinzufügen",
          ...baseProps,
          ...a,
        };
      case ResultListActionType.FLAG_ALL_AS_PROBLEMATIC:
        return {
          Icon: ICONS.ALERT,
          title: "Alle Marken als problematisch markieren",
          ...baseProps,
          enabled: false,
          ...a,
        };
      case ResultListActionType.FLAG_ALL_AS_IGNORED:
        return {
          Icon: ICONS.IGNORE,
          title: "Alle Marken ignorieren",
          ...baseProps,
          enabled: false,
          ...a,
        };
      default:
        return null;
    }
  };

  return (
    <div className={styles.actions}>
      {Array.from(config.entries())
        .map(([type, action]) => buildAction(type, action))
        .filter(notEmpty)
        .filter((a) => a.enabled)
        .map(({ onClick, title, Icon }) => (
          <IconButton key={title} onClick={onClick} title={title}>
            <Icon className={styles.actionIcon} size={ICON_SIZE.DEFAULT} />
          </IconButton>
        ))}
    </div>
  );
};

function createActionMapWithOverrides(defaults: ResultListActionConfigMap, overrides: ResultListActionConfigMap) {
  overrides.forEach((value, key) => {
    defaults.set(key, {
      ...defaults.get(key),
      ...value,
    });
  });

  return defaults;
}

interface TrademarkResultListProps {
  title?: string;
  trademarks: Trademark[];
  filterNonVisuals?: boolean;
  hideHeaderActions?: boolean;
  actions?: ResultListActionConfigMap;
  renderItem: (displayType: TrademarkCardDisplayType, trademark: Trademark) => React.ReactNode;
}

export const TrademarkResultList = ({
  actions = new Map(),
  filterNonVisuals,
  renderItem,
  hideHeaderActions,
  title,
  trademarks,
}: TrademarkResultListProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const [displayType, setDisplayType] = useState(
    user?.interfaceDefaults?.trademarkDisplayType || TrademarkCardDisplayType.CARD,
  );

  const defaultActions: ResultListActionConfigMap = new Map([
    [
      ResultListActionType.ADD_TO_COLLECTION,
      {
        ...actions.get(ResultListActionType.ADD_TO_COLLECTION),
      },
    ],
  ]);

  const actionsConfig = createActionMapWithOverrides(defaultActions, actions);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        {title ? <h3 className={styles.title}>{title}</h3> : null}
        {hideHeaderActions ? null : (
          <div className={styles.headerActions}>
            <DisplayTypeSwitch type={displayType} onChange={setDisplayType} />
            <ResultListActions config={actionsConfig} />
          </div>
        )}
      </div>
      {hideHeaderActions ? null : <Divider />}
      {[TrademarkCardDisplayType.CARD, TrademarkCardDisplayType.COMPACT].includes(displayType)
        ? trademarks.map((trademark) => {
            const key = `resultlist_list_${trademark.id}_${trademark.office}_${trademark.name}`;
            return (
              <div className={styles.listItem} key={key}>
                {renderItem(displayType, trademark)}
              </div>
            );
          })
        : null}
      {displayType === TrademarkCardDisplayType.IMAGE ? (
        <div className={styles.grid}>
          {trademarks
            .filter((t) => (filterNonVisuals ? hasVisual(t.type) : true))
            .map((trademark) => {
              const key = `resultlist_image_${trademark.id}_${trademark.office}_${trademark.name}`;
              return (
                <div className={styles.gridItem} key={key}>
                  {renderItem(displayType, trademark)}
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};
