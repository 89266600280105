import { trademarkImageUrl } from "app/api";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { Modal, ModalContent } from "app/components/Modal/Modal";
import { ViennaClassDescription } from "app/components/ViennaClassDescription/ViennaClassDescription";
import { Trademark } from "app/models/trademark.model";
import { selectViennaClasses, useAppSelector } from "app/redux";
import { useState } from "react";
import styles from "./TrademarkImageModal.module.css";
interface TrademarkImageModalProps {
  open: boolean;
  onClose: () => void;
  trademark?: Trademark | null;
}

export const TrademarkImageModal = ({ open, trademark, onClose }: TrademarkImageModalProps) => {
  const [loading, setLoading] = useState(true);
  const classInfos = useAppSelector(selectViennaClasses(trademark?.viennaClasses || []));

  if (!trademark) {
    return null;
  }

  const { office, id } = trademark;

  const url = trademarkImageUrl(id, office, false);

  const handleLoaded = () => {
    setLoading(false);
  };

  const title = `${trademark.name} - Marke ${office}/${id}`;

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <ModalContent>
        {loading ? (
          <>
            <LoadingIndicator message="Loading" />
          </>
        ) : null}
        <img loading="lazy" onLoad={handleLoaded} className={styles.image} src={url} alt="" />
        <div className={styles.classInfos}>
          {classInfos.map((ci) => (
            <ViennaClassDescription key={ci.viennaId} viennaClass={ci} />
          ))}
        </div>
      </ModalContent>
    </Modal>
  );
};
