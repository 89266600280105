import { motion } from "framer-motion";
import { Route } from "react-router-dom";

type AnimatedRouteProps = {
  element: JSX.Element;
  path: string;
};

export const createAnimatedRoute = ({ path, element }: AnimatedRouteProps) => {
  return (
    <Route
      key={`route_${path}`}
      path={path}
      element={
        <motion.div
          // key needed to prevent flicker
          key={`route_animation_${path}`}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: "100%",
          }}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{ duration: 0.3 }}
        >
          {element}
        </motion.div>
      }
    />
  );
};
