import Fuse from "fuse.js";
import React, { useState } from "react";
import { Button } from "../../../components/Button/Button";
import { Card, CardActions, CardContent, CardTitle } from "../../../components/Card/Card";
import { TextArea } from "../../../components/TextArea/TextArea";
import { TmClass } from "../../../models/tmclass.model";
import { TmClassCloud } from "../TmClassCloud/TmClassCloud";

type MagicNiceClassGeneratorProps = {
  tmClasses: TmClass[];
  onSelectClass: (classId: number) => unknown;
  onSelectWord: (word: TmClass) => unknown;
};

export const MagicNiceClassGenerator = ({ tmClasses, onSelectWord, onSelectClass }: MagicNiceClassGeneratorProps) => {
  const [inputText, setInputText] = useState("");
  const [magicSuggestions, setMagicSuggestions] = useState<TmClass[]>([]);

  const fuse = React.useMemo(() => {
    return new Fuse<TmClass>(tmClasses, {
      keys: ["Begriff"],
      // keep it strict
      threshold: 0.2,
    });
  }, [tmClasses]);

  console.log("MagicNiceClassGenerator", { inputText, itemCount: tmClasses.length });

  const handleMagic = () => {
    let matchingTmClasses: TmClass[] = [];
    const words = (inputText || "").split(/\s|,/).filter((w) => Boolean((w || "").trim()));

    for (const word of words) {
      const res = fuse.search(word, { limit: 200 });

      console.log("res for", word, res);
      matchingTmClasses = [...matchingTmClasses, ...res.map((r) => r.item)];
    }

    console.log({
      matchingTmClasses,
    });

    setMagicSuggestions(matchingTmClasses);
  };

  const handleSelectClass = (c: TmClass) => {
    onSelectClass(Number(c.Klasse));
  };

  const handleSelectWord = (c: TmClass) => {
    onSelectWord(c);
  };

  return (
    <Card item>
      <CardTitle title="Klassen Generator" />
      <CardContent>
        <TextArea placeholder="Begriffe die Ihre Marke beschreiben..." onTextChange={setInputText} />
      </CardContent>
      <CardActions>
        <Button onClick={handleMagic}>Hex Hex</Button>
      </CardActions>
      <CardContent>
        <div>
          <h3>Klassen</h3>
          {/* TODO: sort by occurance count */}
          <TmClassCloud
            hideFilter
            items={magicSuggestions.map((m) => ({
              title: m.Klasse,
              item: m,
            }))}
            onSelect={handleSelectClass}
          />
        </div>
        <div>
          <h3>Begriffe</h3>
          <TmClassCloud
            items={magicSuggestions.map((m) => ({
              title: `${m.Begriff} (Klasse ${m.Klasse})`,
              item: m,
            }))}
            onSelect={handleSelectWord}
          />
        </div>
      </CardContent>
    </Card>
  );
};
