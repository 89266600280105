import { UserEvent, UserMetric } from "app/models/user-event-log.model";
import { toDate } from "./date.util";

export const mapUserEvent = (userEvent: UserEvent): UserEvent => ({
  ...userEvent,
  createdAt: toDate(userEvent.createdAt),
  updatedAt: toDate(userEvent.updatedAt),
});

export const mapMetric = (event: UserMetric): UserMetric => ({
  ...event,
  createdAt: toDate(event.createdAt),
});
