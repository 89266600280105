import clsx from "clsx";
import React from "react";
import styles from "./Card.module.scss";

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  className?: string;
  theme?: CardTheme;
  item?: boolean;
};

export enum CardTheme {
  DEFAULT = "DEFAULT",
}

type CardTitleProps = {
  title: React.ReactNode;
  subtitle?: any;
  compact?: boolean;
  icon?: any;
  onClick?: () => unknown;
};

export const CardTitle = ({ title, subtitle, compact, onClick, icon }: CardTitleProps) => {
  return (
    <div
      onClick={onClick}
      className={clsx(styles.title, {
        [styles.titleCompact]: compact,
        [styles.titleHasAction]: Boolean(onClick),
      })}
    >
      <div
        className={clsx(styles.titleMain, {
          [styles.titleHasIcon]: Boolean(icon),
        })}
      >
        <div>{title}</div>
        {subtitle ? <div>{subtitle}</div> : null}
      </div>
      {icon ? <div className={styles.titleIcon}>{icon}</div> : null}
    </div>
  );
};

type CardActionProps = {
  children: React.ReactNode;
};

export const CardActions = ({ children }: CardActionProps) => {
  return <div className={styles.actions}>{children}</div>;
};

type CardContentSectionProps = {
  children: React.ReactNode;
  title: string;
  description?: string;
  actions?: React.ReactNode;
};

export const CardContentSection = ({ children, title, description, actions }: CardContentSectionProps) => {
  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <div>
          <h4>{title}</h4>
          {description ? <p>{description}</p> : null}
        </div>
        {actions ? actions : null}
      </div>
      <div>{children}</div>
    </div>
  );
};

type CardContentProps = { className?: string; children: React.ReactNode };

export const CardContent = ({ children, className }: CardContentProps) => {
  return <div className={clsx(styles.content, className)}>{children}</div>;
};

export const Card = React.forwardRef(
  ({ children, className, item, theme = CardTheme.DEFAULT, ...props }: Props, ref) => (
    <div
      className={clsx(className, styles.root, {
        [styles.item]: item,
      })}
      {...props}
    >
      {children}
    </div>
  ),
);
