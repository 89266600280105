import { AdminUserData } from "app/hooks";
import { PublicUser, UserFlag } from "app/models";
import { WorkspaceFlag } from "app/models/workspace.model";
import { getRemainingDays } from "./date.util";
import { TRIAL_DAYS } from "./payment.util";

export const getName = (userId: string | undefined | null, users: PublicUser[]) => {
  return users.find((u) => u.userId === userId)?.name || "Unbekannt";
};

export const getAvatarUrl = (userId: string | undefined | null, users: PublicUser[]) => {
  return users.find((u) => u.userId === userId)?.photoUrl || "";
};

export const getUserTypeEmoji = (user: AdminUserData) => {
  if (user.flags?.includes(UserFlag.PLATFORM_ADMIN)) {
    return "👑";
  }
  if (user.flags?.includes(UserFlag.TEST_ACCOUNT)) {
    return "👷";
  }

  // HACK: Trial logic copied from backend
  // For admin purposes only
  const hasCustomPayment =
    user.workspace?.flags.includes(WorkspaceFlag.PAYMENT_CUSTOM) || user.flags?.includes(UserFlag.PAYMENT_CUSTOM);

  if (hasCustomPayment) {
    return "🆓";
  }

  const remainingTrialDays = getRemainingDays(TRIAL_DAYS, new Date(user.createdAt));
  const isInTrial = remainingTrialDays > 0;
  if (isInTrial) {
    return "⏳";
  }

  if (user.workspace?.stripeCustomerId) {
    return "💰";
  }

  const hasPaywall = !user.workspace?.stripeCustomerId;
  if (hasPaywall) {
    return "❌";
  }

  return "";
};
