import { AuthClient } from "app/api/auth.api";
import { Button } from "app/components/Button/Button";
import { Card } from "app/components/Card/Card";
import { useAppSearchParams } from "app/hooks";
import { NO_AUTH_ROUTES } from "app/routes";
import { handleError, handleUnknownError } from "app/util/error-handler";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./ResetEmailCard.module.css";

export const ResetEmailCard = () => {
  const [{ secret, email }] = useAppSearchParams({
    secret: "",
    email: "",
  });
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (!secret || !email) {
      handleError("Ihre Daten zum Zurücksetzen sind leider ungültig. Bitte wenden Sie sich an unseren Kundenservice.", {
        secret,
        email,
      });
      return;
    }
    try {
      await AuthClient.resetEmail(secret);
      toast.success(
        "Ihre E-Mail Adresse  wurde zurückgesetzt. Sie können sich nun mit Ihrer alten E-Mail Adresse einloggen.",
        {
          autoClose: 10000,
        },
      );
      navigate(NO_AUTH_ROUTES.LOGIN);
    } catch (error) {
      handleUnknownError(error);
    }
  };

  return (
    <Card className={styles.login}>
      <div className={styles.loginFormWrapper}>
        <div className={styles.headline}>E-Mail Adresse zurücksetzen</div>
        <p className={styles.subheadline}>
          Hier können Sie Ihre E-Mail Adresse auf <strong>{email}</strong> zurücksetzen.
        </p>
        <Button onClickPromise={handleSubmit} disabled={!secret} className={styles.button}>
          E-Mail Adresse zurücksetzen
        </Button>
      </div>
    </Card>
  );
};
