import { Button } from "app/components/Button/Button";
import { Card, CardContent } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { useAppForm, useAppSearchParams } from "app/hooks";
import { loginOrFail } from "app/redux/slices/auth.slice";
import { useAppDispatch } from "app/redux/store.hooks";
import { NO_AUTH_ROUTES } from "app/routes";
import { handleUnknownError, handleWarning } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import axios from "axios";
import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";
import sharedStyles from "./../SharedNoAuthViewStyles.module.css";
import styles from "./LoginCard.module.css";

type FormData = {
  email: string;
  password: string;
};

const PAGE_NAME = "Login";

export const LoginCard = () => {
  const [loading, setLoading] = useState(false);
  const [shake, setShake] = useState(false);
  const [{ ref }] = useAppSearchParams({
    ref: "",
  });
  const dispatch = useAppDispatch();
  const { registerEmailField, registerPasswordField, handleSubmit, errors, isDirty } = useAppForm<FormData>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async ({ email, password }: FormData) => {
    try {
      setLoading(true);
      trackEvent(AppEvent.LOGIN, {
        page: PAGE_NAME,
        ref,
      });
      await loginOrFail(dispatch)(email, password);
    } catch (error) {
      trackEvent(AppEvent.LOGIN_FAILED, {
        page: PAGE_NAME,
        ref,
      });
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 401) {
          setShake(true);
          setTimeout(() => setShake(false), 500);
          handleWarning("Login fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mail Adresse und Ihr Passwort.", error);
          return;
        }
      }

      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const emailHasErrors = Boolean(errors.email) && isDirty;
  const passwordHasErrors = Boolean(errors.password) && isDirty;

  return (
    <Card className={clsx({ [styles.shake]: shake }, sharedStyles.root)}>
      <CardContent>
        <div className={sharedStyles.headline}>Login</div>
        <form className={sharedStyles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...registerEmailField()}
            type="email"
            name="email"
            placeholder="hanna.mueller@beispiel.de"
            valid={!emailHasErrors}
            label={emailHasErrors ? "Bitte geben Sie eine vollständige E-Mail Adresse ein" : "E-Mail"}
          />
          <div className={styles.loginPasswordWrapper}>
            <Input
              {...registerPasswordField()}
              type="password"
              name="password"
              placeholder="Ihr Passwort"
              valid={!passwordHasErrors}
              label={passwordHasErrors ? "Bitte geben Sie Ihr Passwort ein" : "Passwort"}
            />
            <div className={styles.forgotPasswordLink}>
              <Link to={NO_AUTH_ROUTES.FORGOT_PASSWORD} className={styles.pwResetLink}>
                Passwort vergessen?
              </Link>
            </div>
          </div>
          <Button type="submit" loading={loading} className={sharedStyles.button}>
            Einloggen
          </Button>
        </form>
        <p className={sharedStyles.hint}>
          Noch keinen Account? <Link to={NO_AUTH_ROUTES.SIGNUP}>Jetzt kostenlos anmelden</Link>
        </p>
      </CardContent>
    </Card>
  );
};
