import clsx from "clsx";
import styles from "./QuickActionButton.module.scss";

interface Props {
  title: string;
  Icon?: any;
  className?: string;
}

export const QuickActionButton = ({ Icon, title, className }: Props) => {
  return (
    <button className={clsx(styles.button, className)}>
      {Icon ? <Icon className={styles.icon} /> : null} {title}
    </button>
  );
};
