import { Button, ButtonTheme } from "app/components/Button/Button";
import { List, ListItem } from "app/components/List/List";
import { Modal as ModalElement, ModalActions, ModalContent } from "app/components/Modal/Modal";

type Props = {
  open: boolean;
  onClose: () => void;
  items?: ListItem[];
  title?: string;
  subtitle?: string;
};

export const SelectModal = ({ open, onClose, title, subtitle, items = [] }: Props) => {
  const config = {
    title,
    subtitle,
    content: (
      <List
        items={items.map((i) => ({
          ...i,
          onClick: () => {
            if (i.onClick) {
              i.onClick();
              onClose();
            }
          },
        }))}
      />
    ),
    actions: (
      <Button theme={ButtonTheme.OUTLINE} onClick={onClose}>
        Schließen
      </Button>
    ),
  };

  return (
    <ModalElement open={open} onClose={onClose} title={config.title} subtitle={config.subtitle}>
      <ModalContent>{config.content}</ModalContent>
      <ModalActions>{config.actions}</ModalActions>
    </ModalElement>
  );
};
