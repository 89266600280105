import { getClient } from "app/api/client";
import { SessionInfo } from "app/models/session-info.model";
import { User } from "app/models/user.model";
import { toast } from "react-toastify";

type AuthResponse = {
  user: User;
  tokens: {
    accessToken: string;
    refreshToken: string;
  };
};

const noAuthClient = getClient({
  preventRefresh: true,
  withCredentials: false,
  basePath: "/auth",
});

const client = getClient({
  basePath: "/auth",
});

const showMessage = () => {
  console.log("Not implemented yet");
  toast.warn("Diese Funktion gibt es leider noch nicht...");
};

export const AuthClient = {
  // NO AUTH
  loginWithUsernameAndPassword: async (username: string, password: string) => {
    const { data } = await noAuthClient.post<AuthResponse>(
      "/login",
      {
        username,
        password,
      },
      { withCredentials: true },
    );

    return data;
  },
  validateInvite: async (workspaceId: string, secret: string) => {
    const { data } = await noAuthClient.post<{
      workspaceName: string;
    }>("/invite/validate", {
      workspaceId,
      secret,
    });
    return data;
  },
  resetEmail: async (secret: string): Promise<any> => {
    const { data } = await noAuthClient.post("/email/recover", {
      secret,
    });
    return data;
  },
  resetPassword: async (secret: string, password: string): Promise<any> => {
    const { data } = await noAuthClient.post("/password/forgot/recover", {
      secret,
      password,
    });
    return data;
  },
  sendPasswordResetEmail: async (email: string): Promise<any> => {
    const { data } = await noAuthClient.post("/password/forgot", {
      email,
    });
    return data;
  },
  // USER AUTH
  getUserInfo: async ({ preventRefresh }: { preventRefresh: boolean }) => {
    const client = getClient({ preventRefresh });
    const { data } = await client.get("/auth/userinfo");
    return data;
  },
  logoutAllSessions: async () => {
    const { data } = await client.post("/sessions/logout");
    return data;
  },
  getSessions: async () => {
    const { data } = await client.get<SessionInfo[]>("/sessions");
    return data;
  },
  logoutSession: async () => {
    const { data } = await client.post("/logout");
    return data;
  },

  changeEmail: async (email: string): Promise<any> => {
    const { data } = await client.put<any>("/email", { email });
    return data;
  },
  changePassword: async (password: string): Promise<any> => {
    const { data } = await client.put<any>("/password", { password });
    return data;
  },
  signupWithCredentials: async (name: string, email: string, password: string): Promise<any> => {
    const { data } = await client.post<AuthResponse>("/register", { name, email, password });
    return data;
  },
  joinWithCredentials: async (
    name: string,
    email: string,
    password: string,
    workspaceId: string,
    secret: string,
  ): Promise<any> => {
    const { data } = await client.post<any>("/join", { name, email, password, workspaceId, secret });
    return data;
  },
  reAuthenticate: async (username: string, password: string): Promise<AuthResponse> => {
    const { data } = await client.post<AuthResponse>("/reauthenticate", {
      username,
      password,
    });
    return data;
  },
  verifyEmailResetCode: async (code: string): Promise<any> => {
    showMessage();
  },
  verifyPasswordResetCode: async (code: string): Promise<any> => {
    showMessage();
  },
};
