import { validate } from "validate.js";

export const isValidEmail = (email: string): boolean => {
  if (typeof email !== "string") {
    return false;
  }
  const result = validate(
    { email },
    {
      email: {
        email: true,
      },
    },
  );
  return !result || result.length === 0;
};

export const isValidUrl = (url: string) => {
  if (typeof url !== "string") {
    return false;
  }

  const result = validate({ url }, { url: { url: true } });
  return !result || result.length === 0;
};

export const parseUrl = (url: string): string | undefined => {
  if (isValidUrl(url)) {
    return url;
  }

  const httpsUrl = `https://${url}`;
  if (isValidUrl(httpsUrl)) {
    return httpsUrl;
  }

  return undefined;
};
