export enum ViennaClassIdType {
  CATEGORY = "CATEGORY",
  DIVISION = "DIVISION",
  SECTION = "SECTION",
  AUXILIARY = "AUXILIARY",
  EUIPO_CUSTOM = "EUIPO_CUSTOM",
}

export type ViennaClass = {
  viennaId: string;
  notes: string[];
  type: ViennaClassIdType;
  text: string;
};
