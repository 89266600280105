import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Popover, PopoverList } from "app/components/Popover";
import { SortBy, SortOrder } from "app/models";
import clsx from "clsx";
import { useState } from "react";
import styles from "./SortByButton.module.css";

export enum SearchBy {
  TRADEMARK = "TRADEMARK",
  OWNER = "OWNER",
  REPRESENTATIVE = "REPRESENTATIVE",
}

export type SortOption = {
  title: string;
  sortBy: SortBy;
  sortOrder: SortOrder;
};

export const DEFAULT_SORT_OPTION = {
  title: "Relevanz (Absteigend)",
  sortBy: SortBy.RELEVANCE,
  sortOrder: SortOrder.DESC,
};

const sortOptions: SortOption[] = [
  DEFAULT_SORT_OPTION,
  {
    title: "Relevanz (Aufsteigend)",
    sortBy: SortBy.RELEVANCE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Name (Aufsteigend)",
    sortBy: SortBy.NAME,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Name (Absteigend)",
    sortBy: SortBy.NAME,
    sortOrder: SortOrder.DESC,
  },
  {
    title: "Änderungsdatum (Absteigend)",
    sortBy: SortBy.LAST_UPDATE_DATE,
    sortOrder: SortOrder.DESC,
  },
  {
    title: "Änderungsdatum (Aufsteigend)",
    sortBy: SortBy.LAST_UPDATE_DATE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Antragsdatum (Aufsteigend)",
    sortBy: SortBy.FILED_DATE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Antragsdatum (Absteigend)",
    sortBy: SortBy.FILED_DATE,
    sortOrder: SortOrder.DESC,
  },
  {
    title: "Eintragungsdatum (Aufsteigend)",
    sortBy: SortBy.GRANTED_DATE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Eintragungsdatum (Absteigend)",
    sortBy: SortBy.GRANTED_DATE,
    sortOrder: SortOrder.DESC,
  },

  {
    title: "Ablaufdatum (Aufsteigend)",
    sortBy: SortBy.EXPIRY_DATE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Ablaufdatum (Absteigend)",
    sortBy: SortBy.EXPIRY_DATE,
    sortOrder: SortOrder.DESC,
  },

  {
    title: "Beginn der Widerspruchsfrist (Aufsteigend)",
    sortBy: SortBy.OPPOSITION_START_DATE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Beginn der Widerspruchsfrist (Absteigend)",
    sortBy: SortBy.OPPOSITION_START_DATE,
    sortOrder: SortOrder.DESC,
  },

  {
    title: "Ende der Widerspruchsfrist (Aufsteigend)",
    sortBy: SortBy.OPPOSITION_END_DATE,
    sortOrder: SortOrder.ASC,
  },
  {
    title: "Ende der Widerspruchsfrist (Absteigend)",
    sortBy: SortBy.OPPOSITION_END_DATE,
    sortOrder: SortOrder.DESC,
  },
];

const findSortOption = (sortBy: SortBy, sortOrder: SortOrder) =>
  sortOptions.find((o) => o.sortBy === sortBy && o.sortOrder === sortOrder);

type SortByButtonProps = {
  children?: any;
  sortBy: SortBy;
  sortOrder: SortOrder;
  onChange: (option: SortOption) => void;
};

export const SortByButton = ({ onChange, sortBy, sortOrder }: SortByButtonProps) => {
  const [selectedOption, setSelectedOption] = useState<SortOption>(
    findSortOption(sortBy, sortOrder) || DEFAULT_SORT_OPTION,
  );

  const handleSelectOption = (option: SortOption) => {
    onChange(option);
    setSelectedOption(option);
  };

  const menuItems = sortOptions.map((so) => ({
    onClick: () => handleSelectOption(so),
    title: so.title,
    active: selectedOption.title === so.title,
  }));

  return (
    <Popover
      buttonElement={(active) => (
        <div
          className={clsx(styles.sortByButton, {
            [styles.buttonActive]: active || DEFAULT_SORT_OPTION !== selectedOption,
          })}
          title={selectedOption.title}
        >
          <ICONS.SORT className={styles.icon} size={ICON_SIZE.DEFAULT} />
        </div>
      )}
    >
      <PopoverList items={menuItems} />
    </Popover>
  );
};
