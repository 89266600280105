import { AuthClient } from "app/api/auth.api";
import { AvatarUpload } from "app/components/AvatarUpload/AvatarUpload";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardTitle } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { Input } from "app/components/Input/Input";
import { List } from "app/components/List/List";
import { AuthModal } from "app/components/Modals/AuthModal/AuthModal";
import { useModalToggle } from "app/hooks";
import { AuthProvider } from "app/models/user.model";
import { logoutSaga } from "app/redux/sagas/logout.saga";
import { deleteUserPhoto, fetchSessions, updateUser, updateUserPhoto } from "app/redux/slices/auth.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { settingsRoute } from "app/routes";
import { localizedTimeDistance } from "app/util/date.util";
import { handleError, handleUnknownError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { parseUserAgent } from "app/util/useragent.util";
import deepEqual from "deep-equal";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { getBrowserIcon } from "react-browser-icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./SettingsPageAccount.module.css";

export const SettingsPageAccount = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const { user, sessions } = useAppSelector((state) => state.auth);
  const [name, setName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [password, setPassword] = useState("");
  const [uploadedImage, setUploadedImage] = useState<File | null>();
  const [authModalOpen, openAuthModal, closeAuthModal] = useModalToggle();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSessions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isAccountUntouched =
    !uploadedImage && deepEqual({ name: user?.name, email: user?.email }, { name, email }) && !password;

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogoutEverywhere = async () => {
    trackEvent(AppEvent.LOGOUT_ALL_SESSIONS);
    try {
      await AuthClient.logoutAllSessions();
      logoutSaga({
        hardRefresh: true,
      });
    } catch (error) {
      handleError("Logout konte nicht ausgeführt werden.", error);
    }
  };

  const handleAccountSubmit = async () => {
    if (password) {
      openAuthModal();
      return;
    }
    if (email && email !== user?.email) {
      openAuthModal();
      return;
    }

    return handleAccountUpdate();
  };

  const handleAccountUpdate = async () => {
    try {
      setLoading(true);
      if (name !== user?.name) {
        await dispatch(updateUser({ name })).unwrap();

        toast.success("Wir haben Ihren Namen geändert.");
      }

      if (uploadedImage) {
        trackEvent(AppEvent.CHANGE_PROFILE_PICTURE);
        await dispatch(updateUserPhoto(uploadedImage)).unwrap();
        setUploadedImage(null);
        toast.success("Wir haben Ihr Profilbild aktualisiert.");
      }

      if (email && email !== user?.email) {
        trackEvent(AppEvent.CHANGE_EMAIL);
        await AuthClient.changeEmail(email);
        toast.success("Ihre E-Mail Adresse wurde geändert.");
      }

      if (password) {
        trackEvent(AppEvent.CHANGE_PASSWORD);
        await AuthClient.changePassword(password);
        setPassword("");
        toast.success("Ihr Passwort wurde geändert.");
      }
    } catch (error: any) {
      switch (error?.code) {
        case "auth/invalid-email":
          handleError("Ihre E-Mail Adresse ist ungültig. Bitte überprüfen Sie sie noch einmal.", error);
          emailRef.current?.focus();
          break;
        case "auth/email-already-in-use":
          handleError("Diese E-Mail Adresse wird bereits benutzt.", error);
          emailRef.current?.focus();
          break;
        case "auth/weak-password":
          handleError("Ihr Passwort ist zu kurz. Bitte wählen Sie ein anderes Passwort.", error);
          passwordRef.current?.focus();
          break;
        default:
          handleUnknownError(error);
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  const handleAvatarChange = (photo: null | File) => {
    setUploadedImage(photo);
  };

  const handleImageDelete = async () => {
    try {
      await dispatch(deleteUserPhoto()).unwrap();
    } catch (error) {
      handleUnknownError(error);
    }
  };

  if (!user) {
    return null;
  }

  const sortedLoginSessions = [...sessions]
    .sort((a, b) => new Date(b.lastRefresh || 0).getTime() - new Date(a.lastRefresh || 0).getTime())
    .slice(0, 10);

  return (
    <CardContainer className={styles.firstCard}>
      <Card item className={styles.card}>
        <CardTitle title="Account" subtitle="Hier können Sie Ihren persönlichen Account verwalten." />
        <CardContent>
          <AvatarUpload
            user={user}
            uploadedImage={uploadedImage}
            onDelete={handleImageDelete}
            onChange={handleAvatarChange}
          />
          <div className={styles.input}>
            <Input name="name" value={name} onChange={handleNameChange} label="Ihr Name" />
            <small>
              Ihren Firmennamen können Sie unter <Link to={settingsRoute("WORKSPACE")}>Workspace</Link> einstellen.
            </small>
          </div>
          <div className={styles.input}>
            <Input
              ref={emailRef}
              name="email"
              type="email"
              label="E-Mail Adresse"
              value={email}
              onChange={handleEmailChange}
              disabled={user.authProvider === AuthProvider.GOOGLE}
            />
            {user.authProvider === AuthProvider.EMAIL_PASSWORD && (
              <small>Diese E-Mail Adresse benutzen wir für Ihren Login und Benachrichtigungen.</small>
            )}
            {user.authProvider === AuthProvider.GOOGLE && <small>Ihre E-Mail Adresse wird von Google verwaltet.</small>}
          </div>
          {user.authProvider === AuthProvider.EMAIL_PASSWORD && (
            <div className={styles.input}>
              <Input
                ref={passwordRef}
                name="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                label="Password"
              />
              <small>Hier können Sie Ihr Passwort ändern.</small>
            </div>
          )}
        </CardContent>
        <CardActions>
          <Button type="submit" onClick={handleAccountSubmit} loading={loading} disabled={isAccountUntouched}>
            Account aktualisieren
          </Button>
        </CardActions>
      </Card>
      <Card item>
        <CardTitle
          title="Letzte Logins"
          subtitle="An diesen Geräten fand ein Login statt. Loggen Sie sich überall aus um eine ungewollte Nutzung ihres Accounts zu verhindern."
        />
        <CardContent>
          <List
            items={sortedLoginSessions.map((s) => {
              const { browser, os } = parseUserAgent(s.userAgent);
              const browserIcon = os.name
                ? getBrowserIcon({ browser: browser.name as any, className: "class", size: 24 })
                : undefined;

              const time = s.lastRefresh ? `${localizedTimeDistance(s.lastRefresh)}` : null;
              const text = `${browser.name} ${browser.version} (${os.name})`;
              return {
                id: `${text}_${time}`,
                title: text,
                subtitle: time,
                icon: browserIcon,
              };
            })}
          />
        </CardContent>
        <CardActions>
          <Button theme={ButtonTheme.DANGER} onClick={handleLogoutEverywhere}>
            Überall ausloggen
          </Button>
        </CardActions>
      </Card>
      <AuthModal open={authModalOpen} onClose={closeAuthModal} onSubmit={handleAccountUpdate} />
    </CardContainer>
  );
};
