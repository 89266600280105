import { LatestApplications } from "app/components/LatestApplications/LatestApplications";

interface UpdatesViewProps {
  className?: string;
  children?: React.ReactNode;
}

export const UpdatesView = ({ className, children }: UpdatesViewProps) => {
  return (
    <>
      <LatestApplications />
    </>
  );
};
