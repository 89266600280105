import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { MonitoringClient } from "app/api";
import { Monitoring } from "app/models";

const STATE_KEY = "monitoring";

interface MonitoringState {
  items: Monitoring[];
  loading: boolean;
}

const initialState: MonitoringState = {
  items: [],
  loading: false,
};

export const fetchMonitorings = createAsyncThunk(`${STATE_KEY}/fetch`, MonitoringClient.fetch);

export const updateMonitoring = createAsyncThunk(`${STATE_KEY}/update`, MonitoringClient.update);

export const createMonitoring = createAsyncThunk(`${STATE_KEY}/create`, MonitoringClient.create);

export const removeMonitoring = createAsyncThunk(`${STATE_KEY}/remove`, MonitoringClient.delete);

export const flagMonitoring = createAsyncThunk(`${STATE_KEY}/flag`, MonitoringClient.flag);

export const unflagMonitoring = createAsyncThunk(`${STATE_KEY}/unflag`, MonitoringClient.unflag);

export const addMonitoringComment = createAsyncThunk(`${STATE_KEY}/addComment`, MonitoringClient.addComment);

export const updateMonitoringComment = createAsyncThunk(`${STATE_KEY}/updateComment`, MonitoringClient.updateComment);

export const deleteMonitoringComment = createAsyncThunk(`${STATE_KEY}/deleteComment`, MonitoringClient.deleteComment);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const monitoringSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateMonitoring: (state, { payload }: PayloadAction<Monitoring>) => {
      if (state.items.some((i) => i.uid === payload.uid)) {
        state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.items = state.items.concat(payload);
      }
    },
    deleteMonitoring: (state, action: PayloadAction<{ monitoringId: string }>) => {
      state.items = state.items.filter((monitoring) => monitoring.uid !== action.payload.monitoringId);
    },
    destroy: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchMonitorings.fulfilled, (state, action) => {
        state.items = action.payload;
        state.loading = false;
      })
      .addCase(createMonitoring.fulfilled, (state, { payload }) => {
        if (state.items.some((i) => i.uid === payload.uid)) {
          state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.items = state.items.concat(payload);
        }
      })
      .addCase(removeMonitoring.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.uid !== action.meta.arg);
      })

      // Pending
      .addCase(fetchMonitorings.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchMonitorings.rejected, (state) => {
        state.loading = false;
      })

      // Matcher
      .addMatcher(
        isAnyOf(
          updateMonitoring.fulfilled,
          flagMonitoring.fulfilled,
          unflagMonitoring.fulfilled,
          addMonitoringComment.fulfilled,
          updateMonitoringComment.fulfilled,
          deleteMonitoringComment.fulfilled,
        ),
        (state, { payload }) => {
          if (state.items.some((i) => i.uid === payload.uid)) {
            state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
          } else {
            state.items = state.items.concat(payload);
          }
        },
      );
  },
});

export const monitoringReducer = monitoringSlice.reducer;
