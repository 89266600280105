import { Button, ButtonTheme } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { useAppForm } from "app/hooks";
import { createPortfolio, useAppDispatch } from "app/redux";
import { portfolioDetailRoute } from "app/routes";
import { handleError } from "app/util/error-handler";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
interface Props {
  open: boolean;
  onClose: () => void;
}

type FormData = { name: string; reference: string; color: string };

export const CreatePortfolioModal = ({ open, onClose }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleSubmit, register, errors } = useAppForm<FormData>({
    defaultValues: {
      name: "",
      reference: "",
    },
  });

  const handleAddNewPortfolio = async (formData: FormData) => {
    try {
      const res = await dispatch(
        createPortfolio({
          name: formData.name,
          note: "",
          contact: {
            name: formData.name,
            reference: formData.reference,
          },
          trademarks: [],
        }),
      ).unwrap();

      if (!res) {
        return;
      }

      const portfolioId = res.uid;
      navigate(portfolioDetailRoute(portfolioId));

      onClose();
    } catch (error) {
      handleError("Mandant konnte nicht erstellt werden.", error);
    }
  };

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      await handleAddNewPortfolio(data);
      setLoading(false);
      onClose();
    } catch {
      setLoading(false);
    }
  };

  const nameHasError = Boolean(errors.name);

  return (
    <Modal open={open} onClose={onClose} title={"Mandant erstellen"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <Input
            autoFocus
            type="text"
            placeholder="Name"
            valid={!nameHasError}
            label={nameHasError ? "Geben Sie dem Mandanten einen Namen" : "Name des Mandanten"}
            {...register("name", {
              required: true,
            })}
          />
          <Input
            placeholder="Aktenzeichen"
            type="text"
            label={"Aktenzeichen, Referenznummer oder URL (optional)"}
            {...register("reference", {
              required: false,
            })}
          />
        </ModalContent>
        <ModalActions>
          <Button type="reset" theme={ButtonTheme.OUTLINE} onClick={onClose} disabled={loading}>
            Abbrechen
          </Button>
          <Button type="submit" loading={loading}>
            Erstellen
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
