import { WorkspaceClient } from "app/api";
import { APP_CONST } from "app/assets/constants";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { PendingInvite } from "app/models/workspace.model";
import { handleUnknownError } from "app/util/error-handler";
import { useState } from "react";
import styles from "./PendingInviteModal.module.css";

interface PendingInviteModalProps {
  open: boolean;
  onClose: () => void;
  pendingInvite?: PendingInvite;
}

export const PendingInviteModal = ({ open, onClose, pendingInvite }: PendingInviteModalProps) => {
  const [loading, setLoading] = useState(false);

  if (!pendingInvite) {
    return null;
  }

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await WorkspaceClient.acceptPendingInvite(pendingInvite.workspaceId, pendingInvite.invite.secret);
      // HACK: Reload the whole page to rehydrate store
      window.location.reload();
      onClose();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleReject = async () => {
    try {
      setLoading(true);
      await WorkspaceClient.rejectPendingInvite(pendingInvite.workspaceId, pendingInvite.invite.secret);
      onClose();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={"Sie wurden eingeladen dem " + pendingInvite.workspaceName + " Workspace beizutreten"}
    >
      <ModalContent>
        <div className={styles.wrapper}>
          <img src={APP_CONST.IMAGES.BUY_OKTOMARK} alt="" className={styles.image} />
          <p className={styles.text}>
            Möchten Sie die Einladung von <span className={styles.name}>{pendingInvite.inviterName}</span> akzeptieren?
            Wenn Sie dem Workspace beitreten, werden Ihre Sammlungen und Markenüberwachungen automatisch mit Ihren
            Kolleg:innen geteilt.
          </p>
        </div>
      </ModalContent>
      <ModalActions>
        <Button theme={ButtonTheme.OUTLINE} onClick={handleReject} disabled={loading}>
          Einladung ablehnen
        </Button>
        <Button theme={ButtonTheme.DEFAULT} onClickPromise={handleConfirm} disabled={loading}>
          Workspace beitreten
        </Button>
      </ModalActions>
    </Modal>
  );
};
