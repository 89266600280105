import { createAnimatedRoute } from "app/animated-route";
import { ICONS } from "app/assets/icons/icons";
import { k, l } from "app/i18n";
import { GridNavigationLayout } from "app/layouts/GridLayout/GridNavigationLayout";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { ROUTES } from "app/routes";
import { SettingsPageAccount } from "app/views/SettingsView/SettingsPageAccount/SettingsPageAccount";
import { SettingsPageInterface } from "app/views/SettingsView/SettingsPageInterface/SettingsPageInterface";
import { SettingsPageNotifications } from "app/views/SettingsView/SettingsPageNotifications/SettingsPageNotifications";
import { SettingsPagePayment } from "app/views/SettingsView/SettingsPagePayment/SettingsPagePayment";
import { SettingsPageWorkspace } from "app/views/SettingsView/SettingsPageWorkspace/SettingsPageWorkspace";
import { Route, Routes } from "react-router-dom";

export const SettingsView = () => {
  const pages = [
    {
      ...ROUTES.SETTINGS.children.ACCOUNT,
      component: <SettingsPageAccount />,
    },
    {
      ...ROUTES.SETTINGS.children.WORKSPACE,
      component: <SettingsPageWorkspace />,
    },
    {
      ...ROUTES.SETTINGS.children.PAYMENT,
      component: <SettingsPagePayment />,
    },
    {
      ...ROUTES.SETTINGS.children.NOTIFICATIONS,
      component: <SettingsPageNotifications />,
    },

    {
      ...ROUTES.SETTINGS.children.INTERFACE,
      component: <SettingsPageInterface />,
    },
  ];

  const animatedRoutes = pages.map(({ component, path }) => ({
    element: component,
    path: path,
  }));

  return (
    <OneColumnLayout>
      <Routes>
        {/* Index Route is animated by parent router */}
        <Route
          path=""
          element={<GridNavigationLayout title={l(k.SETTINGS)} titleIcon={ICONS.SETTINGS} items={pages} />}
        />
        {animatedRoutes.map(createAnimatedRoute)}
      </Routes>
    </OneColumnLayout>
  );
};
