import { ScoreCategory } from "app/models";
import { FlaggedTrademarkResolved } from "app/models/flagged-trademark.model";
import { Trademark } from "app/models/trademark.model";
import { getScoreCategoryByScore } from "./score.util";

export function groupByScore(trademarks: Trademark[]) {
  return Object.entries(
    trademarks.reduce<{ [key: string]: Trademark[] }>((prev, curr) => {
      const scoreCategory = getScoreCategoryByScore(curr.score || 0);
      if (!prev[scoreCategory]) {
        prev[scoreCategory] = [];
      }
      prev[scoreCategory].push(curr);
      return prev;
    }, {}),
  ).map(([scoreCategory, trademarks]) => ({ scoreCategory: scoreCategory as ScoreCategory, trademarks }));
}

export function groupByFlag(flaggedTrademarks: FlaggedTrademarkResolved[]) {
  return Array.from(
    Object.entries(
      flaggedTrademarks.reduce<{ [key: string]: Trademark[] }>((prev, curr) => {
        if (!prev[curr.flag]) {
          prev[curr.flag] = [];
        }
        prev[curr.flag].push(curr.trademark);
        return prev;
      }, {}),
    ),
  );
}
