import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { TrademarkClient } from "app/api";

export interface SystemInfoState {
  totalTrademarks: number;
  loading: boolean;
}

const initialState: SystemInfoState = {
  totalTrademarks: 0,
  loading: false,
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchSystemInfo())
//
export const fetchSystemInfo = createAsyncThunk("system-info/fetch", TrademarkClient.fetchCount);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
const systemInfoSlice = createSlice({
  name: "system-info",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchSystemInfo.fulfilled, (state, action) => {
        state.totalTrademarks = action.payload;
        state.loading = false;
      })

      // Pending
      .addCase(fetchSystemInfo.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchSystemInfo.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const systemInfoReducer = systemInfoSlice.reducer;
