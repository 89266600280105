export enum NiceClassType {
  GOOD = "GOOD",
  SERVICE = "SERVICE",
}

export type NiceClass = {
  classNumber: number;
  type: NiceClassType;
  intro: string | null;
  heading: string;
  includesParticular: string[];
  excludesParticular: string[];
};

export type NiceClassLocale = "de" | "en";

export type NiceClassSuggestion = {
  id: string;
  count: number;
};

export type NiceClassSuggestions = {
  classes: NiceClassSuggestion[];
  descriptions: NiceClassSuggestion[];
};
