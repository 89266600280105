import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { AuthClient, refreshTokens, UserClient } from "app/api";
import { SessionInfo, User } from "app/models";
import { appInitSaga } from "app/redux/sagas/app-init.saga";
import { AppDispatch } from "app/redux/store.model";
import { AppEvent, trackEvent } from "app/util/tracking.util";

export interface AuthState {
  user: User | null;
  token: string | null;
  loading: boolean;
  sessions: SessionInfo[];
}

const initialState: AuthState = {
  user: null,
  token: null,
  loading: false,
  sessions: [],
};

export const fetchSessions = createAsyncThunk("auth/sessions/fetch", AuthClient.getSessions);

export const updateUser = createAsyncThunk("auth/user/updateUser", UserClient.update);
export const updateUserPhoto = createAsyncThunk("auth/user/updateUserPhoto", UserClient.updatePhoto);
export const deleteUserPhoto = createAsyncThunk("auth/user/deleteUserPhoto", UserClient.deletePhoto);
export const addUserFlag = createAsyncThunk("auth/user/addUserFlag", UserClient.addFlag);
export const removeUserFlag = createAsyncThunk("auth/user/removeUserFlag", UserClient.removeFlag);
export const updateUserNotifications = createAsyncThunk(
  "auth/user/updateNotifications",
  UserClient.updateNotifications,
);
export const updateUserInterfaceDefaults = createAsyncThunk(
  "auth/user/updateInterfaceDefaults",
  UserClient.updateInterfaceDefaults,
);
export const updateUserContext = createAsyncThunk("auth/user/updateContext", UserClient.updateContext);
export const clearUserContext = createAsyncThunk("auth/user/clearContext", UserClient.clearContext);

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<string | null>) => {
      state.token = action.payload;
    },
    destroy: (state) => {
      // somehow we have to explicitly set user
      state.user = null;
      state.sessions = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchSessions.fulfilled, (state, action) => {
        state.sessions = action.payload;
      })
      .addMatcher(
        isAnyOf(
          updateUserInterfaceDefaults.fulfilled,
          updateUserNotifications.fulfilled,
          updateUserContext.fulfilled,
          clearUserContext.fulfilled,
        ),
        (state, { payload }) => {
          state.user = payload;
        },
      );
  },
});

export const restoreSession = () => async (dispatch: AppDispatch) => {
  try {
    await AuthClient.getUserInfo({
      preventRefresh: true,
    });
  } catch (error) {
    console.warn("Failed to get userInfo", error);
  }

  try {
    dispatch(authSlice.actions.setLoading(true));
    const {
      user,
      tokens: { accessToken },
    } = await refreshTokens();
    dispatch(authSlice.actions.setToken(accessToken));
    dispatch(authSlice.actions.setUser(user));
    trackEvent(AppEvent.RESTORE_SESSION);
    try {
      await appInitSaga(dispatch);
    } catch (error) {
      console.error("Failed to init app", error);
    }
  } catch (error) {
    console.error("Failed to refresh tokens", error);
    dispatch(authSlice.actions.setUser(null));
  } finally {
    dispatch(authSlice.actions.setLoading(false));
  }
};

export const loginOrFail = (dispatch: AppDispatch) => async (email: string, password: string) => {
  console.log("Trying to log in...");

  try {
    dispatch(authSlice.actions.setLoading(true));
    const {
      user,
      tokens: { accessToken },
    } = await AuthClient.loginWithUsernameAndPassword(email, password);

    dispatch(authSlice.actions.setToken(accessToken));
    dispatch(authSlice.actions.setUser(user));
  } catch (error) {
    dispatch(authSlice.actions.setLoading(false));
    throw error;
  }

  try {
    await appInitSaga(dispatch);
  } catch (error) {
    console.error("Failed to init app", error);
  }

  dispatch(authSlice.actions.setLoading(false));
};

export const authReducer = authSlice.reducer;
