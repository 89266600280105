import { CollisonFlag, NotificationInterval, ScoreCategory } from "app/models";
import { Activity, TrademarkOffice, TrademarkStatus, TrademarkType } from "app/models/trademark.model";
import { UserEvent, UserEventType } from "app/models/user-event-log.model";
import {
  collectionDetailRoute,
  monitoringDetailRoute,
  portfolioDetailRoute,
  researchDetailRoute,
  savedSearchRoute,
  searchDetailRoute,
  trademarkDetailRoute,
} from "app/routes";
import { Link } from "react-router-dom";

export type PluralizeType =
  | "days"
  | "trademarks"
  | "collisions"
  | "collections"
  | "researches"
  | "monitorings"
  | "savedSearches";

export const pluralize = (type: PluralizeType, count?: number): string => {
  const e = count === 1 ? "" : "e";
  const n = count === 1 ? "" : "n";
  const en = count === 1 ? "" : "en";

  switch (type) {
    case "days":
      return `${count ? count : "0"} Tag${e}`;
    case "trademarks":
      return `${count ? count : "Keine"} Marke${n}`;
    case "collisions":
      return `${count ? count : "Keine"} Kollision${en}`;
    case "collections":
      return `${count ? count : "Keine"} Sammlung${en}`;
    case "monitorings":
      return `${count ? count : "Keine"} Überwachung${en}`;
    case "researches":
      return `${count ? count : "Keine"} ${count === 1 ? "Recherche" : "Recherchen"}`;
    case "savedSearches":
      return `${count ? count : "Keine"} ${count === 1 ? "gespeicherte Suche" : "gespeicherte Suchen"}`;
    default:
      return `${count} ${type}`;
  }
};

// Keep these in sync with backend i18n.util.ts
const officeMap = {
  [TrademarkOffice.DPMA]: "DPMA",
  [TrademarkOffice.EUIPO]: "EUIPO",
};

// Keep these in sync with backend i18n.util.ts
const typeMap: { [key: string]: string } = {
  [TrademarkType.WORD]: "Wortmarke",
  [TrademarkType.COMBINED]: "Wort-/Bildmarke",
  [TrademarkType.FIGURATIVE]: "Bildmarke",
  [TrademarkType.COLOUR]: "Farbmarke",
  [TrademarkType.SHAPE_3D]: "Dreidimensionale Marke",
  [TrademarkType.ACOUSTIC]: "Klangmarke",
  [TrademarkType.SOUND]: "Hörmarke",
  [TrademarkType.TRACER]: "Kennfadenmarke",
  [TrademarkType.OTHER]: "Sonstige Markenform",
  [TrademarkType.HOLOGRAM]: "Hologrammmarke",
  [TrademarkType.MOTION]: "Bewegungsmarke",
  [TrademarkType.UNKNOWN]: "Undefined",
  [TrademarkType.POSITION]: "Positionsmarke",
  [TrademarkType.PATTERN]: "Mustermarke",
  [TrademarkType.MULTIMEDIA]: "Multimediamarke",
  [TrademarkType.UNKNOWN]: "Unbekannte Markenform",
};

// Keep these in sync with backend i18n.util.ts
const statusMap: { [key: string]: string } = {
  [TrademarkStatus.REGISTERED]: "Eingetragen",
  [TrademarkStatus.CANCELLED]: "Marke gelöscht",
  [TrademarkStatus.REFUSED]: "Eintragung nicht möglich",
  [TrademarkStatus.FILED]: "Anmeldung eingegangen",
  [TrademarkStatus.UNDER_EXAMINATION]: "Widerspruchsfrist läuft",
  [TrademarkStatus.OPPOSED]: "Widerspruch",
  [TrademarkStatus.PUBLISHED]: "Veröffentlicht",
  [TrademarkStatus.WITHDRAWN]: "Zurückgezogen",
  [TrademarkStatus.PENDING]: "Anhängig",
  [TrademarkStatus.UNKNOWN]: "Unbekannt",
  [TrademarkStatus.SURRENDERED]: "Aufgegeben",
  [TrademarkStatus.CANCELLATION_PENDING]: "Löschung austehend",
  [TrademarkStatus.DELETED]: "Akte vernichtet",
  [TrademarkStatus.APPEAL_PENDING]: "Berufung anhängig",
};

export const localizedCreditCardBrand = (brand: string) => {
  switch (brand) {
    case "amex":
      return "American Express";
    case "mastercard":
      return "Mastercard";
    case "visa":
      return "Visa";
    default:
      return "";
  }
};

export const localizedOffice = (office: TrademarkOffice) => {
  return officeMap[office] || officeMap[TrademarkOffice.DPMA];
};

export const localizedType = (type: TrademarkType) => {
  return typeMap[type] || typeMap[TrademarkType.UNKNOWN];
};

export const toTrademarkType = (type: string): TrademarkType => {
  return (Object.keys(typeMap).find((key) => typeMap[key] === type) ||
    Object.keys(typeMap).find((key) => typeMap[key] === TrademarkType.UNKNOWN) ||
    TrademarkType.UNKNOWN) as TrademarkType;
};

export const localizedStatus = (status: TrademarkStatus) => {
  return statusMap[status] || statusMap[TrademarkStatus.UNKNOWN];
};

export const toTrademarkStatus = (status: string): TrademarkStatus => {
  return (Object.keys(statusMap).find((key) => statusMap[key] === status) ||
    Object.keys(statusMap).find((key) => statusMap[key] === TrademarkStatus.UNKNOWN) ||
    TrademarkStatus.UNKNOWN) as TrademarkStatus;
};

export const localizeScoreCategory = (scoreCategory: ScoreCategory): string => {
  switch (scoreCategory) {
    case ScoreCategory.ID:
      return "Anmeldenummer";

    case ScoreCategory.EXACT:
      return "Exakte Treffer";

    case ScoreCategory.SIMILAR:
      return "Ähnliche Marken";

    case ScoreCategory.FUZZY:
      return "Fuzzy-Search";

    default:
      return "Verwandt";
  }
};

export const localizedActivity = (activity: Activity) => {
  switch (activity.name) {
    case "Verlaengerung":
      switch (activity.status) {
        case "Verlaengert":
          return "Verlängert";
        default:
          return activity.status;
      }
    case "Widerspruch":
      switch (activity.status) {
        case "Zurueckgenommen":
          return "Widerspruch zurückgenommen";
        default:
          return activity.status;
      }
    default:
      return activity.name;
  }
};
export const localizeCollisionFlag = (collisionFlag: CollisonFlag) => {
  switch (collisionFlag) {
    case CollisonFlag.PROBLEMATIC:
      return "problematisch";
    case CollisonFlag.UNPROBLEMATIC:
      return "unproblematisch";
    default:
      return "unverifiziert";
  }
};

export const localizeInterval = (notificationInterval: NotificationInterval) => {
  switch (notificationInterval) {
    case NotificationInterval.DAILY:
      return "Täglich";
    case NotificationInterval.WEEKLY:
      return "Wöchentlich";
    case NotificationInterval.MONTHLY:
      return "Monatlich";
  }
};

export const localizeUserEvent = (userId: string, { userId: eventUserId, userName, event }: UserEvent) => {
  const name = userId === eventUserId ? `Sie haben` : `${userName} hat`;
  switch (event.type) {
    // WORKSPACE
    case UserEventType.WORKSPACE_CREATED:
      return `${name} den Workspace ${event.data.workspaceName} erstellt.`;
    case UserEventType.WORKSPACE_DELETED:
      return `${name} den Workspace ${event.data.workspaceName} gelöscht.`;
    case UserEventType.WORKSPACE_RENAMED:
      return `${name} den Workspace ${event.data.oldName} in ${event.data.workspaceName} umbenannt.`;
    case UserEventType.WORKSPACE_INVITE_CREATED:
      return (
        <span>
          {name}{" "}
          <a href={`mailto:${event.data.email}`} target="_blank" rel="noreferrer">
            {event.data.email}
          </a>{" "}
          in den {event.data.workspaceName} Workspace eingeladen.
        </span>
      );
    case UserEventType.WORKSPACE_INVITE_DELETED:
      return (
        <span>
          {name} die Einladung für{" "}
          <a href={`mailto:${event.data.email}`} target="_blank" rel="noreferrer">
            {event.data.email}
          </a>{" "}
          gelöscht.
        </span>
      );
    case UserEventType.WORKSPACE_USER_JOINED:
      return `${event.data.userName} ist dem ${event.data.workspaceName} Workspace beigetreten.`;
    case UserEventType.WORKSPACE_USER_LEFT:
      return `${event.data.userName} hat den ${event.data.workspaceName} Workspace verlassen.`;
    case UserEventType.WORKSPACE_CONTACT_CHANGED:
      return `${name} die Kontaktdaten für den ${event.data.workspaceName} Workspace angepasst.`;
    case UserEventType.WORKSPACE_BRANDING_CHANGED:
      return `${name} das Branding für den ${event.data.workspaceName} Workspace angepasst.`;
    case UserEventType.WORKSPACE_PAYMENT_METHOD_ADDED:
      return `${name} eine Zahlungsmethode für den ${event.data.workspaceName} Workspace hinzugefügt.`;
    case UserEventType.WORKSPACE_PAYMENT_METHOD_DELETED:
      return `${name} eine Zahlungsmethode für den ${event.data.workspaceName} Workspace gelöscht.`;
    case UserEventType.WORKSPACE_BILLING_INFORMATION_CHANGED:
      return `${name} die Rechnungsdaten für den ${event.data.workspaceName} Workspace angepasst.`;

    // COLLECTION
    case UserEventType.COLLECTION_CREATED:
      return (
        <span>
          {name} die Sammlung{" "}
          <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.collectionName}</Link> angelegt.
        </span>
      );
    case UserEventType.COLLECTION_DELETED:
      return `${name} die Sammlung ${event.data.collectionName} gelöscht.`;
    case UserEventType.COLLECTION_RENAMED:
      return (
        <span>
          {name} die Sammlung <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.oldName}</Link> in{" "}
          <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.collectionName}</Link> umbenannt.
        </span>
      );
    case UserEventType.COLLECTION_TRADEMARK_ADDED:
      const addedCollectionTrademarks = event.data.trademarks.length;
      return (
        <span>
          {name}{" "}
          {addedCollectionTrademarks === 1 ? (
            <>
              die Marke{" "}
              <Link
                to={trademarkDetailRoute({
                  id: event.data.trademarks[0].trademarkId,
                  office: event.data.trademarks[0].trademarkOffice,
                })}
              >
                {event.data.trademarks[0].trademarkName || "-"}
              </Link>
            </>
          ) : (
            `${addedCollectionTrademarks} Marken`
          )}{" "}
          zur Sammlung <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.collectionName}</Link>{" "}
          hinzugefügt.
        </span>
      );
    case UserEventType.COLLECTION_TRADEMARK_REMOVED:
      const removedCollectionTrademarks = event.data.trademarks.length;
      return (
        <span>
          {name}{" "}
          {removedCollectionTrademarks === 1 ? (
            <>
              die Marke{" "}
              <Link
                to={trademarkDetailRoute({
                  id: event.data.trademarks[0].trademarkId,
                  office: event.data.trademarks[0].trademarkOffice,
                })}
              >
                {event.data.trademarks[0].trademarkName || "-"}
              </Link>
            </>
          ) : (
            `${removedCollectionTrademarks} Marken`
          )}{" "}
          aus der Sammlung <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.collectionName}</Link>{" "}
          gelöscht.
        </span>
      );
    case UserEventType.COLLECTION_NOTE_CHANGED:
      return (
        <span>
          {name} die Notizen für die Sammlung{" "}
          <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.collectionName}</Link> angepasst.
        </span>
      );
    case UserEventType.COLLECTION_COMMENT_CREATED:
      return (
        <span>
          {name} einen neuen Kommentar zur Sammlung{" "}
          <Link to={collectionDetailRoute(event.data.collectionId)}>{event.data.collectionName}</Link> hinzugefügt.
        </span>
      );

    // MONITORING
    case UserEventType.MONITORING_CREATED:
      return (
        <span>
          {name} eine{" "}
          <Link to={monitoringDetailRoute(event.data.monitoringId)}>Überwachung für {event.data.trademarkName}</Link>{" "}
          angelegt.
        </span>
      );
    case UserEventType.MONITORING_DELETED:
      return (
        <span>
          {name} die Überwachung für{" "}
          <Link to={trademarkDetailRoute({ id: event.data.trademarkId, office: event.data.trademarkOffice })}>
            {event.data.trademarkName}
          </Link>{" "}
          gelöscht.
        </span>
      );
    case UserEventType.MONITORING_TRADEMARK_FLAGGED:
      const flaggedTrademarks = event.data.flaggedTrademarks.length;
      return (
        <span>
          {name}{" "}
          {flaggedTrademarks === 1 ? (
            <Link
              to={trademarkDetailRoute({
                id: event.data.flaggedTrademarks[0].trademarkId,
                office: event.data.flaggedTrademarks[0].trademarkOffice,
              })}
            >
              {event.data.flaggedTrademarks[0].trademarkName || "-"}
            </Link>
          ) : (
            `${flaggedTrademarks} Kollisionen`
          )}{" "}
          als {localizeCollisionFlag(event.data.flag)} für die Überwachung{" "}
          <Link to={monitoringDetailRoute(event.data.monitoringId)}>{event.data.trademarkName}</Link> markiert.
        </span>
      );
    case UserEventType.MONITORING_NOTE_CHANGED:
      return (
        <span>
          {name} die Notizen für die Überwachung{" "}
          <Link to={monitoringDetailRoute(event.data.monitoringId)}>{event.data.trademarkName}</Link> angepasst.
        </span>
      );
    case UserEventType.MONITORING_NOTIFICATION_INTERVAL_CHANGED:
      return (
        <span>
          {name} das Benachrichtigungsintervall für die Überwachung{" "}
          <Link to={monitoringDetailRoute(event.data.monitoringId)}>{event.data.trademarkName}</Link> auf{" "}
          {localizeInterval(event.data.newInterval)} angepasst.
        </span>
      );
    case UserEventType.MONITORING_SENSITIVITY_CHANGED:
      return (
        <span>
          {name} die Empfindlichkeit für die Überwachung{" "}
          <Link to={monitoringDetailRoute(event.data.monitoringId)}>{event.data.trademarkName}</Link> angepasst.
        </span>
      );
    case UserEventType.MONITORING_COMMENT_CREATED:
      return (
        <span>
          {name} einen neuen Kommentar zur{" "}
          <Link to={monitoringDetailRoute(event.data.monitoringId)}>{event.data.trademarkName}</Link> Überwachung
          hinzugefügt.
        </span>
      );

    // PORTFOLIO
    case UserEventType.PORTFOLIO_CREATED:
      return (
        <span>
          {name} den Mandanten <Link to={portfolioDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link>{" "}
          angelegt.
        </span>
      );
    case UserEventType.PORTFOLIO_DELETED:
      return `${name} den Mandanten ${event.data.portfolioName} gelöscht.`;
    case UserEventType.PORTFOLIO_RENAMED:
      return (
        <span>
          {name} den Mandanten <Link to={collectionDetailRoute(event.data.portfolioId)}>{event.data.oldName}</Link> in{" "}
          <Link to={collectionDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link> umbenannt.
        </span>
      );
    case UserEventType.PORTFOLIO_NOTE_CHANGED:
      return (
        <span>
          {name} die Notizen für den Mandanten{" "}
          <Link to={collectionDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link> angepasst.
        </span>
      );
    case UserEventType.PORTFOLIO_COMMENT_CREATED:
      return (
        <span>
          {name} einen neuen Kommentar zum Mandanten{" "}
          <Link to={collectionDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link> hinzugefügt.
        </span>
      );
    case UserEventType.PORTFOLIO_TRADEMARK_ADDED:
      const addedPortfolioTrademarks = event.data.trademarks.length;
      return (
        <span>
          {name}{" "}
          {addedPortfolioTrademarks === 1 ? (
            <>
              die Marke{" "}
              <Link
                to={trademarkDetailRoute({
                  id: event.data.trademarks[0].trademarkId,
                  office: event.data.trademarks[0].trademarkOffice,
                })}
              >
                {event.data.trademarks[0].trademarkName || "-"}
              </Link>
            </>
          ) : (
            `${addedPortfolioTrademarks} Marken`
          )}{" "}
          zum Mandanten <Link to={portfolioDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link>{" "}
          hinzugefügt.
        </span>
      );
    case UserEventType.PORTFOLIO_TRADEMARK_REMOVED:
      const removedPortfolioTrademarks = event.data.trademarks.length;
      return (
        <span>
          {name}{" "}
          {removedPortfolioTrademarks === 1 ? (
            <>
              die Marke{" "}
              <Link
                to={trademarkDetailRoute({
                  id: event.data.trademarks[0].trademarkId,
                  office: event.data.trademarks[0].trademarkOffice,
                })}
              >
                {event.data.trademarks[0].trademarkName || "-"}
              </Link>
            </>
          ) : (
            `${removedPortfolioTrademarks} Marken`
          )}{" "}
          aus dem Mandanten <Link to={collectionDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link>{" "}
          gelöscht.
        </span>
      );
    case UserEventType.PORTFOLIO_RESEARCH_ADDED:
      const addedResearches = event.data.researches.length;
      if (event.data.researches.length === 0) {
        return (
          <span>
            {name} den Mandanten{" "}
            <Link to={portfolioDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link> aktualisiert.
          </span>
        );
      }

      return (
        <span>
          {name}{" "}
          {addedResearches === 1 ? (
            <>
              die Recherche{" "}
              <Link to={collectionDetailRoute(event.data.researches[0].researchId)}>
                {event.data.researches[0].researchName || "-"}
              </Link>
            </>
          ) : (
            `${addedResearches} Recherchen`
          )}{" "}
          zum Mandanten <Link to={portfolioDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link>{" "}
          hinzugefügt.
        </span>
      );
    case UserEventType.PORTFOLIO_RESEARCH_REMOVED:
      const removedResearches = event.data.researches.length;
      return (
        <span>
          {name}{" "}
          {removedResearches === 1 ? (
            <>
              die Recherche{" "}
              <Link to={collectionDetailRoute(event.data.researches[0].researchId)}>
                {event.data.researches[0].researchName || "-"}
              </Link>
            </>
          ) : (
            `${removedResearches} Recherchen`
          )}{" "}
          aus dem Mandanten <Link to={portfolioDetailRoute(event.data.portfolioId)}>{event.data.portfolioName}</Link>{" "}
          entfernt.
        </span>
      );

    // RESEARCH
    case UserEventType.RESEARCH_CREATED:
      return (
        <span>
          {name} die Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          angelegt.
        </span>
      );
    case UserEventType.RESEARCH_DELETED:
      return `${name} die Recherche ${event.data.researchName} gelöscht.`;
    case UserEventType.RESEARCH_RENAMED:
      return (
        <span>
          {name} die Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.oldName}</Link> in{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          umbenannt.
        </span>
      );
    case UserEventType.RESEARCH_TRADEMARK_ADDED:
      const addedResearchTrademarks = event.data.trademarks.length;
      return (
        <span>
          {name}{" "}
          {addedResearchTrademarks === 1 ? (
            <>
              die Marke{" "}
              <Link
                to={trademarkDetailRoute({
                  id: event.data.trademarks[0].trademarkId,
                  office: event.data.trademarks[0].trademarkOffice,
                })}
              >
                {event.data.trademarks[0].trademarkName || "-"}
              </Link>
            </>
          ) : (
            `${addedResearchTrademarks} Marken`
          )}{" "}
          zur Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          hinzugefügt.
        </span>
      );
    case UserEventType.RESEARCH_TRADEMARK_REMOVED:
      const removedResearchTrademarks = event.data.trademarks.length;
      return (
        <span>
          {name}{" "}
          {removedResearchTrademarks === 1 ? (
            <>
              die Marke{" "}
              <Link
                to={trademarkDetailRoute({
                  id: event.data.trademarks[0].trademarkId,
                  office: event.data.trademarks[0].trademarkOffice,
                })}
              >
                {event.data.trademarks[0].trademarkName || "-"}
              </Link>
            </>
          ) : (
            `${removedResearchTrademarks} Marken`
          )}{" "}
          aus der Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          gelöscht.
        </span>
      );
    case UserEventType.RESEARCH_SAVED_SEARCH_ADDED:
      const addedSavedSearches = event.data.savedSearches.length;
      return (
        <span>
          {name}{" "}
          {addedSavedSearches === 1 ? (
            <>
              die Suche{" "}
              <Link to={searchDetailRoute(event.data.savedSearches[0].savedSearchId)}>
                {event.data.savedSearches[0].savedSearchName || "-"}
              </Link>
            </>
          ) : (
            `${addedSavedSearches} Suchen`
          )}{" "}
          zur Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          hinzugefügt.
        </span>
      );
    case UserEventType.RESEARCH_SAVED_SEARCH_REMOVED:
      const removedSavedSearches = event.data.savedSearches.length;
      return (
        <span>
          {name}{" "}
          {removedSavedSearches === 1 ? (
            <>
              die Suche{" "}
              <Link to={savedSearchRoute(event.data.savedSearches[0].savedSearchId)}>
                {event.data.savedSearches[0].savedSearchName || "-"}
              </Link>
            </>
          ) : (
            `${removedSavedSearches} Suchen`
          )}{" "}
          aus der Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          gelöscht.
        </span>
      );
    case UserEventType.RESEARCH_NOTE_CHANGED:
      return (
        <span>
          {name} die Notizen für die Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          angepasst.
        </span>
      );
    case UserEventType.RESEARCH_COMMENT_CREATED:
      return (
        <span>
          {name} einen neuen Kommentar zur Recherche{" "}
          <Link to={researchDetailRoute(event.data.portfolioId, event.data.researchId)}>{event.data.researchName}</Link>{" "}
          hinzugefügt.
        </span>
      );
    default:
      return "";
  }
};
