import { Card, CardContent } from "app/components/Card/Card";
import { useQuickSearch } from "app/hooks/use-quick-search.hook";
import { Trademark } from "app/models/trademark.model";
import { buildTmViewSearchUrl } from "app/util/tmview.util";
import { buildWipoSearchUrl } from "app/util/wipo.util";
import bingLogo from "../../../assets/logos/bing.svg";
import duckDuckGoLogo from "../../../assets/logos/duckduckgo.svg";
import googleLogo from "../../../assets/logos/google.svg";
import oktomarkLogo from "../../../assets/logos/oktomark.svg";
import tmviewLogo from "../../../assets/logos/tmview.svg";
import twitterLogo from "../../../assets/logos/twitter.svg";
import wikipediaLogo from "../../../assets/logos/wikipedia.svg";
import wipoLogo from "../../../assets/logos/wipo.png";
import styles from "./ExtendedSearchCard.module.scss";

interface ExtendedSearchCardProps {
  trademark: Trademark;
}

export const ExtendedSearchCard = ({ trademark }: ExtendedSearchCardProps) => {
  const { searchByTrademarkName } = useQuickSearch();

  const cards = [
    {
      title: trademark.name ? `${trademark.name} bei TMview suchen` : "TMview Suche",
      text: "TMview Suche",
      href:
        buildTmViewSearchUrl({
          query: trademark.name,
        }) || "",
      image: tmviewLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei WIPO suchen` : "WIPO Suche",
      text: "WIPO Suche",
      href:
        buildWipoSearchUrl({
          query: trademark.name,
        }) || "",
      image: wipoLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei Google suchen` : "Google Suche",
      text: "Google Suche",
      href: `https://google.de/search?q=${encodeURIComponent(trademark.name)}`,
      image: googleLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei Bing suchen` : "Bing Suche",
      text: "Bing Suche",
      href: `https://bing.com/search?q=${encodeURIComponent(trademark.name)}`,
      image: bingLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei DuckDuckGo suchen` : "DuckDuckGo Suche",
      text: "DuckDuckGo Suche",
      href: `https://duckduckgo.com/?q=${encodeURIComponent(trademark.name)}`,
      image: duckDuckGoLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei Wikipedia suchen` : "Wikipedia Suche",
      text: "Wikipedia Suche",
      href: `https://de.wikipedia.org/w/index.php?search=${encodeURIComponent(trademark.name)}`,
      image: wikipediaLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei Twitter suchen` : "Twitter Suche",
      text: "Twitter Suche",
      href: `https://twitter.com/search?q=${encodeURIComponent(trademark.name)}`,
      image: twitterLogo,
    },
    {
      title: trademark.name ? `${trademark.name} bei Oktomark suchen` : "Oktomark Suche",
      text: "Oktomark Suche",
      onClick: searchByTrademarkName(trademark),
      image: oktomarkLogo,
    },
  ];

  return (
    <div className={styles.root}>
      {cards.map((card) => (
        <a
          key={card.text}
          href={card.href}
          onClick={card.onClick}
          title={card.title}
          className={styles.link}
          target={"_blank"}
          rel="noreferrer"
        >
          <Card className={styles.card}>
            <CardContent className={styles.cardContent}>
              <img src={card.image} alt="" className={styles.image} />
              {card.text}
            </CardContent>
          </Card>
        </a>
      ))}
    </div>
  );
};
