import { ICONS } from "app/assets/icons/icons";
import { useInterfaceWidth } from "app/hooks";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import clsx from "clsx";
import { useState } from "react";
import { IconType } from "react-icons";
import styles from "./HorizontalLayout.module.scss";

export const PaneSwitcherIcon = {
  COLLECTIONS: ICONS.COLLECTIONS,
  NAV: ICONS.FILE_TRAY,
  SEARCH: ICONS.SEARCH,
  STATS: ICONS.CHART,
  HELP: ICONS.HELP,
  SETTING: ICONS.SETTINGS,
};

type PaneType = "control" | "sidebar" | "main";

type PaneSwitchButtonProps = {
  onClick: () => unknown;
  Icon: any;
  active: boolean;
  visible: boolean;
  iconRight?: boolean;
  title: string;
};

const PaneSwitchButton = ({ onClick, Icon, active, visible, title }: PaneSwitchButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(styles.paneSwitcherButton, {
        [styles.paneSwitcherButtonActive]: active,
        [styles.paneSwitcherButtonVisible]: visible,
      })}
    >
      <Icon className={styles.paneSwitcherButtonIcon} size={24} />
      <span className={styles.paneSwitcherButtonTitle}>{title}</span>
    </button>
  );
};

export interface HorizontalLayoutProps {
  control?: {
    title: string;
    icon?: IconType;
    content: React.ReactNode;
  };
  main: {
    title: string;
    icon?: IconType;
    content: React.ReactNode;
  };
  sidebar?: {
    title: string;
    icon?: IconType;
    content: React.ReactNode;
  } | null;
  fullWidth?: boolean;
  hidePaneSwitcher?: boolean;
}

export const HorizontalLayout = ({ main, control, sidebar, fullWidth, hidePaneSwitcher }: HorizontalLayoutProps) => {
  const { isXs, isMd, isLg } = useInterfaceWidth();
  const [pane, setPane] = useState<PaneType>("main");
  // const [{ nav }, updateParams] = useAppSearchParams({
  //   nav: "",
  // });
  const paneSwitcherHidden = isLg || (isMd && !Boolean(sidebar)) || hidePaneSwitcher;
  const showControlAsMain = !isMd && pane === "control";
  const showSidebarAsMain = (isXs || isMd) && pane === "sidebar";

  // useEffect(() => {
  //   if (nav) {
  //     if (nav === "control") {
  //       if (!paneSwitcherHidden && isXs) {
  //         setPane("control");
  //       }
  //     } else {
  //       setPane("main");
  //     }
  //     // console.log("HorizontalLayout recieved nav request", nav, "resetting");
  //     updateParams({
  //       nav: "",
  //     });
  //   }
  // }, [nav, paneSwitcherHidden, updateParams, isXs]);

  const handleSetVisiblePane = (p: PaneType) => () => setPane(p);

  const paneSwitcher = (
    <div className={styles.paneSwitcherWrapper}>
      <div className={clsx("tutorial-sub-navigation", styles.paneSwitcher)}>
        <PaneSwitchButton
          active={pane === "control"}
          onClick={handleSetVisiblePane("control")}
          Icon={control?.icon ? control.icon : PaneSwitcherIcon.NAV}
          visible={Boolean(control) && !isMd}
          title={control?.title || "-"}
        />
        <PaneSwitchButton
          active={pane === "main"}
          onClick={handleSetVisiblePane("main")}
          Icon={main?.icon ? main.icon : PaneSwitcherIcon.SEARCH}
          visible={Boolean(control) || Boolean(sidebar)}
          title={main?.title || "-"}
        />
        <PaneSwitchButton
          active={pane === "sidebar"}
          onClick={handleSetVisiblePane("sidebar")}
          Icon={sidebar?.icon ? sidebar.icon : PaneSwitcherIcon.STATS}
          visible={Boolean(sidebar) && !isLg}
          title={sidebar?.title || "-"}
        />
      </div>
    </div>
  );

  return (
    <OneColumnLayout>
      <div className={styles.root}>
        {paneSwitcherHidden ? null : paneSwitcher}
        <div className={styles.flexWrapper}>
          {control && isMd ? <div className={styles.control}>{control.content}</div> : null}
          <div
            className={clsx(styles.main, {
              [styles.mainFull]: fullWidth,
            })}
          >
            {showControlAsMain ? control?.content : null}
            {showSidebarAsMain ? sidebar?.content : null}
            {!showControlAsMain && !showSidebarAsMain ? main.content : null}
          </div>
          {sidebar && isLg ? (
            <div
              className={clsx(styles.sidebar, {
                [styles.sidebarFullWidth]: isMd,
              })}
            >
              {sidebar.content}
            </div>
          ) : null}
        </div>
      </div>
    </OneColumnLayout>
  );
};
