import { CollectionClient } from "app/api";
import { CommentsSection } from "app/components/CommentsSection/CommentSection";
import { Collection } from "app/models";
import { CommentVisibility } from "app/models/trademark-comment.model";
import { useAppSelector } from "app/redux/store.hooks";

type CollectionProps = {
  collection: Collection;
};

export const CollectionComments = ({ collection }: CollectionProps) => {
  const user = useAppSelector((state) => state.auth.user);

  const handleNewComment = async (text: string) => {
    console.log("handleNewComment", { text });
    await CollectionClient.addComment({
      id: collection.uid,
      comment: text,
      // TODO: Choose comment visibility
      visibility: CommentVisibility.PUBLIC,
    });
  };

  const handleUpdateComment = async (id: string, text: string) => {
    console.log("handleUpdateComment", { id, text });
    await CollectionClient.updateComment({
      id: collection.uid,
      commentId: id,
      comment: text,
      // TODO: Change comment visibility
      visibility: CommentVisibility.PUBLIC,
    });
  };

  const handleDeleteComment = async (id: string) => {
    console.log("handleDeleteComment", { id });
    await CollectionClient.deleteComment({
      id: collection.uid,
      commentId: id,
    });
  };
  return (
    <div>
      <CommentsSection
        user={user}
        comments={collection.comments || []}
        onAddNew={handleNewComment}
        onUpdate={handleUpdateComment}
        onDelete={handleDeleteComment}
      />
    </div>
  );
};
