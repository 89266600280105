import { CollectionClient } from "app/api";
import { EditableTrademarkList } from "app/components/EditableTrademarkList/EditableTrademarkList";
import { ExportType } from "app/components/Modals/ExportModal/ExportModal";
import { pluralize } from "app/i18n/i18n.util";
import { ContextType, TrademarkReference, TrademarkReferenceWithNote } from "app/models";
import { Trademark } from "app/models/trademark.model";
import { searchSlice, updateUserContext } from "app/redux";
import {
  deleteCollection,
  removeTrademarksFromCollection,
  sortTrademarksInCollection,
  updateCollection,
  updateTrademarkCollectionNotes,
} from "app/redux/slices/collection.slice";
import { AppModal, showModal } from "app/redux/slices/modal.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { ROUTES } from "app/routes";
import { handleError, handleUnknownError } from "app/util/error-handler";
import { sanitizeForFileName } from "app/util/string.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { matchesTrademark } from "app/util/trademark-reference.util";
import { CollectionEmptyState } from "app/views/CollectionsView/CollectionEmptyState/CollectionEmptyState";
import { CollectionSuggestions } from "app/views/CollectionsView/CollectionSuggestions/CollectionSuggestions";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CollectionHeader } from "../CollectionHeader/CollectionHeader";

export const CollectionDetail = () => {
  const { collectionId } = useParams<{ collectionId: string }>();
  const collection = useAppSelector((state) => state.collection.items.find((m) => m.uid === collectionId));

  const [trademarks, setTrademarks] = useState<Trademark[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (collection && collection.trademarks && collection.trademarks.length > 0) {
      if (trademarks.length === 0) {
        setLoading(true);
      }
      // TODO: Move this to redux to get websocket updates aka. real time collaboration
      CollectionClient.fetchResolved(collection.uid).then((res) => {
        setTrademarks(
          res.trademarks.trademarks.map((t) => ({
            ...t,
            note: collection.trademarks?.find(matchesTrademark(t))?.note,
          })),
        );
        setLoading(false);
      });
    } else {
      // Reset
      setTrademarks([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection]);

  if (!collection) {
    return null;
  }

  const { name, uid, note } = collection;

  const showRenameModal = () => {
    showModal(dispatch)({
      type: AppModal.CHANGE_VALUE,
      props: {
        title: `Sammlung ${name} umbenennen`,
        description: `Wie möchten Sie diese Sammlung nennen?`,
        defaultValue: name,
        onSubmit: async (name: string) => {
          trackEvent(AppEvent.RENAME_COLLECTION, {
            uid,
            name,
          });
          try {
            await dispatch(updateCollection({ id: uid, collection: { name, note: note || "" } }));
          } catch (error) {
            handleError("Sammlung konnte nicht umbenannt werden", error);
          }
        },
      },
    });
  };

  const showDeleteModal = () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Sammlung ${name} wirklich löschen?`,
        text: `Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          trackEvent(AppEvent.DELETE_COLLECTION, {
            uid,
          });

          try {
            await dispatch(deleteCollection(uid));
            navigate(ROUTES.COLLECTION.path);
          } catch (error) {
            handleError("Sammlung konnte nicht gelöscht werden", error);
          }
        },
      },
    });
  };

  const showExportModal = () => {
    showModal(dispatch)({
      type: AppModal.EXPORT,
      props: {
        title: `Sammlung exportieren`,
        onExport: (type: ExportType) => handleDownload(uid, type)(),
      },
    });
  };

  const showDebugModal = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: {
          collection,
          trademarks,
        },
      },
    });
  };

  const activateCollectionContext = async () => {
    await dispatch(
      updateUserContext({
        type: ContextType.COLLECTION,
        collectionId: collection.uid,
      }),
    ).unwrap();
    dispatch(
      searchSlice.actions.startNewSearch({
        query: collection.name,
      }),
    );
    navigate(ROUTES.SEARCH.path);
  };

  const handleSaveNote = async (newNote: string) => {
    trackEvent(AppEvent.UPDATE_COLLECTION_NOTE, {
      uid,
    });
    try {
      await dispatch(updateCollection({ id: uid, collection: { name, note: newNote || "" } }));
    } catch (error) {
      handleError("Notiz konnte nicht gespeichert werden", error);
    }
  };

  const handleDownload = (collectionId: string, type: ExportType) => () => {
    trackEvent(AppEvent.DOWNLOAD_COLLECTION, {
      uid: collectionId,
      type,
    });
    return CollectionClient.download(collectionId, type)
      .then((response) => {
        const date = format(new Date(), "dd-MM-yyyy");

        const filename = `Sammlung - ${sanitizeForFileName(collection.name)} - ${date}.${type}`;

        // FIXME: Don't know if this works on all devices and browsers
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => handleUnknownError(error));
  };

  const handleSaveSortOrder = async (trademarkReferences: TrademarkReference[]) => {
    const sortedTrademarkReferences = [...(collection.trademarks || [])].sort(
      (a, b) => trademarkReferences.findIndex(matchesTrademark(a)) - trademarkReferences.findIndex(matchesTrademark(b)),
    );

    await dispatch(
      sortTrademarksInCollection({
        id: collection.uid,
        trademarks: sortedTrademarkReferences,
      }),
    ).unwrap();
  };

  const handleRemoveTrademarks = async (trademarkReferences: TrademarkReference[]) => {
    await dispatch(
      removeTrademarksFromCollection({
        id: collection.uid,
        trademarks: trademarkReferences,
      }),
    ).unwrap();
  };

  const handleTrademarkNoteUpdate = async (trademarkReference: TrademarkReferenceWithNote) => {
    await dispatch(
      updateTrademarkCollectionNotes({
        id: collection.uid,
        trademarks: [trademarkReference],
      }),
    ).unwrap();
  };

  return (
    <>
      <CollectionHeader
        collection={collection}
        trademarks={trademarks}
        onRename={showRenameModal}
        onDelete={showDeleteModal}
        onExport={showExportModal}
        onSaveNote={handleSaveNote}
        onDebug={showDebugModal}
      />
      <EditableTrademarkList
        loading={loading}
        trademarks={trademarks}
        loadingMessage={`Sammlung ${collection.name} wird geladen`}
        emptyState={<CollectionEmptyState collection={collection} />}
        title={`${pluralize("trademarks", trademarks.length)} in dieser Sammlung`}
        onSaveSortOrder={handleSaveSortOrder}
        onAddTrademarks={activateCollectionContext}
        onRemoveTrademarks={handleRemoveTrademarks}
        onTrademarkNoteUpdate={handleTrademarkNoteUpdate}
      />
      {!loading ? <CollectionSuggestions collectionId={uid} /> : null}
    </>
  );
};
