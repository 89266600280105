import { APP_CONST } from "app/assets/constants";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { IconButton } from "app/components/IconButton/IconButton";
import { Popover, PopoverList } from "app/components/Popover";
import { notEmpty } from "app/util/filter.util";
import clsx from "clsx";
import styles from "./TrademarkActions.module.scss";

export enum TrademarkActionType {
  CREATE_MONITORING = "CREATE_MONITORING",
  ADD_TO_COLLECTION = "ADD_TO_COLLECTION",
  ADD_TO_RESEARCH = "ADD_TO_RESEARCH",
  ADD_TO_PORTFOLIO = "ADD_TO_PORTFOLIO",
  ADD_TO_THIS_COLLECTION = "ADD_TO_THIS_COLLECTION",
  ADD_TO_THIS_RESEARCH = "ADD_TO_THIS_RESEARCH",
  ADD_TO_THIS_PORTFOLIO = "ADD_TO_THIS_PORTFOLIO",
  ADD_NOTE = "ADD_NOTE",
  REMOVE_FROM_COLLECTION = "REMOVE_FROM_COLLECTION",
  REMOVE_FROM_PORTFOLIO = "REMOVE_FROM_PORTFOLIO",
  REMOVE_FROM_RESEARCH = "REMOVE_FROM_RESEARCH",
  IGNORE_TRADEMARK = "IGNORE_TRADEMARK",
  SEARCH_BY_NAME = "SEARCH_BY_NAME",
  DEBUG = "DEBUG",
  PROBLEMATIC = "PROBLEMATIC",
  UNPROBLEMATIC = "UNPROBLEMATIC",
  SHARE = "SHARE",
  EXPORT = "EXPORT",
  REMOVE = "REMOVE",
}

export interface TrademarkActionConfig {
  type?: TrademarkActionType;
  title?: string;
  className?: string;
  Icon?: any;
  onClick?: () => Promise<void> | void;
  enabled?: boolean;
}

export function createActionMapWithOverrides(defaults: TrademarkActionConfigMap, overrides: TrademarkActionConfigMap) {
  overrides.forEach((value, key) => {
    defaults.set(key, {
      ...defaults.get(key),
      ...value,
    });
  });

  return defaults;
}

type OktoIconProps = { className?: string };

const OktoIcon = ({ className }: OktoIconProps) => (
  <img src={APP_CONST.IMAGES.OKTO} style={{ width: 18, height: 18 }} alt="" className={className} />
);

export type TrademarkActionConfigMap = Map<TrademarkActionType, TrademarkActionConfig>;

type TrademarkActionProps = { config?: TrademarkActionConfigMap; visibleLimit: number; horizontal?: boolean };

export const TrademarkActions = ({ visibleLimit = 1, config = new Map(), horizontal }: TrademarkActionProps) => {
  const buildAction = (type: TrademarkActionType, a: TrademarkActionConfig): Required<TrademarkActionConfig> | null => {
    const baseProps = {
      type,
      className: "",
      enabled: true,
      onClick: async () => console.log("onClick", type),
    };
    switch (type) {
      case TrademarkActionType.CREATE_MONITORING:
        return {
          Icon: OktoIcon,
          title: "Marke überwachen",
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_TO_COLLECTION:
        return {
          title: "Zu Sammlung hinzufügen",
          Icon: ICONS.COLLECTIONS,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_TO_RESEARCH:
        return {
          title: "Zu Recherche hinzufügen",
          Icon: ICONS.RESEARCH,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_TO_PORTFOLIO:
        return {
          title: "Zu Mandant hinzufügen",
          Icon: ICONS.PORTFOLIO,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_TO_THIS_COLLECTION:
        return {
          title: "Zu dieser Sammlung hinzufügen",
          Icon: ICONS.ADD,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_TO_THIS_RESEARCH:
        return {
          title: "Zu dieser Recherche hinzufügen",
          Icon: ICONS.ADD,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_TO_THIS_PORTFOLIO:
        return {
          title: "Zu diesem Mandanten hinzufügen",
          Icon: ICONS.ADD,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.ADD_NOTE:
        return {
          title: "Notiz hinzufügen",
          Icon: ICONS.NOTE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.REMOVE_FROM_COLLECTION:
        return {
          title: "Aus Sammlung entfernen",
          Icon: ICONS.DELETE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.REMOVE_FROM_RESEARCH:
        return {
          title: "Aus Recherche entfernen",
          Icon: ICONS.DELETE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.REMOVE_FROM_PORTFOLIO:
        return {
          title: "Aus diesem Portfolio entfernen",
          Icon: ICONS.DELETE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.IGNORE_TRADEMARK:
        return {
          title: "Ergebnis ausschließen",
          Icon: ICONS.IGNORE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.SEARCH_BY_NAME:
        return {
          title: "Nach diesem Namen suchen",
          Icon: ICONS.SEARCH,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.DEBUG:
        return {
          title: "Debug",
          Icon: ICONS.DEBUG,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.PROBLEMATIC:
        return {
          title: "Problematisch",
          Icon: ICONS.ALERT,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.UNPROBLEMATIC:
        return {
          title: "Unproblematisch",
          Icon: ICONS.IGNORE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.SHARE:
        return {
          title: "Teilen",
          Icon: ICONS.SHARE,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.EXPORT:
        return {
          title: "Exportieren",
          Icon: ICONS.EXPORT,
          ...baseProps,
          ...a,
        };
      case TrademarkActionType.REMOVE:
        return {
          title: "Entfernen",
          Icon: ICONS.DELETE,
          ...baseProps,
          ...a,
        };
      default:
        return null;
    }
  };

  const actionItems = Array.from(config.entries())
    .map(([type, action]) => buildAction(type, action))
    .filter(notEmpty)
    .filter((a) => a.enabled);

  // at least zero before overflow
  const maxVisibleLimit = Math.max(visibleLimit - 1, 0);

  const visibleActionItems = actionItems.slice(0, maxVisibleLimit);
  const overflowActionItems = actionItems.slice(maxVisibleLimit);

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.horizontal]: horizontal,
      })}
    >
      {visibleActionItems.map(({ onClick, title, Icon, className }) => (
        <IconButton key={title} onClick={onClick} title={title} className={className}>
          <Icon size={ICON_SIZE.DEFAULT} />
        </IconButton>
      ))}
      {overflowActionItems.length > 0 ? (
        <Popover>
          <PopoverList items={overflowActionItems.map(({ title, onClick, Icon }) => ({ title, onClick, Icon }))} />
        </Popover>
      ) : null}
    </div>
  );
};
