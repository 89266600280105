import {
  AppDispatch,
  fetchCollections,
  fetchFeed,
  fetchLastSearches,
  fetchMonitorings,
  fetchNizza,
  fetchNotifications,
  fetchPartners,
  fetchPayment,
  fetchPendingInvites,
  fetchPortfolios,
  fetchResearches,
  fetchSavedSearches,
  fetchSystemInfo,
  fetchVienna,
  fetchWorkspace,
  fetchWorkspaceUsers,
  store,
} from "app/redux";
import { appLogger } from "app/util/logger.util";
import { initSocket } from "app/websocket/websocket";

export const appInitSaga = async (dispatch: AppDispatch) => {
  appLogger.log("App init");

  try {
    await Promise.all([store.dispatch(fetchSystemInfo())]);
  } catch (error) {
    console.error("Failed to initialize app", error);
  }

  dispatch(fetchWorkspace());
  dispatch(fetchWorkspaceUsers());
  dispatch(fetchPendingInvites());
  dispatch(fetchFeed());
  dispatch(fetchPortfolios());
  dispatch(fetchResearches());
  dispatch(fetchCollections());
  dispatch(fetchMonitorings());
  dispatch(fetchNotifications());
  dispatch(fetchSavedSearches());
  dispatch(fetchLastSearches());
  dispatch(fetchNizza());
  dispatch(fetchVienna());
  dispatch(fetchPayment());
  dispatch(fetchPartners());

  initSocket();
};
