import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import styles from "./CheckmarkList.module.css";

type Props = {
  items: string[];
};

export const CheckmarkList = ({ items }: Props) => {
  return (
    <ul className={styles.list}>
      {items.map((i) => (
        <li key={i} className={styles.item}>
          <div>
            <ICONS.CHECKMARK className={styles.checkmark} size={ICON_SIZE.DEFAULT} />
          </div>
          {i}
        </li>
      ))}
    </ul>
  );
};
