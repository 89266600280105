import { AuthClient } from "app/api";
import { Button } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { useAppForm } from "app/hooks";
import { useAppSelector } from "app/redux";
import { handleError } from "app/util/error-handler";
import { useState } from "react";

type AuthModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

type FormData = {
  password: string;
};

export const AuthModal = ({ open, onClose, onSubmit }: AuthModalProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const { registerPasswordField, handleSubmit, errors, isValid } = useAppForm<FormData>();

  if (!user) {
    return null;
  }

  const onModalSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await AuthClient.reAuthenticate(user.email, data.password);
      onClose();
      onSubmit();
    } catch (error) {
      handleError("Da ist etwas schief gelaufen. Bitte überprüfen Sie Ihr Passwort.", error);
    } finally {
      setLoading(false);
    }
  };

  const passwordHasErrors = Boolean(errors.password);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Passwort eingeben"
      subtitle="Bitte geben Sie Ihr aktuelles Passwort ein, um die Änderung zu bestätigen."
    >
      <form onSubmit={handleSubmit(onModalSubmit)}>
        <ModalContent>
          <Input
            {...registerPasswordField()}
            placeholder="Ihr Passwort"
            valid={!passwordHasErrors}
            type="password"
            name="password"
            label={errors.password ? "Das Passwort sollte mindestens 8 Stellen haben" : "Passwort"}
          />
        </ModalContent>
        <ModalActions>
          <Button type="submit" loading={loading} disabled={!isValid}>
            Bestätigen
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
