import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./ButtonGroup.module.css";

type ButtonOption = {
  id: string;
  active: boolean;
  title: ReactNode;
  onClick: () => void;
};

interface Props {
  options: ButtonOption[];
}

type SwitchButtonProps = { active: boolean; onClick: () => unknown; children: React.ReactNode };

export const SwitchButton = ({ children, active, onClick }: SwitchButtonProps) => (
  <button
    type="button"
    onClick={onClick}
    className={clsx(styles.button, {
      [styles.buttonActive]: active,
    })}
  >
    {children}
  </button>
);

export const ButtonGroup = ({ options }: Props) => {
  return (
    <div className={styles.root}>
      {options.map((option) => (
        <SwitchButton key={option.id} active={option.active} onClick={option.onClick}>
          {option.title}
        </SwitchButton>
      ))}
    </div>
  );
};
