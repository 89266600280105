import { TrademarkReference } from "app/models";
import { Trademark } from "app/models/trademark.model";

/**
 * Compares two trademarks or trademark references.
 *
 * Handy with Array.find() or Array.findIndex() functions.
 */
export const matchesTrademark =
  (trademark1: TrademarkReference | Trademark) => (trademark2: TrademarkReference | Trademark) => {
    const id1 = "trademarkId" in trademark1 ? trademark1.trademarkId : trademark1.id;
    const id2 = "trademarkId" in trademark2 ? trademark2.trademarkId : trademark2.id;

    const office1 = "trademarkOffice" in trademark1 ? trademark1.trademarkOffice : trademark1.office;
    const office2 = "trademarkOffice" in trademark2 ? trademark2.trademarkOffice : trademark2.office;

    return id1 === id2 && office1 === office2;
  };
