import { Collection, ContextType, Portfolio, Research } from "app/models";
import { useAppSelector } from "app/redux";

interface ActiveContextBase<T, D> {
  type: T;
  data: D;
}

export type ActiveContext =
  | ActiveContextBase<ContextType.RESEARCH, { research: Research; portfolio: Portfolio }>
  | ActiveContextBase<ContextType.PORTFOLIO, { portfolio: Portfolio }>
  | ActiveContextBase<ContextType.COLLECTION, { collection: Collection }>;

export const useActiveContext = (): ActiveContext | undefined => {
  const context = useAppSelector((state) => state.auth.user?.context);
  const collections = useAppSelector((state) => state.collection.items);
  const portfolios = useAppSelector((state) => state.portfolio.items);
  const researches = useAppSelector((state) => state.research.items);

  const collection = collections.find((c) => c.uid === context?.collectionId);
  const portfolio = portfolios.find((p) => p.uid === context?.portfolioId);
  const research = researches.find((r) => r.uid === context?.researchId);
  const researchPortfolio = portfolios.find((p) => p.researches?.find((r) => r.researchId === context?.researchId));

  switch (context?.type) {
    case ContextType.RESEARCH:
      if (research && researchPortfolio) {
        return {
          type: ContextType.RESEARCH,
          data: {
            research,
            portfolio: researchPortfolio,
          },
        };
      }
      return undefined;
    case ContextType.COLLECTION:
      if (collection) {
        return {
          type: ContextType.COLLECTION,
          data: {
            collection,
          },
        };
      }
      return undefined;
    case ContextType.PORTFOLIO:
      if (portfolio) {
        return {
          type: ContextType.PORTFOLIO,
          data: {
            portfolio,
          },
        };
      }
      return undefined;
    default:
      return undefined;
  }
};
