import clsx from "clsx";
import ReactModal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import styles from "./Modal.module.css";

type ModalContentProps = { className?: string; children: React.ReactNode };

export const ModalContent = ({ children, className }: ModalContentProps) => {
  return <div className={clsx(className, styles.content)}>{children}</div>;
};

type ModalActionProps = {
  children: React.ReactNode;
};

export const ModalActions = ({ children }: ModalActionProps) => {
  return <div className={styles.actions}>{children}</div>;
};

type ModalProps = {
  open: boolean;
  onClose?: () => void;
  classNames?: {
    title?: string;
    modal?: string;
  };
  title?: any;
  subtitle?: string;
  actions?: any;
  children: React.ReactNode;
};

export const Modal = ({ open, classNames, onClose, children, title, subtitle }: ModalProps) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ReactModal
      open={open}
      onClose={handleClose}
      center
      onEscKeyDown={handleClose}
      focusTrapped={false}
      blockScroll={false}
      animationDuration={200}
      classNames={{
        modal: clsx(styles.modal, classNames?.modal),
        closeIcon: styles.closeIcon,
        modalContainer: styles.container,
        closeButton: styles.closeButton,
        overlay: styles.overlay,
      }}
    >
      <div className={styles.titleContainer}>
        {title ? <div className={clsx(styles.title, classNames?.title)}>{title}</div> : null}
        {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
      </div>
      {children}
    </ReactModal>
  );
};
