import { APP_CONST } from "app/assets/constants";
import { ICONS } from "app/assets/icons/icons";
import { usePageContext } from "app/context";
import { useAppSelector } from "app/redux";
import { RouteConfig, ROUTES } from "app/routes";
import { notEmpty, onlyUnique } from "app/util/filter.util";
import { joinPath } from "app/util/path.util";
import { guessSavedSearchTitle } from "app/util/saved-search.util";
import clsx from "clsx";
import { isEqual } from "lodash-es";
import { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { IconType } from "react-icons";
import { Link, useLocation } from "react-router-dom";
import styles from "./BreadCrumbs.module.scss";

type CrumbConfig = {
  title: string;
  path: string;
  icon?: IconType;
};

function buildFullPath(previousPath: string, component: string) {
  return joinPath("/", previousPath, component);
}

export const BreadCrumbs = () => {
  const { pathname } = useLocation();
  const [components, setComponents] = useState<string[]>([]);
  const monitorings = useAppSelector((state) => state.monitoring.items);
  const collections = useAppSelector((state) => state.collection.items);
  const researches = useAppSelector((state) => state.research.items);
  const portfolios = useAppSelector((state) => state.portfolio.items);
  const savedSearches = useAppSelector((state) => state.search.saved);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const pageContext = usePageContext();

  useEffect(() => {
    const newState = pathname.split("/").filter(onlyUnique);
    if (!isEqual(newState, components)) {
      setComponents(newState);
    }
  }, [pathname, components]);

  const crumbs = useMemo(() => {
    return components
      .map<CrumbConfig | null>((component, index) => {
        const previousPath = components.slice(0, index).join("/");
        if (component === "") {
          return {
            icon: ICONS.DASHBOARD,
            title: ROUTES.DASHBOARD.title,
            path: buildFullPath(previousPath, component),
          };
        }
        const routes: RouteConfig[] = Object.values(ROUTES);
        const confedRoute: RouteConfig | undefined = routes.find((r) => r.path.includes(component));

        if (confedRoute) {
          const titleExtra = pageContext.path === pathname ? pageContext.title : "";
          const title = titleExtra ? `${confedRoute.title} ${titleExtra}`.trim() : confedRoute.title;
          return {
            icon: confedRoute.Icon,
            title: title,
            path: buildFullPath(previousPath, confedRoute.path),
          };
        }
        const monitoring = monitorings.find((m) => m.uid === component);
        if (monitoring) {
          return {
            // icon: ICONS.MONITORING,
            title: monitoring.trademark.name,
            path: buildFullPath(previousPath, monitoring.uid),
          };
        }

        const collection = collections.find((c) => c.uid === component);
        if (collection) {
          return {
            // icon: getCollectionIcon(coll.type),
            title: collection.name,
            path: buildFullPath(previousPath, collection.uid),
          };
        }

        const portfolio = portfolios.find((p) => p.uid === component);
        if (portfolio) {
          return {
            // icon: getCollectionIcon(coll.type),
            title: portfolio.name,
            path: buildFullPath(previousPath, portfolio.uid),
          };
        }

        const research = researches.find((r) => r.uid === component);
        if (research) {
          return {
            // icon: getCollectionIcon(coll.type),
            title: research.name,
            path: buildFullPath(previousPath, research.uid),
          };
        }

        const savedSearch = savedSearches.find((s) => s.uid === component);
        if (savedSearch) {
          return {
            // icon: ICONS.SEARCH,
            title: guessSavedSearchTitle(savedSearch),
            path: buildFullPath(previousPath, savedSearch.uid),
          };
        }

        const settingsRoute = Object.values(ROUTES.SETTINGS.children).find((r) => r.path === component);
        if (settingsRoute) {
          return {
            icon: settingsRoute.Icon,
            title: settingsRoute.title,
            path: buildFullPath(previousPath, settingsRoute.path),
          };
        }

        return null;
      })
      .filter(notEmpty);
  }, [
    collections,
    components,
    monitorings,
    pageContext.path,
    pageContext.title,
    pathname,
    portfolios,
    researches,
    savedSearches,
  ]);

  // scroll to end when new crumb is available
  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scroll({
        left: 1000,
        behavior: "smooth",
      });
    }
  }, [crumbs]);

  if (!pathname) {
    return null;
  }

  const isSearchView = pathname?.startsWith(ROUTES.SEARCH.path);

  if (crumbs.length === 0) {
    return null;
  }

  // set page title based on crumbs
  const pageTitle = [APP_CONST.NAME, ...crumbs.map((c) => c.title)].map((c) => ` ${c} `).join(">");

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      {isSearchView ? null : (
        <div className={styles.root}>
          <div className={styles.wrapper} ref={scrollContainerRef}>
            {crumbs.map((c, index) => {
              const isLast = index === crumbs.length - 1;

              return isLast ? (
                <div key={c.path} className={styles.crumbWrapper}>
                  <span
                    className={clsx(styles.crumbLink, styles.crumbLinkNoLink, {
                      [styles.crumbLinkLast]: isLast,
                    })}
                  >
                    {c.icon ? <c.icon size={22} className={styles.crumbIcon} /> : null}
                    {c.title}
                  </span>
                </div>
              ) : (
                <div key={c.path} className={styles.crumbWrapper}>
                  <Link
                    className={clsx(styles.crumbLink, {
                      [styles.crumbLinkLast]: isLast,
                    })}
                    to={c.path}
                  >
                    {c.icon ? <c.icon size={22} className={styles.crumbIcon} /> : null}
                    {c.title}
                  </Link>
                  <ICONS.CHEVRON_RIGHT className={styles.chevron} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
