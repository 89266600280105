import { APP_CONST } from "app/assets/constants";
import { ICONS } from "app/assets/icons/icons";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { DisplayTypeSwitch } from "app/components/DisplayTypeSwitch/DisplayTypeSwitch";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { Pagination } from "app/components/Pagination/Pagination";
import { SortByButton, SortOption } from "app/components/SortByButton/SortByButton";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import {
  ResultGroupActionConfigMap,
  ResultGroupActionType,
  TrademarkResultGroup,
} from "app/components/TrademarkResultGroup/TrademarkResultGroup";
import { Tutorial, TutorialStep } from "app/components/Tutorial/Tutorial";
import { useActiveContext, useInterfaceWidth } from "app/hooks";
import { useUserSavedSearches } from "app/hooks/use-user-saved-searches.hook";
import { k, l, localizeScoreCategory } from "app/i18n";
import { HorizontalLayout } from "app/layouts/HorizontalLayout/HorizontalLayout";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { ContextType, QueryResult, SavedSearch, SearchFilter, SortBy, SortOrder, UserFlag } from "app/models";
import { TrademarkOffice, TrademarkStatus, TrademarkType } from "app/models/trademark.model";
import {
  addTrademarksToCollection,
  addTrademarksToPortfolio,
  addTrademarksToResearch,
  removeTrademarksFromCollection,
  removeTrademarksFromPortfolio,
  removeTrademarksFromResearch,
} from "app/redux";
import { createSavedSearch, loadNextPage, loadPage, loadPrevPage, searchSlice } from "app/redux/slices/search.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { handleError } from "app/util/error-handler";
import { groupByScore } from "app/util/group.util";
import {
  cleanSavedSearch,
  guessSavedSearchTitle,
  isTrademarkIgnored,
  matchSavedSearch,
} from "app/util/saved-search.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { matchesTrademark } from "app/util/trademark-reference.util";
import { toTrademarkReference } from "app/util/trademark.util";
import { SavedSearchCard } from "app/views/SearchView/SavedSearches/SavedSearchCard/SavedSearchCard";
import { SavedSearches } from "app/views/SearchView/SavedSearches/SavedSearches";
import { SearchQuickActionBar } from "app/views/SearchView/SearchQuickActionBar/SearchQuickActionBar";
import clsx from "clsx";
import { sample } from "lodash-es";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { SearchSidebar } from "./SearchSidebar/SearchSidebar";
import styles from "./SearchView.module.css";

const tutorialSteps: TutorialStep[] = [
  {
    title: "Waren und Dienstleistungen",
    target: ".tutorial-search-nizza",
    content:
      "Hier können Sie schnell unterschiedliche Waren- und Dienstleistungsklassen auswählen, falls Sie Ihre Suche weiter eingrenzen wollen.",
    disableBeacon: true,
  },
  {
    title: "Filter",
    target: ".tutorial-search-filter",
    content:
      "Hier können Sie noch weitere Filter einrichten. So können Sie z. B. gezielt nach Wortmarken suchen oder sich auf ein Markenamt konzentrieren.",
    disableBeacon: true,
  },
  {
    title: "Bildersuche",
    target: ".tutorial-search-image-search",
    content:
      "Sie können mit Oktomark auch nach Bildern suchen. Laden Sie einfach ein Bild hoch und Oktomark sucht automatisch für Sie nach ähnlichen Marken.",
    disableBeacon: true,
  },
  {
    title: "Suche speichern",
    target: ".tutorial-save-search",
    content: "Hier können Sie Ihre aktuelle Suche für später speichern und dort weitermachen, wo Sie aufgehört haben.",
    disableBeacon: true,
  },
  // {
  //   title: "Markenüberwachung",
  //   target: ".tutorial-monitoring",
  //   content:
  //     "Mit nur einem Klick, können Sie eine Markenüberwachung einrichten. Klicken Sie einfach auf das Oktomark Logo.",
  //   disableBeacon: true,
  // },
];

const EmptyStateCard = () => {
  const [term, setTerm] = useState("");
  const dispatch = useAppDispatch();
  const { items: userVisibleSavedSearches, activateSavedSearch } = useUserSavedSearches();
  const { isXs, isSm } = useInterfaceWidth();
  useEffect(() => {
    const searchterms = [
      "Oktomark",
      "Düsseldorf",
      "Lego",
      "Google",
      "Apple",
      "Microsoft",
      "Spotify",
      "Netflix",
      "Tesla",
      "BMW",
      "VIVA CON AGUA",
      "M",
      "G",
    ];
    setTerm(sample(searchterms) || "");
  }, []);

  const searchForTerm = () => {
    dispatch(
      searchSlice.actions.startNewSearch({
        query: term,
      }),
    );
  };

  const showSavedSearches = isXs && !isSm;

  return (
    <div className={styles.emptyStateWrapper}>
      {showSavedSearches
        ? userVisibleSavedSearches.map((item) => (
            <SavedSearchCard key={item.uid} savedSearch={item} onActivate={activateSavedSearch} />
          ))
        : null}
      <EmptyState
        fullWidth
        title={l(k.TRADEMARK_SEARCH_EMPTY_STATE_TITLE)}
        text={l(k.TRADEMARK_SEARCH_EMPTY_STATE_TEXT, term)}
        image={APP_CONST.IMAGES.PRODUCT_MANAGER}
      >
        <Button onClick={searchForTerm} style={{ width: "100%" }}>{`Jetzt nach "${term}" suchen`}</Button>
      </EmptyState>
    </div>
  );
};

const NoResultsCard = () => {
  return (
    <EmptyState
      fullWidth
      smallImage
      title={l(k.TRADEMARK_SEARCH_NO_RESULT_STATE_TITLE)}
      text={l(k.TRADEMARK_SEARCH_NO_RESULT_STATE_TEXT)}
      image={APP_CONST.IMAGES.NO_DATA}
    ></EmptyState>
  );
};

const groupResults = (result: QueryResult | null, active: SavedSearch | null) => {
  const trademarks = Array.from(result?.trademarks || []);
  const filteredAndSorted = trademarks.sort((x, y) => {
    const xIgnored = isTrademarkIgnored(active, x);
    const yIgnored = isTrademarkIgnored(active, y);
    return yIgnored === xIgnored ? 0 : yIgnored ? -1 : 1;
  });
  return groupByScore(filteredAndSorted);
};

export const SearchView = () => {
  const totalTrademarks = useAppSelector((state) => state.systemInfo.totalTrademarks);
  const dispatch = useAppDispatch();
  const context = useActiveContext();
  const user = useAppSelector((state) => state.auth.user);
  const [displayType, setDisplayType] = useState(
    user?.interfaceDefaults?.trademarkDisplayType || TrademarkCardDisplayType.CARD,
  );
  const savedSearches = useAppSelector((state) => state.search.saved);
  const [sortBy, setSortBy] = useState(SortBy.RELEVANCE);
  const [sortOrder, setSortOrder] = useState(SortOrder.DESC);
  const { result, searchLoading, current } = useAppSelector((state) => state.search);
  const match = useMatch("/search/:searchId");
  const currentPage = Math.floor(result.offset / result.limit) + 1;
  const pageCount = Math.ceil(result.total / result.limit);
  const { activateSavedSearch } = useUserSavedSearches();
  const savedSearch = savedSearches.find((s) => s.uid === match?.params.searchId);

  // set active saved search when navigated to
  useEffect(() => {
    if (savedSearch) {
      dispatch(searchSlice.actions.startNewSearch(savedSearch));
    }
  }, [savedSearch, dispatch]);

  const [openedSections, setOpenedSections] = useState<Record<string, boolean>>({});

  const toggleSection = (key: string) => () => {
    const newState = !Boolean(openedSections[key]);
    trackEvent(AppEvent.TOGGLE_SEARCH_RESULT_SECTION, {
      key,
      prev: !newState,
      new: newState,
    });
    setOpenedSections((s) => ({
      ...s,
      [key]: newState,
    }));
  };

  const handlePage = (page: number) => {
    loadPage(result, page)(dispatch);
    trackEvent(AppEvent.LOAD_SOME_SEARCH_RESULTS_PAGE, { page });
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleNextPage = () => {
    loadNextPage(result)(dispatch);
    trackEvent(AppEvent.LOAD_NEXT_SEARCH_RESULTS_PAGE);
    window.scrollTo({ top: 0, left: 0 });
  };

  const handlePrevPage = () => {
    loadPrevPage(result)(dispatch);
    trackEvent(AppEvent.LOAD_PREV_SEARCH_RESULTS_PAGE);
    window.scrollTo({ top: 0, left: 0 });
  };

  const updateCurrentFilters = (diff: Partial<SearchFilter>) => {
    dispatch(
      searchSlice.actions.startNewSearch({
        ...current,
        filters: {
          ...current.filters,
          ...diff,
        },
      }),
    );
  };

  const handleSortChange = (sortOption: SortOption) => {
    setSortBy(sortOption.sortBy);
    setSortOrder(sortOption.sortOrder);
    dispatch(
      searchSlice.actions.startNewSearch({
        ...current,
        sortBy: sortOption.sortBy,
        sortOrder: sortOption.sortOrder,
      }),
    );
  };

  const handleSaveSearch = async () => {
    const hasSavedSearch = savedSearches.find(matchSavedSearch(current));

    if (hasSavedSearch) {
      toast.info(`Die Suche ${guessSavedSearchTitle(hasSavedSearch)} existiert bereits.`, {
        onClick: () => activateSavedSearch(hasSavedSearch),
      });
      return;
    }

    try {
      await dispatch(
        createSavedSearch({
          ...cleanSavedSearch(current),
          researchId: context?.type === ContextType.RESEARCH ? context?.data.research.uid : undefined,
        }),
      ).unwrap();
    } catch (error) {
      handleError("Suche konnte nicht gespeichert werden.", error);
    }
  };

  const groupedResults = groupResults(result, current);

  useEffect(() => {
    groupedResults.forEach(({ scoreCategory }) => {
      const isOpen = openedSections[scoreCategory];

      if (isOpen === undefined) {
        setOpenedSections((s) => ({
          ...s,
          [scoreCategory]: true,
        }));
      }
    });
  }, [groupedResults, openedSections]);

  // const onToggleIgnore = (tm: Trademark) => () => {
  //   if (!current.uid) {
  //     return;
  //   }

  //   if (current?.ignored?.map((ignore) => ignore.trademarkId).includes(tm.id)) {
  //     trackEvent(AppEvent.UNIGNORE_TRADEMARK_FROM_SAVED_SEARCH, {
  //       uid: current.uid,
  //       tmId: tm.id,
  //       tmOffice: tm.office,
  //     });
  //     SavedSearchClient.removeIgnoredTrademark(current.uid, tm.id, tm.office);
  //   } else {
  //     trackEvent(AppEvent.IGNORE_TRADEMARK_FROM_SAVED_SEARCH, {
  //       uid: current.uid,
  //       tmId: tm.id,
  //       tmOffice: tm.office,
  //     });
  //     SavedSearchClient.addIgnoredTrademark(current.uid, tm.id, tm.office);
  //   }
  // };

  const renderMainContent = () => {
    if (searchLoading) {
      return (
        <div className={styles.loading}>
          <LoadingIndicator
            message={`Durchsuche ${new Intl.NumberFormat("de-DE").format(totalTrademarks)} Markeneintragungen ...`}
          />
        </div>
      );
    }

    if (result.trademarks.length > 0) {
      return (
        <div className={styles.resultList}>
          {groupedResults.map(({ scoreCategory, trademarks }) => {
            const actions: ResultGroupActionConfigMap = new Map();

            const handleSelect = async () => {
              if (context?.type === ContextType.RESEARCH) {
                const selected = context.data.research.trademarks?.some((t1) =>
                  trademarks.map(toTrademarkReference).some(matchesTrademark(t1)),
                );

                const payload = {
                  id: context.data.research.uid,
                  trademarks: trademarks.map(toTrademarkReference),
                };

                if (!selected) {
                  await dispatch(addTrademarksToResearch(payload)).unwrap();
                } else {
                  await dispatch(removeTrademarksFromResearch(payload)).unwrap();
                }

                return;
              }

              if (context?.type === ContextType.PORTFOLIO) {
                const selected = context.data.portfolio.trademarks?.some((t1) =>
                  trademarks.map(toTrademarkReference).some(matchesTrademark(t1)),
                );
                const payload = {
                  id: context.data.portfolio.uid,
                  trademarks: trademarks.map(toTrademarkReference),
                };
                if (!selected) {
                  await dispatch(addTrademarksToPortfolio(payload)).unwrap();
                } else {
                  await dispatch(removeTrademarksFromPortfolio(payload)).unwrap();
                }

                return;
              }

              if (context?.type === ContextType.COLLECTION) {
                const selected = context.data.collection.trademarks?.some((t1) =>
                  trademarks.map(toTrademarkReference).some(matchesTrademark(t1)),
                );
                const payload = {
                  id: context.data.collection.uid,
                  trademarks: trademarks.map(toTrademarkReference),
                };
                if (!selected) {
                  await dispatch(addTrademarksToCollection(payload)).unwrap();
                } else {
                  await dispatch(removeTrademarksFromCollection(payload)).unwrap();
                }

                return;
              }
            };

            const allTrademarksInPortfolio =
              context?.type === ContextType.PORTFOLIO &&
              trademarks
                .map(toTrademarkReference)
                .every((t1) => context.data.portfolio.trademarks?.find(matchesTrademark(t1)));

            const allTrademarksInResearch =
              context?.type === ContextType.RESEARCH &&
              trademarks
                .map(toTrademarkReference)
                .every((t1) => context.data.research.trademarks?.find(matchesTrademark(t1)));

            const allTrademarksInCollection =
              context?.type === ContextType.COLLECTION &&
              trademarks
                .map(toTrademarkReference)
                .every((t1) => context.data.collection.trademarks?.find(matchesTrademark(t1)));

            const groupIsFullySelected =
              allTrademarksInPortfolio || allTrademarksInResearch || allTrademarksInCollection;

            actions.set(ResultGroupActionType.ADD_TO_CONTEXT, {
              enabled: Boolean(context),
              onClick: handleSelect,
              Icon: groupIsFullySelected ? ICONS.CHECKBOX : ICONS.EMPTY_CHECKBOX,
              title: "",
            });

            const isOpened = Boolean(openedSections[scoreCategory]);

            const scoreStats = result.stats.scores?.find((score) => score.name === scoreCategory);

            const subtitle = scoreStats ? `${scoreStats?.count} Ergebnis${scoreStats?.count === 1 ? "" : "se"}` : "";

            return (
              <TrademarkResultGroup
                actions={actions}
                collapsed={!isOpened}
                toggleCollapsed={toggleSection(scoreCategory)}
                key={scoreCategory}
                title={localizeScoreCategory(scoreCategory)}
                isGrid={displayType === TrademarkCardDisplayType.IMAGE}
                subtitle={subtitle}
                items={trademarks}
                renderItem={(item, i) => (
                  <TrademarkCard
                    key={`${item.id}_${item.office}`}
                    className={i === 0 ? "tutorial-search-context-trademark" : undefined}
                    displayType={displayType}
                    trademark={item}
                    ignored={isTrademarkIgnored(current, item)}
                    isSelectable
                    // onToggleIgnore={current ? onToggleIgnore(item) : undefined}
                  />
                )}
              />
            );
          }, [])}
          <Pagination
            pages={pageCount}
            currentPage={currentPage}
            onPage={handlePage}
            onNext={handleNextPage}
            onPrev={handlePrevPage}
          />
        </div>
      );
    }

    if ((current.query || current.owner || current.representative) && result.trademarks.length === 0) {
      return <NoResultsCard />;
    }

    return <EmptyStateCard />;
  };

  const searchSidebar = (
    <>
      <SearchSidebar
        filters={current.filters}
        trademarks={result}
        handlers={{
          onToggleOffice: (offices: TrademarkOffice[]) => {
            updateCurrentFilters({
              office: offices,
            });
          },
          onToggleOwner: (names: string[]) => {
            updateCurrentFilters({
              owner: names,
            });
          },
          onToggleNizza: (classes: string[]) => {
            updateCurrentFilters({
              niceClass: classes,
            });
          },
          onToggleVienna: (classes: string[]) => {
            updateCurrentFilters({
              viennaClass: classes,
            });
          },
          onToggleRepresentative: (names: string[]) => {
            updateCurrentFilters({
              representative: names,
            });
          },
          onToggleType: (types: TrademarkType[]) => {
            updateCurrentFilters({
              type: types,
            });
          },
          onToggleStatus: (status: TrademarkStatus[]) => {
            updateCurrentFilters({
              status: status,
            });
          },
        }}
      />
    </>
  );

  const sidebar = {
    title: "Meine Suchen",
    content: <SavedSearches />,
  };

  const control = {
    title: "Filter",
    content: searchSidebar,
  };

  const formattedTotal = new Intl.NumberFormat("de-DE").format(totalTrademarks);

  // const tutorial =
  //   context && user?.flags?.includes(UserFlag.TUTORIAL_CONTEXT) ? (
  //     ) : (
  //       );

  const main = {
    title: "Ergebnisse",
    content: (
      <OneColumnLayout>
        <SearchQuickActionBar />
        <CardContainer>
          {user?.flags?.includes(UserFlag.TUTORIAL_CONTEXT) ? null : (
            <Tutorial flag={UserFlag.TUTORIAL_SEARCH} steps={tutorialSteps} />
          )}

          {result.trademarks.length > 0 ? (
            <>
              <div className={styles.extra}>
                {searchLoading ? (
                  <span>{`Durchsuche ${formattedTotal} Marken (DPMA, EUIPO)...`}</span>
                ) : result && result.total > 0 ? (
                  <span>
                    {`${result.total >= 10000 ? "Über " : ""}${new Intl.NumberFormat("de-DE").format(result.total)} ${
                      result.total === 1 ? "Ergebnis" : "Ergebnisse"
                    } aus ${formattedTotal} Marken (${result.took / 1000}s)`}
                  </span>
                ) : (
                  <span>{`${formattedTotal} Marken (DPMA, EUIPO)`}</span>
                )}
              </div>
              <div className={styles.toolbar}>
                <div className={styles.toolbarButtons}>
                  <DisplayTypeSwitch type={displayType} onChange={setDisplayType} />
                  <SortByButton sortBy={sortBy} sortOrder={sortOrder} onChange={handleSortChange} />
                </div>
                <Button
                  theme={ButtonTheme.TRANSPARENT}
                  className={clsx("tutorial-save-search", styles.addButton)}
                  childrenClassName={styles.addButtonChildren}
                  onClickPromise={handleSaveSearch}
                >
                  {l(k.SAVE_SEARCH)}
                </Button>
              </div>
            </>
          ) : null}
          {renderMainContent()}
        </CardContainer>
      </OneColumnLayout>
    ),
  };

  return <HorizontalLayout hidePaneSwitcher={true} control={control} sidebar={sidebar} main={main} />;
};
