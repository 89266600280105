export const ellipsify = (text: string, maxLength: number = 10) =>
  text.length > 10 ? text.substring(0, maxLength) + "..." : text;

export const sanitizeForFileName = (val?: string) => {
  if (!val) {
    return "";
  }

  // Allow as much as possible for beautiful filenames
  // but remove invalid characters
  // https://stackoverflow.com/a/31976060
  return (
    val
      .replace(/[/<>:"\\|?*]/g, "")
      // eslint-disable-next-line no-control-regex
      .replace(/[\x00-\x1F]/g, "")
  );
};

export function safeStringify(obj: any): string {
  try {
    return JSON.stringify(obj);
  } catch (e) {
    return String(obj);
  }
}
