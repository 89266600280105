import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { PartnerClient } from "app/api";
import { Partner } from "app/models";

const STATE_KEY = "partners";

export interface PartnersState {
  items: Partner[];
  loading: boolean;
}

const initialState: PartnersState = {
  loading: false,
  items: [],
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchSystemInfo())
//
export const fetchPartners = createAsyncThunk(`${STATE_KEY}/fetch`, PartnerClient.fetch);

export const createPartner = createAsyncThunk(`${STATE_KEY}/create`, PartnerClient.create);

export const updatePartner = createAsyncThunk(`${STATE_KEY}/update`, PartnerClient.update);

export const updatePartnerImage = createAsyncThunk(`${STATE_KEY}/updateImage`, PartnerClient.updateImage);

export const deletePartner = createAsyncThunk(`${STATE_KEY}/delete`, PartnerClient.delete);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const partnerSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updatePartner: (state, { payload }: PayloadAction<Partner>) => {
      if (state.items.some((i) => i.uid === payload.uid)) {
        state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.items = state.items.concat(payload);
      }
    },
    deletePartner: (state, action: PayloadAction<{ partnerId: string }>) => {
      state.items = state.items.filter((i) => i.uid !== action.payload.partnerId);
    },
    destroy: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchPartners.fulfilled, (state, { payload }) => {
        state.items = payload;
        state.loading = false;
      })
      .addCase(createPartner.fulfilled, (state, { payload }) => {
        if (state.items.some((i) => i.uid === payload.uid)) {
          state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.items = state.items.concat(payload);
        }
      })
      .addCase(deletePartner.fulfilled, (state, action) => {
        state.items = state.items.filter((i) => i.uid !== action.meta.arg);
      })

      // Pending
      .addCase(fetchPartners.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchPartners.rejected, (state) => {
        state.loading = false;
      })

      // Matcher
      .addMatcher(isAnyOf(updatePartner.fulfilled, updatePartnerImage.fulfilled), (state, { payload }) => {
        if (state.items.some((i) => i.uid === payload.uid)) {
          state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.items = state.items.concat(payload);
        }
      });
  },
});

export const partnerReducer = partnerSlice.reducer;
