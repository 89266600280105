import { TrademarkOffice } from "app/models/trademark.model";

export const linkByTradeMarkOffice = (office: TrademarkOffice, id: string) => {
  switch (office) {
    case TrademarkOffice.EUIPO:
      return `https://euipo.europa.eu/eSearch/#details/trademarks/${id}`;
    case TrademarkOffice.DPMA:
      return `https://register.dpma.de/DPMAregister/marke/register/${id}/DE`;
  }
};

export const googleImageSearchLink = (imageUrl: string, trademarkName: string) =>
  `https://images.google.com/searchbyimage?image_url=${encodeURIComponent(imageUrl)}&q=${encodeURIComponent(
    trademarkName,
  )}`;
