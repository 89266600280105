import { Button, ButtonTheme } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { useAppForm } from "app/hooks";
import { PortfolioContact } from "app/models";
import { useEffect, useState } from "react";

interface EditContactModalProps {
  open: boolean;
  onClose: () => void;
  contact?: Partial<PortfolioContact>;
  onSubmit?: (value: PortfolioContact) => Promise<void>;
  title?: string | null;
  description?: string | null;
}

type ContactKey = keyof PortfolioContact;

export const EditContactModal = ({
  open,
  onClose,
  contact,
  onSubmit,
  title = "",
  description = "",
}: EditContactModalProps) => {
  const [loading, setLoading] = useState(false);

  const { handleSubmit, register, isValid, setValue, reset } = useAppForm<PortfolioContact>({
    defaultValues: {
      name: contact?.name ?? "",
      address1: contact?.address1 ?? "",
      address2: contact?.address2 ?? "",
      phone: contact?.phone ?? "",
      email: contact?.email ?? "",
      reference: contact?.reference ?? "",
    },
  });

  const onSubmitValue = async (data: PortfolioContact) => {
    if (!isValid) {
      return;
    }
    setLoading(true);
    try {
      if (onSubmit && data) {
        await onSubmit(data);
      }
      setLoading(false);
      onClose();
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    for (const [key, value] of Object.entries(contact || {})) {
      setValue(key as ContactKey, value);
    }
  }, [contact, setValue]);

  const handleTestData = () => {
    const random = (Math.random() + 1).toString(36).substring(7);
    const name = contact?.name || `Hanna Musterfrau (${random})`;
    reset({
      name,
      email: `test+${random}@example.com`,
      address1: "Musterstraße 1",
      address2: "12345 Musterstadt",
      phone: "+49123456789",
      reference: `https://www.google.com/search?q=${name}`,
    });
  };

  return (
    <Modal open={open} onClose={onClose} title={title} subtitle={description || ""}>
      <form onSubmit={handleSubmit(onSubmitValue)}>
        <ModalContent>
          <Input type="text" label="Name" placeholder="Name" {...register("name")} />
          <Input type="address" label="Addresszeile 1" placeholder="Musterstraße 1" {...register("address1")} />
          <Input type="address" label="Addresszeile 2" placeholder="12345 Musterstadt" {...register("address2")} />
          <Input type="tel" label="Telefon" placeholder="+491523123247" {...register("phone")} />
          <Input type="email" label="E-Mail" placeholder="hanna@example.com" {...register("email")} />
          <Input
            type="text"
            label="Aktenzeichen"
            placeholder="Aktenzeichen, Referenznummer oder URL"
            {...register("reference")}
          />
          <RenderIfTestAccount padding={true} center={true}>
            <Button type="button" theme={ButtonTheme.LINK} onClick={handleTestData} disabled={loading}>
              Vorausfüllen
            </Button>
          </RenderIfTestAccount>
        </ModalContent>
        <ModalActions>
          <Button type="button" theme={ButtonTheme.OUTLINE} onClick={onClose} disabled={loading}>
            Abbrechen
          </Button>
          <Button type="submit" loading={loading}>
            Speichern
          </Button>
        </ModalActions>
      </form>
    </Modal>
  );
};
