import clsx from "clsx";
import React from "react";
import styles from "./Input.module.scss";

type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  className?: string;
  valid?: boolean;
  onTextChange?: (value: string) => void;
  onEnter?: () => void;
  name?: string;
  label?: string;
};

export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    { children, className, valid = true, onChange, onTextChange, onEnter, onKeyUp, name = "", label, ...props },
    ref,
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e);
      }
      if (onTextChange) {
        onTextChange(e.target.value);
      }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (onKeyUp) {
        onKeyUp(e);
      }

      if (e.key === "Enter" && valid && onEnter) {
        console.log(onEnter);
        onEnter();
      }
    };

    return (
      <div className={clsx(styles.root, className)}>
        {label ? (
          <label
            className={clsx(
              styles.label,
              {
                [styles.labelInvalid]: !valid,
              },
              className,
            )}
            htmlFor={name}
          >
            {label}
          </label>
        ) : (
          label
        )}
        <input
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          name={name}
          className={clsx(styles.input, {
            [styles.inputInvalid]: !valid,
          })}
          ref={ref}
          {...props}
        >
          {children}
        </input>
      </div>
    );
  },
);
