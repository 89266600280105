import { FlaggedTrademark, QueryType } from "app/models";
import { CollisonFlag, Monitoring, NotificationInterval } from "app/models/monitoring.model";
import { CommentVisibility } from "app/models/trademark-comment.model";
import { TrademarkReference } from "app/models/trademark-reference.model";
import { mapMonitoring } from "app/util/monitoring.util";
import { getClient } from "./client";

const client = getClient({ basePath: "/monitoring" });

export const MonitoringClient = {
  fetch: async () => {
    const { data } = await client.get<Monitoring[]>("/");
    return data.map(mapMonitoring);
  },

  create: async ({
    trademarkId,
    trademarkOffice,
    note,
    flaggedTrademarks,
  }: TrademarkReference & { note?: string; flaggedTrademarks?: FlaggedTrademark[] }) => {
    const { data } = await client.post<Monitoring>("/", {
      trademarkId,
      trademarkOffice,
      note,
      flaggedTrademarks,
    });
    return mapMonitoring(data);
  },

  delete: async (id: string) => {
    await client.delete(id);
  },

  update: async ({
    id,
    note,
    interval,
    sensitivity,
  }: {
    id: string;
    note?: string;
    interval?: NotificationInterval;
    sensitivity?: QueryType[];
  }) => {
    const { data } = await client.put<Monitoring>(`/${id}`, { note, interval, sensitivity });
    return mapMonitoring(data);
  },

  flag: async ({ id, flag, trademarks }: { id: string; flag: CollisonFlag; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Monitoring>(`/${id}/flag`, {
      flag,
      trademarks,
    });
    return mapMonitoring(data);
  },

  unflag: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Monitoring>(`/${id}/unflag`, {
      trademarks,
    });
    return mapMonitoring(data);
  },

  addComment: async ({ id, comment, visibility }: { id: string; comment: string; visibility: CommentVisibility }) => {
    const { data } = await client.post<Monitoring>(`/${id}/comments`, {
      comment,
      visibility,
    });
    return mapMonitoring(data);
  },

  updateComment: async ({
    id,
    commentId,
    comment,
    visibility,
  }: {
    id: string;
    commentId: string;
    comment: string;
    visibility: CommentVisibility;
  }) => {
    const { data } = await client.put<Monitoring>(`/${id}/comments/${commentId}`, {
      comment,
      visibility,
    });
    return mapMonitoring(data);
  },

  deleteComment: async ({ id, commentId }: { id: string; commentId: string }) => {
    const { data } = await client.delete<Monitoring>(`/${id}/comments/${commentId}`);
    return mapMonitoring(data);
  },

  download: async (id: string, type: "pdf" | "csv" | "xlsx") => {
    return client.get(`/${id}/${type}`, {
      responseType: "arraybuffer",
    });
  },
};
