import { UserEvent } from "app/models/user-event-log.model";
import { mapUserEvent } from "app/util/notification.util";
import { getClient } from "./client";

const client = getClient({ basePath: "/notification" });

export const NotificationClient = {
  fetch: async () => {
    const { data } = await client.get<UserEvent[]>("/");
    return data.map(mapUserEvent);
  },
};
