import { getClient } from "app/api";
import { Operator, QueryResult, QueryType, SortBy, SortOrder } from "app/models";
import { TrademarkOffice, TrademarkStatus, TrademarkType } from "app/models/trademark.model";
import { mapQueryResult } from "app/util/trademark.util";
import { CancelToken } from "axios";

export type FutziSearchQuery = {
  ids?: string[];
  query?: string;
  owner?: string;
  representative?: string;
  queryType?: QueryType[];
  limit?: number;
  offset?: number;
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  filters?: {
    office?: TrademarkOffice[];
    status?: TrademarkStatus[];
    type?: TrademarkType[];
    owner?: string[];
    representative?: string[];
    niceClass?: string[];
    niceClassOperator?: Operator;
    viennaClass?: string[];
    viennaClassOperator?: Operator;
  };
};

export type FutziImageSearchQuery = {
  image: string;
  limit?: number;
  offset?: number;
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  filters?: {
    office?: TrademarkOffice[];
    status?: TrademarkStatus[];
    type?: TrademarkType[];
    owner?: string[];
    representative?: string[];
    niceClass?: string[];
    niceClassOperator?: Operator;
    viennaClass?: string[];
    viennaClassOperator?: Operator;
  };
};
const client = getClient({ basePath: "/futzi" });

export const FutziClient = {
  fetchLastSearches: async (cancelToken?: CancelToken) => {
    const { data } = await client.get<FutziSearchQuery[]>("/", { cancelToken });
    return data;
  },
  search: async (query: FutziSearchQuery, cancelToken?: CancelToken) => {
    const { data } = await client.post<QueryResult>("/", query, { cancelToken });

    return mapQueryResult(data);
  },
  searchImage: async (query: FutziImageSearchQuery, cancelToken?: CancelToken) => {
    const { data } = await client.post<QueryResult>("/images", query, { cancelToken });

    return mapQueryResult(data);
  },
};
