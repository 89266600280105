import { WorkspaceClient } from "app/api";
import { ICONS } from "app/assets/icons/icons";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { List } from "app/components/List/List";
import { Role } from "app/models/role.model";
import { PublicUser } from "app/models/user.model";
import { Workspace, WorkspaceInvitationStatus } from "app/models/workspace.model";
import { AppModal, showModal } from "app/redux/slices/modal.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";

type Props = {
  workspace: Workspace;
};

export const SettingsPageWorkspaceUsers = ({ workspace }: Props) => {
  const user = useAppSelector((state) => state.auth.user);
  const [email, setEmail] = useState("");
  const { users } = useAppSelector((state) => state.workspace);
  const dispatch = useAppDispatch();

  const pendingInvitations = workspace.invitations.filter((u) => u.status === WorkspaceInvitationStatus.PENDING) || [];

  const handleInvite = async () => {
    if (!email) {
      return;
    }

    if (
      workspace.invitations.some(
        (invite) => invite.email === email && invite.status === WorkspaceInvitationStatus.PENDING,
      )
    ) {
      toast.info(`${email} wurde bereits eingeladen. Sie können die Einladung löschen und erneut versenden.`, {
        autoClose: 10000,
      });
      return;
    }

    try {
      trackEvent(AppEvent.INVITE_USER, {
        email,
      });
      await WorkspaceClient.inviteUser(email);
      toast.success(`${email} wurde erfolgreich eingeladen`);
      setEmail("");
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        if (error.response && error.response.status === 409) {
          toast.info("Das Mitglied ist bereits in Ihrem Workspace.");
          return;
        }
      }
      handleError("Einladung konnte nicht verschickt werden", error);
    }
  };

  const handleDeleteInvite = async (inviteEmail: string) => {
    try {
      trackEvent(AppEvent.DELETE_USER_INVITE, {
        inviteEmail,
      });
      await WorkspaceClient.deleteInvite(inviteEmail);
    } catch (error) {
      handleError("Einladung konnte nicht gelöscht werden", error);
    }
  };

  const handleRemoveUser = async (userId: string) => {
    trackEvent(AppEvent.REMOVE_USER_FROM_WORKSPACE, {
      userId,
      isSelf: user?.userId === userId,
    });
    if (user?.userId === userId) {
      toast.error("Sie können sich nicht selbst entfernen.");
      return;
    }

    if (workspace.users.some((u) => u.userId === userId && u.roles.includes(Role.WORKSPACE_OWNER))) {
      trackEvent(AppEvent.REMOVE_WORKSPACE_OWNER, {
        userId,
        isSelf: user?.userId === userId,
      });
      toast.error("Sie können die Workspace Inhaber:in nicht entfernen.");
      return;
    }

    try {
      await WorkspaceClient.removeUser(userId);
    } catch (error) {
      handleError("User konnte nicht gelöscht werden", error);
    }
  };

  const showConfirmRemoveUserModal = (user: PublicUser) => () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `${user.name} entfernen`,
        text: `Möchten sie ${user.name} wirklich aus Ihrem Workspace entfernen? Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          console.log(user);
          await handleRemoveUser(user.userId);
        },
      },
    });
  };

  const showRevokeInviteModal = (email: string) => () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Einladung löschen`,
        text: `Möchten sie die Einladung für ${email} wirklich löschen? Sie können die Einladung jederzeit erneut versenden.`,
        onConfirm: async () => {
          await handleDeleteInvite(email);
        },
      },
    });
  };

  const userList = (
    <CardContentSection title="Mitglieder">
      <List
        items={users.map((u) => ({
          id: u.uid,
          title: u.name,
          subtitle: u.email,
          children:
            u.userId === workspace.userId ? null : (
              <ICONS.DELETE onClick={showConfirmRemoveUserModal(u)} title="Mitglied entfernen" />
            ),
        }))}
      />
    </CardContentSection>
  );

  const userInvite = (
    <CardContentSection title="Mitglied einladen">
      <Input placeholder="hanna@example.com" value={email} onTextChange={setEmail} />
      <Button theme={ButtonTheme.TRANSPARENT} onClickPromise={handleInvite}>
        Einladen
      </Button>
    </CardContentSection>
  );

  const invitationsList = (
    <CardContentSection title="Offene Einladungen">
      <List
        items={pendingInvitations.map((i) => ({
          id: i.email,
          title: i.email,
          children: <ICONS.DELETE onClick={showRevokeInviteModal(i.email)} title="Einladung löschen" />,
        }))}
      />
    </CardContentSection>
  );

  return (
    <Card>
      <CardTitle
        title="Mitglieder"
        subtitle="Hier können Sie weitere Mitglieder einladen oder aus Ihrem Workspace entfernen."
      />
      <CardContent>
        {userList}
        {pendingInvitations.length > 0 && invitationsList}
        {userInvite}
      </CardContent>
    </Card>
  );
};
