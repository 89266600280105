import { Trademark } from "app/models/trademark.model";
import { getClient } from "./client";

export const getDpmaTrademarkXml = async (id: string) => {
  const client = getClient();
  const { data } = await client.get<string>(`/dpma/${id}/xml`);
  return data;
};

export const importDpmaTrademark = async (id: string) => {
  const client = getClient();
  const { data } = await client.get<Trademark>(`/dpma-import/trademark/${id}`);
  return data;
};

export const startDailyDpmaImport = async () => {
  const client = getClient();
  const { data } = await client.get<void>("/dpma-import/daily");
  return data;
};

export const startDpmaImport = async (date: string) => {
  const client = getClient();
  const { data } = await client.get<void>(`/dpma-import/daily/${date}`);
  return data;
};

export const reCreateDpmaIndex = async () => {
  const client = getClient();
  const { data } = await client.get<void>("/dpma-import/index/dpma-v3");
  return data;
};
