import clsx from "clsx";
import { ButtonHTMLAttributes } from "react";
import styles from "./IconButton.module.scss";

type IconButtonProps = { onClick?: () => void; title: string } & ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({ onClick, children, title, className }: IconButtonProps) => {
  return (
    <button title={title} className={clsx(styles.button, className)} onClick={onClick}>
      {children}
    </button>
  );
};
