import { APP_CONST } from "app/assets/constants";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Modal, ModalContent } from "app/components/Modal/Modal";
import { Trademark } from "app/models/trademark.model";
import { createMonitoring, useAppDispatch } from "app/redux";
import { monitoringDetailRoute } from "app/routes";
import { handleError } from "app/util/error-handler";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CreateMonitoringModal.module.scss";

interface Props {
  open: boolean;
  onClose: () => void;
  trademark?: Trademark | null;
}

export const CreateMonitoringModal = ({ open, onClose, trademark }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCreateMonitoring = async () => {
    if (!trademark) {
      return;
    }

    try {
      setLoading(true);
      const createdMonitoring = await dispatch(
        createMonitoring({
          trademarkId: trademark.id,
          trademarkOffice: trademark.office,
        }),
      ).unwrap();

      navigate(monitoringDetailRoute(createdMonitoring.uid));

      onClose();
    } catch (error) {
      handleError("Überwachung konnte nicht angelegt werden", error);
    } finally {
      setLoading(false);
    }
  };

  const highlightText = `${trademark?.name} (${trademark?.office})`;

  return (
    <Modal
      classNames={{
        title: styles.modalTitle,
        modal: styles.modal,
      }}
      open={open}
      onClose={onClose}
      title="Marke mit Oktomark überwachen"
    >
      <ModalContent>
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <img src={APP_CONST.IMAGES.BUY_OKTOMARK} alt="" className={styles.image} />
          </div>
          <div className={styles.right}>
            <p className={styles.text}>
              Lassen Sie die Marke <span className={styles.name}>{highlightText}</span> jetzt von Oktomark überwachen
              und Sie werden umgehend benachrichtigt, sobald eine potenzielle Kollision erkannt wird. Die Überwachung
              greift auch zum Beispiel bei folgenden Ereignissen:
            </p>
            <ul>
              <li>Eine kollidierende Marke ändert sich</li>
              <li>Statusänderung (z. B. Widerspruch)</li>
              <li>Der Markenschutz läuft bald aus</li>
            </ul>
          </div>
        </div>
        <div className={styles.buttonWrapper}>
          <Button className={styles.cta} loading={loading} onClick={handleCreateMonitoring} theme={ButtonTheme.WARNING}>
            Überwachung einrichten
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
