import { Research } from "app/models/research.model";
import { toDate } from "./date.util";

export const mapResearch = (research: Research): Research => ({
  ...research,
  createdAt: toDate(research.createdAt),
  updatedAt: toDate(research.updatedAt),
  comments:
    research.comments?.map((comment) => ({
      ...comment,
      createdAt: toDate(comment.createdAt),
      updatedAt: toDate(comment.updatedAt),
    })) || [],
});
