import FingerprintJS, { GetResult } from "@fingerprintjs/fingerprintjs";
// import * as Sentry from "@sentry/react";
// import { captureException } from "@sentry/react";
// import { Integrations } from "@sentry/tracing";
import { sendMetrics } from "app/websocket/websocket";
import { isProductionEnvironment } from "./env.util";

let fingerprint: GetResult | null = null;

export const initTracking = async () => {
  try {
    const fpPromise = await FingerprintJS.load();
    fingerprint = await fpPromise.get();
  } catch (error) {
    console.error(error);
  }
  if (isProductionEnvironment()) {
    // Sentry.init({
    //   dsn: "https://ccecf9236143436985c10033cbcb4776@o1007178.ingest.sentry.io/5969879",
    //   integrations: [new Integrations.BrowserTracing()],
    //   // Set tracesSampleRate to 1.0 to capture 100%
    //   // of transactions for performance monitoring.
    //   // We recommend adjusting this value in production
    //   tracesSampleRate: 1.0,
    // });
  }
};

export const getCurrentFingerPrint = () => {
  if (!fingerprint) {
    return null;
  }
  const {
    components: { timezone, screenResolution, platform, languages, reducedMotion, vendorFlavors, vendor },
  } = fingerprint;
  return {
    timezone: timezone.value,
    screenResolution: screenResolution.value,
    platform: platform.value,
    userAgent: navigator.userAgent,
    languages: languages.value,
    reducedMotion: reducedMotion.value,
    vendorFlavors: vendorFlavors.value,
    vendor: vendor.value,
  };
};

export enum AppEvent {
  ACTIVATE_SAVED_SEARCH = "ACTIVATE_SAVED_SEARCH",
  CHANGE_COLLISION_TAB = "CHANGE_COLLISION_TAB",
  CHANGE_DEFAULT_DISPLAY_TYPE = "CHANGE_DEFAULT_DISPLAY_TYPE",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_PROFILE_PICTURE = "CHANGE_PROFILE_PICTURE",
  CLICK_MAIN_NAVIGATION = "CLICK_MAIN_NAVIGATION",
  CLICK_UPSELLING_BANNER = "CLICK_UPSELLING_BANNER",
  CLICK_UPSELLING_ON_PAYMENT_PAGE = "CLICK_UPSELLING_ON_PAYMENT_PAGE",
  CLICK_UPSELLING_ON_WORKSPACE_PAGE = "CLICK_UPSELLING_ON_WORKSPACE_PAGE",
  CLOSE_UPSELLING_MODAL = "CLOSE_UPSELLING_MODAL",
  CREATE_COLLECTION = "CREATE_COLLECTION",
  CREATE_PARTNER = "CREATE_PARTNER",
  CREATE_PORTFOLIO = "CREATE_PORTFOLIO",
  CREATE_RESEARCH = "CREATE_RESEARCH",
  DELETE_COLLECTION = "DELETE_COLLECTION",
  DELETE_MONITORING = "DELETE_MONITORING",
  DELETE_PORTFOLIO = "DELETE_PORTFOLIO",
  DELETE_RESEARCH = "DELETE_RESEARCH",
  DELETE_USER_INVITE = "DELETE_USER_INVITE",
  DISABLE_DARKMODE = "DISABLE_DARKMODE",
  DOWNLOAD_COLLECTION = "DOWNLOAD_COLLECTION",
  DOWNLOAD_MONITORING = "DOWNLOAD_MONITORING",
  DOWNLOAD_PORTFOLIO = "DOWNLOAD_PORTFOLIO",
  DOWNLOAD_RESEARCH = "DOWNLOAD_RESEARCH",
  ENABLE_DARKMODE = "ENABLE_DARKMODE",
  FAILED_TO_JOIN_WORKSPACE = "FAILED_TO_JOIN_WORKSPACE",
  IGNORE_TRADEMARK_FROM_SAVED_SEARCH = "IGNORE_TRADEMARK_FROM_SAVED_SEARCH",
  IMAGE_SEARCH = "IMAGE_SEARCH",
  INVITE_USER = "INVITE_USER",
  JOIN_WORKSPACE = "JOIN_WORKSPACE",
  LOAD_MORE_SEARCH_RESULTS = "LOAD_MORE_SEARCH_RESULTS",
  LOAD_NEXT_SEARCH_RESULTS_PAGE = "LOAD_NEXT_SEARCH_RESULTS_PAGE",
  LOAD_PREV_SEARCH_RESULTS_PAGE = "LOAD_PREV_SEARCH_RESULTS_PAGE",
  LOAD_SOME_SEARCH_RESULTS_PAGE = "LOAD_SOME_SEARCH_RESULTS_PAGE",
  LOGIN = "LOGIN",
  LOGIN_FAILED = "LOGIN_FAILED",
  LOGOUT_ALL_SESSIONS = "LOGOUT_ALL_SESSIONS",
  LOGOUT_VIA_MAIN_NAVIGATION = "LOGOUT_VIA_MAIN_NAVIGATION",
  OPEN_FILTERS = "OPEN_FILTERS",
  OPEN_IMAGE_SEARCH = "OPEN_IMAGE_SEARCH",
  OPEN_MAIN_NAVIGATION = "OPEN_MAIN_NAVIGATION",
  OPEN_NIZZA_DIALER = "OPEN_NIZZA_DIALER",
  PAGE_VIEW = "PAGE_VIEW",
  PORTFOLIO_ADD_COLLECTION = "PORTFOLIO_ADD_COLLECTION",
  PORTFOLIO_ADD_MONITORING = "PORTFOLIO_ADD_MONITORING",
  PORTFOLIO_ADD_RESEARCH = "PORTFOLIO_ADD_RESEARCH",
  PORTFOLIO_REMOVE_COLLECTION = "PORTFOLIO_REMOVE_COLLECTION",
  PORTFOLIO_REMOVE_MONITORING = "PORTFOLIO_REMOVE_MONITORING",
  PORTFOLIO_REMOVE_RESEARCH = "PORTFOLIO_REMOVE_RESEARCH",
  REMOVE_COLLISION_FLAG_FROM_GROUP = "REMOVE_COLLISION_FLAG_FROM_GROUP",
  REMOVE_USER_FROM_WORKSPACE = "REMOVE_USER_FROM_WORKSPACE",
  REMOVE_WORKSPACE_OWNER = "REMOVE_WORKSPACE_OWNER",
  RENAME_COLLECTION = "RENAME_COLLECTION",
  RENAME_PORTFOLIO = "RENAME_PORTFOLIO",
  RENAME_RESEARCH = "RENAME_RESEARCH",
  RENAME_WORKSPACE = "RENAME_WORKSPACE",
  RESTORE_SESSION = "RESTORE_SESSION",
  SAVE_INTERFACE_DEFAULTS = "SAVE_INTERFACE_DEFAULTS",
  SAVE_MONITORING_INTERVAL = "SAVE_MONITORING_INTERVAL",
  SAVE_MONITORING_NOTE = "SAVE_MONITORING_NOTE",
  SET_COLLISION_FLAG = "SET_COLLISION_FLAG",
  SET_COLLISION_FLAG_TO_GROUP = "SET_COLLISION_FLAG_TO_GROUP",
  SHOW_MODAL = "SHOW_MODAL",
  SIGNUP = "SIGNUP",
  SIGNUP_OR_LOGIN_FAILED = "SIGNUP_OR_LOGIN_FAILED",
  TOGGLE_SEARCH_RESULT_SECTION = "TOGGLE_SEARCH_RESULT_SECTION",
  UNIGNORE_TRADEMARK_FROM_SAVED_SEARCH = "UNIGNORE_TRADEMARK_FROM_SAVED_SEARCH",
  UPDATE_COLLECTION_NOTE = "UPDATE_COLLECTION_NOTE",
  UPDATE_NOTIFICATION_PREFERENCE = "UPDATE_NOTIFICATION_PREFERENCE",
  UPDATE_PARTNER = "UPDATE_PARTNER",
  UPDATE_PORTFOLIO_COLOR = "UPDATE_PORTFOLIO_COLOR",
  UPDATE_PORTFOLIO_CONTACT = "UPDATE_PORTFOLIO_CONTACT",
  UPDATE_PORTFOLIO_NOTE = "UPDATE_PORTFOLIO_NOTE",
  UPDATE_PORTFOLIO_REFERENCE_NUMBER = "UPDATE_PORTFOLIO_REFERENCE_NUMBER",
  UPDATE_RESEARCH_FIELDS = "UPDATE_RESEARCH_FIELDS",
  UPDATE_RESEARCH_NOTE = "UPDATE_RESEARCH_NOTE",
  UPDATE_WORKSPACE_BRANDING = "UPDATE_WORKSPACE_BRANDING",
  UPDATE_WORKSPACE_CONTACT = "UPDATE_WORKSPACE_CONTACT",
}

export const trackEvent = (event: AppEvent, meta?: object) => {
  try {
    sendMetrics({
      type: event,
      data: {
        page: window.location.href,
        fingerprint: getCurrentFingerPrint(),
        ...meta,
      },
    });
  } catch (error) {
    if (isProductionEnvironment()) {
      // captureException(error);
    }
    console.error("Error sending metrics", error);
  }
};
