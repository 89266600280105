import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { CollectionClient } from "app/api";
import { Collection, QueryResult } from "app/models";

const STATE_KEY = "collections";

type Collections = Record<
  string,
  {
    collection: Collection;
    trademarks: QueryResult | null;
  }
>;

export interface CollectionsState {
  allCollections: Collections;
  items: Collection[];
  loading: boolean;
}

const initialState: CollectionsState = {
  loading: false,
  items: [],
  allCollections: {},
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchSystemInfo())
//
export const fetchCollections = createAsyncThunk(`${STATE_KEY}/fetch`, CollectionClient.fetch);

export const createCollection = createAsyncThunk(`${STATE_KEY}/create`, CollectionClient.create);

export const updateCollection = createAsyncThunk(`${STATE_KEY}/update`, CollectionClient.update);

export const deleteCollection = createAsyncThunk(`${STATE_KEY}/delete`, CollectionClient.delete);

export const addTrademarksToCollection = createAsyncThunk(`${STATE_KEY}/addTrademarks`, CollectionClient.addTrademarks);

export const sortTrademarksInCollection = createAsyncThunk(
  `${STATE_KEY}/sortTrademarks`,
  CollectionClient.sortTrademarks,
);

export const removeTrademarksFromCollection = createAsyncThunk(
  `${STATE_KEY}/removeTrademarks`,
  CollectionClient.removeTrademarks,
);

export const updateTrademarkCollectionNotes = createAsyncThunk(
  `${STATE_KEY}/updateTrademarkNotes`,
  CollectionClient.updateTrademarkNotes,
);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const collectionSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateCollection: (state, { payload }: PayloadAction<Collection>) => {
      state.allCollections[payload.uid] = {
        collection: payload,
        trademarks: null,
      };
      if (state.items.some((i) => i.uid === payload.uid)) {
        state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.items = state.items.concat(payload);
      }
    },
    deleteCollection: (state, action: PayloadAction<{ collectionId: string }>) => {
      state.items = state.items.filter((i) => i.uid !== action.payload.collectionId);
      delete state.allCollections[action.payload.collectionId];
    },
    destroy: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchCollections.fulfilled, (state, { payload }) => {
        for (const collection of payload) {
          state.allCollections[collection.uid] = {
            collection: collection,
            trademarks: null,
          };
        }
        state.items = payload;
        state.loading = false;
      })
      .addCase(createCollection.fulfilled, (state, { payload }) => {
        state.allCollections[payload.uid] = {
          collection: payload,
          trademarks: null,
        };
        if (state.items.some((i) => i.uid === payload.uid)) {
          state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.items = state.items.concat(payload);
        }
      })
      .addCase(deleteCollection.fulfilled, (state, action) => {
        delete state.allCollections[action.meta.arg];
        state.items = state.items.filter((i) => i.uid !== action.meta.arg);
      })

      // Pending
      .addCase(fetchCollections.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchCollections.rejected, (state) => {
        state.loading = false;
      })

      // Matcher
      .addMatcher(
        isAnyOf(
          updateCollection.fulfilled,
          addTrademarksToCollection.fulfilled,
          sortTrademarksInCollection.fulfilled,
          removeTrademarksFromCollection.fulfilled,
          updateTrademarkCollectionNotes.fulfilled,
        ),
        (state, { payload }) => {
          state.allCollections[payload.uid] = {
            collection: payload,
            trademarks: null,
          };
          if (state.items.some((i) => i.uid === payload.uid)) {
            state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
          } else {
            state.items = state.items.concat(payload);
          }
        },
      );
  },
});

export const collectionReducer = collectionSlice.reducer;
