import { compareDesc, differenceInDays, format, formatDistanceStrict, formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

type DateOrNull = Date | null | undefined;

type MaybeDate = string | DateOrNull;

export const localizedMonth = (date: MaybeDate) => {
  return formatDate("dd. MMMM", date);
};

export const localizedDate = (date: MaybeDate) => {
  return formatDate("dd. MMMM yyyy", date);
};

export const localizedDateTime = (date: MaybeDate) => {
  return formatDate("dd. MMMM yyyy HH:mm", date);
};

export const localizedDateTimeSeconds = (date: MaybeDate) => {
  return formatDate("dd. MMMM yyyy HH:mm:ss", date);
};

export const localizedDistanceTime = (date?: Date) => {
  if (!date) {
    return "";
  }
  return formatDistanceToNow(date, {
    locale: de,
    includeSeconds: false,
  });
};

export const formatDate = (formatString: string, date: MaybeDate) => {
  if (!date) {
    return "-";
  }

  try {
    return format(new Date(date), formatString, {
      locale: de,
    });
  } catch (e) {
    console.warn("Error formatting date", e, date);
    return "-";
  }
};

export const localizedTimeDistance = (date: Date | string) => {
  try {
    return formatDistanceStrict(new Date(date), new Date(), { locale: de, addSuffix: true });
  } catch (e) {
    console.warn("Error formatting time distance", e, date);
    return "-";
  }
};

export const isFutureDate = (date: Date | string | undefined) => {
  if (!date) {
    return false;
  }

  return new Date().getTime() < new Date(date).getTime();
};

export const getRemainingDays = (days: number, start: Date, end: Date = new Date()) => {
  const elapsed = differenceInDays(end, start);

  const remaining = days - elapsed;
  return remaining > 0 ? remaining : 0;
};

export const toDate = (date: string | Date | undefined | null) => (date ? new Date(date) : undefined);

export const sortByDateDesc = (aDate: DateOrNull, bDate: DateOrNull): number => {
  if (!aDate || !bDate) {
    return 0;
  }
  return compareDesc(bDate, aDate);
};
