import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentClient } from "app/api";
import { PaymentInformation } from "app/models";

interface PaymentState extends PaymentInformation {
  loading: boolean;
}

const initialState: PaymentState = {
  isInTrial: false,
  remainingTrialDays: 0,
  hasCustomPayment: false,
  needsSubscription: false,
  billingInformation: {
    name: "",
    email: "",
    locale: "de",
    address: {
      line1: "",
      line2: "",
      city: "",
      zip: "",
      state: "",
      country: "DE",
    },
  },
  cards: [],
  sepas: [],
  invoices: [],
  subscriptions: [],
  loading: false,
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchPayment())
//
export const fetchPayment = createAsyncThunk("payment/fetch", PaymentClient.getPaymentInformation);
export const bookPremium = createAsyncThunk("payment/bookPremium", PaymentClient.bookPremium);
export const cancelPremium = createAsyncThunk("payment/cancelPremium", PaymentClient.cancelPremium);
export const updateBillingInformation = createAsyncThunk(
  "payment/updateBillingInformation",
  PaymentClient.updateBillingInformation,
);
export const addPaymentMethod = createAsyncThunk("payment/addPaymentMethod", PaymentClient.addPaymentMethod);
export const deletePaymentMethod = createAsyncThunk("payment/deletePaymentMethod", PaymentClient.deletePaymentMethod);
export const setDefaultPaymentMethod = createAsyncThunk(
  "payment/setDefaultPaymentMethod",
  PaymentClient.setDefaultPaymentMethod,
);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<PaymentInformation>) => {
      // XXX/FIXME: Using state = {...state, ...action.payload} won't work
      return {
        ...state,
        ...action.payload,
      };
    },
    destroy: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchPayment.fulfilled, (state, action) => ({ ...state, ...action.payload, loading: false }))
      .addCase(bookPremium.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(cancelPremium.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(updateBillingInformation.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(addPaymentMethod.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(deletePaymentMethod.fulfilled, (state, action) => ({ ...state, ...action.payload }))
      .addCase(setDefaultPaymentMethod.fulfilled, (state, action) => ({ ...state, ...action.payload }))

      // Pending
      .addCase(fetchPayment.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchPayment.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const paymentReducer = paymentSlice.reducer;
