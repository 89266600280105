import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { EmptyState, EmptyStateTutorial } from "app/components/EmptyState/EmptyState";
import { AppModal, showModal, useAppDispatch } from "app/redux";
import { AppEvent, trackEvent } from "app/util/tracking.util";

export const UpsellingEmptyState = () => {
  const dispatch = useAppDispatch();

  const handleUpselling = () => {
    trackEvent(AppEvent.CLICK_UPSELLING_BANNER);
    showModal(dispatch)({ type: AppModal.UPSELLING });
  };

  return (
    <EmptyState title="Abonnement aktivieren" fullWidth noBg item>
      <EmptyStateTutorial
        text={`Aktivieren Sie jetzt das Oktomark Abonnement für Ihre Kanzlei und laden Sie Ihre Kollegen und Kolleginnen ein.`}
        image={APP_CONST.IMAGES.BUY_OKTOMARK}
      />
      <Button onClick={handleUpselling}>Jetzt aktivieren</Button>
    </EmptyState>
  );
};
