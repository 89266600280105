import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { ResearchClient } from "app/api";
import { QueryResult, Research } from "app/models";

const STATE_KEY = "researches";

type Researches = Record<
  string,
  {
    research: Research;
    trademarks: QueryResult | null;
  }
>;

export interface ResearchesState {
  allResearches: Researches;
  items: Research[];
  loading: boolean;
}

const initialState: ResearchesState = {
  loading: false,
  items: [],
  allResearches: {},
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchSystemInfo())
//
export const fetchResearches = createAsyncThunk(`${STATE_KEY}/fetch`, ResearchClient.fetch);

export const createResearch = createAsyncThunk(`${STATE_KEY}/create`, ResearchClient.create);

export const updateResearch = createAsyncThunk(`${STATE_KEY}/update`, ResearchClient.update);

export const deleteResearch = createAsyncThunk(`${STATE_KEY}/delete`, ResearchClient.delete);

export const addTrademarksToResearch = createAsyncThunk(`${STATE_KEY}/addTrademarks`, ResearchClient.addTrademarks);

export const sortTrademarksInResearch = createAsyncThunk(`${STATE_KEY}/sortTrademarks`, ResearchClient.sortTrademarks);

export const removeTrademarksFromResearch = createAsyncThunk(
  `${STATE_KEY}/removeTrademarks`,
  ResearchClient.removeTrademarks,
);

export const updateTrademarkResearchNotes = createAsyncThunk(
  `${STATE_KEY}/updateTrademarkNotes`,
  ResearchClient.updateTrademarkNotes,
);

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
export const researchSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateResearch: (state, { payload }: PayloadAction<Research>) => {
      state.allResearches[payload.uid] = {
        research: payload,
        trademarks: null,
      };
      if (state.items.some((i) => i.uid === payload.uid)) {
        state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.items = state.items.concat(payload);
      }
    },
    deleteResearch: (state, action: PayloadAction<{ researchId: string }>) => {
      state.items = state.items.filter((i) => i.uid !== action.payload.researchId);
      delete state.allResearches[action.payload.researchId];
    },
    destroy: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchResearches.fulfilled, (state, { payload }) => {
        for (const research of payload) {
          state.allResearches[research.uid] = {
            research: research,
            trademarks: null,
          };
        }
        state.items = payload;
        state.loading = false;
      })
      .addCase(createResearch.fulfilled, (state, { payload }) => {
        state.allResearches[payload.uid] = {
          research: payload,
          trademarks: null,
        };
        if (state.items.some((i) => i.uid === payload.uid)) {
          state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.items = state.items.concat(payload);
        }
      })
      .addCase(deleteResearch.fulfilled, (state, action) => {
        delete state.allResearches[action.meta.arg];
        state.items = state.items.filter((i) => i.uid !== action.meta.arg);
      })

      // Pending
      .addCase(fetchResearches.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchResearches.rejected, (state) => {
        state.loading = false;
      })

      // Matcher
      .addMatcher(
        isAnyOf(
          updateResearch.fulfilled,
          addTrademarksToResearch.fulfilled,
          sortTrademarksInResearch.fulfilled,
          removeTrademarksFromResearch.fulfilled,
          updateTrademarkResearchNotes.fulfilled,
        ),
        (state, { payload }) => {
          state.allResearches[payload.uid] = {
            research: payload,
            trademarks: null,
          };
          if (state.items.some((i) => i.uid === payload.uid)) {
            state.items = state.items.map((i) => (i.uid === payload.uid ? payload : i));
          } else {
            state.items = state.items.concat(payload);
          }
        },
      );
  },
});

export const researchReducer = researchSlice.reducer;
