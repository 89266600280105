import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { ContextType, Research } from "app/models";
import { searchSlice, updateUserContext, useAppDispatch } from "app/redux";
import { ROUTES } from "app/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  research: Research;
};

export const ResearchEmptyState = ({ research }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activateResearchContext = async () => {
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        query: research.name,
      }),
    );

    dispatch(
      updateUserContext({
        type: ContextType.RESEARCH,
        researchId: research.uid,
      }),
    );
  };

  return (
    <EmptyState
      title="Jetzt Marken hinzufügen"
      text={`Starten Sie jetzt Ihre Recherche und markieren Sie relevante Marken.`}
      image={APP_CONST.IMAGES.RESEARCH}
      fullWidth
      noBg
    >
      <Button onClick={activateResearchContext}>Recherche starten</Button>
    </EmptyState>
  );
};
