import React, { useContext, useState } from "react";

type PageContextType = {
  title: string | null;
  path: string | null;
  updateContext: (path: string, title: string) => unknown;
};

export const PageContext = React.createContext<PageContextType>({
  title: null,
  path: null,
  updateContext: (path: string, title: string) => null,
});

export function usePageContext() {
  const context = useContext(PageContext);

  return context;
}

export const PageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState("");
  const [path, setPath] = useState("");

  const updateContext = (path: string, title: string) => {
    setTitle(title);
    setPath(path);
  };

  return (
    <PageContext.Provider
      value={{
        title,
        path,
        updateContext,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};
