import { Trademark } from "app/models/trademark.model";

// https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
export const onlyUnique = (value: any, index: number, self: any) => self.indexOf(value) === index;

export const uniqueTrademarks = (a: Trademark, b: Trademark) => a.id === b.id && a.office === b.office;

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}
