import { ICONS } from "app/assets/icons/icons";
import { CardWithActions } from "app/components/CardWithActions/CardWithActions";
import { DefaultPopoverButton, Popover, PopoverList } from "app/components/Popover";
import { useIsAdmin } from "app/hooks";
import { localizedOffice, localizedStatus, localizedType } from "app/i18n";
import { SavedSearch } from "app/models";
import { deleteSavedSearch, updateSavedSearch } from "app/redux";
import { AppModal, showModal } from "app/redux/slices/modal.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { localizedTimeDistance } from "app/util/date.util";
import { handleError } from "app/util/error-handler";
import { guessSavedSearchTitle, matchSavedSearch } from "app/util/saved-search.util";
import clsx from "clsx";
import styles from "./SavedSearchCard.module.scss";

interface SavedSearchCardProps {
  className?: string;
  savedSearch: SavedSearch;
  onActivate: (s: SavedSearch) => unknown;
  noActions?: boolean;
}

export const SavedSearchCard = ({ className, savedSearch, onActivate, noActions }: SavedSearchCardProps) => {
  const title = guessSavedSearchTitle(savedSearch);
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const isActive = useAppSelector((state) => matchSavedSearch(state.search.current)(savedSearch));
  const research = useAppSelector((state) =>
    state.research.items.find((research) =>
      research.savedSearches?.some(({ savedSearchId }) => savedSearchId === savedSearch.uid),
    ),
  );
  const portfolio = useAppSelector((state) =>
    state.portfolio.items.find((portfolio) =>
      portfolio.researches?.some(({ researchId }) => researchId === research?.uid),
    ),
  );

  const showRenameModal = () => {
    showModal(dispatch)({
      type: AppModal.CHANGE_VALUE,
      props: {
        title: `Suche ${title} umbenennen`,
        description: "Wie möchten Sie diese Suche nennen?",
        defaultValue: title,
        onSubmit: async (name: string) => {
          try {
            await dispatch(updateSavedSearch({ ...savedSearch, name })).unwrap();
          } catch (error) {
            handleError("Suche konnte nicht umbenannt werden.", error);
          }
        },
      },
    });
  };

  const showConfirmDeleteModal = () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Suche ${title} löschen?`,
        text: `Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          try {
            await dispatch(deleteSavedSearch(savedSearch.uid)).unwrap();
          } catch (error) {
            handleError("Suche konnte nicht gelöscht werden.", error);
          }
        },
      },
    });
  };

  const showDebugModal = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: savedSearch,
      },
    });
  };

  const handleSearch = () => {
    onActivate(savedSearch);
  };

  const renderIfSet = (name: string, value?: string) => {
    if (value) {
      return (
        <li
          className={clsx(styles.filterItem, {
            [styles.filterItemActive]: isActive,
          })}
        >
          {value}
        </li>
      );
    }
  };

  const ignored = savedSearch.ignored || [];

  const menuItems = [
    {
      title: "Umbenennen",
      onClick: showRenameModal,
      Icon: ICONS.EDIT,
    },
    {
      title: "Löschen",
      onClick: showConfirmDeleteModal,
      Icon: ICONS.DELETE,
    },
    {
      title: "Debug",
      onClick: showDebugModal,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
  ];

  const menu = noActions ? null : (
    <Popover
      buttonElement={(active) => (
        <DefaultPopoverButton
          active={active}
          className={clsx({
            [styles.iconActive]: isActive,
          })}
        />
      )}
    >
      <PopoverList
        items={menuItems}
        className={clsx({
          [styles.listActive]: isActive,
        })}
      />
    </Popover>
  );

  return (
    <CardWithActions
      actions={menu}
      className={clsx(styles.root, className, {
        [styles.active]: isActive,
      })}
      onClick={handleSearch}
    >
      <div className={styles.content}>
        <h4 className={styles.title}>{title}</h4>
        {research && portfolio ? (
          <p className={styles.subtitle}>
            <ICONS.RESEARCH className={styles.subtitleIcon} /> Recherche {research.name}
          </p>
        ) : null}
        {savedSearch?.createdAt ? (
          <p className={styles.subtitle}>{localizedTimeDistance(savedSearch?.createdAt)}</p>
        ) : null}
        <ul>
          {/* TODO: fix this madness  */}
          {renderIfSet("Inhaber", savedSearch.filters?.owner?.join(", "))}
          {renderIfSet("Vertreter", savedSearch?.filters?.representative?.join(", "))}
          {renderIfSet("Nizza", savedSearch.filters?.niceClass?.join(", "))}
          {renderIfSet("Vienna", savedSearch.filters?.viennaClass?.join(", "))}
          {renderIfSet("Suchtyp", savedSearch.filters?.queryType?.join(", "))}
          {renderIfSet("Status", savedSearch.filters?.status?.map(localizedStatus).join(", "))}
          {renderIfSet("Type", savedSearch.filters?.type?.map(localizedType).join(", "))}
          {renderIfSet("Office", savedSearch.filters?.office?.map(localizedOffice).join(", "))}
          {/* {renderIfSet("SortBy", item.sortBy)} */}
          {/* {renderIfSet("SortOrder", item.sortOrder)} */}
        </ul>
        {/* <div className={styles.queryFilter}>{`${filterCount || "Keine"} Filter`}</div> */}
        {ignored.length > 0 ? (
          <div className={styles.queryExcludes}>{`${ignored.length || 0} ausgeschlossen`}</div>
        ) : null}
      </div>
    </CardWithActions>
  );
};
