import { FutziSearchQuery } from "app/api";
import { CollisonFlag, NotificationInterval } from "./monitoring.model";
import { CommentVisibility } from "./trademark-comment.model";
import { TrademarkOffice } from "./trademark.model";

export enum UserEventType {
  // USER
  FUTZI_QUERY_EXECUTED = "FUTZI_QUERY_EXECUTED",
  // WORKSPACE
  WORKSPACE_CREATED = "WORKSPACE_CREATED",
  WORKSPACE_DELETED = "WORKSPACE_DELETED",
  WORKSPACE_RENAMED = "WORKSPACE_RENAMED",
  WORKSPACE_INVITE_CREATED = "WORKSPACE_INVITE_CREATED",
  WORKSPACE_INVITE_DELETED = "WORKSPACE_INVITE_DELETED",
  WORKSPACE_USER_JOINED = "WORKSPACE_USER_JOINED",
  WORKSPACE_USER_LEFT = "WORKSPACE_USER_LEFT",
  WORKSPACE_CONTACT_CHANGED = "WORKSPACE_CONTACT_CHANGED",
  WORKSPACE_BRANDING_CHANGED = "WORKSPACE_BRANDING_CHANGED",
  WORKSPACE_PAYMENT_METHOD_ADDED = "WORKSPACE_PAYMENT_METHOD_ADDED",
  WORKSPACE_PAYMENT_METHOD_DELETED = "WORKSPACE_PAYMENT_METHOD_DELETED",
  WORKSPACE_BILLING_INFORMATION_CHANGED = "WORKSPACE_BILLING_INFORMATION_CHANGED",
  // COLLECTIONS
  COLLECTION_CREATED = "COLLECTION_CREATED",
  COLLECTION_DELETED = "COLLECTION_DELETED",
  COLLECTION_RENAMED = "COLLECTION_RENAMED",
  COLLECTION_TRADEMARK_ADDED = "COLLECTION_TRADEMARK_ADDED",
  COLLECTION_TRADEMARK_REMOVED = "COLLECTION_TRADEMARK_REMOVED",
  COLLECTION_NOTE_CHANGED = "COLLECTION_NOTE_CHANGED",
  COLLECTION_COMMENT_CREATED = "COLLECTION_COMMENT_CREATED",
  // PORTFOLIOS
  PORTFOLIO_CREATED = "PORTFOLIO_CREATED",
  PORTFOLIO_DELETED = "PORTFOLIO_DELETED",
  PORTFOLIO_RENAMED = "PORTFOLIO_RENAMED",
  PORTFOLIO_TRADEMARK_ADDED = "PORTFOLIO_TRADEMARK_ADDED",
  PORTFOLIO_TRADEMARK_REMOVED = "PORTFOLIO_TRADEMARK_REMOVED",
  PORTFOLIO_RESEARCH_ADDED = "PORTFOLIO_RESEARCH_ADDED",
  PORTFOLIO_RESEARCH_REMOVED = "PORTFOLIO_RESEARCH_REMOVED",
  PORTFOLIO_NOTE_CHANGED = "PORTFOLIO_NOTE_CHANGED",
  PORTFOLIO_COMMENT_CREATED = "PORTFOLIO_COMMENT_CREATED",
  // RESEARCHES
  RESEARCH_CREATED = "RESEARCH_CREATED",
  RESEARCH_DELETED = "RESEARCH_DELETED",
  RESEARCH_RENAMED = "RESEARCH_RENAMED",
  RESEARCH_TRADEMARK_ADDED = "RESEARCH_TRADEMARK_ADDED",
  RESEARCH_TRADEMARK_REMOVED = "RESEARCH_TRADEMARK_REMOVED",
  RESEARCH_SAVED_SEARCH_ADDED = "RESEARCH_SAVED_SEARCH_ADDED",
  RESEARCH_SAVED_SEARCH_REMOVED = "RESEARCH_SAVED_SEARCH_REMOVED",
  RESEARCH_NOTE_CHANGED = "RESEARCH_NOTE_CHANGED",
  RESEARCH_COMMENT_CREATED = "RESEARCH_COMMENT_CREATED",
  // MONITORINGS
  MONITORING_CREATED = "MONITORING_CREATED",
  MONITORING_DELETED = "MONITORING_DELETED",
  MONITORING_TRADEMARK_FLAGGED = "MONITORING_TRADEMARK_FLAGGED",
  MONITORING_NOTE_CHANGED = "MONITORING_NOTE_CHANGED",
  MONITORING_NOTIFICATION_INTERVAL_CHANGED = "MONITORING_NOTIFICATION_INTERVAL_CHANGED",
  MONITORING_SENSITIVITY_CHANGED = "MONITORING_SENSITIVITY_CHANGED",
  MONITORING_COMMENT_CREATED = "MONITORING_COMMENT_CREATED",
  // PARTNER
  PARTNER_CREATED = "PARTNER_CREATED",
  PARTNER_UPDATED = "PARTNER_UPDATED",
  PARTNER_DELETED = "PARTNER_DELETED",
}

interface UserEventTrademarkReferenceEntity {
  trademarkName: string;
  trademarkId: string;
  trademarkOffice: TrademarkOffice;
}

interface UserEventResearchReferenceEntity {
  researchId: string;
  researchName: string;
}

interface UserEventSavedSearchReferenceEntity {
  savedSearchId: string;
  savedSearchName: string;
}

// USER
interface FutziQueryExecutedData {
  query: FutziSearchQuery;
}

// WORKSPACE
class WorkspaceBase {
  workspaceId!: string;
  workspaceName!: string;
}

interface WorkspaceCreatedData extends WorkspaceBase {}

interface WorkspaceDeletedData extends WorkspaceBase {}

interface WorkspaceRenamedData extends WorkspaceBase {
  oldName: string;
}

interface WorkspaceInviteCreatedData extends WorkspaceBase {
  email: string;
}

interface WorkspaceInviteDeletedData extends WorkspaceBase {
  email: string;
}

interface WorkspaceUserJoinedData extends WorkspaceBase {
  userId: string;
  userName: string;
}

interface WorkspaceUserLeftData extends WorkspaceBase {
  userId: string;
  userName: string;
}

interface WorkspaceContactChangedData extends WorkspaceBase {}

interface WorkspaceBrandingChangedData extends WorkspaceBase {}

interface WorkspacePaymentMethodAddedData extends WorkspaceBase {}

interface WorkspacePaymentMethodDeletedData extends WorkspaceBase {}

interface WorkspaceBillingInformationChanged extends WorkspaceBase {}

// COLLECTIONS
interface CollectionBase {
  collectionId: string;
  collectionName: string;
}

interface CollectionCreatedData extends CollectionBase {
  note: string;
}

interface CollectionDeletedData extends CollectionBase {}

interface CollectionRenamedData extends CollectionBase {
  oldName: string;
}

interface CollectionTrademarksAddedData extends CollectionBase {
  trademarks: UserEventTrademarkReferenceEntity[];
}

interface CollectionTrademarksRemovedData extends CollectionBase {
  trademarks: UserEventTrademarkReferenceEntity[];
}

interface CollectionNoteChangedData extends CollectionBase {
  note: string;
}

interface CollectionCommentCreatedData extends CollectionBase {
  comment: string;
  commentVisibility: CommentVisibility;
}

// PORTFOLIOS
interface PortfolioBase {
  portfolioId: string;
  portfolioName: string;
}

interface PortfolioCreatedData extends PortfolioBase {
  note: string;
}

interface PortfolioDeletedData extends PortfolioBase {}

interface PortfolioRenamedData extends PortfolioBase {
  oldName: string;
}

interface PortfolioTrademarksAddedData extends PortfolioBase {
  trademarks: UserEventTrademarkReferenceEntity[];
}

interface PortfolioTrademarksRemovedData extends PortfolioBase {
  trademarks: UserEventTrademarkReferenceEntity[];
}

interface PortfolioResearchesAddedData extends PortfolioBase {
  researches: UserEventResearchReferenceEntity[];
}

interface PortfolioResearchesRemovedData extends PortfolioBase {
  researches: UserEventResearchReferenceEntity[];
}

interface PortfolioNoteChangedData extends PortfolioBase {
  note: string;
}

interface PortfolioCommentCreatedData extends PortfolioBase {
  comment: string;
  commentVisibility: CommentVisibility;
}

// RESEARCHES
interface ResearchBase {
  researchId: string;
  researchName: string;
  portfolioId: string;
  portfolioName: string;
}

interface ResearchCreatedData extends ResearchBase {
  note: string;
}

interface ResearchDeletedData extends ResearchBase {}

interface ResearchRenamedData extends ResearchBase {
  oldName: string;
}

interface ResearchTrademarksAddedData extends ResearchBase {
  trademarks: UserEventTrademarkReferenceEntity[];
}

interface ResearchTrademarksRemovedData extends ResearchBase {
  trademarks: UserEventTrademarkReferenceEntity[];
}

interface ResearchSavedSearchAddedData extends ResearchBase {
  savedSearches: UserEventSavedSearchReferenceEntity[];
}

interface ResearchSavedSearchRemovedData extends ResearchBase {
  savedSearches: UserEventSavedSearchReferenceEntity[];
}

interface ResearchNoteChangedData extends ResearchBase {
  note: string;
}

interface ResearchCommentCreatedData extends ResearchBase {
  comment: string;
  commentVisibility: CommentVisibility;
}

// MONITORINGS
interface MonitoringBase {
  monitoringId: string;
  trademarkName: string;
  trademarkId: string;
  trademarkOffice: TrademarkOffice;
}

interface MonitoringCreatedData extends MonitoringBase {}

interface MonitoringDeletedData extends MonitoringBase {}

interface MonitoringTrademarkFlaggedData extends MonitoringBase {
  flaggedTrademarks: UserEventTrademarkReferenceEntity[];
  flag: CollisonFlag;
}

interface MonitoringNoteChangedData extends MonitoringBase {
  oldNote: string;
  newNote: string;
}

interface MonitoringNotificationIntervalChangedData extends MonitoringBase {
  oldInterval: NotificationInterval;
  newInterval: NotificationInterval;
}

interface MonitoringSensitivityChangedData extends MonitoringBase {}

interface MonitoringCommentCreatedData extends MonitoringBase {
  comment: string;
  commentVisibility: CommentVisibility;
}

// PARTNER
interface PartnerBase {
  partnerId: string;
}

interface PartnerCreatedData extends PartnerBase {}

interface PartnerUpdatedData extends PartnerBase {}

interface PartnerDeletedData extends PartnerBase {}

interface UserEventBase<T, D> {
  uid: string;
  userId: string;
  // You can specify the userName if you already have it. Otherwise it will be resolved automatically.
  userName?: string;
  createdAt?: Date;
  updatedAt?: Date;
  event: {
    type: T;
    data: D;
  };
}

export type UserMetric = {
  id: string;
  userId: string;
  createdAt?: Date;
  data: any;
  type: string;
};

export type UserEvent =
  | UserEventBase<UserEventType.FUTZI_QUERY_EXECUTED, FutziQueryExecutedData>

  // WORKSPACE
  | UserEventBase<UserEventType.WORKSPACE_CREATED, WorkspaceCreatedData>
  | UserEventBase<UserEventType.WORKSPACE_DELETED, WorkspaceDeletedData>
  | UserEventBase<UserEventType.WORKSPACE_RENAMED, WorkspaceRenamedData>
  | UserEventBase<UserEventType.WORKSPACE_INVITE_CREATED, WorkspaceInviteCreatedData>
  | UserEventBase<UserEventType.WORKSPACE_INVITE_DELETED, WorkspaceInviteDeletedData>
  | UserEventBase<UserEventType.WORKSPACE_USER_JOINED, WorkspaceUserJoinedData>
  | UserEventBase<UserEventType.WORKSPACE_USER_LEFT, WorkspaceUserLeftData>
  | UserEventBase<UserEventType.WORKSPACE_CONTACT_CHANGED, WorkspaceContactChangedData>
  | UserEventBase<UserEventType.WORKSPACE_BRANDING_CHANGED, WorkspaceBrandingChangedData>
  | UserEventBase<UserEventType.WORKSPACE_PAYMENT_METHOD_ADDED, WorkspacePaymentMethodAddedData>
  | UserEventBase<UserEventType.WORKSPACE_PAYMENT_METHOD_DELETED, WorkspacePaymentMethodDeletedData>
  | UserEventBase<UserEventType.WORKSPACE_BILLING_INFORMATION_CHANGED, WorkspaceBillingInformationChanged>

  // COLLECTIONS
  | UserEventBase<UserEventType.COLLECTION_CREATED, CollectionCreatedData>
  | UserEventBase<UserEventType.COLLECTION_DELETED, CollectionDeletedData>
  | UserEventBase<UserEventType.COLLECTION_RENAMED, CollectionRenamedData>
  | UserEventBase<UserEventType.COLLECTION_TRADEMARK_ADDED, CollectionTrademarksAddedData>
  | UserEventBase<UserEventType.COLLECTION_TRADEMARK_REMOVED, CollectionTrademarksRemovedData>
  | UserEventBase<UserEventType.COLLECTION_NOTE_CHANGED, CollectionNoteChangedData>
  | UserEventBase<UserEventType.COLLECTION_COMMENT_CREATED, CollectionCommentCreatedData>

  // PORTFOLIOS
  | UserEventBase<UserEventType.PORTFOLIO_CREATED, PortfolioCreatedData>
  | UserEventBase<UserEventType.PORTFOLIO_DELETED, PortfolioDeletedData>
  | UserEventBase<UserEventType.PORTFOLIO_RENAMED, PortfolioRenamedData>
  | UserEventBase<UserEventType.PORTFOLIO_TRADEMARK_ADDED, PortfolioTrademarksAddedData>
  | UserEventBase<UserEventType.PORTFOLIO_TRADEMARK_REMOVED, PortfolioTrademarksRemovedData>
  | UserEventBase<UserEventType.PORTFOLIO_RESEARCH_ADDED, PortfolioResearchesAddedData>
  | UserEventBase<UserEventType.PORTFOLIO_RESEARCH_REMOVED, PortfolioResearchesRemovedData>
  | UserEventBase<UserEventType.PORTFOLIO_NOTE_CHANGED, PortfolioNoteChangedData>
  | UserEventBase<UserEventType.PORTFOLIO_COMMENT_CREATED, PortfolioCommentCreatedData>

  // RESEARCHES
  | UserEventBase<UserEventType.RESEARCH_CREATED, ResearchCreatedData>
  | UserEventBase<UserEventType.RESEARCH_DELETED, ResearchDeletedData>
  | UserEventBase<UserEventType.RESEARCH_RENAMED, ResearchRenamedData>
  | UserEventBase<UserEventType.RESEARCH_TRADEMARK_ADDED, ResearchTrademarksAddedData>
  | UserEventBase<UserEventType.RESEARCH_TRADEMARK_REMOVED, ResearchTrademarksRemovedData>
  | UserEventBase<UserEventType.RESEARCH_SAVED_SEARCH_ADDED, ResearchSavedSearchAddedData>
  | UserEventBase<UserEventType.RESEARCH_SAVED_SEARCH_REMOVED, ResearchSavedSearchRemovedData>
  | UserEventBase<UserEventType.RESEARCH_NOTE_CHANGED, ResearchNoteChangedData>
  | UserEventBase<UserEventType.RESEARCH_COMMENT_CREATED, ResearchCommentCreatedData>

  // MONITORINGS
  | UserEventBase<UserEventType.MONITORING_CREATED, MonitoringCreatedData>
  | UserEventBase<UserEventType.MONITORING_DELETED, MonitoringDeletedData>
  | UserEventBase<UserEventType.MONITORING_TRADEMARK_FLAGGED, MonitoringTrademarkFlaggedData>
  | UserEventBase<UserEventType.MONITORING_NOTE_CHANGED, MonitoringNoteChangedData>
  | UserEventBase<UserEventType.MONITORING_NOTIFICATION_INTERVAL_CHANGED, MonitoringNotificationIntervalChangedData>
  | UserEventBase<UserEventType.MONITORING_SENSITIVITY_CHANGED, MonitoringSensitivityChangedData>
  | UserEventBase<UserEventType.MONITORING_COMMENT_CREATED, MonitoringCommentCreatedData>

  // PARTNER
  | UserEventBase<UserEventType.PARTNER_CREATED, PartnerCreatedData>
  | UserEventBase<UserEventType.PARTNER_UPDATED, PartnerUpdatedData>
  | UserEventBase<UserEventType.PARTNER_DELETED, PartnerDeletedData>;
