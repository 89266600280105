import { Sepa } from "app/models/payment-information.model";
import classes from "./Iban.module.css";

interface IbanProps {
  sepa: Sepa;
}

export const Iban = ({ sepa }: IbanProps) => {
  return (
    <div className={classes.root}>
      <p>
        Die letzten vier Ziffern Ihrer IBAN lauten:
        <br />
        <strong>
          {sepa?.country}** **** **** **** {sepa?.last4}
        </strong>
      </p>
    </div>
  );
};
