import { AppInterfaceTheme } from "app/models/app-interface-theme.enum";
import useDarkMode from "use-dark-mode";

export const useAppDarkMode = () => {
  const darkMode = useDarkMode(false, {
    classNameDark: AppInterfaceTheme.DARK,
    classNameLight: AppInterfaceTheme.LIGHT,
  });

  return darkMode;
};
