import { Editor, EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { useCloseEffect } from "app/hooks/use-close-effect.hook";
import { handleError } from "app/util/error-handler";
import { rawHtmlToSafeHtml } from "app/util/html";
import clsx from "clsx";
// import "easymde/dist/easymde.min.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BiCodeBlock, BiHeading, BiParagraph } from "react-icons/bi";
import { BsCodeSlash, BsFileBreak } from "react-icons/bs";
import { IoArrowRedo, IoArrowUndo } from "react-icons/io5";
import {
  MdFormatBold,
  MdFormatClear,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatStrikethrough,
} from "react-icons/md";
import { VscHorizontalRule } from "react-icons/vsc";
import styles from "./AppTextEditor.module.scss";

export type AppTextRendererProps = {
  text?: string | null;
  placeholder?: string | null;
};

export const AppTextRenderer = ({ text, placeholder }: AppTextRendererProps) => {
  const rendered = useMemo(() => {
    const htmlToRender = text || placeholder || null;
    if (!htmlToRender) {
      return "";
    }
    return rawHtmlToSafeHtml(htmlToRender);
  }, [text, placeholder]);

  return <div className={clsx(styles.textDisplay)} dangerouslySetInnerHTML={{ __html: rendered }}></div>;
};

type MenuBarProps = {
  editor?: Editor | null;
  hideActions?: boolean;
  onCancel?: () => unknown;
  onSave?: () => unknown;
  saving?: boolean;
};

const MenuBar = ({ editor, hideActions, onCancel, onSave, saving }: MenuBarProps) => {
  if (!editor) {
    return null;
  }

  // order adapted from google drive
  const actions = [
    {
      title: "Undo",
      icon: IoArrowUndo,
      onClick: () => editor.chain().focus().undo().run(),
    },
    {
      title: "Redo",
      icon: IoArrowRedo,
      onClick: () => editor.chain().focus().redo().run(),
    },
    {
      activeSelector: "bold",
      title: "Bold",
      icon: MdFormatBold,
      onClick: () => editor.chain().focus().toggleBold().run(),
    },
    {
      activeSelector: "italic",
      title: "Italic",
      icon: MdFormatItalic,
      onClick: () => editor.chain().focus().toggleItalic().run(),
    },
    {
      activeSelector: "strike",
      icon: MdFormatStrikethrough,
      title: "Strike",
      onClick: () => editor.chain().focus().toggleStrike().run(),
    },

    {
      activeSelector: "paragraph",
      icon: BiParagraph,
      title: "Paragraph",
      disabled: true,
      onClick: () => editor.chain().focus().setParagraph().run(),
    },
    {
      activeSelector: "heading",
      activeFilter: { level: 1 },
      icon: BiHeading,
      disabled: true,
      title: "H1",
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
    },
    {
      activeSelector: "heading",
      activeFilter: { level: 2 },
      disabled: true,
      icon: BiHeading,
      title: "H2",
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
    },
    {
      activeSelector: "heading",
      activeFilter: { level: 3 },
      icon: BiHeading,
      disabled: true,
      title: "H3",
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
    },
    {
      activeSelector: "heading",
      activeFilter: { level: 4 },
      icon: BiHeading,
      disabled: true,
      title: "H4",
      onClick: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
    },
    {
      activeSelector: "heading",
      activeFilter: { level: 5 },
      icon: BiHeading,
      disabled: true,
      title: "H5",
      onClick: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
    },
    {
      activeSelector: "heading",
      activeFilter: { level: 6 },
      icon: BiHeading,
      disabled: true,
      title: "H6",
      onClick: () => editor.chain().focus().toggleHeading({ level: 6 }).run(),
    },
    {
      activeSelector: "bulletList",
      title: "Bullet list",
      icon: MdFormatListBulleted,
      onClick: () => editor.chain().focus().toggleBulletList().run(),
    },
    {
      activeSelector: "orderedList",
      title: "Ordered list",
      icon: MdFormatListNumbered,
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
    },
    {
      activeSelector: "blockquote",
      title: "Blockquote",
      icon: MdFormatQuote,
      onClick: () => editor.chain().focus().toggleBlockquote().run(),
    },
    {
      activeSelector: "code",
      icon: BsCodeSlash,
      title: "Code",
      disabled: true,
      onClick: () => editor.chain().focus().toggleCode().run(),
    },
    {
      activeSelector: "codeBlock",
      title: "Code block",
      icon: BiCodeBlock,
      disabled: true,
      onClick: () => editor.chain().focus().toggleCodeBlock().run(),
    },

    {
      title: "Horizontal rule",
      icon: VscHorizontalRule,
      onClick: () => editor.chain().focus().setHorizontalRule().run(),
    },
    {
      title: "Hard break",
      icon: BsFileBreak,
      disabled: true,
      onClick: () => editor.chain().focus().setHardBreak().run(),
    },

    {
      title: "Clear format",
      icon: MdFormatClear,
      onClick: () => editor.chain().focus().unsetAllMarks().clearNodes().run(),
    },
  ];

  return (
    <div className={styles.editorActions}>
      <div className={styles.editorActionsMain}>
        {actions
          .filter((a) => !a.disabled)
          .map((a) => (
            <button
              key={a.title}
              title={a.title}
              onClick={a.onClick}
              className={clsx(styles.editorButton, {
                [styles.editorButtonActive]: a.activeSelector
                  ? editor.isActive(a.activeSelector, a.activeFilter)
                  : false,
              })}
            >
              <span>{a.icon ? <a.icon className={styles.editorActionIcon} size={20} /> : "x"}</span>
            </button>
          ))}
      </div>
      {hideActions ? null : (
        <div className={styles.editorActionsExtra}>
          {onSave ? (
            <button disabled={saving} onClick={onSave} className={clsx(styles.editorButton, styles.editorButtonWide)}>
              {saving ? (
                <LoadingIndicator hideMessage={true} className={styles.loading} size={12} />
              ) : (
                <span>Speichern</span>
              )}
            </button>
          ) : null}
          {onCancel ? (
            <button disabled={saving} onClick={onCancel} className={clsx(styles.editorButton, styles.editorButtonWide)}>
              <span>Abbrechen</span>
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

interface AppTextEditorProps {
  onSave: (text: string) => Promise<unknown>;
  defaultValue?: string;
  autofocus?: boolean;
  defaultEditable?: boolean;
  forceEditable?: boolean;
  hideActions?: boolean;
  disableEdit?: boolean;
  onLeaveUnchanged?: () => unknown;
}

export const AppTextEditor = ({
  defaultValue = "",
  onSave,
  hideActions,
  autofocus = true,
  defaultEditable = false,
  forceEditable = false,
  disableEdit = false,
  onLeaveUnchanged,
}: AppTextEditorProps) => {
  const [saving, setSaving] = useState(false);
  const [cancelable, setCancelable] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(defaultEditable || forceEditable);

  const editor = useEditor({
    extensions: [StarterKit],
    content: defaultValue,
    autofocus,
    editable: editMode,
  });

  const enableEditMode = () => {
    setEditMode(true);
  };

  useEffect(() => {
    editor?.setEditable(editMode);
  }, [editMode, editor]);

  useEffect(() => {
    editor?.commands.setContent(defaultValue);
  }, [defaultValue, editor]);

  useEffect(() => {
    if (editMode) {
      const timeout = setTimeout(() => {
        setCancelable(true);
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [editMode]);

  const handleCancel = useCallback(() => {
    if (onLeaveUnchanged) {
      onLeaveUnchanged();
    }
    setCancelable(false);
    setEditMode(forceEditable || false);
    editor?.commands.setContent(defaultValue);
  }, [defaultValue, editor?.commands, forceEditable, onLeaveUnchanged]);

  const handleSave = useCallback(async () => {
    const value = editor?.getHTML() || "";

    if (value !== defaultValue && !saving) {
      try {
        setSaving(true);
        await onSave(value);
      } catch (error) {
        console.error("Error saving note", error);
        handleError("Text konnte nicht gespeichert werden", error);
      } finally {
        setSaving(false);
        setEditMode(forceEditable || false);
        setCancelable(false);
      }
    }
  }, [editor, defaultValue, saving, onSave, forceEditable]);

  useCloseEffect(ref, () => {
    if (editMode && cancelable) {
      handleSave();
    }
  });

  return (
    <div className={styles.root} ref={ref}>
      <div
        className={clsx(styles.editor, {
          [styles.editable]: editMode,
        })}
      >
        <div className={styles.editorContent}>
          <EditorContent editor={editor} />
        </div>
        {editMode ? (
          <MenuBar
            editor={editor}
            hideActions={hideActions}
            onCancel={handleCancel}
            onSave={handleSave}
            saving={saving}
          />
        ) : null}
        {!editMode && !disableEdit ? (
          <div className={styles.overlay} onClick={enableEditMode} title="Bearbeiten">
            <ICONS.EDIT className={styles.icon} size={ICON_SIZE.DEFAULT} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
