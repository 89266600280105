import { ICONS } from "app/assets/icons/icons";
import { ActiveContext, useActiveContext } from "app/hooks";
import { pluralize } from "app/i18n";
import { ContextType, UserFlag } from "app/models";
import { clearUserContext, useAppDispatch } from "app/redux";
import { collectionDetailRoute, portfolioDetailRoute, researchDetailRoute } from "app/routes";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tutorial, TutorialStep } from "../Tutorial/Tutorial";
import styles from "./ContextFooter.module.scss";

export const ContextFooter = () => {
  const dispatch = useAppDispatch();
  const context = useActiveContext();
  const [hasTutorial, setHasTutorial] = useState(false);
  // const navigate = useNavigate();
  // const portfolio = useAppSelector(
  //   (state) =>
  //     state.portfolio.items.find((p) => p.uid === context?.portfolio?.uid) ||
  //     state.portfolio.items.find((p) => p.researches?.some((r) => r.researchId === context?.research?.uid)),
  // );

  useEffect(() => {
    // setHasTutorial(false);

    const timeout = setTimeout(() => {
      // console.log("effect", context);
      if (context) {
        setHasTutorial(true);
      } else {
        setHasTutorial(false);
      }
    }, 800);

    return () => {
      clearTimeout(timeout);
    };
  }, [context]);

  if (!context) {
    return null;
  }

  const handleNavigate = async () => {
    // const researchId = context.research?.uid;
    // const portfolioId = context.portfolio?.uid;

    await dispatch(clearUserContext()).unwrap();
    // * do not go to research page. might interfere with current user flow.
    // * Users can still go there via the link in the context footer
    // if (researchId && portfolioId) {
    //   navigate(researchDetailRoute(portfolioId, researchId));
    // } else if (portfolioId) {
    //   navigate(portfolioDetailRoute(portfolioId));
    // }
  };

  const { link, type, name, trademarkCount, tutorialTitle, tutorialText, tutorialTrademarkText } =
    getContextTexts(context);

  const tutorialSteps: TutorialStep[] = [
    {
      title: tutorialTitle,
      target: ".tutorial-search-context",
      content: tutorialText,
      disableBeacon: true,
    },
    {
      title: "Marken markieren",
      target: ".tutorial-search-context-trademark",
      content: tutorialTrademarkText,
      disableBeacon: true,
    },
  ];

  return (
    <>
      {hasTutorial ? <Tutorial flag={UserFlag.TUTORIAL_CONTEXT} steps={tutorialSteps} /> : null}
      <div className={styles.root}>
        <div className={clsx("tutorial-search-context", styles.element)}>
          <Link to={link} className={styles.link}>
            {type} | <span className={styles.title}>{name} |</span> {pluralize("trademarks", trademarkCount)} ausgewählt
          </Link>
          <button onClick={handleNavigate} className={styles.button} title="Beenden">
            <ICONS.CLOSE />
          </button>
        </div>
      </div>
    </>
  );
};

function getContextTexts(context: ActiveContext) {
  switch (context.type) {
    case ContextType.RESEARCH:
      return {
        type: "Recherche",
        name: context.data.research.name,
        link: researchDetailRoute(context.data.portfolio.uid, context.data.research.uid),
        trademarkCount: context.data.research.trademarks?.length || 0,
        tutorialTitle: "Aktuelle Recherche",
        tutorialText: "Wenn eine Recherche aktiv ist, werden alle markierten Marken automatisch hinzugefügt.",
        tutorialTrademarkText: "Markieren Sie einfach alle relevanten Marken, um sie zu Ihrer Recherche hinzuzufügen.",
      };
    case ContextType.PORTFOLIO:
      return {
        type: "Mandant",
        name: context.data.portfolio.name,
        link: portfolioDetailRoute(context.data.portfolio.uid),
        trademarkCount: context.data.portfolio.trademarks?.length || 0,
        tutorialTitle: "Aktuelles Mandantenportfolio",
        tutorialText: "Wenn ein Mandantenportfolio aktiv ist, werden alle markierten Marken automatisch hinzugefügt.",
        tutorialTrademarkText:
          "Markieren Sie einfach alle relevanten Marken, um sie zu Ihrem Mandantenportfolio hinzuzufügen.",
      };
    case ContextType.COLLECTION:
      return {
        type: "Sammlung",
        name: context.data.collection.name,
        link: collectionDetailRoute(context.data.collection.uid),
        trademarkCount: context.data.collection.trademarks?.length || 0,
        tutorialTitle: "Aktuelle Sammlung",
        tutorialText: "Wenn eine Sammlung aktiv ist, werden alle markierten Marken automatisch hinzugefügt.",
        tutorialTrademarkText: "Markieren Sie einfach alle relevanten Marken, um sie zu Ihrer Sammlung hinzuzufügen.",
      };
    default:
      return {
        type: "",
        name: "",
        link: "",
        trademarkCount: 0,
        tutorialTitle: "",
        tutorialText: "",
        tutorialTrademarkText: "",
      };
  }
}
