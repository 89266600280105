import { PartnerClient } from "app/api";
import { APP_CONST } from "app/assets/constants";
import { ICONS } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { useAppSelector } from "app/redux";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useState } from "react";
import { PartnerContactSettings } from "./PartnerContactSettings/PartnerContactSettings";
import { PartnerDebugSettings } from "./PartnerDebugSettings/PartnerDebugSettings";
import { PartnerIntroSettings } from "./PartnerIntroSettings/PartnerIntroSettings";
import { PartnerPublicSettings } from "./PartnerPublicSettings/PartnerPublicSettings";
import { PartnerUspSettings } from "./PartnerUspSettings/PartnerUspSettings";

export const PartnerView = () => {
  const { items, loading: partnerLoading } = useAppSelector((state) => state.partner);
  const [loading, setLoading] = useState(false);

  const partner = items.find(Boolean);

  const handlePartnerCreate = async () => {
    setLoading(true);
    try {
      trackEvent(AppEvent.CREATE_PARTNER);
      await PartnerClient.create({});
    } catch (error) {
      handleError("Partnerseite konnte nicht erstellt werden.", error);
    } finally {
      setLoading(false);
    }
  };

  if (partnerLoading) {
    return <LoadingIndicator />;
  }

  if (!partner) {
    return (
      <EmptyState
        title="Jetzt Partner werden"
        image={APP_CONST.IMAGES.EATING_ICE_CREAM}
        text="Werden Sie offizieller Oktomark Partner und holen Sie noch mehr raus! Das Oktomark Partner Programm gibt Ihnen auch eine weitere Werbefläche im Internet und hilft Ihnen die besten Aufträge zu sichern, damit Sie mehr Zeit für die wichtigen Dinge im Leben haben. Eis essen zum Beispiel."
        fullWidth
      >
        <Button onClick={handlePartnerCreate} loading={loading}>
          Partner Webseite einrichten
        </Button>
      </EmptyState>
    );
  }

  return (
    <OneColumnLayout>
      <CardContainer
        header={{
          title: "Meine Partnerseite",
          Icon: ICONS.PARTNER,
        }}
      >
        <PartnerIntroSettings partner={partner} />
        <PartnerUspSettings
          partner={partner}
          title={"Individueller Block 1"}
          subtitle={
            "Hier können Sie kreativ werden und Ihre Stärken zeigen. Schreiben Sie zum Beispiel etwas über Ihre Arbeitsweise und Ihr Team."
          }
          imageKey={"usp1Image"}
          headlineKey={"usp1Headline"}
          descriptionKey={"usp1Description"}
        />
        <PartnerUspSettings
          partner={partner}
          title={"Individueller Block 2"}
          subtitle={"Hier können Sie kreativ sein. Bei welchen Problemen können Sie zum Beispiel helfen?"}
          imageKey={"usp2Image"}
          headlineKey={"usp2Headline"}
          descriptionKey={"usp2Description"}
        />
        <PartnerUspSettings
          partner={partner}
          title={"Individueller Block 3"}
          subtitle={
            "Hier können Sie kreativ sein. Was sagen zum Beispiel Ihre Kunden über Sie oder welche Dienstleistungen bieten Sie zusätzlich an?"
          }
          imageKey={"usp3Image"}
          headlineKey={"usp3Headline"}
          descriptionKey={"usp3Description"}
        />
        <PartnerContactSettings partner={partner} />
        <PartnerPublicSettings partner={partner} />
        <RenderIfTestAccount>
          <PartnerDebugSettings partner={partner} />
        </RenderIfTestAccount>
      </CardContainer>
    </OneColumnLayout>
  );
};
