import { getClient } from "app/api";

export const callDebugRoute = async () => {
  const client = await getClient();
  const { data } = await client.get("/debug");
  return data;
};

export const triggerDailyOnboardingMailCron = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/cron/onboarding");
  return data;
};

export const triggerDailyMonitoringReportCron = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/cron/monitoring-report-mail");
  return data;
};

export const triggerDailyMonitoringExpireMailCron = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/cron/monitoring-expire-mail");
  return data;
};

export const triggerWeeklyStatisticsMailCron = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/cron/statistics");
  return data;
};

export const triggerHourlyBackupCron = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/cron/backup");
  return data;
};

export const triggerHourlyMonitoringReportCron = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/cron/monitoring-report-generation");
  return data;
};

export const triggerOnboardingMail = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/mails/onboarding");
  return data;
};

export const triggerWeeklyStatisticsMail = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/mails/weekly-statistics");
  return data;
};

export const triggerMonitoringReportMail = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/mails/monitoring-report");
  return data;
};

export const triggerMonitoringExpireMail = async () => {
  const client = await getClient();
  const { data } = await client.post("/debug/mails/monitoring-expire");
  return data;
};
