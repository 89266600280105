import { Card } from "app/components/Card/Card";
import clsx from "clsx";
import { ReactNode } from "react";
import styles from "./EmptyState.module.css";

type EmptyStateTutorialProps = {
  text?: string;
  image: string;
};

export const EmptyStateTutorial = ({ text, image }: EmptyStateTutorialProps) => {
  return (
    <div className={styles.emptyStateTutorial}>
      <div className={styles.emptyStateText}>{text}</div>
      <img src={image} alt="" width="auto" height="200px" />
    </div>
  );
};

interface EmptyStateProps {
  className?: string;
  image?: string;
  title?: string;
  text?: ReactNode;
  fullWidth?: boolean;
  noBg?: boolean;
  smallImage?: boolean;
  item?: boolean;
  children?: React.ReactNode;
}

export const EmptyState = ({
  className,
  image,
  text,
  title,
  children,
  fullWidth,
  noBg,
  smallImage,
  item,
}: EmptyStateProps) => {
  return (
    <Card
      className={clsx(styles.root, className, {
        [styles.fullWidth]: fullWidth,
        [styles.noBg]: noBg,
      })}
      item={item}
    >
      {image ? <img src={image} alt="" className={clsx({ [styles.smallImage]: smallImage }, styles.image)} /> : null}
      <div className={styles.content}>
        {title ? <div className={styles.heading}>{title}</div> : null}
        {text ? <p className={styles.text}>{text}</p> : null}
        {children}
      </div>
    </Card>
  );
};
