import { Card, CardActions, CardTitle } from "app/components/Card/Card";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { WorkspaceCancellation } from "../WorkspaceCancellation/WorkspaceCancellation";

export const SettingsPageWorkspaceDebug = () => {
  return (
    <RenderIfTestAccount padding={true}>
      <Card>
        <CardTitle title="Aktionen" subtitle="Hier können Sie Ihren Workspace löschen" />
        <CardActions>
          <WorkspaceCancellation />
        </CardActions>
      </Card>
    </RenderIfTestAccount>
  );
};
