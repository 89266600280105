import { CollapsibleCard } from "app/components/CollapsibleCard/CollapsibleCard";

export const PortfolioHelp = () => {
  return (
    <CollapsibleCard title="Wie funktionieren Mandanten?">
      <p>
        Legen Sie Mandanten an, um alle Arbeitsergebnisse rund um Mandanten zu sammeln. Speichern Sie Recherchen, Suchen
        und Marken.
      </p>
      <ul>
        <li>Erstellen Sie Recherchen, fügen Sie gefundene Marken hinzu und kommentieren Sie diese.</li>
        <li>Exportieren Sie Recherchen als PDF, XLSX oder CSV</li>
        <li>Fügen Sie alle Marken des Mandanten hinzu und überwachen Sie diese mit nur einem Klick.</li>
      </ul>
    </CollapsibleCard>
  );
};
