import clsx from "clsx";
import React from "react";
import styles from "./TextArea.module.scss";

export type TextAreaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  className?: string;
  onTextChange?: (text: string) => void;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ children, className, onChange, onTextChange = (text: string) => null, ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(e);
      }
      onTextChange(e.target.value);
    };
    return (
      <textarea className={clsx(styles.root, className)} onChange={handleChange} ref={ref} {...props}>
        {children}
      </textarea>
    );
  },
);
