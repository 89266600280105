import { EuipoImportOptions } from "app/models/admin/euipo-import.model";
import { EuipoFileInfo } from "app/models/admin/euipo-stats";
import { getClient } from "./client";

const client = getClient({ basePath: "/euipo-import" });

export const EuipoImportClient = {
  startImport: (options: EuipoImportOptions) => client.post("/", options),
  startDailyImport: () => client.post("/daily"),
  getStats: async () => {
    const { data } = await client.get<{ stats: EuipoFileInfo[] }>("/stats");
    return data;
  },
  resetImportStatus: async (file: EuipoFileInfo) => {
    const { data } = await client.post(`/file/${file.uid}/reset-import`);
    return data;
  },
  setImported: async (file: EuipoFileInfo) => {
    const { data } = await client.post(`/file/${file.uid}/set-imported`);
    return data;
  },
  dropQueues: async () => {
    const { data } = await client.post(`/drop-queues`);
    return data;
  },
};
