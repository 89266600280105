import { ContextType, SavedSearch } from "app/models";
import { updateUserContext, useAppDispatch, useAppSelector } from "app/redux";
import { savedSearchRoute } from "app/routes";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useNavigate } from "react-router-dom";

export function useUserSavedSearches() {
  const userId = useAppSelector((state) => state.auth.user?.userId);
  const researches = useAppSelector((state) => state.research.items);
  const savedSearches = useAppSelector((state) => state.search.saved);
  const loading = useAppSelector((state) => state.search.savedLoading);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userVisibleSavedSearches = savedSearches
    .filter(
      (savedSearch) =>
        savedSearch.userId === userId ||
        researches.some((research) =>
          research.savedSearches?.some(({ savedSearchId }) => savedSearchId === savedSearch.uid),
        ),
    )
    .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  const activateSavedSearch = (s: SavedSearch) => {
    trackEvent(AppEvent.ACTIVATE_SAVED_SEARCH, { id: s.uid });
    navigate(savedSearchRoute(s.uid));
    const research = researches.find((research) =>
      research.savedSearches?.some(({ savedSearchId }) => savedSearchId === s.uid),
    );
    if (research) {
      dispatch(
        updateUserContext({
          type: ContextType.RESEARCH,
          researchId: research.uid,
        }),
      );
    }
  };

  return { items: userVisibleSavedSearches, activateSavedSearch, loading };
}
