import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { Toggle } from "app/components/Toggle/Toggle";
import { useAppForm } from "app/hooks";
import { Partner } from "app/models";
import { updatePartner, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

type Props = {
  partner: Partner;
};

export const PartnerPublicSettings = ({ partner }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const defaultFormValues = getDefaultFormData(partner);

  const { handleSubmit, register, isValid, isDirty, reset, control } = useAppForm<Partner>({
    defaultValues: defaultFormValues,
  });

  const handleResetForm = useCallback(() => {
    reset(getDefaultFormData(partner));
  }, [partner, reset]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm, partner]);

  const handleUpdate = async (data: Partner) => {
    if (!partner?.uid) {
      return;
    }
    if (!isValid || !isDirty) {
      return;
    }
    setLoading(true);
    try {
      trackEvent(AppEvent.UPDATE_PARTNER, data);
      await dispatch(updatePartner({ id: partner.uid, partner: data })).unwrap();
    } catch (error) {
      handleError("Partnerseite konnte nicht aktualisiert werden.", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card item>
      <CardTitle
        title={"Öffentliche Partnerseite"}
        subtitle="Wählen Sie Ihre URL und veröffentlichen Sie Ihre Partnerseite"
      />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <CardContent>
          <CardContentSection
            title="Profil URL"
            description="Unter welcher URL soll Ihre Partnerseite erreichbar sein?"
          >
            <Input type="text" placeholder="muster-kanzlei" {...register("slug")} />
            <Controller
              name="slug"
              control={control}
              render={({ field }) => (
                <p>
                  <a
                    href={`https://oktomark.de/partner/${field.value || partner.uid}`}
                    target="_blank"
                    rel="noreferrer nofollow noopener"
                  >
                    https://oktomark.de/partner/{field.value || partner.uid}
                  </a>
                </p>
              )}
            />
          </CardContentSection>
          <CardContentSection
            title="Vorschau"
            description="Gestalten Sie Ihre Partnerseite ganz in Ruhe. Eine Vorschau auf Ihre Partnerseite ist jederzeit hier für Sie verfügbar."
          >
            <Controller
              name="slug"
              control={control}
              render={({ field }) => (
                <p>
                  <a
                    href={`https://oktomark.de/partner/${field.value || partner.uid}/vorschau`}
                    target="_blank"
                    rel="noreferrer nofollow noopener"
                  >
                    https://oktomark.de/partner/{field.value || partner.uid}/vorschau
                  </a>
                </p>
              )}
            />
          </CardContentSection>
          <CardContentSection
            title="Veröffentlicht"
            description="Aktivieren Sie Ihre Partnerseite, wenn Sie Ihre Konfiguration abgeschlossen haben. Sie können Ihre Partnerseite jederzeit aktivieren oder deaktivieren."
          >
            <Controller
              name="public"
              control={control}
              render={({ field }) => <Toggle checked={Boolean(field.value)} {...field} />}
            />
          </CardContentSection>
        </CardContent>
        <CardActions>
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty}>
            Speichern
          </Button>
          <Button type="button" onClick={handleResetForm} theme={ButtonTheme.LINK} disabled={loading || !isDirty}>
            Abbrechen
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

function getDefaultFormData(partner: Partner) {
  return {
    public: partner.public,
    slug: partner.slug,
  };
}
