import { Badge } from "app/components/Badge/Badge";
import { localizedType } from "app/i18n";
import { TrademarkType } from "app/models/trademark.model";
import styles from "./TypeIndicator.module.css";

const cssClassForStatus = (type: TrademarkType) => {
  switch (type) {
    default:
      return styles.default;
  }
};

type TrademarkTypeIndicatiorProps = {
  type: TrademarkType;
  noBg?: boolean;
};

export const TypeIndicator = ({ type, noBg }: TrademarkTypeIndicatiorProps) => {
  return (
    <Badge noBg={noBg} className={cssClassForStatus(type)}>
      {localizedType(type)}
    </Badge>
  );
};
