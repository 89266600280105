export enum WebsocktMessageType {
  COLLECTION_DELETE = "COLLECTION_DELETE",
  COLLECTION_UPDATE = "COLLECTION_UPDATE",
  LAST_SEARCH_UPDATE = "LAST_SEARCH_UPDATE",
  NOTIFICATION_UPDATE = "NOTIFICATION_UPDATE",
  PARTNER_UPDATE = "PARTNER_UPDATE",
  PARTNER_DELETE = "PARTNER_DELETE",
  PAYMENT_UPDATE = "PAYMENT_UPDATE",
  PORTFOLIO_DELETE = "PORTFOLIO_DELETE",
  PORTFOLIO_UPDATE = "PORTFOLIO_UPDATE",
  RESEARCH_DELETE = "RESEARCH_DELETE",
  RESEARCH_UPDATE = "RESEARCH_UPDATE",
  SAVED_SEARCH_DELETE = "SAVED_SEARCH_DELETE",
  SAVED_SEARCH_UPDATE = "SAVED_SEARCH_UPDATE",
  TRADEMARK_MONITORING_DELETE = "TRADEMARK_MONITORING_DELETE",
  TRADEMARK_MONITORING_UPDATE = "TRADEMARK_MONITORING_UPDATE",
  USER_UPDATE = "USER_UPDATE",
  WORKSPACE_DELETE = "WORKSPACE_DELETE",
  WORKSPACE_PENDING_INVITE = "WORKSPACE_PENDING_INVITE",
  WORKSPACE_USER_REMOVED = "WORKSPACE_USER_REMOVED",
  WORKSPACE_UPDATE = "WORKSPACE_UPDATE",
}
