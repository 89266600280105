import { useEffect, useState } from "react";
import { fetchNiceClassesSuggestions } from "../../../api";
import { Button, ButtonTheme } from "../../../components/Button/Button";
import { Card, CardActions } from "../../../components/Card/Card";
import { NiceClassSuggestions } from "../../../models";
import { TmClass } from "../../../models/tmclass.model";
import { UserNiceClassDefinition } from "../model";
import { TmClassCloud } from "../TmClassCloud/TmClassCloud";
import styles from "./UserClassDefinitionCard.module.scss";

type UserClassDefinitionCardProps = {
  c: UserNiceClassDefinition;
  allClassItems: TmClass[];
  onToggle: (c: TmClass) => unknown;
  onRemoveClass: () => unknown;
};

export const UserClassDefinitionCard = ({
  c,
  allClassItems,
  onToggle,
  onRemoveClass,
}: UserClassDefinitionCardProps) => {
  const [suggestions, setSuggestions] = useState<NiceClassSuggestions>({ classes: [], descriptions: [] });

  useEffect(() => {
    fetchNiceClassesSuggestions({ classes: [], descriptions: c.classes.map((s) => s.Begriff) }).then(setSuggestions);
  }, [c.classes]);

  //   const uniqueClassItems = allClassItems.map((a) => a.Begriff).filter(onlyUnique);
  console.log("UserClassDefCard", {
    c,
    suggestions,
    // allallClassItemsCount: allClassItems.length,
    // TODO: find out why some words a double
    // uniqueallClassItemsCount: uniqueClassItems.length,
  });

  return (
    <Card item>
      <h2>{c.classNumber}</h2>
      <div>
        <h3>Ausgewählt</h3>
        <div className={styles.wordcloud}>
          <TmClassCloud
            onSelect={onToggle}
            items={c.classes.map((i) => ({
              title: i.Begriff,
              item: i,
            }))}
          />
          {/* TODO: allow adding own word */}
          {/* TODO: allow re-ordering by drag and drop */}
        </div>
      </div>
      <div>
        <h3>Vorschläge</h3>
        <div className={styles.wordcloud}>
          {/* TODO: add fuzzy search */}
          {/* TODO: make clickable / match with tmclass items if possible */}
          {/* TODO: mark non tm-class begriffe */}
          {/* TODO: welche begriffe lassen sich eher eintragen und welche nicht (nizza grinder) */}
          <TmClassCloud
            onSelect={onToggle}
            items={suggestions.descriptions.map((i, j) => ({
              title: `${i.id} (${i.count})`,
              // TODO: map to actual tmclass
              item: {
                id: `suggestion_${c.classNumber}_${j}`,
                Begriff: i.id,
                Klasse: String(c.classNumber),
                Grou: false,
                Harm: false,
                Harmonisiert: false,
                IDli: false,
                MGS: false,
                Nice: false,
              },
            }))}
          />
        </div>
      </div>
      <div>
        <h3>Alle bekannten Begriffe</h3>
        <div className={styles.wordcloud}>
          {/* TODO: show most used words at the top */}
          {/* TODO: add fuzzy search */}
          <TmClassCloud
            onSelect={onToggle}
            items={allClassItems.map((i) => ({
              title: i.Begriff,
              item: i,
            }))}
          />
        </div>
      </div>
      <CardActions>
        <Button theme={ButtonTheme.DANGER} onClick={onRemoveClass}>
          Klasse {c.classNumber} entfernen
        </Button>
      </CardActions>
    </Card>
  );
};
