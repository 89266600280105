import { APP_CONST } from "app/assets/constants";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { NoAuthLayout } from "app/layouts/NoAuthLayout/NoAuthLayout";
import styles from "./AppLoadingView.module.css";

export const AppLoadingView = () => {
  return (
    <NoAuthLayout>
      <div className={styles.loadingOverlay}>
        <img className={styles.loadingOverlayLogo} src={APP_CONST.IMAGES.OKTO} alt="" />
        <div className={styles.loadingOverlayTitle}>Oktomark</div>
        <LoadingIndicator message="App wird geladen..." />
      </div>
    </NoAuthLayout>
  );
};
