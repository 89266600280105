import pdfTitlePageBottomHalf from "app/assets/pdf-title-pages/pdf-title-page-bottom-half.png";
import pdfTitlePageDefault from "app/assets/pdf-title-pages/pdf-title-page-left-bar.png";
import pdfTitlePageTopHalf from "app/assets/pdf-title-pages/pdf-title-page-top-half.png";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { ImagePicker } from "app/components/ImagePicker/ImagePicker";
import { useAppForm } from "app/hooks";
import { PdfTitleStyle, Workspace, WorkspaceBranding } from "app/models/workspace.model";
import { updateWorkspaceBranding, updateWorkspaceBrandingLogo } from "app/redux";
import { useAppDispatch } from "app/redux/store.hooks";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import clsx from "clsx";
import { omit } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { TwitterPicker, TwitterPickerProps } from "react-color";
import { Controller } from "react-hook-form";
import styles from "./SettingsPageWorkspaceBranding.module.scss";

const TwitterPickerStyles = (color?: string): TwitterPickerProps["styles"] => ({
  default: {
    body: {
      padding: "0",
    },
    card: { padding: 0, boxShadow: "none", background: "transparent" },
    hash: {
      border: "1px solid var(--theme-color-selected)",
      borderRadius: "var(--theme-border-radius)",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      backgroundColor: color || "var(--theme-color-background)",
      color: "#f0f0f0",
    },
    input: {
      height: "26px",
      backgroundColor: "var(--theme-color-background)",
      color: "var(--theme-color-text)",
      border: "1px solid var(--theme-color-selected)",
      borderRadius: "var(--theme-border-radius)",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      boxShadow: "none",
    },
  },
});

type Props = {
  workspace: Workspace;
};
type FormData = WorkspaceBranding & { file?: File };

export const SettingsPageWorkspaceBranding = ({ workspace }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { handleSubmit, isValid, isDirty, setValue, reset, control } = useAppForm<FormData>({
    defaultValues: getDefaultFormData(workspace),
  });

  const handleResetForm = useCallback(() => {
    reset(getDefaultFormData(workspace));
  }, [workspace, reset]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm, workspace]);

  const handleUpdate = async (data: FormData) => {
    if (!isValid || !isDirty) {
      return;
    }
    setLoading(true);
    try {
      trackEvent(AppEvent.UPDATE_PARTNER, data);
      if (data.file) {
        await dispatch(updateWorkspaceBrandingLogo(data.file)).unwrap();
      }

      // XXX: When new file has been uploaded we omit some fields to avoid saving old values
      const omits = ["file"];
      if (data.file) {
        omits.push("logoUrl");
      }
      await dispatch(updateWorkspaceBranding(omit(data, omits))).unwrap();

      setValue("file", undefined);
    } catch (error) {
      handleError("Branding konnte nicht aktualisiert werden.", error);
    } finally {
      setLoading(false);
    }
  };

  const pdfTitlePageStyles: Array<{ id: PdfTitleStyle; name: string; preview: string }> = [
    { id: "default", name: "Standard", preview: pdfTitlePageDefault },
    { id: "top-half", name: "Obere Hälfte", preview: pdfTitlePageTopHalf },
    { id: "bottom-half", name: "Untere Hälfte", preview: pdfTitlePageBottomHalf },
  ];

  return (
    <Card item>
      <CardTitle title="Design" subtitle="Hier können Sie Ihre Berichte, Logos und Farben anpassen" />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <CardContent>
          <CardContentSection title="Logo" description="Das Logo wird zum Beispiel auf der Titelseite benutzt.">
            <Controller
              name={"logoPngUrl"}
              control={control}
              render={({ field }) => (
                <Controller
                  name="file"
                  control={control}
                  render={({ field: field2 }) => (
                    <ImagePicker url={field.value} file={field2.value} onUpload={field2.onChange} />
                  )}
                />
              )}
            />
            <Controller
              name={"logoPngUrl"}
              control={control}
              render={({ field }) =>
                field.value ? (
                  <Button type="button" theme={ButtonTheme.LINK} onClick={() => field.onChange("")}>
                    Löschen
                  </Button>
                ) : (
                  <div></div>
                )
              }
            />
          </CardContentSection>

          <CardContentSection title="Farben" description="Diese Farben benutzen wir als Akzentelemente in Berichten.">
            <div className={styles.colorPicker}>
              <label htmlFor="primaryColor">Primärfarbe</label>
              <Controller
                name={"primaryColor"}
                control={control}
                render={({ field }) => (
                  <TwitterPicker
                    width="100%"
                    triangle={"hide"}
                    color={field.value}
                    onChangeComplete={({ hex }) => field.onChange(hex)}
                    styles={TwitterPickerStyles(field.value)}
                  />
                )}
              />
            </div>
            <div className={styles.colorPicker}>
              <label htmlFor="Secondary Color">Sekundärfarbe</label>
              <Controller
                name={"secondaryColor"}
                control={control}
                render={({ field }) => (
                  <TwitterPicker
                    width="100%"
                    triangle={"hide"}
                    color={field.value}
                    onChangeComplete={({ hex }) => field.onChange(hex)}
                    styles={TwitterPickerStyles(field.value)}
                  />
                )}
              />
            </div>
          </CardContentSection>
          <CardContentSection title="Layout" description="Konfigurieren Sie das Design Ihrer Berichte.">
            <Controller
              name={"pdfTitleStyle"}
              control={control}
              render={({ field }) => (
                <div className={styles.exportTitlePage}>
                  {pdfTitlePageStyles.map(({ id, name, preview }) => (
                    <button
                      key={id}
                      type="button"
                      onClick={() => field.onChange(id)}
                      className={clsx({
                        [styles.active]: field.value === id,
                      })}
                    >
                      <h4>{name}</h4>
                      <img src={preview} alt="" />
                    </button>
                  ))}
                </div>
              )}
            />
          </CardContentSection>
        </CardContent>
        <CardActions>
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty}>
            Design aktualisieren
          </Button>
          <Button type="button" onClick={handleResetForm} theme={ButtonTheme.LINK} disabled={loading || !isDirty}>
            Abbrechen
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

function getDefaultFormData(workspace: Workspace) {
  return {
    logoPngUrl: workspace.branding?.logoPngUrl,
    pdfTitleStyle: workspace.branding?.pdfTitleStyle || "default",
    primaryColor: workspace.branding?.primaryColor ?? "",
    secondaryColor: workspace.branding?.secondaryColor ?? "",
  };
}
