import { Badge } from "app/components/Badge/Badge";
import { localizedActivity, localizedStatus } from "app/i18n";
import { Trademark, TrademarkStatus } from "app/models/trademark.model";
import { localizedDate } from "app/util/date.util";
import clsx from "clsx";
import styles from "./StateIndicator.module.css";

const cssClassForStatus = (status: TrademarkStatus) => {
  switch (status) {
    case TrademarkStatus.OPPOSED:
      return styles.opposed;
    case TrademarkStatus.PUBLISHED:
      return styles.opposed;
    case TrademarkStatus.APPEAL_PENDING:
      return styles.appealPending;
    case TrademarkStatus.REGISTERED:
      return styles.registered;
    case TrademarkStatus.REFUSED:
      return styles.underExamination;
    case TrademarkStatus.UNDER_EXAMINATION:
      return styles.filed;
    case TrademarkStatus.FILED:
      return styles.filed;
    default:
      return styles.default;
  }
};

type StateIndicatorProps = {
  trademark: Trademark;
  noBg?: boolean;
};

export const StateIndicator = ({ trademark: { status, activities, lastUpdate }, noBg }: StateIndicatorProps) => {
  const lastActivity = activities.slice().reverse().find(Boolean);

  let text = localizedDate(lastUpdate) + " - " + localizedStatus(status);

  if (lastActivity) {
    text = localizedDate(lastActivity.date) + " - " + localizedActivity(lastActivity) + " / " + localizedStatus(status);
  }
  return (
    <Badge noBg={noBg} className={clsx(styles.base, cssClassForStatus(status))}>
      {text}
    </Badge>
  );
};
