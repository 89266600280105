import { APP_CONST } from "app/assets/constants";
import { ICONS } from "app/assets/icons/icons";
import { Card, CardContent } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { Input } from "app/components/Input/Input";
import { k, l, localizedOffice, localizedStatus, localizedType, toTrademarkStatus, toTrademarkType } from "app/i18n";
import { SearchFilter } from "app/models";
import { QueryResult } from "app/models/query-result.model";
import { TrademarkOffice, TrademarkStatus, TrademarkType } from "app/models/trademark.model";
import { searchSlice } from "app/redux/slices/search.slice";
import { useAppDispatch } from "app/redux/store.hooks";
import { ROUTES } from "app/routes";
import clsx from "clsx";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SearchSidebar.module.scss";

type ToggleListProps = {
  headline: string;
  items: { name: string; count: number; active: boolean }[];
  showFilter?: boolean;
  onToggleFilter: (name: string) => void;
  onSearch?: (name: string) => void;
};

const ToggleList = ({ headline, items, showFilter, onToggleFilter, onSearch }: ToggleListProps) => {
  const [filter, setFilter] = useState({
    length: 5,
    value: "",
    visible: false,
  });

  //TODO: fix filter reset after click
  useEffect(() => {
    setFilter({
      length: 5,
      value: "",
      visible: false,
    });
  }, [items]);

  const onShowFilter = () => {
    setFilter({
      ...filter,
      visible: true,
    });
  };

  const onFilter = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      value,
    });
  };

  const onShowMore = () => {
    setFilter({
      ...filter,
      length: filter.length + 50,
    });
  };

  const handleOnSearch = (name: string) => (e: MouseEvent<SVGElement>) => {
    e.stopPropagation();
    onSearch?.(name);
  };

  return (
    <Card className={styles.list}>
      <CardContent>
        <div className={styles.headline}>
          <span>
            {headline} ({items.length})
          </span>
          {showFilter && !filter.visible && (
            <span title={`${headline} durchsuchen`} className={styles.showFilter} onClick={onShowFilter}>
              Suche
            </span>
          )}
        </div>
        {onFilter && filter.visible && <Input placeholder="Suche" value={filter.value} onChange={onFilter} />}
        {items
          .filter((i) => !filter || i.name.toLowerCase().match(filter.value.toLowerCase()))
          .slice(0, filter.length)
          .map(({ name, count, active }) => (
            <div
              key={name}
              className={clsx(styles.text, {
                [styles.active]: active,
              })}
              title={active ? "Filter löschen" : `Nach ${name} filtern`}
              onClick={() => onToggleFilter(name)}
            >
              <span className={styles.name}>{name}</span>
              <span className={styles.count}>({new Intl.NumberFormat("de-DE").format(count)})</span>
              <ICONS.CLOSE title="Filter löschen" className={styles.removeFilterIcon} />
              {onSearch && (
                <ICONS.SEARCH
                  title={`Nach ${name} suchen`}
                  className={styles.filterIcon}
                  onClick={handleOnSearch(name)}
                />
              )}
            </div>
          ))}
        {filter.length <
          items.filter((o) => !filter || o.name.toLowerCase().match(filter.value.toLowerCase())).length && (
          <div className={styles.more} onClick={onShowMore}>
            Mehr &hellip;
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export type SearchSidebarHandlers = {
  onToggleOffice: (office: TrademarkOffice[]) => unknown;
  onToggleOwner: (names: string[]) => unknown;
  onToggleRepresentative: (names: string[]) => unknown;
  onToggleNizza: (classes: string[]) => unknown;
  onToggleVienna: (classes: string[]) => unknown;
  onToggleType: (types: TrademarkType[]) => unknown;
  onToggleStatus: (types: TrademarkStatus[]) => unknown;
};

type Props = {
  trademarks: QueryResult;
  handlers: SearchSidebarHandlers;
  filters?: Partial<SearchFilter>;
};

export const SearchSidebar = ({ trademarks: { trademarks, stats }, handlers, filters }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchByTrademarkOwner = (owner?: string) => {
    if (!owner) {
      return;
    }
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        owner: owner,
      }),
    );
  };

  const searchByTrademarkRepresentative = (representative?: string) => {
    if (!representative) {
      return;
    }
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        representative: representative,
      }),
    );
  };

  const addOrRemove = (val: string, arr?: any[]) => {
    let values: string[] = arr || [];

    if (values.includes(val)) {
      values = values.filter((v) => v !== val);
    } else {
      values = [...values, val];
    }

    return values;
  };

  const toggleOffice = (office: string) => {
    console.log("toggleOffice", office);
    handlers.onToggleOffice(addOrRemove(office, filters?.office) as TrademarkOffice[]);
  };

  const toggleRepresentative = (name: string) => {
    console.log("toggleRepresentative", name);
    handlers.onToggleRepresentative(addOrRemove(name, filters?.representative));
  };

  const toggleOwner = (name: string) => {
    console.log("toggleOwner", name);
    handlers.onToggleOwner(addOrRemove(name, filters?.owner));
  };

  const toggleNizza = (name: string) => {
    const selected = name.replace("Nizza ", "");
    console.log("toggleNizza", selected);
    handlers.onToggleNizza(addOrRemove(selected, filters?.niceClass));
  };

  const toggleVienna = (name: string) => {
    console.log("toggleVienna", name);
    handlers.onToggleVienna(addOrRemove(name, filters?.viennaClass));
  };

  const toggleType = (name: string) => {
    console.log("toggleType", name);
    handlers.onToggleType(addOrRemove(toTrademarkType(name), filters?.type) as TrademarkType[]);
  };

  const toggleStatus = (name: string) => {
    console.log("toggleStatus", name);
    handlers.onToggleStatus(addOrRemove(toTrademarkStatus(name), filters?.status) as TrademarkStatus[]);
  };

  const emptyStateContent = (
    <EmptyState
      image={APP_CONST.IMAGES.FILTER}
      text="Hier können Sie Ihre Ergebnisse schnell weiter eingrenzen, indem Sie gezielt Filter miteinander kombinieren."
      //   text="Führen Sie eine Suche aus und erhalten Sie hier eine Übersicht aller Inhaber, Vertreter und eingetragener Klassen
      // der Suchergebnisse."
      smallImage
    ></EmptyState>
  );

  const mainContent = (
    <div className={styles.root}>
      <ToggleList
        headline="Markenamt"
        items={stats.offices.map((o) => ({
          ...o,
          name: localizedOffice(o.name as TrademarkOffice),
          active: Boolean(filters?.office?.includes(o.name as TrademarkOffice)),
        }))}
        onToggleFilter={toggleOffice}
      />
      <ToggleList
        headline="Typ"
        items={stats.types.map((trademark) => ({
          ...trademark,
          name: localizedType(trademark.name as TrademarkType),
          active: Boolean(filters?.type?.includes(trademark.name as TrademarkType)),
        }))}
        onToggleFilter={toggleType}
      />
      <ToggleList
        headline="Status"
        items={stats.statuses.map((s) => ({
          ...s,
          name: localizedStatus(s.name as TrademarkStatus),
          active: Boolean(filters?.status?.includes(s.name as TrademarkStatus)),
        }))}
        onToggleFilter={toggleStatus}
      />
      <ToggleList
        headline="Nizzaklassen"
        items={stats.niceClasses.map((r) => ({
          ...r,
          name: `Nizza ${r.name}`,
          active: Boolean(filters?.niceClass?.includes(r.name)),
        }))}
        showFilter
        onToggleFilter={toggleNizza}
      />
      {stats.viennaClasses.length > 0 ? (
        <ToggleList
          headline="Bildklassen"
          items={stats.viennaClasses
            .map((vc) => ({
              ...vc,
              name: vc.name,
              active: Boolean(filters?.viennaClass?.includes(vc.name)),
            }))
            .sort((a, b) => Number(b.count) - Number(a.count))}
          showFilter
          onToggleFilter={toggleVienna}
        />
      ) : null}
      <ToggleList
        headline={l(k.OWNER)}
        items={stats.owners.map((o) => ({
          ...o,
          active: Boolean(filters?.owner?.includes(o.name)),
        }))}
        showFilter
        onToggleFilter={toggleOwner}
        onSearch={searchByTrademarkOwner}
      />
      <ToggleList
        headline={l(k.REPRESENTATIVE)}
        items={stats.representatives.map((r) => ({
          ...r,
          active: Boolean(filters?.representative?.includes(r.name)),
        }))}
        showFilter
        onToggleFilter={toggleRepresentative}
        onSearch={searchByTrademarkRepresentative}
      />
    </div>
  );

  return (
    <CardContainer
      header={{
        title: l(k.SEARCH_SIDEBAR_TITLE),
        Icon: ICONS.FILTER,
      }}
      className={styles.container}
    >
      {trademarks && trademarks.length > 0 ? mainContent : emptyStateContent}
    </CardContainer>
  );
};
