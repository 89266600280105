import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Toggle } from "app/components/Toggle/Toggle";
import { useAppDarkMode } from "app/hooks/use-app-dark-mode";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import "./toggle-overrides.css";
interface ThemeToggleProps {
  onChange?: (darkMode: boolean) => unknown;
}

export const ThemeToggle = ({ onChange }: ThemeToggleProps) => {
  const darkMode = useAppDarkMode();

  const handleDarkModeChange = (enabled: boolean) => {
    if (enabled) {
      trackEvent(AppEvent.ENABLE_DARKMODE);
      darkMode.enable();
    } else {
      trackEvent(AppEvent.DISABLE_DARKMODE);
      darkMode.disable();
    }

    if (onChange) {
      onChange(enabled);
    }
  };

  return (
    <Toggle
      icons={{
        checked: <ICONS.LIGHT_THEME size={ICON_SIZE.DEFAULT} />,
        unchecked: <ICONS.DARK_THEME size={ICON_SIZE.DEFAULT} />,
      }}
      checked={darkMode.value}
      onChange={handleDarkModeChange}
    />
  );
};
