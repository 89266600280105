import { CardContainer } from "app/components/CardContainer/CardContainer";
import { PreviewCardNavItem } from "app/components/PreviewCardNavItem/PreviewCardNavItem";
import { IconType } from "react-icons";
import styles from "./GridNavigationLayout.module.scss";
interface GridNavigationLayoutProps {
  items?: GridNavigationItemConfig[];
  title?: string;
  titleIcon?: IconType;
  link?: string;
  item?: boolean;
  children?: React.ReactNode;
}

export type GridNavigationItemConfig = {
  path: string;
  title: string;
  createdAt?: Date;
  subtitle?: string;
  Icon: IconType;
  children?: React.ReactNode;
  actions?: React.ReactNode;
  onClick?: () => unknown;
};

export const GridNavigationLayout = ({ items, title, titleIcon, link, item, children }: GridNavigationLayoutProps) => {
  if (!items) {
    return null;
  }

  return (
    <CardContainer
      header={
        title && titleIcon
          ? {
              title: title,
              Icon: titleIcon,
              link: link,
            }
          : undefined
      }
      className={styles.root}
      flex={true}
    >
      <div className={styles.wrapper}>
        {items.map((i) => (
          <div key={i.path} className={styles.item}>
            <PreviewCardNavItem
              path={i.path}
              title={i.title}
              Icon={i.Icon}
              subtitle={i.subtitle}
              children={i.children}
              createdAt={i.createdAt}
              actions={i.actions}
              onClick={i.onClick}
            />
          </div>
        ))}
        {children ? <div className={styles.item}>{children}</div> : null}
      </div>
    </CardContainer>
  );
};
