import { WithNote } from "./with.model";

export enum TrademarkOffice {
  EUIPO = "EUIPO",
  DPMA = "DPMA",
}

export enum TrademarkStatus {
  // Pending
  FILED = "FILED",
  PUBLISHED = "PUBLISHED",
  PENDING = "PENDING",
  UNDER_EXAMINATION = "UNDER_EXAMINATION",
  APPEAL_PENDING = "APPEAL_PENDING",
  OPPOSED = "OPPOSED",
  // Active
  REGISTERED = "REGISTERED",
  // Inactive
  REFUSED = "REFUSED",
  CANCELLATION_PENDING = "CANCELLATION_PENDING",
  WITHDRAWN = "WITHDRAWN",
  SURRENDERED = "SURRENDERED",
  CANCELLED = "CANCELLED",
  DELETED = "DELETED",
  UNKNOWN = "UNKNOWN",
}

export const cancelledTrademarkStatuses = [
  TrademarkStatus.WITHDRAWN,
  TrademarkStatus.SURRENDERED,
  TrademarkStatus.CANCELLED,
  TrademarkStatus.DELETED,
];

export const pendingTrademarkStatuses = [
  TrademarkStatus.FILED,
  TrademarkStatus.PUBLISHED,
  TrademarkStatus.PENDING,
  TrademarkStatus.UNDER_EXAMINATION,
  TrademarkStatus.APPEAL_PENDING,
  TrademarkStatus.OPPOSED,
];

export const activeTrademarkStatuses = Object.values(TrademarkStatus).filter(
  (status) => !cancelledTrademarkStatuses.includes(status),
);

export enum TrademarkType {
  WORD = "WORD",
  COMBINED = "COMBINED",
  FIGURATIVE = "FIGURATIVE",
  SHAPE_3D = "SHAPE_3D",
  COLOUR = "COLOUR",
  ACOUSTIC = "ACOUSTIC",
  SOUND = "SOUND",
  TRACER = "TRACER",
  OTHER = "OTHER",
  HOLOGRAM = "HOLOGRAM",
  MOTION = "MOTION",
  POSITION = "POSITION",
  PATTERN = "PATTERN",
  MULTIMEDIA = "MULTIMEDIA",
  UNKNOWN = "UNKNOWN",
}

export type NiceClass = {
  id: string;
  version: string;
  default: boolean;
  description: string;
};

export type Activity = {
  id: string;
  name: string;
  status: string;
  date?: Date | null;
};

export type Contact = {
  name: string;
  address: {
    postOfficeBox: string;
    street: string;
    city: string;
    country: string;
    postcode: string;
  };
};

export type Trademark = {
  id: string;
  office: TrademarkOffice;
  type: TrademarkType;
  name: string;
  status: TrademarkStatus;
  lastImport: Date;
  lastUpdate: Date;
  owners: Contact[];
  previousOwners: Contact[];
  representatives: Contact[];
  previousRepresentatives: Contact[];
  contact: Contact;
  previousContacts: Contact[];
  granted?: Date | null;
  expired?: Date | null;
  oppositionStart?: Date | null;
  oppositionEnd?: Date | null;
  filed?: Date | null;
  niceClasses: NiceClass[];
  viennaClasses: string[];
  score?: number;
  activities: Activity[];
};

export type TrademarkWithNote = WithNote<Trademark>;

export type Stats = {
  name: string;
  count: number;
};
