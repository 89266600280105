import { useEffect, useState } from "react";

export function useHover(ref: HTMLElement | null): boolean {
  const [value, setValue] = useState(false);
  useEffect(() => {
    const node = ref;
    const handleMouseEnter = () => {
      setValue(true);
    };
    const handleMouseLeave = () => {
      setValue(false);
    };
    if (node) {
      node.addEventListener("mouseenter", handleMouseEnter);
      node.addEventListener("mouseleave", handleMouseLeave);
      return () => {
        node.removeEventListener("mouseenter", handleMouseEnter);
        node.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [ref]);
  return value;
}
