import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { EmptyState, EmptyStateTutorial } from "app/components/EmptyState/EmptyState";
import { Collection, ContextType } from "app/models";
import { useAppDispatch, searchSlice, updateUserContext } from "app/redux";
import { ROUTES } from "app/routes";
import { useNavigate } from "react-router-dom";

type Props = {
  collection: Collection;
};

export const CollectionEmptyState = ({ collection }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const activateCollectionContext = async () => {
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        query: collection.name,
      }),
    );

    dispatch(
      updateUserContext({
        type: ContextType.COLLECTION,
        collectionId: collection.uid,
      }),
    );
  };

  return (
    <EmptyState title="Jetzt Marken hinzufügen" fullWidth noBg>
      <EmptyStateTutorial
        text={`Sie können beliebig viele Marken zu dieser Sammlung hinzufügen. Benutzen Sie einfach die Oktomark Suche und fügen Sie alle relavanten Marken zu Ihrer Sammlung hinzu. Sie können auch ganze Kategorien zu einer Sammlung hinzufügen.`}
        image={APP_CONST.IMAGES.TUTORIAL.COLLECTION}
      />
      <Button onClick={activateCollectionContext}>Jetzt Marken suchen</Button>
    </EmptyState>
  );
};
