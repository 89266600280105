import { ICONS } from "app/assets/icons/icons";
import { Card, CardContent, CardTitle } from "app/components/Card/Card";
import { useBoolToggle } from "app/hooks";
import { Collapse } from "react-collapse";
import styles from "./CollapsibleCard.module.scss";
interface Props {
  className?: string;
  title: string;
  subtitle?: string;
  defaultOpen?: boolean;
  children: React.ReactNode;
}

export const CollapsibleCard = ({ title, subtitle, defaultOpen, children, className }: Props) => {
  const [open, toggleOpen] = useBoolToggle(defaultOpen || false);
  return (
    <Card className={className}>
      <CardTitle
        compact={true}
        onClick={toggleOpen}
        title={title}
        subtitle={subtitle}
        icon={open ? <ICONS.CHEVRON_UP /> : <ICONS.CHEVRON_DOWN />}
      />
      <Collapse isOpened={open}>
        <CardContent className={styles.content}>{children}</CardContent>
      </Collapse>
    </Card>
  );
};
