import { OktomarkBase } from "./oktomark-base.model";
import { Role } from "./role.model";

export enum WorkspaceFlag {
  PAYMENT_CUSTOM = "PAYMENT_CUSTOM",
  PAYMENT_BETA = "PAYMENT_BETA",
  PAYMENT_TEST = "PAYMENT_TEST",
}

export interface UserWithRole {
  userId: string;
  roles: Role[];
}

export enum WorkspaceInvitationStatus {
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
}
export interface WorkspaceInvitation {
  email: string;
  status: WorkspaceInvitationStatus;
  createdAt: Date;
  invitedBy: string;
  secret: string;
  acceptedAt: Date | null;
  rejectedAt: Date | null;
}

export interface WorkspaceContact {
  name?: string;
  address1?: string;
  address2?: string;
  phone?: string;
  email?: string;
}

export type PdfTitleStyle = "default" | "top-half" | "bottom-half";

export interface WorkspaceBranding {
  primaryColor?: string;
  secondaryColor?: string;
  logoUrl?: string;
  logoPngUrl?: string;
  pdfTitleStyle?: PdfTitleStyle;
}

export interface Workspace extends OktomarkBase {
  name: string;
  users: UserWithRole[];
  invitations: WorkspaceInvitation[];
  contact?: WorkspaceContact;
  branding?: WorkspaceBranding;
  flags: WorkspaceFlag[];
  stripeCustomerId?: string;
}

export type PendingInvite = {
  workspaceId: string;
  workspaceName: string;
  inviterName: string;
  invite: WorkspaceInvitation;
};
