import clsx from "clsx";
import { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import styles from "./LoadingIndicator.module.css";

interface Props {
  className?: string;
  message?: string;
  color?: string;
  secondaryColor?: string;
  size?: number;
  hideMessage?: boolean;
}

export const LoadingIndicator = ({
  className,
  hideMessage,
  message,
  color = "#008ffb",
  secondaryColor = "#FFFFFF",
  size = 32,
}: Props) => {
  const [longRunningQuery, setLongRunningQuery] = useState(false);

  useEffect(() => {
    if (!hideMessage) {
      const timeout = setTimeout(() => {
        setLongRunningQuery(true);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [hideMessage]);

  return (
    <div className={clsx(styles.root, className)}>
      <Oval color={color} height={size} width={size} secondaryColor={secondaryColor} />
      {!longRunningQuery && message && !hideMessage ? <div className={styles.message}>{message}</div> : null}
      {longRunningQuery && !hideMessage ? <div className={styles.message}>Einen Moment noch &hellip;</div> : null}
    </div>
  );
};
