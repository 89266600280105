import { CollectionClient } from "app/api";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { SimilarTrademarksHeader } from "app/components/SimilarTrademarksHeader/SimilarTrademarksHeader";
import {
  TrademarkActionConfig,
  TrademarkActionType,
} from "app/components/TrademarkCard/TrademarkActions/TrademarkActions";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { k, l } from "app/i18n";
import { Trademark } from "app/models/trademark.model";
import { addTrademarksToCollection, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { useEffect, useState } from "react";

type CollectionSuggestionsProps = {
  collectionId: string;
};

export const CollectionSuggestions = ({ collectionId }: CollectionSuggestionsProps) => {
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState<Trademark[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    setSuggestions([]);
    CollectionClient.getSuggestions(collectionId)
      .then(setSuggestions)
      .finally(() => setLoading(false));
  }, [collectionId]);

  if (loading) {
    return <LoadingIndicator message={l(k.LOADING_TRADEMARK_LIST_SUGGESTIONS)} />;
  }

  return (
    <>
      <SimilarTrademarksHeader />
      {suggestions.map((suggestion) => {
        const handleAddToThisCollection = async () => {
          try {
            await dispatch(
              addTrademarksToCollection({
                id: collectionId,
                trademarks: [
                  {
                    trademarkId: suggestion.id,
                    trademarkOffice: suggestion.office,
                  },
                ],
              }),
            ).unwrap();
          } catch (error) {
            handleError(`Marke konnte nicht zur Sammlung hinzugefügt werden.`, error);
          }
        };
        const actions = new Map<TrademarkActionType, TrademarkActionConfig>([
          [
            TrademarkActionType.ADD_TO_THIS_COLLECTION,
            {
              enabled: true,
              onClick: handleAddToThisCollection,
            },
          ],
        ]);
        return (
          <TrademarkCard
            actions={actions}
            displayType={TrademarkCardDisplayType.COMPACT}
            key={suggestion.id}
            trademark={suggestion}
            noBg
            isSelectable
          />
        );
      })}
    </>
  );
};
