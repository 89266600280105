import { ICONS } from "app/assets/icons/icons";
import clsx from "clsx";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./ImageUploadArea.module.scss";

interface ImageUploadAreaProps {
  onUpload: (files: File[]) => void;
  text?: string;
  className?: string;
}
export const ImageUploadArea = ({
  onUpload,
  text = "Bild ablegen oder klicken zum auswählen",
  className,
}: ImageUploadAreaProps) => {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      onUpload(acceptedFiles);
    },
    [onUpload],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={clsx(
        styles.root,
        {
          [styles.active]: isDragActive,
        },
        className,
      )}
      title={text}
    >
      <input {...getInputProps()} accept="image/*" />
      <div className={styles.content}>
        <ICONS.CAMERA size={24} />
        <p>{text}</p>
      </div>
    </div>
  );
};
