import { Button } from "app/components/Button/Button";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import clsx from "clsx";
import { useState } from "react";
import { defaultStyles, FileIcon } from "react-file-icon";
import styles from "./ExportModal.module.scss";
interface ExportModalProps {
  open: boolean;
  onClose: () => void;
  onExport?: (type: ExportType) => Promise<void>;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  disabledTypes?: ExportType[];
}

export enum ExportType {
  PDF = "pdf",
  CSV = "csv",
  XLSX = "xlsx",
}

export const ExportModal = ({
  open,
  onClose,
  onExport,
  title,
  subtitle,
  buttonText,
  disabledTypes = [],
}: ExportModalProps) => {
  const [type, setType] = useState<ExportType | null>();

  const enabledTypes = [ExportType.PDF, ExportType.XLSX, ExportType.CSV].filter((t) => !disabledTypes.includes(t));

  const handleExport = async () => {
    if (type && onExport) {
      await onExport(type);
      onClose();
    }
  };

  const options = [
    {
      type: ExportType.PDF,
      icon: <FileIcon extension={"pdf"} {...defaultStyles.pdf} />,
      title: "PDF",
    },
    {
      type: ExportType.XLSX,
      icon: <FileIcon extension={"xlsx"} {...defaultStyles.xlsx} />,
      title: "Excel",
    },
    {
      type: ExportType.CSV,
      icon: <FileIcon extension={"csv"} {...defaultStyles.csv} />,
      title: "CSV",
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={title || "Export"}
      subtitle={subtitle || "Als PDF, Excel oder CSV Datei exportieren"}
    >
      <ModalContent>
        <div className={styles.buttons}>
          {options
            .filter((et) => enabledTypes.includes(et.type))
            .map((et) => (
              <button
                onClick={() => setType(et.type)}
                key={et.type}
                className={clsx(styles.filebutton, {
                  [styles.buttonActive]: type === et.type,
                })}
              >
                <div className={styles.icon}>{et.icon}</div>
                {et.title}
                {type === et.type}
              </button>
            ))}
        </div>
      </ModalContent>
      <ModalActions>
        <Button onClickPromise={handleExport} disabled={!type}>
          {buttonText || "Jetzt exportieren"}
        </Button>
      </ModalActions>
    </Modal>
  );
};
