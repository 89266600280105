import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { FeedClient, FeedFilterParams } from "app/api";
import { QueryResult } from "app/models";

export interface FeedState {
  result: QueryResult;
  loading: boolean;
  moreLoading: boolean;
}

const initialState: FeedState = {
  result: {
    total: 0,
    took: 0,
    limit: 100,
    offset: 0,
    trademarks: [],
    stats: {
      offices: [],
      owners: [],
      representatives: [],
      statuses: [],
      types: [],
      niceClasses: [],
      viennaClasses: [],
      scores: [],
    },
  },
  loading: false,
  moreLoading: false,
};

export const fetchFeed = createAsyncThunk("feed/fetch", FeedClient.fetch);
export const fetchMore = createAsyncThunk("feed/fetchMore", (params: FeedFilterParams) => FeedClient.fetch(params));

const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchFeed.fulfilled, (state, action) => {
        state.result = action.payload;
        state.loading = false;
      })
      .addCase(fetchMore.fulfilled, (state, action) => {
        state.result = { ...action.payload, trademarks: [...state.result.trademarks, ...action.payload.trademarks] };
        state.moreLoading = false;
      })

      // Pending
      .addCase(fetchFeed.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchMore.pending, (state) => {
        state.moreLoading = true;
      })

      // Rejected
      .addCase(fetchFeed.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchMore.rejected, (state) => {
        state.moreLoading = false;
      });
  },
});

export const feedReducer = feedSlice.reducer;
