import { ICON_SIZE } from "app/assets/icons/icons";
import clsx from "clsx";
import { IconType } from "react-icons";
import styles from "./PopoverList.module.scss";

export type PopoverListItem = {
  title: string;
  subtitle?: string;
  Icon?: IconType;
  onClick: () => unknown;
  hidden?: boolean;
  active?: boolean;
};

export type PopoverListProps = {
  items: PopoverListItem[];
  className?: string;
};

export const PopoverList = ({ items, className }: PopoverListProps) => {
  return (
    <ul className={clsx(styles.list, className)}>
      {items
        .filter((i) => !i.hidden)
        .map(({ Icon, title, onClick, active, subtitle }, index) => (
          <li
            key={`${index}_${title}`}
            onClick={onClick}
            className={clsx(styles.listItem, {
              [styles.listItemActive]: active,
            })}
          >
            {Icon ? <Icon className={styles.icon} size={ICON_SIZE.DEFAULT} /> : null}
            <div className={styles.titleWrapper}>
              <div>{title}</div>
              <div className={styles.subtitle}>{subtitle}</div>
            </div>
          </li>
        ))}
    </ul>
  );
};
