import { Button, ButtonTheme } from "app/components/Button/Button";
import { useIsAdmin } from "app/hooks";
import { AppModal, showModal, useAppDispatch } from "app/redux";

type Props = {
  className?: string;
  json?: object | null;
  xml?: string | null;
  xmlFetchHandler?: () => any;
};

export const DebugButton = ({ json, xml, xmlFetchHandler }: Props) => {
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();

  const handleDebug = async () => {
    if (xmlFetchHandler) {
      const fetchedXml = await xmlFetchHandler();
      showModal(dispatch)({
        type: AppModal.DEBUG,
        props: {
          json,
          xml: fetchedXml,
        },
      });
    } else {
      showModal(dispatch)({
        type: AppModal.DEBUG,
        props: {
          json,
          xml,
        },
      });
    }
  };
  return isAdmin ? (
    <Button theme={ButtonTheme.OUTLINE} onClick={handleDebug}>
      Debug
    </Button>
  ) : null;
};
