import { getClient } from "app/api";
import { Research, ResolvedResearch, TrademarkReferenceWithNote } from "app/models";
import { CommentVisibility } from "app/models/trademark-comment.model";
import { TrademarkReference } from "app/models/trademark-reference.model";
import { Trademark } from "app/models/trademark.model";
import { mapResearch } from "app/util/research.util";
import { mapQueryResult, mapTrademark } from "app/util/trademark.util";

const client = getClient({ basePath: "/research" });

export const ResearchClient = {
  fetch: async () => {
    const { data } = await client.get<Research[]>("/");
    return data.map(mapResearch);
  },
  fetchResolved: async (id: string) => {
    const { data } = await client.get<ResolvedResearch>(`/${id}`);
    return {
      ...data,
      research: mapResearch(data.research),
      trademarks: mapQueryResult(data.trademarks),
    };
  },
  create: async (research: { name: string; portfolioId: string; trademarks: TrademarkReferenceWithNote[] }) => {
    const { data } = await client.post<Research>("/", research);
    return mapResearch(data);
  },
  update: async ({ id, research }: { id: string; research: Partial<Pick<Research, "name" | "note">> }) => {
    const { data } = await client.put<Research>(`/${id}`, research);
    return mapResearch(data);
  },
  delete: async (id: string) => {
    const { data } = await client.delete(`/${id}`);
    return data;
  },
  addTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Research>(`/${id}/trademarks/add`, {
      trademarks,
    });
    return mapResearch(data);
  },
  sortTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Research>(`/${id}/trademarks/sort`, {
      trademarks,
    });
    return mapResearch(data);
  },
  removeTrademarks: async ({ id, trademarks }: { id: string; trademarks: TrademarkReference[] }) => {
    const { data } = await client.post<Research>(`/${id}/trademarks/remove`, {
      trademarks,
    });
    return mapResearch(data);
  },
  updateTrademarkNotes: async ({ id, trademarks }: { id: string; trademarks: TrademarkReferenceWithNote[] }) => {
    const { data } = await client.put<Research>(`/${id}/notes`, {
      trademarks,
    });
    return mapResearch(data);
  },
  addComment: async ({ id, comment, visibility }: { id: string; comment: string; visibility: CommentVisibility }) => {
    const { data } = await client.post<Research>(`/${id}/comments`, {
      comment,
      visibility,
    });
    return mapResearch(data);
  },
  updateComment: async ({
    id,
    commentId,
    comment,
    visibility,
  }: {
    id: string;
    commentId: string;
    comment: string;
    visibility: CommentVisibility;
  }) => {
    const { data } = await client.put<Research>(`/${id}/comments/${commentId}`, {
      comment,
      visibility,
    });
    return mapResearch(data);
  },
  deleteComment: async ({ id, commentId }: { id: string; commentId: string }) => {
    const { data } = await client.delete<Research>(`/${id}/comments/${commentId}`);
    return mapResearch(data);
  },
  getSuggestions: async (researchId: string) => {
    const { data } = await client.get<Trademark[]>(`/${researchId}/suggestions`);
    return data.map(mapTrademark);
  },
  download: async (researchId: string, type: "pdf" | "csv" | "xlsx") => {
    return client.get(`/${researchId}/${type}`, {
      responseType: "arraybuffer",
    });
  },
};
