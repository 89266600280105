import { ICONS } from "app/assets/icons/icons";
import styles from "./ExternalLink.module.css";

type Props = React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> & {
  text: string;
};

export const ExternalLink = ({ text, ...props }: Props) => (
  <a {...props} target="_blank" rel="noopener noreferrer">
    {text} <ICONS.EXTERNAL_LINK className={styles.icon} />
  </a>
);
