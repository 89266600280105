import { Badge } from "app/components/Badge/Badge";
import { TrademarkOffice } from "app/models/trademark.model";
import styles from "./TrademarkOfficeBadge.module.css";

type TrademarkOfficeBadgeProps = {
  trademarkOffice: TrademarkOffice;
  noBg?: boolean;
};

const badgeColorByTradeMarkOffice = (trademarkOffice: TrademarkOffice) => {
  switch (trademarkOffice) {
    case TrademarkOffice.EUIPO:
      return styles.euipo;
    case TrademarkOffice.DPMA:
      return styles.dpma;
    default:
      return styles.default;
  }
};

export const TrademarkOfficeBadge = ({ trademarkOffice, noBg }: TrademarkOfficeBadgeProps) => {
  return (
    <Badge noBg={noBg} className={badgeColorByTradeMarkOffice(trademarkOffice)}>
      {trademarkOffice.toUpperCase()}
    </Badge>
  );
};
