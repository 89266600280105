import { Card } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { ViennaClassDescription } from "app/components/ViennaClassDescription/ViennaClassDescription";
import { useFuse } from "app/hooks";
import { k, l } from "app/i18n";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { ViennaClass } from "app/models/vienna-class.model";
import { useAppSelector } from "app/redux/store.hooks";
import Fuse from "fuse.js";
import { useState } from "react";
import styles from "./ClassesView.module.scss";

const fuseOptions: Fuse.IFuseOptions<ViennaClass> = {
  keys: ["viennaId", "text", "notes"],
  threshold: 0.6,
};

export const ViennaClassesView = () => {
  const [search, setSearch] = useState<string>("");
  const items = useAppSelector((state) => state.vienna.items);
  const filteredList = useFuse<ViennaClass>(items, search, { limit: 20 }, fuseOptions);
  const itemsToRender = search ? filteredList.map((fr) => fr.item) : items;
  return (
    <OneColumnLayout>
      <h3 className={styles.headline}>{l(k.VIENNA_CLASSIFICATION)}</h3>
      <p className={styles.subheadline}>{l(k.VIENNA_CLASSIFICATION_DESCRIPTION, String(items.length))}</p>
      <h4>Aktuelle Version: VCL 8</h4>
      <Input className={styles.filter} placeholder="Filter" onTextChange={setSearch} />
      <div className={styles.results}>
        {itemsToRender.map((vc) => (
          <Card className={styles.card}>
            <ViennaClassDescription viennaClass={vc} />
          </Card>
        ))}
      </div>
    </OneColumnLayout>
  );
};
