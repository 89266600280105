import { useEffect } from "react";

export const useCloseEffect = (
  referenceElement: React.RefObject<HTMLElement>,
  onOutsideClick: () => void,
  onInsideClick?: () => void,
) => {
  useEffect(() => {
    const handleClose = (e: MouseEvent) => {
      if (referenceElement && referenceElement !== null) {
        const currentReference = referenceElement.current;
        // console.log("useContainerClick", {
        //   currentReference: currentReference,
        //   target: e.target,
        // });
        if (currentReference) {
          if (currentReference.contains(e.target as Node)) {
            // console.log("inside click");
            if (onInsideClick) {
              onInsideClick();
            }
          } else {
            // console.log("outside click");
            onOutsideClick();
          }
        }
      }
    };

    window.addEventListener("click", handleClose);

    return () => {
      window.removeEventListener("click", handleClose);
    };
  }, [referenceElement, onOutsideClick, onInsideClick]);
};
