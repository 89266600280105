import { apiUrl } from "app/api";
import { trademarkImageUrl } from "app/api/image.api";
import errorImage from "app/assets/error-image.png";
import { Trademark, TrademarkOffice } from "app/models/trademark.model";
import { AppModal, showModal } from "app/redux/slices/modal.slice";
import { useAppDispatch } from "app/redux/store.hooks";
import { hasVisual } from "app/util/trademark.util";
import clsx from "clsx";
import React, { useCallback, useRef, useState } from "react";
import styles from "./TrademarkImage.module.css";

interface TrademarkImageProps {
  // id: string;
  // office: TrademarkOffice;
  thumbnail?: boolean;
  trademark: Trademark;
  className?: string;
}

const imageUrlOrFallback = (trademark: Trademark, thumbnail?: boolean) => {
  if (!hasVisual(trademark.type)) {
    return errorImage;
  }

  return trademarkImageUrl(trademark.id, trademark.office, thumbnail);
};

export const TrademarkImage = ({ trademark, thumbnail, className }: TrademarkImageProps) => {
  const { id, office } = trademark;
  const [image, setImage] = useState<string>(imageUrlOrFallback(trademark, thumbnail));
  const [hasError, setHasError] = useState(false);
  const dispatch = useAppDispatch();

  const imageRef = useRef<HTMLImageElement>(null);

  const showImageModal = (e: React.MouseEvent) => {
    // XXX: Avoid trademark select
    e.stopPropagation();

    if (hasError) {
      return;
    }
    showModal(dispatch)({
      type: AppModal.IMAGE,
      props: {
        trademark,
      },
    });
  };
  const handleImageError = useCallback(() => {
    try {
      // Image is not in AWS cache, use backend URL instead
      if (image.includes("amazonaws")) {
        const imageUrl = `${apiUrl()}/futzi/trademark/image${
          thumbnail || office === TrademarkOffice.EUIPO ? "/thumbnail" : ""
        }?id=${id}&office=${office}`;
        setImage(imageUrl);
        // Image is not in cache and could not be retrieved
      } else {
        console.warn(`${image} did not load`);
        setHasError(true);
      }
    } catch (e) {
      console.log("Error removing broken picture", e);
    }
  }, [image, id, office, thumbnail]);

  return (
    <div
      onClick={showImageModal}
      className={clsx(
        styles.root,
        {
          [styles.hasError]: hasError,
          [styles.nonInteractive]: !hasVisual(trademark.type),
        },
        className,
      )}
    >
      <img
        className={clsx(styles.image)}
        ref={imageRef}
        src={hasError ? errorImage : image}
        alt={`Trademak ${id} in office ${office}`}
        loading="lazy"
        title={hasError ? "Bild konnte nicht geladen werden" : undefined}
        onError={handleImageError}
      />
    </div>
  );
};
