import { ICONS } from "app/assets/icons/icons";
import { AppTextEditor } from "app/components/AppTextEditor/AppTextEditor";
import { Avatar } from "app/components/Avatar/Avatar";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardContent } from "app/components/Card/Card";
import { CardWithActions } from "app/components/CardWithActions/CardWithActions";
import { Popover, PopoverList, PopoverListItem } from "app/components/Popover";
import { useIsAdmin } from "app/hooks";
import { PublicUser, User } from "app/models";
import { TrademarkComment } from "app/models/trademark-comment.model";
import { AppModal, showModal, useAppDispatch, useAppSelector } from "app/redux";
import { localizedTimeDistance } from "app/util/date.util";
import { getAvatarUrl, getName } from "app/util/user.util";
import clsx from "clsx";
import { useState } from "react";
import styles from "./CommentsSection.module.scss";

type CommentHeaderProps = {
  user?: User | null;
  users: PublicUser[];
  comment?: TrademarkComment | null;
  extraText?: string | null;
  showDate?: boolean;
};

const CommentHeader = ({ comment, user, users, extraText, showDate = true }: CommentHeaderProps) => {
  const userId = comment?.userId || user?.userId;

  if (!userId) {
    return null;
  }

  const photoUrl = getAvatarUrl(userId, users);
  const name = getName(userId, users);
  const date = comment?.updatedAt || comment?.createdAt;
  return (
    <>
      <div className={styles.labelWrapper}>
        {user ? (
          <div className={styles.userNameLabel}>
            <Avatar photoURL={photoUrl} size={20} displayName={name} />
            &nbsp;{name}
          </div>
        ) : null}
        {date && showDate ? <div className={styles.dateLabel}>{localizedTimeDistance(date)}</div> : null}
        {extraText ? <div className={styles.extraLabel}>{extraText}</div> : null}
      </div>
    </>
  );
};

type EditableCommentProps = {
  user?: User | null;
  users: PublicUser[];
  comment: TrademarkComment;
  onUpdate?: (id: string, text: string) => Promise<unknown>;
  onDelete?: (id: string) => Promise<unknown>;
};

const EditableComment = ({ user, users, comment, onDelete, onUpdate }: EditableCommentProps) => {
  // const [editMode, setEditMode] = useState(false);
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();
  const canEdit = user?.userId === comment?.userId;

  const showDebugModal = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: {
          comment,
          user,
          users,
        },
      },
    });
  };

  // const edit = () => {
  //   if (canEdit) {
  //     setEditMode(true);
  //   }
  // };

  // const reset = () => {
  //   setEditMode(false);
  // };

  const handleSave = async (text: string) => {
    // console.log("EditableComment save", text);
    if (onUpdate) {
      await onUpdate(comment.uid, text);
      // setEditMode(false);
    }
  };

  const handleDelete = async () => {
    if (onDelete) {
      onDelete(comment.uid);
    }
  };

  const menuItems: PopoverListItem[] = [
    // {
    //   title: "Bearbeiten",
    //   onClick: edit,
    //   Icon: ICONS.EDIT,
    //   hidden: !canEdit || editMode,
    // },
    {
      title: "Löschen",
      onClick: handleDelete,
      Icon: ICONS.DELETE,
      hidden: !onDelete || !canEdit,
    },
    {
      title: "Debug",
      onClick: showDebugModal,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
  ];

  const hasMenu = menuItems.some((i) => !i.hidden);

  const actions = hasMenu ? (
    <Popover>
      <PopoverList items={menuItems} />
    </Popover>
  ) : null;

  return (
    <CardWithActions actions={actions} contentClassName={styles.cardContent}>
      <CommentHeader user={user} users={users} comment={comment} />
      <AppTextEditor defaultValue={comment.comment} onSave={handleSave} disableEdit={!canEdit} />
    </CardWithActions>
  );
};

interface CommentsSectionProps {
  className?: string;
  comments: TrademarkComment[];
  user?: User | null;
  onAddNew: (text: string) => Promise<unknown>;
  onUpdate?: (id: string, text: string) => Promise<unknown>;
  onDelete?: (id: string) => Promise<unknown>;
  onCancel?: () => unknown;
}

export const CommentsSection = ({
  className,
  user,
  comments,
  onAddNew,
  onUpdate,
  onDelete,
  onCancel,
}: CommentsSectionProps) => {
  const users = useAppSelector((state) => state.workspace.users);
  const [showNewComment, setShowNewComment] = useState(false);

  const handleNewComment = async (text: string) => {
    onAddNew(text);
    setShowNewComment(false);
  };

  const showComments = async () => {
    setShowNewComment(true);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setShowNewComment(false);
  };

  const addNew = (
    <div className={styles.row}>
      <div className={styles.indicator}>
        <div className={styles.dot}></div>
      </div>
      <div>
        <Card>
          <CardContent>
            <CommentHeader user={user} users={users} extraText={"Neuer Kommentar"} showDate={false} />
            <AppTextEditor onSave={handleNewComment} onLeaveUnchanged={handleCancel} defaultEditable={true} />
          </CardContent>
        </Card>
      </div>
    </div>
  );

  return (
    <div className={clsx(styles.root, className)}>
      {comments.map((c) => {
        return (
          <div className={styles.row} key={c.uid}>
            <div className={styles.indicator}>
              <div className={styles.dot}></div>
            </div>
            <div>
              <EditableComment onDelete={onDelete} user={user} users={users} comment={c} onUpdate={onUpdate} />
            </div>
          </div>
        );
      })}
      {showNewComment ? (
        addNew
      ) : (
        <Button
          className={clsx(styles.addNewButton, {
            [styles.addNewButtonInset]: comments.length > 0,
          })}
          theme={ButtonTheme.TRANSPARENT}
          onClick={showComments}
        >
          Neuer Kommentar
        </Button>
      )}
    </div>
  );
};
