import { SavedSearch } from "app/models";

const baseUrl = "https://branddb.wipo.int/branddb/en/";

export const buildWipoSearchUrl = (search: Partial<SavedSearch>) => {
  const q = search.query;
  if (!q || q === "") {
    return null;
  }
  return `${baseUrl}?q=${encodeURIComponent(
    JSON.stringify({
      searches: [
        {
          fi: "BRAND",
          te: q.split(" ").join("+"),
        },
      ],
    }),
  )}`;
};
