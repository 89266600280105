import { QueryType } from "app/models";
import clsx from "clsx";
import { debounce } from "lodash-es";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styles from "./FuzzinessSlider.module.scss";
import "./FuzzinessSliderOverrides.css";

interface FuzzinessSliderProps {
  className?: string;
  queryTypes: QueryType[];
  onChange: (queryTypes: QueryType[]) => void;
}

const toQueryType = (type: number) => {
  switch (type) {
    case 0:
      return QueryType.EXACT;
    case 1:
      return QueryType.CASE_INSENITIVE;
    case 2:
      return QueryType.OPTIMIZED_EXACT;
    case 3:
      return QueryType.OPTIMIZED_CASE_INSENSITIVE;
    case 4:
      return QueryType.ANALYZED;
    case 5:
      return QueryType.PHRASE;
    case 6:
      return QueryType.PHRASE_WILDCARD;
    case 7:
      return QueryType.WILDCARD_PHRASE_WILDCARD;
    case 8:
      return QueryType.FUZZY_AND;
    case 9:
      return QueryType.FUZZY;
    case 10:
      return QueryType.OR;
    case 11:
      return QueryType.FUZZY_OR;
    default:
      return QueryType.FUZZY_OR;
  }
};

const toValue = (queryTypes: QueryType[]) => {
  if (queryTypes.includes(QueryType.FUZZY_OR)) {
    return 11;
  } else if (queryTypes.includes(QueryType.OR)) {
    return 10;
  } else if (queryTypes.includes(QueryType.FUZZY)) {
    return 9;
  } else if (queryTypes.includes(QueryType.FUZZY_AND)) {
    return 8;
  } else if (queryTypes.includes(QueryType.WILDCARD_PHRASE_WILDCARD)) {
    return 7;
  } else if (queryTypes.includes(QueryType.PHRASE_WILDCARD)) {
    return 6;
  } else if (queryTypes.includes(QueryType.PHRASE)) {
    return 5;
  } else if (queryTypes.includes(QueryType.ANALYZED)) {
    return 4;
  } else if (queryTypes.includes(QueryType.OPTIMIZED_CASE_INSENSITIVE)) {
    return 3;
  } else if (queryTypes.includes(QueryType.OPTIMIZED_EXACT)) {
    return 2;
  } else if (queryTypes.includes(QueryType.CASE_INSENITIVE)) {
    return 1;
  } else if (queryTypes.includes(QueryType.EXACT)) {
    return 0;
  } else {
    return 11;
  }
};

const MARKS = {
  0: "Exakt",
  1: "5%",
  2: "10%",
  3: "20%",
  4: "30%",
  5: "40%",
  6: "50%",
  7: "60%",
  8: "70%",
  9: "Standard",
  10: "90%",
  11: "100%",
};

export const queryTypesToMarkText = (queryTypes: QueryType[]) => {
  return MARKS[toValue(queryTypes)];
};

const getQueryTypes = (min: number, max: number) => {
  const list = [];
  for (let i = min; i <= max; i++) {
    list.push(toQueryType(i));
  }
  return list;
};

export const FuzzinessSlider = ({ className, queryTypes, onChange }: FuzzinessSliderProps) => {
  const defaultValue = toValue(queryTypes);

  const handleChange = (newMax: number | number[]) => {
    onChange(getQueryTypes(0, Array.isArray(newMax) ? newMax[0] : newMax));
  };

  const debounced = debounce(handleChange, 100);

  return (
    <div className={clsx(styles.root, className)}>
      <Slider
        min={0}
        max={11}
        defaultValue={defaultValue}
        marks={MARKS}
        onAfterChange={debounced}
        railStyle={{ backgroundColor: "var(--theme-color-border)" }}
        trackStyle={{ backgroundColor: "var(--theme-color-primary)" }}
        handleStyle={{
          borderColor: "var(--theme-color-primary)",
          backgroundColor: "var(--theme-color-layer)",
        }}
        dotStyle={{
          backgroundColor: "var(--theme-color-layer)",
          borderColor: "var(--theme-color-border)",
        }}
        activeDotStyle={{
          backgroundColor: "var(--theme-color-layer)",
          borderColor: "var(--theme-color-primary)",
        }}
      />
    </div>
  );
};
