import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Card } from "app/components/Card/Card";
import { IconButton } from "app/components/IconButton/IconButton";
import { notEmpty } from "app/util/filter.util";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import styles from "./TrademarkResultGroup.module.scss";

export enum ResultGroupActionType {
  ADD_TO_CONTEXT = "ADD_TO_CONTEXT",
  ADD_TO_SOMEWHERE = "ADD_TO_SOMEWHERE",
  FLAG_ALL_AS_IGNORED = "FLAG_ALL_AS_IGNORED",
  FLAG_ALL_AS_PROBLEMATIC = "FLAG_ALL_AS_PROBLEMATIC",
  REMOVE_FLAGS = "REMOVE_FLAGS",
}

export interface ResultGroupActionConfig {
  title?: string;
  Icon?: any;
  onClick?: () => Promise<void> | (() => void);
  enabled?: boolean;
}

export type ResultGroupActionConfigMap = Map<ResultGroupActionType, ResultGroupActionConfig>;

type ResultGroupActionsProps = { config?: ResultGroupActionConfigMap };

const ResultGroupActions = ({ config = new Map() }: ResultGroupActionsProps) => {
  const buildAction = (
    type: ResultGroupActionType,
    a: ResultGroupActionConfig,
  ): Required<ResultGroupActionConfig> | null => {
    const baseProps = {
      className: "",
      enabled: true,
      onClick: async () => console.log("onClick", type),
    };
    switch (type) {
      case ResultGroupActionType.ADD_TO_CONTEXT:
        return {
          Icon: ICONS.EMPTY_CHECKBOX,
          title: "Hinzufügen",
          ...baseProps,
          ...a,
        };
      case ResultGroupActionType.FLAG_ALL_AS_PROBLEMATIC:
        return {
          Icon: ICONS.ALERT,
          title: "Alle Marken als problematisch markieren",
          ...baseProps,
          enabled: false,
          ...a,
        };
      case ResultGroupActionType.FLAG_ALL_AS_IGNORED:
        return {
          Icon: ICONS.IGNORE,
          title: "Alle Marken ignorieren",
          ...baseProps,
          enabled: false,
          ...a,
        };
      case ResultGroupActionType.ADD_TO_SOMEWHERE:
        return {
          Icon: ICONS.MENU,
          title: "Marken hinzufügen zu...",
          ...baseProps,
          enabled: false,
          ...a,
        };
      default:
        return null;
    }
  };

  return (
    <div className={styles.actions}>
      {Array.from(config.entries())
        .map(([type, action]) => buildAction(type, action))
        .filter(notEmpty)
        .filter((a) => a.enabled)
        .map(({ onClick, title, Icon }) => (
          <IconButton key={title} onClick={onClick} title={title}>
            <Icon className={styles.actionIcon} size={ICON_SIZE.DEFAULT} />
          </IconButton>
        ))}
    </div>
  );
};

function createActionMapWithOverrides(defaults: ResultGroupActionConfigMap, overrides: ResultGroupActionConfigMap) {
  overrides.forEach((value, key) => {
    defaults.set(key, {
      ...defaults.get(key),
      ...value,
    });
  });

  return defaults;
}

interface Props<T> {
  className?: string;
  title: string;
  subtitle?: string;
  actions?: ResultGroupActionConfigMap;
  variant?: "primary" | "warning" | "danger" | "gray";
  items: T[];
  renderItem: (item: T, i: number) => any;
  toggleCollapsed?: () => unknown;
  children?: never;
  isGrid?: boolean;
  collapsed?: boolean;
  hideHeader?: boolean;
}

const NO_OP = () => null;

const DEFAULT_COLLAPSED = false;

export const TrademarkResultGroup = <T extends unknown>({
  toggleCollapsed = NO_OP,
  subtitle,
  actions = new Map(),
  title,
  items,
  renderItem,
  variant = "primary",
  hideHeader,
  collapsed = DEFAULT_COLLAPSED,
  isGrid,
}: Props<T>) => {
  const [hasOpened, setHasOpened] = useState(false);
  const defaultActions: ResultGroupActionConfigMap = new Map([]);

  useEffect(() => {
    if (!collapsed) {
      setHasOpened(true);
    }
  }, [collapsed]);

  const actionsConfig = createActionMapWithOverrides(defaultActions, actions);
  const hoverTitle = collapsed ? `${title} aufklappen` : `${title} einklappen`;

  return (
    <>
      {hideHeader ? null : (
        <Card
          className={clsx(styles.root, {
            [styles.rootBlue]: variant === "primary",
            [styles.rootRed]: variant === "danger",
            [styles.rootGray]: variant === "gray",
          })}
        >
          <div className={styles.icon} onClick={toggleCollapsed}>
            {collapsed ? (
              <ICONS.CHEVRON_RIGHT size={ICON_SIZE.DEFAULT} title="Liste ausklappen" />
            ) : (
              <ICONS.CHEVRON_DOWN size={ICON_SIZE.DEFAULT} title="Liste einklappen" />
            )}
          </div>
          <div className={styles.titleWrapper} onClick={toggleCollapsed} title={hoverTitle}>
            <div className={styles.title}>{title}</div>
            {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
          </div>
          <ResultGroupActions config={actionsConfig} />
        </Card>
      )}
      {hasOpened && items.length > 0 ? (
        <Collapse isOpened={!collapsed}>
          {isGrid ? (
            <div className={styles.grid}>{items.map((item, i) => renderItem(item, i))}</div>
          ) : (
            items.map((item, i) => renderItem(item, i))
          )}
        </Collapse>
      ) : null}
    </>
  );
};
