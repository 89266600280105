import { APP_CONST } from "app/assets/constants";
import { SortBy, SortOrder } from "app/models";
import { NiceClassType } from "app/models/nice-class.model";
import { QueryResult } from "app/models/query-result.model";
import { TrademarkReference } from "app/models/trademark-reference.model";
import { NiceClass, Trademark, TrademarkType } from "app/models/trademark.model";
import { sortBy } from "lodash-es";
import { toast } from "react-toastify";
import { toDate } from "./date.util";

const trademarkImageTypes = [
  TrademarkType.COMBINED,
  TrademarkType.FIGURATIVE,
  TrademarkType.ACOUSTIC,
  TrademarkType.SHAPE_3D,
];

export const hasVisual = (type: TrademarkType) => {
  return trademarkImageTypes.includes(type);
};

export const mapQueryResult = (queryResult: QueryResult): QueryResult => ({
  ...queryResult,
  trademarks: queryResult.trademarks.map(mapTrademark),
});

export const mapNiceClass = (nc: NiceClass) => {
  const idasNumber = Number(nc.id);
  return {
    classNumber: idasNumber,
    id: nc.id,
    heading: nc.description,
    type: idasNumber < 35 ? NiceClassType.SERVICE : NiceClassType.GOOD,
    intro: null,
    includesParticular: [],
    excludesParticular: [],
  };
};

export const mapTrademark = (trademark: Trademark): Trademark => ({
  ...trademark,
  lastImport: toDate(trademark.lastImport) || new Date(),
  lastUpdate: toDate(trademark.lastUpdate) || new Date(),
  granted: toDate(trademark.granted),
  expired: toDate(trademark.expired),
  oppositionStart: toDate(trademark.oppositionStart),
  oppositionEnd: toDate(trademark.oppositionEnd),
  filed: toDate(trademark.filed),
  activities: trademark.activities.map((activity) => ({
    ...activity,
    date: toDate(activity.date),
  })),
});

export const toTrademarkReference = (trademark: Trademark): TrademarkReference => ({
  trademarkId: trademark.id,
  trademarkOffice: trademark.office,
});

export const toTrademarkReferenceString = ({ trademarkId, trademarkOffice }: TrademarkReference): string =>
  `${trademarkId}/${trademarkOffice}`;

export const toTrademarkIdString = (trademark: Trademark) => {
  return toTrademarkReferenceString(toTrademarkReference(trademark));
};

export const sortTrademarks = (trademarks: Trademark[], by: SortBy, order: SortOrder) => {
  switch (by) {
    case SortBy.RELEVANCE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.score]));
    case SortBy.NAME:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.name?.toLowerCase()]));
    case SortBy.LAST_UPDATE_DATE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.lastUpdate]));
    case SortBy.FILED_DATE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.filed]));
    case SortBy.GRANTED_DATE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.granted]));
    case SortBy.EXPIRY_DATE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.expired]));
    case SortBy.OPPOSITION_START_DATE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.oppositionStart]));
    case SortBy.OPPOSITION_END_DATE:
      return reverse(order)(sortBy(trademarks, [(trademark) => trademark.oppositionEnd]));
    default:
      return trademarks;
  }
};

const reverse = (order: SortOrder) => (trademarks: Trademark[]) =>
  order === SortOrder.DESC ? trademarks.reverse() : trademarks;

export const shareTrademark = async (trademark: Trademark) => {
  const url = APP_CONST.LINKS.TRADEMARK(trademark.id, trademark.office);

  if (navigator.share) {
    navigator.share({
      title: trademark.name ? `${trademark.name} teilen` : "Teilen",
      url: url,
    });
  } else {
    try {
      await navigator.clipboard.writeText(url);
      toast.success("Die URL wurde in Ihre Zwischenablage kopiert.");
    } catch {
      // NoOp
    }
  }
};
