import { PaymentClient, WorkspaceClient } from "app/api";
import { APP_CONST } from "app/assets/constants";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { CheckmarkList } from "app/components/CheckmarkList/CheckmarkList";
import { Input } from "app/components/Input/Input";
import { InviteUser } from "app/components/InviteUser/InviteUser";
import { BillingInformation, Subscription } from "app/models/payment-information.model";
import { bookPremium, useAppDispatch, useAppSelector } from "app/redux";
import { handleUnknownError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { BillingInformationForm } from "app/views/SettingsView/SettingsPagePayment/BillingInformation/BillingInformationForm";
import { PaymentMethodForm } from "app/views/SettingsView/SettingsPagePayment/PaymentMethod/PaymentMethodForm";
import { PricingTable } from "app/views/SettingsView/SettingsPagePayment/PricingTable/PricingTable";
import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { toast } from "react-toastify";
import validate from "validate.js";
import styles from "./UpsellingModal.module.scss";

type Props = {
  open: boolean;
  onClose: () => void;
};

const billingDetailsFormConstraints = {
  name: {
    presence: { allowEmpty: false },
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
  },
  "address.line1": {
    presence: { allowEmpty: false },
  },
  "address.line2": {
    presence: { allowEmpty: true },
  },
  "address.city": {
    presence: { allowEmpty: false },
  },
  "address.state": {
    presence: { allowEmpty: true },
  },
  "address.zip": {
    presence: { allowEmpty: false },
  },
  "address.country": {
    presence: { allowEmpty: false },
  },
};

export const UpsellingModal = ({ open, onClose }: Props) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [inputStep, setInputStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { hasCustomPayment, billingInformation: defaultBillingInformation } = useAppSelector((state) => state.payment);
  const workspace = useAppSelector((state) => state.workspace.workspace);
  const [billingInformation, setBillingInformation] = useState(defaultBillingInformation);
  const [isValid, setIsValid] = useState({
    orgName: false,
    billingInformation: false,
    paymentMethod: false,
  });
  const [subscriptionPreview, setSubscriptionPreview] = useState<Subscription[]>([]);
  const [workspaceName, setWorkspaceName] = useState(workspace?.name || "");
  const [emails, setEmails] = useState<string[]>(["", "", ""]);
  const dispatch = useAppDispatch();
  const hasWorkspace = Boolean(workspace);
  const workspaceUsers = workspace?.users.length;

  useEffect(() => {
    setIsValid((s) => ({
      ...s,
      billingInformation: !!!validate(billingInformation, billingDetailsFormConstraints),
      orgName: Boolean(workspace?.name) || Boolean(workspaceName),
    }));
  }, [billingInformation, workspaceName, workspace?.name]);

  useEffect(() => {
    setBillingInformation(defaultBillingInformation);
  }, [defaultBillingInformation]);

  useEffect(() => {
    PaymentClient.getSubscriptionPreview().then(setSubscriptionPreview);
  }, [workspaceUsers]);

  const handleBillingInformationChange = (update: BillingInformation) => {
    setBillingInformation(update);
    setIsValid((prev) => ({
      ...prev,
      billingInformation: !!!validate(update, billingDetailsFormConstraints),
    }));
  };

  const handlePaymentMethodChange = (valid: boolean) => {
    setIsValid((prev) => ({
      ...prev,
      paymentMethod: valid,
    }));
  };

  const handleMoneyMoneyMoney = async (stripeToken: string) => {
    try {
      setLoading(true);
      await dispatch(bookPremium(stripeToken));

      toast.success("Wir wünschen Ihnen viel Spaß mit Oktomark Premium!");

      handleNext();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    trackEvent(AppEvent.CLOSE_UPSELLING_MODAL);
    onClose();

    setTimeout(() => {
      setInputStep(0);
    }, 500);
  };

  const handleWorkspaceSubmit = async () => {
    try {
      await WorkspaceClient.create(workspaceName);
      // HACK: Do not call handleNext() here because workspace page gets removed
      // and billing information page will be the new current page
      // handleNext();
    } catch (error) {
      handleUnknownError(error);
    }
  };

  const handleCustomPaymentSubmit = async () => {
    try {
      handleNext();
    } catch (error) {
      handleUnknownError(error);
    }
  };

  const handleSubmit = async () => {
    try {
      // HACK: This one is important!
      // We submit the Stripe form with this click, which triggers handleMoneyMoneyMoney
      ref.current?.click();
    } catch (error) {
      handleUnknownError(error);
    }
  };

  const handleInvite = async () => {
    try {
      setLoading(true);
      await Promise.all(emails.filter(Boolean).map(WorkspaceClient.inviteUser));

      toast.success("Die Einladungen wurden erfolgreich versendet.");

      handleClose();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    if (inputStep < pages.length - 1) {
      setInputStep(inputStep + 1);
    }
  };

  const handleBack = () => {
    if (inputStep > 0) {
      setInputStep(inputStep - 1);
    }
  };

  const features = [
    "Erstellen Sie einen Workspace für sich und Ihre Kollegen/Kolleginnen",
    "Führen Sie beliebig viele Recherchen aus und legen Sie beliebig viele Mandanten",
    "Benutzen Sie Ihr eigenes Design für Exporte",
    "Nutzen Sie unseren Premium Support",
  ];

  const pages = [
    {
      title: "Ihr Workspace",
      // description: "Wie möchten Sie Ihren Workspace nennen?",
      content: (
        <>
          <p className={styles.subtitle}>Welchen Namen möchten Sie Ihrem neuen Workspace geben?</p>
          <Input placeholder="Ihre Kanzlei" value={workspaceName} onTextChange={setWorkspaceName} />
          <p className={styles.hint}>Sie können Ihren Workspace jederzeit in den Einstellungen umbennen.</p>
        </>
      ),
      pricingTable: false,
      nextButton: (
        <Button onClick={handleWorkspaceSubmit} disabled={!isValid.orgName}>
          Weiter
        </Button>
      ),
      closeButton: (
        <Button theme={ButtonTheme.TRANSPARENT} onClick={handleClose} disabled={loading}>
          Abbrechen
        </Button>
      ),
      hidden: hasWorkspace,
    },
    {
      title: "Ihre Rechnungsdaten",
      content: (
        <BillingInformationForm billingInformation={billingInformation} onChange={handleBillingInformationChange} />
      ),
      pricingTable: false,
      nextButton: (
        <Button onClick={handleNext} disabled={!isValid.orgName || !isValid.billingInformation}>
          Weiter
        </Button>
      ),
      closeButton: (
        <Button theme={ButtonTheme.TRANSPARENT} onClick={handleBack} disabled={hasWorkspace || loading}>
          Zurück
        </Button>
      ),
      hidden: hasCustomPayment,
    },
    {
      title: "Zahlungsmethode auswählen",
      content: (
        <PaymentMethodForm
          ref={ref}
          billingInformation={billingInformation}
          onSubmit={handleMoneyMoneyMoney}
          onChange={handlePaymentMethodChange}
          valid={isValid.orgName && isValid.paymentMethod && isValid.billingInformation}
          loading={loading}
          setLoading={setLoading}
        />
      ),
      pricingTable: true,
      nextButton: (
        <Button
          onClick={handleSubmit}
          disabled={!isValid.orgName || !isValid.billingInformation || !isValid.paymentMethod}
          loading={loading}
        >
          Kostenpflichtig buchen
        </Button>
      ),
      closeButton: (
        <Button theme={ButtonTheme.TRANSPARENT} onClick={handleBack} disabled={loading}>
          Zurück
        </Button>
      ),
      hidden: hasCustomPayment,
    },
    {
      title: "Workspace einrichten",
      content: (
        <>
          <p>
            <strong>Das Upgrade ist für Sie kostenlos!</strong>
          </p>
          <p>Vielen Dank, dass Sie Oktomark ausprobieren und wir freuen uns auf Ihr Feedback.</p>
          <p>
            Sie erreichen uns jederzeit über den Feedback Button hier im Interface oder per Mail an{" "}
            <a href="mailto:info@oktomark.de" target="_blank" rel="noreferrer">
              info@oktomark.de
            </a>
            .
          </p>
          <p>
            <strong>Wir wünschen Ihnen viel Spaß!</strong>
          </p>
        </>
      ),
      pricingTable: false,
      nextButton: (
        <Button onClick={handleCustomPaymentSubmit} loading={loading}>
          Weiter
        </Button>
      ),
      closeButton: (
        <Button theme={ButtonTheme.TRANSPARENT} onClick={handleBack} disabled={loading}>
          Zurück
        </Button>
      ),
      hidden: !hasCustomPayment,
    },
    {
      title: "Team einladen",
      description: "Laden Sie jetzt Ihre Kollegen und Koweitere Teammitglieder ein. Ihr ",
      content: <InviteUser emails={emails} onChange={setEmails} />,
      pricingTable: false,
      nextButton: (
        <Button onClick={handleInvite} disabled={!emails.some(Boolean)} loading={loading}>
          Einladen
        </Button>
      ),
      closeButton: (
        <Button theme={ButtonTheme.TRANSPARENT} onClick={handleClose} disabled={loading}>
          Später
        </Button>
      ),
      hidden: false,
    },
  ].filter((page) => !page.hidden);

  return (
    <ReactModal
      open={loading || open}
      onClose={handleClose}
      center
      onEscKeyDown={handleClose}
      focusTrapped={false}
      blockScroll={true}
      classNames={{
        modal: styles.modal,
        closeIcon: styles.closeIcon,
        modalContainer: styles.container,
        closeButton: styles.closeButton,
        overlay: styles.overlay,
      }}
    >
      <div className={styles.first}>
        <div className={styles.brand}>
          <img className={styles.brandLogo} src={APP_CONST.IMAGES.OKTO} alt="" />
          <div className={styles.brandName}>Jetzt aktivieren</div>
        </div>
        <div className={styles.textWrapper}>
          <p className={styles.tagline}>
            Aktivieren Sie Ihr Abonnement und benutzen Sie alle Oktomark Features über die Testphase hinaus.
          </p>
          <CheckmarkList items={features} />
          <p className={styles.subline}>
            Sie können Ihr Abonnement jederzeit kündigen und sollten Sie Fragen haben, melden Sie sich einfach bei uns.
          </p>
        </div>
      </div>

      <div className={styles.second}>
        {pages.map(({ title, description, content, pricingTable, closeButton, nextButton }, i) => (
          <div key={title} style={{ display: inputStep === i ? "block" : "none" }}>
            <div className={styles.brand}>
              <div className={styles.upgradeNow}>{title}</div>
            </div>
            <div className={styles.stepper}>
              {pages.map(({ title }, i) => (
                <div
                  key={title}
                  className={clsx(styles.pill, {
                    [styles.pillActive]: inputStep === i,
                  })}
                ></div>
              ))}
            </div>
            <div className={styles.content}>
              {description && <p className={styles.description}>{description}</p>}
              {pricingTable && (
                <div className={styles.pricingTable}>
                  <PricingTable subscriptions={subscriptionPreview} small />
                </div>
              )}
              <div className={styles.form}>{content}</div>
              <div className={styles.buttons}>
                {closeButton}
                {nextButton}
              </div>
            </div>
          </div>
        ))}
      </div>
    </ReactModal>
  );
};
