import { ICONS } from "app/assets/icons/icons";
import wdlIcon from "app/assets/icons/wdl.svg";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { DropdownSelect, DropdownSelectOption } from "app/components/DropdownSelect/DropdownSelect";
import { Popover } from "app/components/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { k, l, localizedStatus, localizedType } from "app/i18n";
import { NiceClass } from "app/models/nice-class.model";
import { QueryResult } from "app/models/query-result.model";
import { Operator, SearchFilter } from "app/models/search.model";
import {
  cancelledTrademarkStatuses,
  pendingTrademarkStatuses,
  TrademarkOffice,
  TrademarkStatus,
  TrademarkType,
} from "app/models/trademark.model";
import { ViennaClass } from "app/models/vienna-class.model";
import { useAppSelector } from "app/redux/store.hooks";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { NizzaDialer } from "app/views/SearchView/SearchFilters/NizzaDialer/NizzaDialer";
import { QuickActionButton } from "app/views/SearchView/SearchQuickActionBar/QuickActionButton/QuickActionButton";
import { useMemo } from "react";
import styles from "./SearchFilters.module.scss";

export enum SearchBy {
  TRADEMARK = "TRADEMARK",
  OWNER = "OWNER",
  REPRESENTATIVE = "REPRESENTATIVE",
}

const officeFilterOptions = Object.keys(TrademarkOffice)
  .map((office) => office as TrademarkOffice)
  .map((office) => ({
    id: office,
    name: office,
  }));

const toTypeFilterOption = (t: TrademarkType) => ({
  id: t,
  name: localizedType(t),
});

const toStatusFilterOption = (s: TrademarkStatus) => ({
  id: s,
  name: localizedStatus(s),
});

const toViennaFilterOption = (available: string[]) => (i: ViennaClass) => ({
  id: i.viennaId,
  name: `${i.viennaId} (${i.text})`,
  disabled: available.length > 0 && !available.includes(i.viennaId),
});
const toNiceFilterOption = (available: number[]) => (i: NiceClass) => ({
  id: String(i.classNumber),
  name: `${i.classNumber} (${i.heading})`,
  disabled: available.length > 0 && !available.includes(i.classNumber),
});

const typeFilterOptions = Object.keys(TrademarkType)
  .map((s) => s as TrademarkType)
  .map(toTypeFilterOption);

const statusFilterOptions = Object.keys(TrademarkStatus)
  .map((s) => s as TrademarkStatus)
  .map(toStatusFilterOption);

export type SearchFilerChangeHandlers = {
  onClear: () => unknown;
  onOfficeChange: (o: TrademarkOffice[]) => unknown;
  onOwnerChange: (n: string[]) => unknown;
  onRepresentativeChange: (n: string[]) => unknown;
  onNiceChange: (n: string[]) => unknown;
  onNiceOperatorChange: (o: Operator) => unknown;
  onViennaChange: (n: string[]) => unknown;
  onViennaOperatorChange: (o: Operator) => unknown;
  onTypeChange: (o: TrademarkType[]) => unknown;
  onStatusChange: (o: TrademarkStatus[]) => unknown;
};
interface SearchFiltersProps {
  queryResult: QueryResult;
  filters?: Partial<SearchFilter>;
  handlers: SearchFilerChangeHandlers;
  disabledViews?: SearchFilterView[];
}

export enum SearchFilterView {
  FILTERS = "filters",
  NIZZA = "nizza",
  IMAGE_SEARCH = "image-search",
  MAP = "map",
}

export const SearchFilters = ({ filters, handlers, queryResult }: SearchFiltersProps) => {
  const viennaItems = useAppSelector((state) => state.vienna.items);
  const { result } = useAppSelector((state) => state.search);
  const nizzaClasses = useAppSelector((state) => state.nizza.items);

  const viennaTagOptions = useMemo(
    () => viennaItems.map(toViennaFilterOption(result.stats.viennaClasses.map((n) => n.name))),
    [viennaItems, result.stats.viennaClasses],
  );
  const niceTagOptions = useMemo(
    () => nizzaClasses.map(toNiceFilterOption(result.stats.niceClasses.map((n) => Number(n.name)))),
    [nizzaClasses, result.stats.niceClasses],
  );
  const selectedTypes = (filters?.type || []).map(toTypeFilterOption);
  const selectedStatusOptions = (filters?.status || []).map(toStatusFilterOption);
  const selectedViennaClasses = viennaTagOptions.filter((o) => (filters?.viennaClass || []).includes(o.id));
  const selectedNiceClasses = niceTagOptions.filter((o) => (filters?.niceClass || []).includes(o.id));
  const selectedOffices = (filters?.office || []).map((office) => ({
    id: office,
    name: office,
  }));

  const selectedOwners = (filters?.owner || []).map((o) => ({
    id: o,
    name: o,
  }));

  const selectedRepresentatives = (filters?.representative || []).map((o) => ({
    id: o,
    name: o,
  }));

  const ownersFilterOptions = queryResult.stats.owners.map((o) => ({
    id: o.name,
    name: o.name,
  }));
  const representativeFilterOptions = queryResult.stats.representatives.map((o) => ({
    id: o.name,
    name: o.name,
  }));

  const filterCount =
    selectedTypes.length +
    selectedStatusOptions.length +
    selectedViennaClasses.length +
    selectedNiceClasses.length +
    selectedOffices.length +
    selectedOwners.length +
    selectedRepresentatives.length;

  const hasFilter = filterCount > 0;

  const handleTrademarkOfficeFilterChange = (selected: DropdownSelectOption[]) => {
    handlers.onOfficeChange(selected.map((s) => s.id as TrademarkOffice));
  };

  const handleNiceClassChange = (selected: DropdownSelectOption[]) => {
    handlers.onNiceChange(selected.map((s) => s.id));
  };

  const handleViennaClassesFilterChange = (selected: DropdownSelectOption[]) => {
    handlers.onViennaChange(selected.map((s) => s.id));
  };

  const handleTrademarkTypeFilterChange = (selected: DropdownSelectOption[]) => {
    handlers.onTypeChange(selected.map((s) => s.id as TrademarkType));
  };

  const handleTrademarkStatusFilterChange = (selected: DropdownSelectOption[]) => {
    handlers.onStatusChange(selected.map((s) => s.id as TrademarkStatus));
  };

  const handleRepresentativesFilterChange = (selected: DropdownSelectOption[]) => {
    handlers.onRepresentativeChange(selected.map((s) => s.name));
  };

  const handleOwnerFilterChange = (selected: DropdownSelectOption[]) => {
    handlers.onOwnerChange(selected.map((s) => s.name));
  };

  const handleFilterClear = () => {
    handlers.onClear();
  };

  const filtersView = (
    <PopoverContent className={styles.filterWrapper}>
      <div className={styles.dropdowns}>
        <div className={styles.headline}>Filter</div>
        <DropdownSelect
          selected={selectedNiceClasses}
          placeholder="Nizza-Klassen"
          multi
          searchable
          options={niceTagOptions}
          onSelect={handleNiceClassChange}
        />
        <DropdownSelect
          selected={selectedViennaClasses}
          placeholder="Wiener-Klassen"
          multi
          searchable
          options={viennaTagOptions}
          onSelect={handleViennaClassesFilterChange}
        />
        <DropdownSelect
          selected={selectedOffices}
          placeholder="Markenamt"
          multi
          searchable
          options={officeFilterOptions}
          onSelect={handleTrademarkOfficeFilterChange}
        />
        <DropdownSelect
          selected={selectedTypes}
          placeholder="Typ"
          multi
          searchable
          options={typeFilterOptions}
          onSelect={handleTrademarkTypeFilterChange}
        />
        <DropdownSelect
          selected={selectedStatusOptions}
          placeholder="Status"
          multi
          searchable
          options={statusFilterOptions}
          onSelect={handleTrademarkStatusFilterChange}
        />
        <DropdownSelect
          selected={selectedOwners}
          placeholder={l(k.OWNER)}
          multi
          searchable
          options={ownersFilterOptions}
          onSelect={handleOwnerFilterChange}
        />
        <DropdownSelect
          selected={selectedRepresentatives}
          placeholder={l(k.REPRESENTATIVE)}
          multi
          searchable
          options={representativeFilterOptions}
          onSelect={handleRepresentativesFilterChange}
        />
        <Button
          className={styles.clearButton}
          onClick={handleFilterClear}
          theme={ButtonTheme.LINK}
          disabled={!hasFilter}
        >
          Alle Filter löschen
        </Button>
      </div>
      <div className={styles.quickSelects}>
        <div className={styles.headline}>Häufig benutzt</div>
        {/* <p className={styles.subheadline}>Wir haben die beliebtesten Filter für Sie zusammengefasst.</p> */}
        <div className={styles.speedDial}>
          <div className={styles.speedDialGroup}>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onOfficeChange([TrademarkOffice.DPMA]);
              }}
              theme={ButtonTheme.LINK}
            >
              Nur DPMA Marken
            </Button>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onOfficeChange([TrademarkOffice.EUIPO]);
              }}
              theme={ButtonTheme.LINK}
            >
              Nur EUIPO Marken
            </Button>
          </div>
          <div className={styles.speedDialGroup}>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onStatusChange(Object.values(TrademarkStatus));
              }}
              theme={ButtonTheme.LINK}
            >
              Alle Marken
            </Button>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onStatusChange(pendingTrademarkStatuses);
              }}
              theme={ButtonTheme.LINK}
            >
              Anhängige Marken
            </Button>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onStatusChange(cancelledTrademarkStatuses);
              }}
              theme={ButtonTheme.LINK}
            >
              Inaktive Marken
            </Button>
          </div>
          <div className={styles.speedDialGroup}>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onTypeChange([TrademarkType.WORD]);
              }}
              theme={ButtonTheme.LINK}
            >
              Nur Wortmarken
            </Button>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onTypeChange([TrademarkType.FIGURATIVE]);
              }}
              theme={ButtonTheme.LINK}
            >
              Nur Bildmarken
            </Button>
            <Button
              className={styles.speedDialButton}
              onClick={() => {
                handlers.onTypeChange([TrademarkType.COMBINED]);
              }}
              theme={ButtonTheme.LINK}
            >
              Nur Wort-/Bildmarken
            </Button>
          </div>
        </div>
      </div>
    </PopoverContent>
  );

  const selectedNizza = filters?.niceClass || [];

  const handleToggleNiceClass = (id: string) => {
    if (selectedNizza.includes(id)) {
      handlers.onNiceChange(selectedNizza.filter((s) => s !== id));
    } else {
      handlers.onNiceChange([...selectedNizza, id]);
    }
  };

  const handleResetNiceClass = () => {
    handlers.onNiceChange([]);
  };

  const nizzaTitle = selectedNizza.length > 0 ? `${selectedNizza.join(", ")}` : "Klassen";

  const WDLICon = (props: any) => {
    return <img src={wdlIcon} alt="" {...props} />;
  };

  return (
    <>
      <Popover
        closeOnInteraction={false}
        placement="bottom"
        onOpen={() => trackEvent(AppEvent.OPEN_NIZZA_DIALER)}
        buttonElement={() => <QuickActionButton title={nizzaTitle} Icon={WDLICon} className="tutorial-search-nizza" />}
      >
        <PopoverContent>
          <NizzaDialer
            operator={filters?.niceClassOperator || Operator.OR}
            selected={selectedNiceClasses.map(({ id }) => id)}
            onToggle={handleToggleNiceClass}
            onOperatorChange={handlers.onNiceOperatorChange}
            onReset={handleResetNiceClass}
            available={queryResult.stats.niceClasses.map((n) => Number(n.name))}
          />
        </PopoverContent>
      </Popover>
      <Popover
        closeOnInteraction={false}
        placement="bottom"
        onOpen={() => trackEvent(AppEvent.OPEN_FILTERS)}
        buttonElement={() => (
          <QuickActionButton
            title={hasFilter ? `${filterCount} Filter` : "Filter"}
            Icon={ICONS.FILTER}
            className="tutorial-search-filter"
          />
        )}
      >
        {filtersView}
      </Popover>
    </>
  );
};
