import { ICONS } from "app/assets/icons/icons";
import { Card, CardContentSection } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EditableTextField } from "app/components/EditableTextField/EditableTextField";
import { PopoverListItem } from "app/components/Popover";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { useIsAdmin } from "app/hooks";
import { pluralize } from "app/i18n/i18n.util";
import { Collection } from "app/models";
import { Trademark } from "app/models/trademark.model";
import { useAppSelector } from "app/redux/store.hooks";
import { localizedTimeDistance } from "app/util/date.util";
import { onlyUnique } from "app/util/filter.util";
import { CollectionComments } from "app/views/CollectionsView/CollectionComments/CollectionComments";
import { useMemo } from "react";
import styles from "./CollectionHeader.module.scss";

type Props = {
  collection: Collection;
  trademarks: Trademark[];
  onExport: () => void;
  onRename: () => void;
  onDelete: () => void;
  onDebug: () => void;
  onSaveNote: (note: string) => Promise<void>;
};

export const CollectionHeader = ({
  collection,
  trademarks,
  onExport,
  onRename,
  onDelete,
  onDebug,
  onSaveNote,
}: Props) => {
  const isAdmin = useIsAdmin();
  const collectionUser = useAppSelector((state) =>
    state.auth.user?.userId === collection.userId
      ? state.auth.user
      : state.workspace.users.find((u) => u.userId === collection?.userId),
  );

  const allNizza = useMemo(
    () =>
      trademarks
        .map((tm) => tm.niceClasses.map((n) => n.id))
        .flat()
        .filter(onlyUnique)
        .map(Number)
        .sort((a, b) => a - b)
        .join(", "),
    [trademarks],
  );

  let menuItems: PopoverListItem[] = [
    {
      Icon: ICONS.DOWNLOAD,
      title: `Sammlung exportieren`,
      onClick: onExport,
    },
    {
      Icon: ICONS.EDIT,
      title: "Umbenennen",
      onClick: onRename,
    },
    {
      Icon: ICONS.DELETE,
      title: `Löschen`,
      onClick: onDelete,
    },
    {
      title: "Debug",
      onClick: onDebug,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
  ];

  return (
    <CardContainer
      className={styles.root}
      header={{
        title: collection.name,
        Icon: ICONS.COLLECTION,
        actions: menuItems,
      }}
    >
      <div className={styles.datawrapper}>
        <Card className={styles.header}>
          <CardContentSection title={"Information"}>
            {collection.createdAt ? (
              <div className={styles.listSubTitle}>
                Erstellt {localizedTimeDistance(collection.createdAt)} von {collectionUser?.name || "Unbekannt"}
              </div>
            ) : null}
            {trademarks.length > 0 ? (
              <div className={styles.listSubTitle}>{pluralize("trademarks", trademarks.length)} </div>
            ) : null}
          </CardContentSection>
          {trademarks.length > 0 ? (
            <CardContentSection title={"Nizza-Klassen"}>
              <div className={styles.listSubTitle}>{allNizza}</div>
            </CardContentSection>
          ) : null}
        </Card>
        <div className={styles.header}>
          <Card>
            <CardContentSection title={"Notiz"}>
              <EditableTextField text={collection.note} onSave={onSaveNote} />
            </CardContentSection>
          </Card>
          <RenderIfTestAccount>
            <CollectionComments collection={collection} />
          </RenderIfTestAccount>
        </div>
      </div>
    </CardContainer>
  );
};
