import { createAnimatedRoute } from "app/animated-route";
import portfolioImage from "app/assets/collections.svg";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { Card } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { PortfolioEmptyState } from "app/components/PortfolioEmptyState/PortfolioEmptyState";
import { k, l } from "app/i18n";
import { pluralize } from "app/i18n/i18n.util";
import { GridNavigationItemConfig, GridNavigationLayout } from "app/layouts/GridLayout/GridNavigationLayout";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { AppModal, showModal } from "app/redux";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { withNestedRoutes } from "app/routes";
import clsx from "clsx";
import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { PortfolioDetail } from "./PortfolioDetail/PortfolioDetail";
import { PortfolioHelp } from "./PortfolioDetail/PortfolioHelp/PortfolioHelp";
import styles from "./PortfoliosView.module.scss";

export const PortfoliosView = () => {
  const { items, loading } = useAppSelector((state) => state.portfolio);
  const dispatch = useAppDispatch();

  const portfolios: GridNavigationItemConfig[] = useMemo(
    () =>
      items
        .map((l) => ({
          path: l.uid,
          title: l.name,
          Icon: ICONS.PORTFOLIO,
          subtitle: `${pluralize("trademarks", l.trademarks?.length || 0)}`,
          createdAt: l.createdAt,
          children: null,
        }))
        .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
    [items],
  );

  const showCreatePortfolioModal = () => {
    showModal(dispatch)({
      type: AppModal.CREATE_PORTFOLIO,
      props: {},
    });
  };

  const indexRouteElement =
    portfolios.length > 0 ? (
      <>
        <GridNavigationLayout title={l(k.MY_PORTFOLIOS)} titleIcon={ICONS.PORTFOLIO} items={portfolios}>
          <Card className={clsx("tutorial-create-portfolio", styles.addCard)} onClick={showCreatePortfolioModal}>
            <ICONS.ADD size={ICON_SIZE.LARGE} />
            <span>Neuen Mandanten anlegen</span>
          </Card>
        </GridNavigationLayout>
        <CardContainer>
          {portfolios.length === 0 ? <PortfoliosEmptyState onNewPortfolio={showCreatePortfolioModal} /> : null}
          <PortfolioHelp />
        </CardContainer>
      </>
    ) : (
      <CardContainer className={styles.emptyState}>
        <PortfolioEmptyState />
      </CardContainer>
    );

  const animatedRoutes = [
    {
      path: withNestedRoutes(":portfolioId"),
      element: <PortfolioDetail />,
    },
  ];

  return (
    <OneColumnLayout>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <Routes>
          {/* Index Route is animated by parent router */}
          <Route path="" element={indexRouteElement} />
          {animatedRoutes.map(createAnimatedRoute)}
        </Routes>
      )}
    </OneColumnLayout>
  );
};

const PortfoliosEmptyState = ({ onNewPortfolio }: { onNewPortfolio: () => void }) => {
  return (
    <EmptyState
      className={styles.empty}
      title={l(k.MY_PORTFOLIOS)}
      text={l(k.PORTFOLIO_EMPTYSTATE_TEXT)}
      image={portfolioImage}
      fullWidth
    >
      <Button shadow className={clsx("tutorial-create-portfolio", styles.addButton)} onClick={onNewPortfolio}>
        Neuen Mandanten anlegen
      </Button>
    </EmptyState>
  );
};
