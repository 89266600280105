import { AdminClient } from "app/api";
import { Collection, Monitoring, Portfolio, Research, SavedSearch } from "app/models";
import { UserEvent, UserMetric } from "app/models/user-event-log.model";
import { User } from "app/models/user.model";
import { Workspace } from "app/models/workspace.model";
import { useEffect, useState } from "react";

export type AdminUserData = User & {
  lastSession: any;
  workspace?: Workspace | null;
};

export function useAccounts() {
  const [users, setUsers] = useState<AdminUserData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [u, sessions, workspaces] = await Promise.all([
        AdminClient.getUsers(),
        AdminClient.getLatestSessions(),
        AdminClient.getWorkspaces(),
      ]);
      setWorkspaces(workspaces);
      setUsers(
        u.map((user) => {
          const lastSession = sessions
            .filter((s) => s[0] === user.userId && s.length > 1)
            .map((s) => s[1])
            .find(Boolean);
          return {
            ...user,
            lastSession: lastSession?.lastRefresh || null,
            workspace: workspaces.find((o) => o.users.some((u) => u.userId === user.userId)),
          };
        }),
      );
    } catch (error) {
      console.log("Error fetching users", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return { users, loading, workspaces, fetchUsers: fetchData };
}

export type AdminSecrets = {
  bullBoardUrl: string | null;
};

export function useAdminSecrets() {
  const [secrets, setSecrets] = useState<AdminSecrets>({
    bullBoardUrl: null,
  });

  useEffect(() => {
    AdminClient.getSecrets().then((res) => {
      setSecrets((s) => ({
        ...s,
        ...res,
      }));
    });
  }, []);

  return { secrets };
}

export function useUserEventLog(userId: string) {
  const [items, setItems] = useState<UserEvent[]>([]);

  useEffect(() => {
    AdminClient.getUserEventLog(userId).then((items) => {
      setItems(items.sort((a, b) => (b.createdAt?.getTime() || 0) - (a.createdAt?.getTime() || 0)).filter(Boolean));
    });
  }, [userId]);

  return items;
}

export function useUserMails(userId: string) {
  const [items, setItems] = useState<any[]>([]);

  const fetch = () => {
    AdminClient.getUserMails(userId).then((items) => {
      setItems(items);
    });
  };

  useEffect(fetch, [userId]);

  return { items, fetch };
}

export function useUserMetrics(userId: string) {
  const [items, setItems] = useState<UserMetric[]>([]);

  useEffect(() => {
    AdminClient.getUserMetrics(userId).then((items) => {
      setItems(items.sort((a, b) => (b.createdAt?.getTime() || 0) - (a.createdAt?.getTime() || 0)).filter(Boolean));
    });
  }, [userId]);

  return items;
}

export function useMonitorings(userId: string) {
  const [monitorings, setMonitorings] = useState<Monitoring[]>([]);

  useEffect(() => {
    AdminClient.getMonitorings(userId).then(setMonitorings);
  }, [userId]);

  return monitorings;
}

export function useCollections(userId: string) {
  const [collections, setCollections] = useState<Collection[]>([]);

  useEffect(() => {
    AdminClient.getCollections(userId).then(setCollections);
  }, [userId]);

  return collections;
}

export function usePortfolios(userId: string) {
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);

  useEffect(() => {
    AdminClient.getPortfolios(userId).then(setPortfolios);
  }, [userId]);

  return portfolios;
}

export function useResearches(userId: string) {
  const [researches, setResearches] = useState<Research[]>([]);

  useEffect(() => {
    AdminClient.getResearches(userId).then(setResearches);
  }, [userId]);

  return researches;
}

export function useSavedSearches(userId: string) {
  const [savedSearches, setSavedSearches] = useState<SavedSearch[]>([]);

  useEffect(() => {
    AdminClient.getSavedSearches(userId).then(setSavedSearches);
  }, [userId]);

  return savedSearches;
}
