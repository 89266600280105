import { Collection } from "app/models";
import { toDate } from "./date.util";

export const mapCollection = (collection: Collection): Collection => ({
  ...collection,
  createdAt: toDate(collection.createdAt),
  updatedAt: toDate(collection.updatedAt),
  comments:
    collection.comments?.map((comment) => ({
      ...comment,
      createdAt: toDate(comment.createdAt),
      updatedAt: toDate(comment.updatedAt),
    })) || [],
});
