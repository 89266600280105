import { AppTextEditor } from "app/components/AppTextEditor/AppTextEditor";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { ImagePicker } from "app/components/ImagePicker/ImagePicker";
import { Input } from "app/components/Input/Input";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { useAppForm } from "app/hooks";
import { Partner } from "app/models";
import { updatePartner, updatePartnerImage, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { omit } from "lodash-es";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";

type Props = {
  partner: Partner;
};

type FormData = Partner & { file?: File };

export const PartnerIntroSettings = ({ partner }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const defaultFormValues = getDefaultFormData(partner);

  const { handleSubmit, register, isValid, isDirty, setValue, reset, control } = useAppForm<FormData>({
    defaultValues: defaultFormValues,
  });

  const handleResetForm = useCallback(() => {
    reset(getDefaultFormData(partner));
  }, [partner, reset]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm, partner]);

  const handleUpdate = async (data: FormData) => {
    if (!partner?.uid) {
      return;
    }
    if (!isValid || !isDirty) {
      return;
    }
    setLoading(true);
    try {
      trackEvent(AppEvent.UPDATE_PARTNER, data);
      if (data.file) {
        await dispatch(
          updatePartnerImage({
            id: partner.uid,
            blob: data.file,
            key: "image",
          }),
        ).unwrap();
      }

      // XXX: When new file has been uploaded we omit some fields to avoid saving old values
      const omits = ["file"];
      if (data.file) {
        omits.push("image");
      }
      await dispatch(updatePartner({ id: partner.uid, partner: omit(data, omits) })).unwrap();
      setValue("file", undefined);
    } catch (error) {
      handleError("Partnerseite konnte nicht aktualisiert werden.", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTestData = async () => {
    await reset(getTestData(partner));
    setValue("name", "Oktomark", { shouldDirty: true });
  };

  return (
    <Card item>
      <CardTitle title={`Intro`} subtitle="Gestalten Sie Ihr Intro und stellen Sie sich Ihren Mandanten vor" />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <CardContent>
          <CardContentSection
            title="Ihr Name"
            description="Geben Sie Ihren Kanzleinamen für die Überschrift an. Ihren offiziellen Namen mit Rechtsform können Sie im Abschnitt Kontaktdetails hinterlegen."
          >
            <Input type="text" placeholder="Beispiel Kanzlei" {...register("name")} />
          </CardContentSection>
          <CardContentSection
            title="Ihre Überschrift"
            description="Hier können Sie kreativ werden und Ihre eigene Überschrift eingeben."
          >
            <Input type="text" placeholder="Wir haben ein Herz für neue Ideen" {...register("tagline")} />
          </CardContentSection>
          <CardContentSection
            title="Titelbild"
            description="Hinterlegen Sie ein Titelbild für Ihre Partnerseite. Zum Beispiel Ihr Logo oder ein Teamfoto."
          >
            <Controller
              name={"image"}
              control={control}
              render={({ field }) => (
                <Controller
                  name="file"
                  control={control}
                  render={({ field: field2 }) => (
                    <ImagePicker url={field.value} file={field2.value} onUpload={field2.onChange} />
                  )}
                />
              )}
            />
            <Controller
              name={"image"}
              control={control}
              render={({ field }) =>
                field.value ? (
                  <Button theme={ButtonTheme.LINK} onClick={() => field.onChange("")}>
                    Löschen
                  </Button>
                ) : (
                  <div></div>
                )
              }
            />
          </CardContentSection>
          <CardContentSection title="Intro" description="Stellen Sie sich kurz vor und beschreiben Sie Ihre Kanzlei.">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <AppTextEditor
                  autofocus={false}
                  forceEditable={true}
                  hideActions={true}
                  defaultValue={field.value}
                  onSave={async (html) => {
                    let text = html;
                    if (html === "<p></p>") {
                      text = "";
                    }
                    field.onChange(text);
                  }}
                />
              )}
            />
          </CardContentSection>
        </CardContent>
        <CardActions>
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty}>
            Intro aktualieren
          </Button>
          <Button type="button" onClick={handleResetForm} theme={ButtonTheme.LINK} disabled={loading || !isDirty}>
            Abbrechen
          </Button>
          <RenderIfTestAccount>
            <Button type="button" onClick={handleTestData} theme={ButtonTheme.LINK} disabled={loading}>
              Vorausfüllen
            </Button>
          </RenderIfTestAccount>
        </CardActions>
      </form>
    </Card>
  );
};

function getDefaultFormData(partner: Partner) {
  return {
    name: partner.name,
    description: partner.description,
    tagline: partner.tagline,
    logo: partner.logo,
    image: partner.image,
    video: partner.video,
  };
}

function getTestData(partner: Partner) {
  return {
    ...getDefaultFormData(partner),
    name: partner.name || "Beispiel Kanzlei",
    tagline: partner.tagline || "",
    description:
      partner.description ||
      "<p>Du willst deine Marke selbst beim DPMA oder EUIPO anmelden? Unser Team unterstützt dich dabei. Melde dich einfach kostenlos bei Oktomark an und erkläre uns kurz, wobei du Hilfe brauchst.</p><ul><li><p>Unser Team hilft dir, so gut es geht und hilft dir auch den richtigen Partner zu finden</p></li><li><p>Nutze die intelligente Oktomark Suche für deine Recherchen</p></li><li><p>Wir unterstützten dich beim Erstellen deines Waren- und Dienstleistungsverzeichnis</p></li></ul>",
  };
}
