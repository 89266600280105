import { NoAuthLayout } from "app/layouts/NoAuthLayout/NoAuthLayout";
import { NO_AUTH_ROUTES } from "app/routes";
import { AcceptInvitationCard } from "app/views/NoAuthView/AcceptInvitationCard/AcceptInvitationCard";
import { ForgotPasswordCard } from "app/views/NoAuthView/ForgotPasswordCard/ForgotPasswordCard";
import { LoginCard } from "app/views/NoAuthView/LoginCard/LoginCard";
import { LogoutCard } from "app/views/NoAuthView/LogoutCard/LogoutCard";
import { ResetEmailCard } from "app/views/NoAuthView/ResetEmailCard/ResetEmailCard";
import { ResetPasswordCard } from "app/views/NoAuthView/ResetPasswordCard/ResetPasswordCard";
import { SignupCard } from "app/views/NoAuthView/SignupCard/SignupCard";
import { SignupPendingCard } from "app/views/NoAuthView/SignupPendingCard/SignupPendingCard";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";

export const NoAuthView = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const cta =
    pathname === NO_AUTH_ROUTES.LOGIN
      ? {
          text: "Kostenlos anmelden",
          action: () => navigate(NO_AUTH_ROUTES.SIGNUP),
        }
      : {
          text: "Zum Login",
          action: () => navigate(NO_AUTH_ROUTES.LOGIN),
        };

  return (
    <NoAuthLayout cta={cta}>
      <Routes>
        <Route path={NO_AUTH_ROUTES.LOGIN} element={<LoginCard />} />
        <Route path={NO_AUTH_ROUTES.RESET_PASSWORD} element={<ResetPasswordCard />} />
        <Route path={NO_AUTH_ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordCard />} />
        <Route path={NO_AUTH_ROUTES.SIGNUP} element={<SignupCard />} />
        <Route path={NO_AUTH_ROUTES.SIGNUP_PENDING} element={<SignupPendingCard />} />
        <Route path={NO_AUTH_ROUTES.RESET_EMAIL} element={<ResetEmailCard />} />
        <Route path={NO_AUTH_ROUTES.LOGOUT_SUCCESS} element={<LogoutCard />} />
        <Route path={NO_AUTH_ROUTES.ACCEPT_INVITE} element={<AcceptInvitationCard />} />
        <Route
          path="*"
          element={
            <Navigate
              to={{
                pathname: NO_AUTH_ROUTES.LOGIN,
                search: `?redirect=${encodeURIComponent(pathname + search)}`,
              }}
            />
          }
        />
      </Routes>
    </NoAuthLayout>
  );
};
