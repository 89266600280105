import { Button } from "app/components/Button/Button";
import { JsonDebug } from "app/components/JsonDebug/JsonDebug";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";

type DebugModalProps = {
  open: boolean;
  onClose: () => void;
  json?: object | null;
  xml?: string | null;
};

export const DebugModal = ({ open, onClose, json, xml }: DebugModalProps) => {
  return (
    <Modal open={open} onClose={onClose} title={"Debug"}>
      <ModalContent>
        {json ? <JsonDebug json={json} collapsed={1} /> : null}
        {xml ? <pre>{String(xml)}</pre> : null}
      </ModalContent>
      <ModalActions>
        <Button onClick={onClose}>Schließen</Button>
        <Button
          onClick={() => {
            navigator.clipboard.writeText(JSON.stringify(json));
          }}
        >
          JSON kopieren
        </Button>
      </ModalActions>
    </Modal>
  );
};
