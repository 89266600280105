import { CreditCardIcon } from "app/assets/icons/credit-card-icons";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, Sepa } from "app/models/payment-information.model";
import { useState } from "react";
import { FaUniversity } from "react-icons/fa";

interface PaymentMethodListProps {
  sepas: Sepa[];
  cards: Card[];
  onSetDefault: (paymentMethodId: string) => Promise<void>;
  onDelete: (paymentMethodId: string) => Promise<void>;
}

export const PaymentMethodList = ({ sepas, cards, onSetDefault, onDelete }: PaymentMethodListProps) => {
  const [loading, setLoading] = useState("");

  const handlePaymentMethodSetDefault = (paymentMethodId: string) => async () => {
    try {
      setLoading(paymentMethodId);
      await onSetDefault(paymentMethodId);
    } catch (error) {
      // TODO error handling
    } finally {
      setLoading("");
    }
  };

  const handlePaymentMethodDelete = (paymentMethodId: string) => async () => {
    try {
      setLoading(paymentMethodId);
      await onDelete(paymentMethodId);
    } catch (error) {
      // TODO error handling
    } finally {
      setLoading("");
    }
  };

  return (
    <div>
      {cards.map((card) => (
        <div key={card.id}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>
              <CreditCardIcon brand={card.brand} /> **** **** **** {card.last4}
            </span>
            <span style={{ display: "flex" }}>
              <Button
                theme={ButtonTheme.LINK}
                onClick={handlePaymentMethodSetDefault(card.id)}
                disabled={loading === card.id}
              >
                Aktivieren
              </Button>
              <span style={{ width: "20px" }}></span>
              <Button
                theme={ButtonTheme.LINK}
                onClick={handlePaymentMethodDelete(card.id)}
                disabled={loading === card.id}
              >
                Löschen
              </Button>
            </span>
          </div>
        </div>
      ))}
      {sepas.map((sepa) => (
        <div key={sepa.id}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <FaUniversity /> {sepa.country}** **** **** **** {sepa.last4}
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                theme={ButtonTheme.LINK}
                onClick={handlePaymentMethodSetDefault(sepa.id)}
                disabled={loading === sepa.id}
              >
                Aktivieren
              </Button>
              <span style={{ width: "20px" }}></span>
              <Button
                theme={ButtonTheme.LINK}
                onClick={handlePaymentMethodDelete(sepa.id)}
                disabled={loading === sepa.id}
              >
                Löschen
              </Button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
