import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import styles from "./Button.module.scss";

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  className?: string;
  childrenClassName?: string;
  theme?: ButtonTheme;
  loading?: boolean;
  shadow?: boolean;
  onClickPromise?: (e?: any) => Promise<unknown>;
};

export enum ButtonTheme {
  DEFAULT = "DEFAULT",
  TRANSPARENT = "TRANSPARENT",
  OUTLINE = "OUTLINE",
  DANGER = "DANGER",
  WARNING = "WARNING",
  POSITIVE = "POSITIVE",
  LINK = "LINK",
  WHITE = "WHITE",
}

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      childrenClassName,
      theme = ButtonTheme.DEFAULT,
      disabled,
      loading,
      onClickPromise,
      onClick,
      shadow = false,
      type,
      ...props
    },
    ref,
  ) => {
    const [showLoading, setShowLoading] = useState<boolean>(false);
    const [promiseLoading, setPromiseLoading] = useState<boolean>(false);

    const isLoading = loading || promiseLoading;

    // Only show spinner for long running actions
    useEffect(() => {
      let timeout: number | null = null;
      if (isLoading) {
        timeout = window.setTimeout(() => {
          setShowLoading(true);
        }, 200);
      } else {
        setShowLoading(false);
      }

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }, [isLoading]);

    const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClickPromise) {
        try {
          setPromiseLoading(true);
          await onClickPromise(e);
        } catch (error) {
          console.log("Error executing click handler", error);
        } finally {
          setPromiseLoading(false);
        }
      } else if (onClick) {
        onClick(e);
      }
    };

    return (
      <button
        ref={ref}
        type={type || "button"}
        className={clsx(
          styles.root,
          {
            [styles.shadow]: shadow,
            [styles.transparentTheme]: theme === ButtonTheme.TRANSPARENT,
            [styles.themeDefault]: theme === ButtonTheme.DEFAULT,
            [styles.linkTheme]: theme === ButtonTheme.LINK,
            [styles.outlineTheme]: theme === ButtonTheme.OUTLINE,
            [styles.dangerTheme]: theme === ButtonTheme.DANGER,
            [styles.warningTheme]: theme === ButtonTheme.WARNING,
            [styles.positiveTheme]: theme === ButtonTheme.POSITIVE,
            [styles.whiteTheme]: theme === ButtonTheme.WHITE,
            [styles.loading]: isLoading,
          },
          className,
        )}
        onClick={handleClick}
        disabled={disabled || isLoading}
        {...props}
      >
        <div className={clsx(styles.children, childrenClassName)}>
          {children}
          {showLoading ? (
            <Oval wrapperClass={styles.loader} color="#FFFFFF" height={14} width={14} secondaryColor="#FFFFFF" />
          ) : null}
        </div>
      </button>
    );
  },
);
