import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { FutziClient, FutziSearchQuery, SavedSearchClient } from "app/api";
import { QueryResult, SavedSearch, SortBy, SortOrder } from "app/models";
import { AppDispatch } from "app/redux/store.model";

const STATE_KEY = "search";

export const DEFAULT_LIMIT = 25;

export const defaultQueryFilter = {
  office: [],
  type: [],
  queryType: [],
  status: [],
  niceClass: [],
  viennaClass: [],
  owner: [],
  representative: [],
};

export const defaultSavedSearch = {
  uid: "",
  userId: "",
  name: "",
  query: "",
  owner: "",
  representative: "",
  updatedAt: new Date(),
  createdAt: new Date(),
  ignored: [],
  filters: defaultQueryFilter,
  sortBy: SortBy.RELEVANCE,
  sortOrder: SortOrder.DESC,
};

export const defaultQueryResult = {
  total: 0,
  took: 0,
  limit: DEFAULT_LIMIT,
  offset: 0,
  trademarks: [],
  stats: {
    offices: [],
    owners: [],
    representatives: [],
    statuses: [],
    types: [],
    niceClasses: [],
    viennaClasses: [],
    scores: [],
  },
};

export interface SearchState {
  saved: SavedSearch[];
  savedLoading: boolean;
  last: FutziSearchQuery[];
  lastLoading: boolean;
  searchLoading: boolean;
  current: SavedSearch;
  result: QueryResult;
  image: Blob | null;
  offset: number;
}

const initialState: SearchState = {
  saved: [],
  savedLoading: false,
  last: [],
  lastLoading: false,
  searchLoading: false,
  current: defaultSavedSearch,
  result: defaultQueryResult,
  image: null,
  offset: 0,
};

export const fetchSavedSearches = createAsyncThunk(`${STATE_KEY}/fetch`, SavedSearchClient.fetch);
export const fetchLastSearches = createAsyncThunk(`${STATE_KEY}/fetch/last`, FutziClient.fetchLastSearches);
export const createSavedSearch = createAsyncThunk(`${STATE_KEY}/create`, SavedSearchClient.create);
export const updateSavedSearch = createAsyncThunk(`${STATE_KEY}/update`, SavedSearchClient.update);
export const deleteSavedSearch = createAsyncThunk(`${STATE_KEY}/delete`, SavedSearchClient.delete);

export const searchSlice = createSlice({
  name: STATE_KEY,
  initialState,
  reducers: {
    updateSavedSearch: (state, { payload }: PayloadAction<SavedSearch>) => {
      if (state.saved.some((i) => i.uid === payload.uid)) {
        state.saved = state.saved.map((i) => (i.uid === payload.uid ? payload : i));
      } else {
        state.saved = state.saved.concat(payload);
      }
    },
    deleteSavedSearch: (state, action: PayloadAction<{ savedSearchId: string }>) => {
      state.saved = state.saved.filter((i) => i.uid !== action.payload.savedSearchId);
    },
    updateLast: (state, action: PayloadAction<FutziSearchQuery>) => {
      state.last = [action.payload, ...state.last];
    },
    startNewSearch: (state, action: PayloadAction<Partial<SavedSearch>>) => {
      state.current = { ...defaultSavedSearch, ...action.payload };
    },
    updateImage: (state, action: PayloadAction<Blob>) => {
      state.image = action.payload;
    },
    updateResult: (state, action: PayloadAction<QueryResult>) => {
      state.result = action.payload;
      state.offset = action.payload.offset;
    },
    setSearchLoading: (state, action: PayloadAction<boolean>) => {
      state.searchLoading = action.payload;
    },
    updateOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    reset: (state) => {
      state.current = defaultSavedSearch;
      state.image = null;
      state.result = defaultQueryResult;
    },
    resetResult: (state) => {
      state.result = defaultQueryResult;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchSavedSearches.fulfilled, (state, action) => {
        state.saved = action.payload;
        state.savedLoading = false;
      })
      .addCase(fetchLastSearches.fulfilled, (state, action) => {
        state.last = action.payload;
        state.lastLoading = false;
      })
      .addCase(deleteSavedSearch.fulfilled, (state, action) => {
        state.saved = state.saved.filter((i) => i.uid !== action.meta.arg);
      })

      // Pending
      .addCase(fetchSavedSearches.pending, (state) => {
        state.savedLoading = true;
      })
      .addCase(fetchLastSearches.pending, (state) => {
        state.lastLoading = true;
      })

      // Rejected
      .addCase(fetchSavedSearches.rejected, (state) => {
        state.savedLoading = false;
      })
      .addCase(fetchLastSearches.rejected, (state) => {
        state.lastLoading = false;
      })

      // Matcher
      .addMatcher(isAnyOf(createSavedSearch.fulfilled, updateSavedSearch.fulfilled), (state, { payload }) => {
        if (state.saved.some((i) => i.uid === payload.uid)) {
          state.saved = state.saved.map((i) => (i.uid === payload.uid ? payload : i));
        } else {
          state.saved = state.saved.concat(payload);
        }
      });
  },
});

export const execImageSearch = (blob: Blob, data: Partial<SavedSearch>) => async (dispatch: AppDispatch) => {
  const { filters, sortBy, sortOrder } = data;

  const query: Partial<SavedSearch> = {
    filters,
    sortBy,
    sortOrder,
  };

  dispatch(searchSlice.actions.updateImage(blob));

  dispatch(searchSlice.actions.startNewSearch(query));
};

export const loadPage = (result: QueryResult, page: number) => (dispatch: AppDispatch) => {
  const newOffset = (page - 1) * result.limit;
  return dispatch(searchSlice.actions.updateOffset(newOffset));
};

export const loadNextPage = (result: QueryResult) => (dispatch: AppDispatch) => {
  const newOffset = result.offset + result.limit;
  return dispatch(searchSlice.actions.updateOffset(newOffset));
};

export const loadPrevPage = (result: QueryResult) => (dispatch: AppDispatch) => {
  const newOffset = result.offset - result.limit;
  return dispatch(searchSlice.actions.updateOffset(newOffset));
};

export const searchReducer = searchSlice.reducer;
