import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { UpsellingEmptyState } from "app/components/UpsellingEmptyState/UpsellingEmptyState";
import { localizedCreditCardBrand } from "app/i18n";
import { BillingInformation } from "app/models/payment-information.model";
import {
  addPaymentMethod,
  deletePaymentMethod,
  setDefaultPaymentMethod,
  updateBillingInformation
} from "app/redux/slices/payment.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { localizedMonth } from "app/util/date.util";
import { handleUnknownError } from "app/util/error-handler";
import deepEqual from "deep-equal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import validate from "validate.js";
import { BillingInformationForm } from "./BillingInformation/BillingInformationForm";
import { InvoiceList } from "./InvoiceTable/InvoiceList";
import { PaymentCancellation } from "./PaymentCancellation/PaymentCancellation";
import { CreditCard } from "./PaymentMethod/CreditCard";
import { Iban } from "./PaymentMethod/Iban";
import { PaymentMethodForm } from "./PaymentMethod/PaymentMethodForm";
import { PaymentMethodList } from "./PaymentMethod/PaymentMethodList";
import { PricingTable } from "./PricingTable/PricingTable";
import styles from "./SettingsPagePayment.module.css";

export const SettingsPagePayment = () => {
  const { needsSubscription, hasCustomPayment } = useAppSelector((state) => state.payment);
  const {
    billingInformation: defaultBillingInformation,
    sepas,
    cards,
    invoices,
    subscriptions,
    loading: paymentStateLoading,
  } = useAppSelector((state) => state.payment);
  const [billingInformation, setBillingInformation] = useState(defaultBillingInformation);
  const [isValid, setIsValid] = useState({
    billingInformation: !!!validate(billingInformation, billingDetailsFormConstraints),
    paymentMethod: false,
  });
  const [billingInformationLoading, setBillingInformationLoading] = useState(false);
  const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
  const workspace = useAppSelector((state) => state.workspace.workspace);
  const dispatch = useAppDispatch();

  const isUntouched = { billingInformation: deepEqual(defaultBillingInformation, billingInformation) };

  useEffect(() => {
    setBillingInformation(defaultBillingInformation);
  }, [defaultBillingInformation]);

  if (paymentStateLoading) {
    return <LoadingIndicator message="Zahlungsinformationen werden geladen" />;
  }

  const defaultCard = cards?.find((card) => card.default);
  const defaultSepa = sepas?.find((sepa) => sepa.default);

  const hasMore = defaultCard
    ? cards.length > 1 || sepas.length > 0
    : defaultSepa
    ? sepas.length > 1 || cards.length > 0
    : false;

  const handleBillingInformationChange = (update: BillingInformation) => {
    setBillingInformation(update);
    setIsValid((prev) => ({
      ...prev,
      billingInformation: !!!validate(update, billingDetailsFormConstraints),
    }));
  };

  const handlePaymentMethodChange = (valid: boolean) => {
    setIsValid((prev) => ({
      ...prev,
      paymentMethod: valid,
    }));
  };

  const handleBillingInformationSubmit = async () => {
    try {
      setBillingInformationLoading(true);

      await dispatch(updateBillingInformation(billingInformation));

      toast.success("Wir haben Ihre Rechnungsdaten angepasst und werden sie für Ihre nächste Rechnung verwenden.", {
        autoClose: 10000,
      });
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setBillingInformationLoading(false);
    }
  };

  const handlePaymentMethodSubmit = async (paymentMethodId: string) => {
    try {
      setPaymentMethodLoading(true);

      await dispatch(addPaymentMethod(paymentMethodId));

      toast.success("Wir haben Ihre Zahlungsmethode angepasst und werden sie für Ihre nächste Rechnung verwenden.", {
        autoClose: 10000,
      });
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setPaymentMethodLoading(false);
    }
  };

  const handlePaymentMethodSetDefault = async (paymentMethodId: string) => {
    try {
      await dispatch(setDefaultPaymentMethod(paymentMethodId));

      toast.success("Wir haben Ihre Zahlungsmethode angepasst und werden sie für Ihre nächste Rechnung verwenden.", {
        autoClose: 10000,
      });
    } catch (error) {
      handleUnknownError(error);
    }
  };

  const handlePaymentMethodDelete = async (paymentMethodId: string) => {
    try {
      await dispatch(deletePaymentMethod(paymentMethodId));

      toast.success("Wir haben Ihre Zahlungsmethode gelöscht.");
    } catch (error) {
      handleUnknownError(error);
    }
  };

  if (hasCustomPayment) {
    return (
      <EmptyState
        title="Oktomark ist für Sie kostenlos"
        image={APP_CONST.IMAGES.FEEDBACK}
        text={
          <span>
            Vielen Dank, dass Sie Oktomark ausprobieren und wir freuen uns auf Ihr Feedback. Sie erreichen uns jederzeit
            über den Feedback Button hier im Interface oder per Mail an{" "}
            <a href="mailto:info@oktomark.de" target="_blank" rel="noreferrer">
              info@oktomark.de
            </a>
            .
          </span>
        }
      />
    );
  }

  if (!workspace) {
    return (
      <CardContainer>
        <UpsellingEmptyState />
      </CardContainer>
    );
  }

  return (
    <CardContainer>
      {needsSubscription ? (
        <UpsellingEmptyState />
      ) : (
        <Card item className={styles.card}>
          <CardTitle
            title="Monatliche Kosten für Ihren Workspace"
            subtitle={
              subscriptions[0]?.currentPeriodEnd
                ? `Dies ist eine Voransicht auf die Rechnung, die am ${localizedMonth(
                    subscriptions[0].currentPeriodEnd,
                  )} belastet wird.`
                : "Dies ist eine Voransicht auf Ihre nächste Rechnung."
            }
          />
          <CardContent>
            <PricingTable subscriptions={subscriptions} />
          </CardContent>
        </Card>
      )}
      {invoices.length > 0 && (
        <Card item={!needsSubscription} className={styles.card}>
          <CardTitle title="Rechnungen" subtitle="Hier können Sie Ihre alten Rechnungen noch einmal herunterladen." />
          <CardContent>
            <InvoiceList invoices={invoices} />
            <p className={styles.hint}>Oktomark ist ein Produkt der Einsen und Nullen UG (haftungsbeschränkt).</p>
          </CardContent>
        </Card>
      )}
      {!needsSubscription ? (
        <>
          <Card item className={styles.card}>
            <CardTitle
              title="Rechnungsdaten"
              subtitle="Hier können Sie Ihre Rechnungsdaten ändern. Wir berücksichtigen sie dann bei Ihrer nächsten Rechnung."
            />
            <CardContent>
              <BillingInformationForm
                billingInformation={billingInformation}
                onChange={handleBillingInformationChange}
              />
            </CardContent>
            <CardActions>
              <Button
                disabled={!isValid.billingInformation || isUntouched.billingInformation}
                onClick={handleBillingInformationSubmit}
                loading={billingInformationLoading}
              >
                Rechnungsdaten speichern
              </Button>
            </CardActions>
          </Card>
          <Card item className={styles.card}>
            <CardTitle
              title="Zahlungsmethoden"
              subtitle="Hier können Sie Ihre Zahlungsmethode ändern. Wir berücksichtigen sie dann bei Ihrer nächsten Rechnung."
            />
            <CardContent>
              {defaultCard && (
                <>
                  <p style={{ marginTop: 0 }}>
                    Sie bezahlen aktuell mit Ihrer {localizedCreditCardBrand(defaultCard.brand)} Kreditkarte.
                  </p>
                  <CreditCard billingInformation={billingInformation} card={defaultCard} />
                </>
              )}
              {defaultSepa && (
                <CardContentSection
                  title={"Aktuelle Zahlungsmethode"}
                  description={"Sie bezahlen aktuell mit dem SEPA Lastschriftverfahren."}
                >
                  <Iban sepa={defaultSepa} />
                </CardContentSection>
              )}
              {hasMore && (
                <CardContentSection
                  title={"Andere Zahlungsmethoden"}
                  description={
                    "Hier können Sie eine andere Zahlungsmethode aktivieren oder sie löschen, falls Sie sie nicht mehr benötigen."
                  }
                >
                  <PaymentMethodList
                    cards={cards.filter((card) => !card.default)}
                    sepas={sepas.filter((sepa) => !sepa.default)}
                    onSetDefault={handlePaymentMethodSetDefault}
                    onDelete={handlePaymentMethodDelete}
                  />
                </CardContentSection>
              )}
              <CardContentSection
                title={"Neue Zahlungsmethode"}
                description={"Hier können Sie eine neue Zahlungsmethode hinzufügen."}
              >
                <PaymentMethodForm
                  billingInformation={billingInformation}
                  onSubmit={handlePaymentMethodSubmit}
                  onChange={handlePaymentMethodChange}
                  valid={isValid.paymentMethod}
                  submitButtonText="Zahlungsmethode hinzufügen"
                  loading={paymentMethodLoading}
                  setLoading={setPaymentMethodLoading}
                />
              </CardContentSection>
            </CardContent>
          </Card>
          <Card item className={styles.card}>
            <CardTitle title="Workspace Abonnement" subtitle="Hier können Sie Ihr Workspace Abonnement kündigen." />
            <CardActions>
              <PaymentCancellation />
            </CardActions>
          </Card>
        </>
      ) : null}
    </CardContainer>
  );
};

const billingDetailsFormConstraints = {
  name: {
    presence: { allowEmpty: false },
  },
  email: {
    presence: { allowEmpty: false },
    email: true,
  },
  "address.line1": {
    presence: { allowEmpty: false },
  },
  "address.line2": {
    presence: { allowEmpty: true },
  },
  "address.city": {
    presence: { allowEmpty: false },
  },
  "address.state": {
    presence: { allowEmpty: true },
  },
  "address.zip": {
    presence: { allowEmpty: false },
  },
  "address.country": {
    presence: { allowEmpty: false },
  },
};
