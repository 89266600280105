import { APP_CONST } from "app/assets/constants";
import { Card, CardContent } from "app/components/Card/Card";
import clsx from "clsx";
import sharedStyles from "./../SharedNoAuthViewStyles.module.css";
import styles from "./SignupPendingCard.module.css";

export const SignupPendingCard = () => {
  return (
    <Card className={sharedStyles.root}>
      <CardContent>
        <div className={styles.success}>
          <div className={clsx(sharedStyles.headline, styles.headline)}>Vielen Dank für Ihre Anmeldung!</div>
          <p className={clsx(sharedStyles.subheadline, styles.subheadline)}>
            Wir freuen uns, dass Sie sich für Oktomark entschieden haben. Ihr Account wird sofort von uns freigeschaltet
            und Sie erhalten eine E-Mail, wenn es soweit ist.
          </p>
          <img className={sharedStyles.image} src={APP_CONST.IMAGES.SIGNUP_PENDING} alt="" />
        </div>
      </CardContent>
    </Card>
  );
};
