import { Button, ButtonTheme } from "app/components/Button/Button";
import { List, ListItem } from "app/components/List/List";
import { Modal as ModalElement, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { k, l } from "app/i18n";
import { pluralize } from "app/i18n/i18n.util";
import { Research } from "app/models";
import { Trademark } from "app/models/trademark.model";
import { addTrademarksToResearch, useAppDispatch, useAppSelector } from "app/redux";
import { researchDetailRoute } from "app/routes";
import { handleError } from "app/util/error-handler";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export enum AddTrademarkToResearchModalView {
  ADD = "add",
  NEW = "new",
}

type AddTrademarkToResearchModalProps = {
  open: boolean;
  onClose: () => void;
  onCreateNewResearch?: (researchId: string) => void;
  trademarks?: Trademark[];
  defaultView?: AddTrademarkToResearchModalView;
};

export const AddTrademarkToResearchModal = ({ open, onClose, trademarks = [] }: AddTrademarkToResearchModalProps) => {
  const items = useAppSelector((state) => state.research.items);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const portfolios = useAppSelector((state) => state.portfolio.items);

  const goToResearch = (id: string) => {
    const researchPortfolio = portfolios.find((p) => p.researches?.find((r) => r.researchId === id));
    if (researchPortfolio) {
      navigate(researchDetailRoute(researchPortfolio.uid, id));
    }
  };

  const addTrademarkToResearch = (research: Research) => async () => {
    if (!trademarks) {
      return;
    }
    try {
      await dispatch(
        addTrademarksToResearch({
          id: research.uid,
          trademarks: trademarks.map(({ id, office }) => ({
            trademarkId: id,
            trademarkOffice: office,
          })),
        }),
      ).unwrap();
      if (trademarks.length > 1) {
        toast.success(`${pluralize("trademarks", trademarks?.length)} Marken zu ${research.name} hinzugefügt.`, {
          onClick: () => goToResearch(research.uid),
        });
      } else {
        toast.success(`${trademarks.map((trademark) => trademark.name).join(", ")} zu ${research.name} hinzugefügt.`, {
          onClick: () => goToResearch(research.uid),
        });
      }
      onClose();
    } catch (error) {
      handleError(`Marke konnte nicht zur Recherche hinzugefügt werden.`, error);
    }
  };

  const listItems: ListItem[] = items
    .map((l) => ({
      id: l.uid,
      title: l.name,
      active: false,
      subtitle:
        portfolios.find((p) => p.researches?.find((r) => r.researchId === l.uid))?.name ||
        `${pluralize("trademarks", l.trademarks?.length || 0)}`,
      onClick: addTrademarkToResearch(l),
    }))
    .sort((a, b) => a.title.localeCompare(b.title));

  return (
    <ModalElement
      open={open}
      onClose={onClose}
      title={l(k.ADD_TO_RESEARCH)}
      subtitle={`Fügen Sie ${
        trademarks.length === 1 ? `${trademarks[0]?.name || "diese Marke"}` : `${trademarks.length} Marken`
      } zu einer Recherche hinzu.`}
    >
      <ModalContent>
        <List items={listItems} />
      </ModalContent>
      <ModalActions>
        <Button theme={ButtonTheme.OUTLINE} onClick={onClose}>
          Schließen
        </Button>
      </ModalActions>
    </ModalElement>
  );
};
