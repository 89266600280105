import { AppTextEditor } from "app/components/AppTextEditor/AppTextEditor";
import clsx from "clsx";
import styles from "./EditableTextField.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
  text?: string | null;
  onSave: (text: string) => Promise<unknown>;
}

export const EditableTextField = ({ className, text, onSave, ...props }: Props) => {
  const handleSave = async (html: string) => {
    let text = html;
    if (html === "<p></p>") {
      text = "";
    }

    await onSave(text);
  };

  return (
    <div className={clsx(styles.root, className)} {...props}>
      <AppTextEditor onSave={handleSave} defaultValue={text || ""} hideActions={false} />

      {/* {edit ? (
        <AppTextEditor
          onSave={handleSave}
          defaultValue={text || ""}
          hideActions={false}
          onLeaveUnchanged={toggleEdit}
        />
      ) : (
        <div onClick={toggleEdit} className={styles.button}>
          <AppTextRenderer text={text} placeholder="Notiz hinzufügen" />
        </div>
      )} */}
    </div>
  );
};
