import { getClient } from "app/api";
import { QueryResult } from "app/models/query-result.model";
import { mapQueryResult } from "app/util/trademark.util";

export type FeedFilterParams = {
  owner?: string[];
  representative?: string[];
  office?: string[];
  type?: string[];
  status?: string[];
  niceClass?: string[];
  viennaClass?: string[];
  limit?: number;
  offset?: number;
};

export const FeedClient = {
  fetch: async (params?: FeedFilterParams) => {
    const client = await getClient();

    const { data } = await client.get<QueryResult>("/feed", {
      params,
    });

    return mapQueryResult(data);
  },
};
