import { CollapsibleCard } from "app/components/CollapsibleCard/CollapsibleCard";
import styles from "./CollectionHelp.module.scss";

export const CollectionHelp = () => {
  return (
    <CollapsibleCard className={styles.card} title="Wie funktionieren Sammlungen?">
      <p>Sammlungen können Sie ganz individuell benutzen und so geht keine Marke mehr verloren.</p>
      <p>Hier ein paar Beispiele:</p>
      <ul>
        <li>Organisieren Sie Ideen in einzelnen Sammlungen.</li>
        <li>Benutzen Sie eine Sammlungen z. B. für Favoriten, um sich Marken schnell zu merken.</li>
      </ul>
      <p>Oktomark schlägt Ihnen automatisch neue Marken vor, die in Ihrer Liste noch fehlen könnten.</p>
    </CollapsibleCard>
  );
};
