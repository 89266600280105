import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { k, l } from "app/i18n";
import { AppModal, showModal, useAppDispatch } from "app/redux";

export const PortfolioEmptyState = () => {
  const dispatch = useAppDispatch();

  const showCreatePortfolioModal = () => {
    showModal(dispatch)({
      type: AppModal.CREATE_PORTFOLIO,
      props: {},
    });
  };

  return (
    <EmptyState
      className="tutorial-portfolio-empty-state"
      title={l(k.DASHBOARD_PORTOFOLIO_EMPTY_STATE_TITLE)}
      fullWidth
      image={APP_CONST.IMAGES.PORTFOLIO}
      text={l(k.DASHBOARD_PORTOFOLIO_EMPTY_STATE_TEXT)}
    >
      <Button onClick={showCreatePortfolioModal} style={{ width: "100%" }}>
        Mandanten anlegen
      </Button>
    </EmptyState>
  );
};
