import { APP_CONST } from "app/assets/constants";
import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { Card } from "app/components/Card/Card";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { k, l, pluralize } from "app/i18n";
import { GridNavigationItemConfig, GridNavigationLayout } from "app/layouts/GridLayout/GridNavigationLayout";
import { Portfolio, Research } from "app/models";
import { AppModal, showModal, useAppDispatch } from "app/redux";
import { researchDetailRoute } from "app/routes";
import styles from "./PortfolioResearches.module.scss";

interface Props {
  portfolio: Portfolio;
  researches: Research[];
}

export const PortfolioResearches = ({ portfolio, researches }: Props) => {
  const dispatch = useAppDispatch();

  const handleCreateResearch = () => {
    showModal(dispatch)({
      type: AppModal.CREATE_RESEARCH,
      props: {
        portfolio,
      },
    });
  };

  const items: GridNavigationItemConfig[] =
    researches
      .map((research) => ({
        path: researchDetailRoute(portfolio.uid, research.uid),
        title: research.name,
        subtitle: pluralize("trademarks", research.trademarks?.length),
        Icon: ICONS.RESEARCH,
        createdAt: research.createdAt,
      }))
      .sort((a, b) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())) || [];

  return (
    <>
      {researches.length > 0 ? (
        <GridNavigationLayout
          title={`${pluralize("researches", researches.length)} in diesem Mandanten`}
          titleIcon={ICONS.RESEARCHES}
          items={items}
        >
          <Card className={styles.addCard} onClick={handleCreateResearch}>
            <ICONS.ADD size={ICON_SIZE.LARGE} />
            <span>Neue Recherche starten</span>
          </Card>
        </GridNavigationLayout>
      ) : (
        <PortfolioResearchesEmptyState onClick={handleCreateResearch} />
      )}
    </>
  );
};

const PortfolioResearchesEmptyState = ({ onClick }: { onClick: () => void }) => {
  return (
    <EmptyState
      title={l(k.PORTOFOLIO_RESEARCHES_EMPTY_STATE_TITLE)}
      fullWidth
      image={APP_CONST.IMAGES.RESEARCH}
      text={l(k.PORTOFOLIO_RESEARCHES_EMPTY_STATE_TEXT)}
      className="tutorial-portfolio-research-empty-state"
    >
      <Button onClick={onClick} style={{ width: "100%" }}>
        Jetzt Recherche starten
      </Button>
    </EmptyState>
  );
};
