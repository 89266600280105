import { TrademarkClient } from "app/api";
import { getDpmaTrademarkXml } from "app/api/dpma.api";
import { ICONS } from "app/assets/icons/icons";
import { Card, CardContent } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EmptyState } from "app/components/EmptyState/EmptyState";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { AddTrademarkToCollectionModalView } from "app/components/Modals/AddTrademarkToCollectionModal/AddTrademarkToCollectionModal";
import { AddTrademarkToPortfolioModalView } from "app/components/Modals/AddTrademarkToPortfolioModal/AddTrademarkToPortfolioModal";
import { AddTrademarkToResearchModalView } from "app/components/Modals/AddTrademarkToResearchModal/AddTrademarkToResearchModal";
import { ExportType } from "app/components/Modals/ExportModal/ExportModal";
import { NiceClassDescription } from "app/components/NiceClassDescription/NiceClassDescription";
import { Popover, PopoverList, PopoverListItem } from "app/components/Popover";
import { SimilarTrademarksHeader } from "app/components/SimilarTrademarksHeader/SimilarTrademarksHeader";
import { TrademarkBadges } from "app/components/TrademarkCard/TrademarkBadges/TrademarkBadges";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { TrademarkImage } from "app/components/TrademarkImage/TrademarkImage";
import { Tutorial, TutorialStep } from "app/components/Tutorial/Tutorial";
import { ViennaClassDescription } from "app/components/ViennaClassDescription/ViennaClassDescription";
import { usePageContext } from "app/context";
import { useAppSearchParams, useIsAdmin } from "app/hooks";
import { k, l, localizedType } from "app/i18n";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { Trademark, TrademarkOffice, TrademarkType } from "app/models/trademark.model";
import { UserFlag } from "app/models/user.model";
import { AppModal, selectViennaClasses, showModal, useAppDispatch, useAppSelector } from "app/redux";
import { localizedDate, sortByDateDesc } from "app/util/date.util";
import { handleUnknownError } from "app/util/error-handler";
import { sanitizeForFileName } from "app/util/string.util";
import { shareTrademark } from "app/util/trademark.util";
import { TrademarkBaseDataCard } from "app/views/TrademarkDetailView/TrademarkBaseDataCard/TrademarkBaseDataCard";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExtendedSearchCard } from "./ExtendedSearchCard/ExtendedSearchCard";
import styles from "./TrademarkDetailView.module.scss";
import { TrademarkSidebar } from "./TrademarkSidebar/TrademarkSidebar";

const tutorialSteps: TutorialStep[] = [
  {
    title: "Markenüberwachung",
    target: ".tutorial-monitoring",
    content:
      "Mit nur einem Klick können Sie Ihre Marke von Oktomark überwachen lassen. Wir prüfen Ihre Marke täglich auf Kollisionen und benachrichtigen Sie, falls Oktomark etwas für Sie entdeckt hat.",
    disableBeacon: true,
  },
];

const NoTrademarkFoundEmptyState = () => {
  return (
    <EmptyState
      title="Marke nicht gefunden..."
      text="Oktomark kennt diese Marke leider (noch) nicht. Versuche es später vielleicht nochmal. In der Zwischenzeit suchen wir nach dem Fehler..."
    />
  );
};

export const TrademarkDetailView = () => {
  const [trademark, setTrademark] = useState<Trademark | null>(null);
  const [pendingRequest, setPendingRequest] = useState<boolean>(true);
  const [suggestions, setSuggestions] = useState<Trademark[]>([]);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const isAdmin = useIsAdmin();
  const viennaClasses = useAppSelector(selectViennaClasses(trademark?.viennaClasses || []));
  const { pathname } = useLocation();

  const context = usePageContext();

  const [{ id, office }] = useAppSearchParams({
    id: "",
    office: "",
  });

  useEffect(() => {
    if (trademark) {
      context.updateContext(pathname, `${trademark?.name} (${trademark?.office})`);
    }
  }, [context, pathname, trademark]);

  const isMonitored = useAppSelector((state) =>
    state.monitoring.items.some((monitoring) => monitoring.trademark.id === id),
  );

  const hasVisual =
    trademark?.type === TrademarkType.COMBINED ||
    trademark?.type === TrademarkType.FIGURATIVE ||
    trademark?.type === TrademarkType.ACOUSTIC ||
    trademark?.type === TrademarkType.SHAPE_3D;

  useEffect(() => {
    if (id && office) {
      setPendingRequest(true);
      TrademarkClient.fetch({ id, office })
        .then(setTrademark)
        .finally(() => setPendingRequest(false));
    }
  }, [id, office]);

  useEffect(() => {
    if (id && office) {
      setSuggestionsLoading(true);
      setSuggestions([]);
      TrademarkClient.fetchSuggestions({ id, office })
        .then(setSuggestions)
        .finally(() => setSuggestionsLoading(false));
    }
  }, [id, office]);

  if (!id || !office) {
    return null;
  }

  if (pendingRequest) {
    return (
      <OneColumnLayout>
        <LoadingIndicator message="Marke wird geladen..." />
      </OneColumnLayout>
    );
  }

  if (!trademark) {
    return (
      <OneColumnLayout>
        <NoTrademarkFoundEmptyState />
      </OneColumnLayout>
    );
  }

  const showDebugModal = async () => {
    let xml = null;
    if (trademark?.office === TrademarkOffice.DPMA) {
      xml = await getDpmaTrademarkXml(trademark.id);
    }
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: trademark,
        xml: xml,
      },
    });
  };

  const openCreateMonitoringModal = () => {
    showModal(dispatch)({
      type: AppModal.CREATE_MONITORING,
      props: {
        trademark,
      },
    });
  };
  const showAddToCollectionModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_COLLECTION,
      props: {
        defaultView: AddTrademarkToCollectionModalView.ADD,
        trademarks: [trademark],
      },
    });
  };

  const showAddToResearchModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_RESEARCH,
      props: {
        defaultView: AddTrademarkToResearchModalView.ADD,
        trademarks: [trademark],
      },
    });
  };

  const showAddToPortfolioModal = () => {
    showModal(dispatch)({
      type: AppModal.ADD_TRADEMARK_TO_PORTFOLIO,
      props: {
        defaultView: AddTrademarkToPortfolioModalView.ADD,
        trademarks: [trademark],
      },
    });
  };

  const handleExport = () => {
    showModal(dispatch)({
      type: AppModal.EXPORT,
      props: {
        title: "Marke exportieren",
        onExport: async (type: ExportType) => {
          await TrademarkClient.download({ id: trademark.id, office: trademark.office, type })
            .then((response) => {
              const date = format(new Date(), "dd-MM-yyyy");

              const filename = `${sanitizeForFileName(
                trademark.name || localizedType(trademark.type),
              )} - ${date}.${type}`;

              // FIXME: Don't know if this works on all devices and browsers
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", filename);
              document.body.appendChild(link);
              link.click();
            })
            .catch((error) => handleUnknownError(error));
        },
      },
    });
  };

  const menuItems: PopoverListItem[] = [
    {
      Icon: ICONS.MONITORING,
      title: "Marke überwachen",
      onClick: openCreateMonitoringModal,
      hidden: isMonitored,
    },
    {
      Icon: ICONS.BOOKMARK,
      title: "Zu Sammlung hinzufügen",
      onClick: showAddToCollectionModal,
    },
    {
      Icon: ICONS.RESEARCH,
      title: "Zu Recherche hinzufügen",
      onClick: showAddToResearchModal,
      hidden: !isAdmin,
    },
    {
      Icon: ICONS.PORTFOLIO,
      title: "Zu Mandant hinzufügen",
      onClick: showAddToPortfolioModal,
      hidden: !isAdmin,
    },
    {
      Icon: ICONS.SHARE,
      title: "Teilen",
      onClick: () => shareTrademark(trademark),
    },
    {
      Icon: ICONS.EXPORT,
      title: "Marke exportieren",
      onClick: handleExport,
    },
    {
      title: "Debug",
      onClick: showDebugModal,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
  ];

  return (
    <OneColumnLayout>
      <CardContainer className={styles.firstCard}>
        <Tutorial flag={UserFlag.TUTORIAL_TRADEMARK_MONITORING} steps={tutorialSteps} />
        <Card className={styles.root}>
          <CardContent>
            <div className={styles.titleWrapper}>
              <div className={styles.title}>{trademark.name}</div>
              <Popover>
                <PopoverList items={menuItems} />
              </Popover>
            </div>
            <TrademarkBadges trademark={trademark} />
            {hasVisual ? (
              <div className={styles.imageWrapper}>
                <TrademarkImage trademark={trademark} className={styles.image} />
              </div>
            ) : null}
          </CardContent>
        </Card>
      </CardContainer>
      {trademark.activities.length > 0 && (
        <CardContainer header={{ title: "Verfahrensdaten" }}>
          <Card className={styles.root}>
            <CardContent>
              <div className={styles.activitiesWrapper}>
                <table className={styles.activities}>
                  <thead>
                    <tr>
                      <th>Datum</th>
                      <th>Art</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...trademark.activities]
                      .sort((a, b) => sortByDateDesc(b.date, a.date))
                      .map((activity) => (
                        <tr key={`${activity.id}_${activity.name}_${activity.status}`}>
                          <td>{localizedDate(activity.date)}</td>
                          <td>{activity.name}</td>
                          <td>{activity.status}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </CardContainer>
      )}
      <div className={styles.datawrapper}>
        <div>
          <CardContainer header={{ title: "Stammdaten" }}>
            <TrademarkBaseDataCard trademark={trademark} />
          </CardContainer>
          <CardContainer header={{ title: "Erweiterte Suche" }}>
            <ExtendedSearchCard trademark={trademark} />
          </CardContainer>
        </div>
        <CardContainer header={{ title: "Kontaktdaten" }}>
          <TrademarkSidebar trademark={trademark} />
        </CardContainer>
      </div>
      <CardContainer header={{ title: l(k.NICE_CLASSIFICATION) }}>
        <Card className={styles.root}>
          <CardContent>
            {trademark.niceClasses.map((nc) => (
              <NiceClassDescription key={nc.id} niceClass={nc} />
            ))}
          </CardContent>
        </Card>
      </CardContainer>
      {hasVisual ? (
        <CardContainer header={{ title: l(k.VIENNA_CLASSIFICATION) }}>
          <Card className={styles.root}>
            <CardContent>
              {trademark.viennaClasses.length === 0 ? (
                <div className={styles.subheadline}>Keine Klassen angegeben</div>
              ) : null}
              {viennaClasses.map((vc) => (
                <ViennaClassDescription key={vc.viennaId} viennaClass={vc} />
              ))}
            </CardContent>
          </Card>
        </CardContainer>
      ) : null}
      {suggestionsLoading ? (
        <>
          <LoadingIndicator message={l(k.LOADING_TRADEMARK_LIST_SUGGESTIONS)} />
        </>
      ) : (
        <>
          <SimilarTrademarksHeader />
          {suggestions.map((trademark) => (
            <TrademarkCard
              displayType={TrademarkCardDisplayType.COMPACT}
              key={`suggestion_${trademark.id}`}
              trademark={trademark}
              noBg
              isSelectable
            />
          ))}
        </>
      )}
    </OneColumnLayout>
  );
};
