import clsx from "clsx";
import { Subscription } from "../../../../models/payment-information.model";
import styles from "./PricingTable.module.scss";

interface PricingTableProps {
  subscriptions: Subscription[];
  small?: boolean;
}

export const PricingTable = ({ subscriptions }: PricingTableProps) => {
  const price = subscriptions.reduce((acc, subscription) => (acc += subscription.price * subscription.quantity), 0);
  return (
    <div className={clsx(styles.root)}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Einzelpreis</th>
            <th>Anzahl</th>
            <th>Gesamtpreis</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr key={subscription.name}>
              <td>{subscription.name}</td>
              <td>
                {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
                  subscription.price / 100,
                )}
              </td>
              <td>{subscription.quantity}</td>
              <td>
                {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(
                  (subscription.price * subscription.quantity) / 100,
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.divider} />
      <div className={styles.extra}>
        <div>
          Gesamtsumme {new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(price / 100)}
        </div>
        <div className={styles.hint}>Nicht umsatzsteuerpflichtig nach § 19 UStG</div>
      </div>
    </div>
  );
};
