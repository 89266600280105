import { Modal, ModalContent } from "app/components/Modal/Modal";
import { NiceClassDescription } from "app/components/NiceClassDescription/NiceClassDescription";
import { Trademark } from "app/models/trademark.model";
import styles from "./NiceClassModal.module.css";

interface NiceClassModalProps {
  open: boolean;
  onClose: () => void;
  trademark?: Trademark | null;
}

export const NiceClassModal = ({ open, onClose, trademark }: NiceClassModalProps) => {
  const niceClasses = trademark?.niceClasses || [];

  const title = (
    <>
      Nizza-Klassen der Marke <span className={styles.titleHighlight}>{trademark?.name || ""}</span>
    </>
  );

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <ModalContent>
        {niceClasses.map((ci) => (
          <NiceClassDescription key={ci.id} niceClass={ci} />
        ))}
      </ModalContent>
    </Modal>
  );
};
