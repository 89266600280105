import { MonitoringClient, TrademarkClient } from "app/api";
import { ICONS } from "app/assets/icons/icons";
import { Button } from "app/components/Button/Button";
import { ButtonGroup } from "app/components/ButtonGroup/ButtonGroup";
import { Card, CardContentSection } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { CommentsSection } from "app/components/CommentsSection/CommentSection";
import { EditableTextField } from "app/components/EditableTextField/EditableTextField";
import { FuzzinessSlider, queryTypesToMarkText } from "app/components/FuzzinessSlider/FuzzinessSlider";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { ExportType } from "app/components/Modals/ExportModal/ExportModal";
import { Popover } from "app/components/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import {
  TrademarkActionConfig,
  TrademarkActionType,
} from "app/components/TrademarkCard/TrademarkActions/TrademarkActions";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import {
  ResultListActionConfigMap,
  ResultListActionType,
  TrademarkResultList,
} from "app/components/TrademarkResultList/TrademarkResultList";
import { Tutorial, TutorialStep } from "app/components/Tutorial/Tutorial";
import { useIsAdmin } from "app/hooks";
import { TabButtonStyle, TabLayout, TabViewConfig } from "app/layouts/TabLayout/TabLayout";
import { QueryType, User, UserFlag } from "app/models";
import { CollisonFlag, Monitoring, MonitoringResolved, NotificationInterval } from "app/models/monitoring.model";
import { CommentVisibility, TrademarkComment } from "app/models/trademark-comment.model";
import { Trademark } from "app/models/trademark.model";
import {
  addMonitoringComment,
  AppModal,
  deleteMonitoringComment,
  flagMonitoring,
  removeMonitoring,
  showModal,
  unflagMonitoring,
  updateMonitoring,
  updateMonitoringComment,
  useAppDispatch,
  useAppSelector,
} from "app/redux";
import { ROUTES } from "app/routes";
import { handleError, handleUnknownError } from "app/util/error-handler";
import { notEmpty } from "app/util/filter.util";
import { groupByScore } from "app/util/group.util";
import { sanitizeForFileName } from "app/util/string.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import clsx from "clsx";
import { format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { isDesktop } from "react-device-detect";
import { IconType } from "react-icons";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./MonitoringDetail.module.scss";

const tutorialSteps: TutorialStep[] = [
  {
    title: "Die Markenüberwachung",
    target: ".tutorial-search",
    content:
      "Oktomark informiert Sie per E-Mail, sobald eine potenzielle Kollision entdeckt wurde oder sich die Informationen der überwachten Marke ändern.",
    disableBeacon: true,
    placement: "center",
  },
  {
    title: "Einstellungen",
    target: ".tutorial-monitoring-settings",
    content:
      "Hier können Sie die Empfindlichkeit und das Benachrichtigungsintervall für jede Überwachung gezielt einstellen.",
    disableBeacon: true,
  },
  {
    title: "Kollisionen markieren",
    target: ".tutorial-monitoring-collisions",
    content:
      "Markieren Sie jetzt alle gefundenen Kollisionen als problematisch oder unproblematisch. So lernt Oktomark automatisch dazu und informiert Sie nur noch über neue Kollisionen.",
    disableBeacon: true,
    placement: "top",
    // We have to scroll the element into the view on mobile devices
    // Seems currently broken.
    // See https://github.com/gilbarbara/react-joyride/issues/732#issuecomment-1055208052
    disableScrolling: isDesktop,
  },
];

function groupTrademarks(monitoring: MonitoringResolved) {
  const problematic = monitoring.flaggedTrademarks
    .filter((flaggedTrademark) => flaggedTrademark.flag === CollisonFlag.PROBLEMATIC)
    .map((f) => f.trademark);
  const unproblematic = monitoring.flaggedTrademarks
    .filter((flaggedTrademark) => flaggedTrademark.flag === CollisonFlag.UNPROBLEMATIC)
    .map((f) => f.trademark);
  const collisions = groupByScore(monitoring.collisions);

  return {
    collisions,
    problematic,
    unproblematic,
  };
}

const nameForCollisionFlag: { [key: string]: string } = {
  [CollisonFlag.UNPROBLEMATIC]: "Unproblematisch",
  [CollisonFlag.PROBLEMATIC]: "Problematisch",
  [CollisonFlag.UNKNOWN]: "Nicht markiert",
};

const iconForCollisionFlag: { [key: string]: IconType } = {
  [CollisonFlag.UNPROBLEMATIC]: ICONS.IGNORE,
  [CollisonFlag.PROBLEMATIC]: ICONS.ALERT,
  [CollisonFlag.UNKNOWN]: ICONS.UNKNOWN,
};

type UserCommentsProps = {
  monitoring: Monitoring;
};

const MonitoringComments = ({ monitoring }: UserCommentsProps) => {
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const handleNewComment = async (text: string) => {
    try {
      await dispatch(
        addMonitoringComment({
          id: monitoring.uid,
          comment: text,
          // TODO: Choose comment visibility
          visibility: CommentVisibility.PUBLIC,
        }),
      );
    } catch (error) {
      handleError("Kommentar konnte nicht gespeichert werden.", error);
    }
  };

  const handleUpdateComment = async (id: string, text: string) => {
    try {
      await dispatch(
        updateMonitoringComment({
          id: monitoring.uid,
          commentId: id,
          comment: text,
          // TODO: Change comment visibility
          visibility: CommentVisibility.PUBLIC,
        }),
      );
    } catch (error) {
      handleError("Kommentar konnte nicht aktualisiert werden.", error);
    }
  };

  const handleDeleteComment = async (id: string) => {
    try {
      await dispatch(
        deleteMonitoringComment({
          id: monitoring.uid,
          commentId: id,
        }),
      );
    } catch (error) {
      handleError("Kommentar konnte nicht gelöscht werden.", error);
    }
  };

  return (
    <div>
      <CommentsSection
        user={user}
        comments={monitoring.comments || []}
        onAddNew={handleNewComment}
        onUpdate={handleUpdateComment}
        onDelete={handleDeleteComment}
      />
    </div>
  );
};

type CollisionFlagButtonProps = {
  flag: CollisonFlag;
  active?: boolean;
  onClick?: () => unknown;
};

const CollisionFlagButton = ({ flag, active, onClick }: CollisionFlagButtonProps) => {
  const Icon = iconForCollisionFlag[flag];
  return (
    <button
      onClick={onClick}
      disabled={active}
      title={nameForCollisionFlag[flag]}
      className={clsx(styles.collisionFlagButton, {
        [styles.active]: active,
        [styles.problematic]: flag === CollisonFlag.PROBLEMATIC,
        [styles.unproblematic]: flag === CollisonFlag.UNPROBLEMATIC,
        [styles.unknown]: flag === CollisonFlag.UNKNOWN,
      })}
    >
      <div className={styles.collisionFlagButtonIcon}>
        <Icon />
      </div>
    </button>
  );
};

type TrademarkRendererProps = {
  trademark: Trademark;
  monitoring: Monitoring;
  comments: TrademarkComment[];
  disabledHandlers: CollisonFlag[];
  displayType: TrademarkCardDisplayType;
};

const TrademarkRenderer = ({
  monitoring,
  trademark,
  displayType,
  comments = [],
  disabledHandlers,
}: TrademarkRendererProps) => {
  // const [commentsVisible, setCommentsVisible] = useState(false);
  const dispatch = useAppDispatch();

  const handleFlagCollision = (tm: Trademark, flag: CollisonFlag) => async () => {
    const trademark = {
      trademarkId: tm.id,
      trademarkOffice: tm.office,
    };
    trackEvent(AppEvent.SET_COLLISION_FLAG, {
      flag,
      trademark,
    });
    dispatch(
      flagMonitoring({
        id: monitoring.uid,
        flag,
        trademarks: [trademark],
      }),
    );
  };

  // const handleNoteSave = async (note: string) => {
  //   dispatch(updateMonitoring({ id: monitoring.uid, note, interval: monitoring.interval }));
  // };

  const isProblematic = disabledHandlers.includes(CollisonFlag.PROBLEMATIC);
  const isUnproblematic = disabledHandlers.includes(CollisonFlag.UNPROBLEMATIC);

  const actions = new Map<TrademarkActionType, TrademarkActionConfig>([
    [
      TrademarkActionType.PROBLEMATIC,
      {
        enabled: !isProblematic,
        onClick: handleFlagCollision(trademark, CollisonFlag.PROBLEMATIC),
      },
    ],
    [
      TrademarkActionType.UNPROBLEMATIC,
      {
        enabled: !isUnproblematic,
        onClick: handleFlagCollision(trademark, CollisonFlag.UNPROBLEMATIC),
      },
    ],
    [
      TrademarkActionType.ADD_NOTE,
      {
        enabled: false && comments.length === 0,
        // onClick: showComments,
      },
    ],
  ]);

  return (
    <div className={styles.trademarkWrapper}>
      <TrademarkCard key={trademark.id} displayType={displayType} trademark={trademark} actions={actions} />
      {/* TODO: add tm comments */}
      {/* <CardAddonComments
        newTextId={`tm_monitoring_detail_${trademark.id}_${trademark.office}`}
        user={user}
        comments={comments}
        onAddNew={handleNoteSave}
        showAddNew={commentsVisible && comments.length === 0}
        onUpdate={handleNoteSave}
      /> */}
      <div className={clsx(styles.buttonGroupWrapper, "tutorial-monitoring-collisions")}>
        <CollisionFlagButton
          flag={CollisonFlag.UNKNOWN}
          active={!isUnproblematic && !isProblematic}
          onClick={handleFlagCollision(trademark, CollisonFlag.UNKNOWN)}
        />
        <CollisionFlagButton
          flag={CollisonFlag.UNPROBLEMATIC}
          active={isUnproblematic}
          onClick={handleFlagCollision(trademark, CollisonFlag.UNPROBLEMATIC)}
        />
        <CollisionFlagButton
          flag={CollisonFlag.PROBLEMATIC}
          active={isProblematic}
          onClick={handleFlagCollision(trademark, CollisonFlag.PROBLEMATIC)}
        />
      </div>
    </div>
  );
};

type TrademarkListRendererProps = {
  trademarks: Trademark[];
  monitoring: Monitoring;
  user?: User | null;
  comments?: TrademarkComment[];
  displayType: TrademarkCardDisplayType;
  flagType?: CollisonFlag;
};

const TrademarkListRenderer = ({ flagType, monitoring, trademarks, comments = [] }: TrademarkListRendererProps) => {
  const dispatch = useAppDispatch();

  const tmRefs = trademarks.map((trademark) => ({
    trademarkId: trademark.id,
    trademarkOffice: trademark.office,
  }));

  if (!trademarks || trademarks.length === 0) {
    return null;
  }

  const handleFlagGroup = (flag: CollisonFlag) => async () => {
    trackEvent(AppEvent.SET_COLLISION_FLAG_TO_GROUP, {
      flag,
      tmRefs,
      monitoring: monitoring.uid,
    });
    dispatch(
      flagMonitoring({
        id: monitoring.uid,
        flag,
        trademarks: tmRefs,
      }),
    );
  };

  const actions: ResultListActionConfigMap = new Map([
    [
      ResultListActionType.FLAG_ALL_AS_PROBLEMATIC,
      {
        // enabled: flagType !== CollisonFlag.PROBLEMATIC,
        enabled: false,
        onClick: handleFlagGroup(CollisonFlag.PROBLEMATIC),
      },
    ],
    [
      ResultListActionType.ADD_TO_COLLECTION,
      {
        enabled: false,
      },
    ],
    [
      ResultListActionType.FLAG_ALL_AS_IGNORED,
      {
        // enabled: flagType !== CollisonFlag.UNPROBLEMATIC,
        enabled: false,
        onClick: handleFlagGroup(CollisonFlag.UNPROBLEMATIC),
      },
    ],
    [
      ResultListActionType.REMOVE_FLAGS,
      {
        // enabled:
        //   (flagType ? [CollisonFlag.UNPROBLEMATIC, CollisonFlag.PROBLEMATIC].includes(flagType) : false),
        enabled: false,
        onClick: async () => {
          trackEvent(AppEvent.REMOVE_COLLISION_FLAG_FROM_GROUP, {
            trademarks: tmRefs,
            monitoring: monitoring.uid,
          });
          dispatch(unflagMonitoring({ id: monitoring.uid, trademarks: tmRefs }));
        },
      },
    ],
  ]);

  return (
    <TrademarkResultList
      actions={actions}
      trademarks={trademarks}
      renderItem={(displayType, item) => (
        <TrademarkRenderer
          key={item.id}
          trademark={item}
          disabledHandlers={flagType ? [flagType] : []}
          monitoring={monitoring}
          displayType={displayType}
          comments={comments}
        />
      )}
    ></TrademarkResultList>
  );
};

export const MonitoringDetail = () => {
  const { monitoringId } = useParams<{ monitoringId: string }>();
  const monitoring = useAppSelector((state) => state.monitoring.items.find((m) => m.uid === monitoringId));
  // const monitoring = monitorings.find((m) => m.uid === monitoringId);
  // const { uid: monitoringId, note: defaultNote, trademark, comments } = monitoring;
  const user = useAppSelector((state) => state.auth.user);
  const isAdmin = useIsAdmin();
  const dispatch = useAppDispatch();
  const length = monitoring ? monitoring.flaggedTrademarks.length + monitoring.collisions.length : 0;
  const [loading, setLoading] = useState(false);
  const [resolvedTrademarks, setResolvedTrademarks] = useState<Trademark[]>([]);
  const [interval, setInterval] = useState(monitoring?.interval || NotificationInterval.DAILY);
  const displayType = user?.interfaceDefaults?.trademarkDisplayType || TrademarkCardDisplayType.CARD;
  const navigate = useNavigate();

  useEffect(() => {
    if (!monitoring?.interval) {
      return;
    }

    setInterval(monitoring.interval);
  }, [monitoring?.interval]);

  useEffect(() => {
    if (!monitoring) {
      return;
    }
    setLoading(true);

    TrademarkClient.resolve(monitoring.flaggedTrademarks.map((f) => f.trademark).concat(monitoring.collisions))
      .then((result) => setResolvedTrademarks(result.trademarks))
      .finally(() => setLoading(false));

    // HACK: We only resolve trademarks when the monitoring changes or the flagged/unflagged trademarks change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitoringId, length]);

  const monitoringResolved: MonitoringResolved | null = useMemo(
    () =>
      monitoring
        ? {
            ...monitoring,
            flaggedTrademarks: monitoring.flaggedTrademarks
              .map((flaggedTrademark) => {
                const resolvedTrademark = resolvedTrademarks.find(
                  (r) => r.id === flaggedTrademark.trademark.trademarkId,
                );
                if (!resolvedTrademark) {
                  return undefined;
                }
                return {
                  flag: flaggedTrademark.flag,
                  trademark: resolvedTrademark,
                };
              })
              .filter(notEmpty),
            collisions: [
              ...(monitoring?.collisions || [])
                .map((collision) => resolvedTrademarks.find((r) => r.id === collision.trademarkId))
                .filter(notEmpty),
              ...monitoring.flaggedTrademarks
                .filter((tm) => tm.flag === CollisonFlag.UNKNOWN)
                .map((flaggedTrademark) => {
                  const resolvedTrademark = resolvedTrademarks.find(
                    (r) => r.id === flaggedTrademark.trademark.trademarkId,
                  );
                  if (!resolvedTrademark) {
                    return undefined;
                  }
                  return resolvedTrademark;
                })
                .filter(notEmpty),
            ],
          }
        : null,
    [monitoring, resolvedTrademarks],
  );

  if (!monitoring || !monitoringResolved) {
    return null;
  }

  const { uid, trademark, note, comments } = monitoring;

  const { collisions, problematic, unproblematic } = groupTrademarks(monitoringResolved);

  const showConfirmDeleteModal = () => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Überwachung ${trademark.name} löschen?`,
        text: `Möchten Sie die Markenüberwachung für ${trademark.name} wirklich löschen? Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          try {
            trackEvent(AppEvent.DELETE_MONITORING, {
              uid,
            });
            await dispatch(removeMonitoring(uid));
            toast.success("Überwachung gelöscht");
            navigate(ROUTES.MONITORING.path);
          } catch (e) {
            handleError("Überwachung konnte nicht gelöscht werden", e);
          }
        },
      },
    });
  };

  const handleNoteSave = async (t: string) => {
    trackEvent(AppEvent.SAVE_MONITORING_NOTE, {
      uid,
    });
    dispatch(
      updateMonitoring({
        id: uid,
        note: t,
      }),
    );
  };

  const handleIntervalSave = (i: NotificationInterval) => () => {
    trackEvent(AppEvent.SAVE_MONITORING_INTERVAL, {
      uid,
      interval: i,
    });
    setInterval(i);
    dispatch(
      updateMonitoring({
        id: uid,
        interval: i,
      }),
    );

    toast.success("Wir haben das Intervall angepasst und werden es in Zukunft berücksichtigen.", {
      autoClose: 10000,
    });
  };

  const handleSensitivitySave = (s: QueryType[]) => {
    trackEvent(AppEvent.SAVE_MONITORING_INTERVAL, {
      uid,
      sensitivity: s,
    });
    dispatch(
      updateMonitoring({
        id: uid,
        sensitivity: s,
      }),
    );
    toast.success("Wir haben die Empfindlichkeit angepasst und mögliche Kollisionen werden jetzt neu berechnet.", {
      autoClose: 10000,
    });
  };

  const handleDownload = (monitoringId: string, type: ExportType) => () => {
    trackEvent(AppEvent.DOWNLOAD_MONITORING, {
      monitoringId,
      type,
    });
    return MonitoringClient.download(monitoringId, type)
      .then((response) => {
        const date = format(new Date(), "dd-MM-yyyy");

        // FIXME: Don't know if this works on all devices and browsers
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Kollisionsbericht - ${sanitizeForFileName(monitoring.trademark.name)} - ${date}.${type}`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => handleUnknownError(error));
  };

  const showExportModal = () => {
    showModal(dispatch)({
      type: AppModal.EXPORT,
      props: {
        title: "Bericht exportieren",
        onExport: async (type: ExportType) => handleDownload(uid, type)(),
      },
    });
  };

  const showDebugModal = () => {
    showModal(dispatch)({
      type: AppModal.DEBUG,
      props: {
        json: monitoring,
      },
    });
  };

  const menuItems = [
    {
      Icon: ICONS.DOWNLOAD,
      title: "Bericht exportieren",
      onClick: showExportModal,
    },
    {
      Icon: ICONS.DELETE,
      title: "Löschen",
      onClick: showConfirmDeleteModal,
    },
    {
      title: "Debug",
      onClick: showDebugModal,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
  ];

  // const allTrademarks = resolvedFlaggedTrademarks;

  const allUnverified = collisions.map(({ trademarks }) => trademarks).flat();

  const views: TabViewConfig[] = [
    {
      id: "unknown",
      title: "Unverifiziert",
      children:
        allUnverified.length > 0 ? (
          <TrademarkListRenderer
            monitoring={monitoring}
            trademarks={allUnverified}
            flagType={CollisonFlag.UNKNOWN}
            comments={comments}
            displayType={displayType}
            user={user}
          />
        ) : null,
      // default: true,
      button: {
        extra: String(allUnverified.length),
        style: TabButtonStyle.PRIMARY,
      },
    },
    {
      id: "critical",
      title: nameForCollisionFlag[CollisonFlag.PROBLEMATIC],
      children:
        problematic.length > 0 ? (
          <TrademarkListRenderer
            monitoring={monitoring}
            trademarks={problematic}
            flagType={CollisonFlag.PROBLEMATIC}
            comments={comments}
            displayType={displayType}
            user={user}
          />
        ) : null,
      button: {
        extra: String(problematic.length),
        style: TabButtonStyle.DANGER,
      },
    },
    {
      id: "unproblematic",
      title: nameForCollisionFlag[CollisonFlag.UNPROBLEMATIC],
      children:
        unproblematic.length > 0 ? (
          <TrademarkListRenderer
            monitoring={monitoring}
            trademarks={unproblematic}
            flagType={CollisonFlag.UNPROBLEMATIC}
            comments={comments}
            displayType={displayType}
            user={user}
          />
        ) : null,
      button: {
        extra: String(unproblematic.length),
        style: TabButtonStyle.GRAY,
      },
    },
    // {
    //   id: "all",
    //   title: "Alle Marken",
    //   children:
    //     allTrademarks.length > 0 ? (
    //       <TrademarkListRenderer
    //         monitoring={monitoring}
    //         trademarks={allTrademarks}
    //         comments={comments}
    //         displayType={displayType}
    //         user={user}
    //       />
    //     ) : null,
    //   button: {
    //     extra: String(allTrademarks?.length),
    //     style: TabButtonStyle.PRIMARY,
    //   },
    // },
  ];

  const monitoringNotificationSettings = (
    <PopoverContent>
      <CardContentSection
        title={"Empfindlichkeit der Überwachung"}
        description="Hier können Sie die Empfindlichkeit ändern, falls die Überwachung zu viele falsche Kollisionen liefert."
      >
        <FuzzinessSlider queryTypes={monitoring.sensitivity} onChange={handleSensitivitySave} />
      </CardContentSection>
      <CardContentSection
        title={"Intervall für Benachrichtigungen"}
        description="Wählen Sie, wie häufig Sie über Kollisionen benachrichtigt werden möchten."
      >
        <ButtonGroup
          options={[
            {
              id: "Täglich",
              title: "Täglich",
              active: interval === NotificationInterval.DAILY,
              onClick: handleIntervalSave(NotificationInterval.DAILY),
            },
            {
              id: "Wöchentlich",
              title: "Wöchentlich",
              active: interval === NotificationInterval.WEEKLY,
              onClick: handleIntervalSave(NotificationInterval.WEEKLY),
            },
            {
              id: "Monatlich",
              title: "Monatlich",
              active: interval === NotificationInterval.MONTHLY,
              onClick: handleIntervalSave(NotificationInterval.MONTHLY),
            },
          ]}
        />
      </CardContentSection>
    </PopoverContent>
  );

  return (
    <>
      <CardContainer
        header={{
          title: monitoring.trademark.name,
          Icon: ICONS.MONITORING,
          actions: menuItems,
        }}
      >
        <div className={styles.datawrapper}>
          <TrademarkCard displayType={TrademarkCardDisplayType.CARD} trademark={trademark} hideActions={true} />
          <div className={styles.header}>
            <Card>
              <CardContentSection title={"Notiz"}>
                <EditableTextField text={note} onSave={handleNoteSave} />
              </CardContentSection>
            </Card>
            <RenderIfTestAccount>
              <MonitoringComments monitoring={monitoring} />
            </RenderIfTestAccount>
          </div>
        </div>
      </CardContainer>
      <CardContainer
        header={{
          title: "Kollisionen",
          Icon: ICONS.MONITORING,
        }}
        actionContent={
          <Popover
            closeOnInteraction={false}
            buttonElement={() => (
              <Button className="tutorial-monitoring-settings">
                Empfindlichkeit ({queryTypesToMarkText(monitoring.sensitivity)})
              </Button>
            )}
          >
            {monitoringNotificationSettings}
          </Popover>
        }
      >
        {loading ? (
          <LoadingIndicator message="Kollisionen werden geladen" />
        ) : allUnverified.length > 0 || problematic.length > 0 || unproblematic.length > 0 ? (
          <TabLayout
            views={views}
            onChange={(tabId: string) => {
              trackEvent(AppEvent.CHANGE_COLLISION_TAB, {
                tabId,
              });
            }}
          />
        ) : (
          "Keine Kollisionen gefunden"
        )}
      </CardContainer>
      <Tutorial flag={UserFlag.TUTORIAL_TRADEMARK_MONITORING} steps={tutorialSteps} />
    </>
  );
};
