import ReactAvatar from "react-avatar";
import styles from "./Avatar.module.css";

interface Props {
  displayName?: string;
  photoURL?: string;
  size?: number;
  className?: string;
}

export const Avatar = ({ displayName = "", size = 100, photoURL, className }: Props) => {
  return (
    <ReactAvatar
      color={"var(--theme-color-primary"}
      maxInitials={2}
      round={true}
      size={String(size)}
      className={styles.avatar}
      name={displayName}
      src={photoURL}
    />
  );
};
