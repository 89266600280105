import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { Card } from "app/components/Card/Card";
import { Popover, PopoverList } from "app/components/Popover";
import { pluralize } from "app/i18n";
import { GridNavigationItemConfig, GridNavigationLayout } from "app/layouts/GridLayout/GridNavigationLayout";
import { ContextType, Research, SavedSearch } from "app/models";
import {
  AppModal,
  deleteSavedSearch,
  searchSlice,
  showModal,
  updateUserContext,
  useAppDispatch,
  useAppSelector,
} from "app/redux";
import { ROUTES, savedSearchRoute } from "app/routes";
import { handleError } from "app/util/error-handler";
import { notEmpty } from "app/util/filter.util";
import { countFilters, guessSavedSearchTitle } from "app/util/saved-search.util";
import { useNavigate } from "react-router-dom";
import styles from "./ResearchSavedSearches.module.scss";

type Props = {
  research: Research;
};

export const ResearchSavedSearches = ({ research }: Props) => {
  const dispatch = useAppDispatch();
  const savedSearches = useAppSelector((state) => state.search.saved);
  const navigate = useNavigate();
  const handleActivateContext = () => {
    dispatch(
      updateUserContext({
        type: ContextType.RESEARCH,
        researchId: research.uid,
      }),
    );
  };

  const handleNewSearch = () => {
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        query: research.name,
      }),
    );

    dispatch(
      updateUserContext({
        type: ContextType.RESEARCH,
        researchId: research.uid,
      }),
    );
  };

  const showConfirmDeleteModal = (s: SavedSearch) => {
    showModal(dispatch)({
      type: AppModal.CONFIRM_DELETE,
      props: {
        title: `Suche ${guessSavedSearchTitle(s)} löschen?`,
        text: `Sie können diese Aktion nicht rückgängig machen.`,
        onConfirm: async () => {
          try {
            await dispatch(deleteSavedSearch(s.uid)).unwrap();
          } catch (error) {
            handleError("Suche konnte nicht gelöscht werden.", error);
          }
        },
      },
    });
  };

  const items: GridNavigationItemConfig[] =
    research.savedSearches
      ?.map((reference) => savedSearches.find((savedSearch) => savedSearch.uid === reference.savedSearchId))
      .filter(notEmpty)
      .map((savedSearch) => ({
        path: savedSearchRoute(savedSearch.uid),
        title: guessSavedSearchTitle(savedSearch),
        subtitle: countFilters(savedSearch.filters) > 0 ? `${countFilters(savedSearch.filters)} Filter` : "",
        Icon: ICONS.SEARCH,
        createdAt: savedSearch.createdAt,
        onClick: handleActivateContext,
        actions: (
          <Popover>
            <PopoverList
              items={[
                {
                  title: "Löschen",
                  Icon: ICONS.DELETE,
                  onClick: () => showConfirmDeleteModal(savedSearch),
                },
              ]}
            />
          </Popover>
        ),
      }))
      .sort((a, b) => a.title?.toLowerCase().localeCompare(b.title?.toLowerCase())) || [];

  if (items.length === 0) {
    return null;
  }

  return (
    <GridNavigationLayout
      title={`${pluralize("savedSearches", items.length)} in dieser Recherche`}
      titleIcon={ICONS.SEARCH}
      items={items}
      item={true}
    >
      <Card className={styles.addCard} onClick={handleNewSearch}>
        <ICONS.ADD size={ICON_SIZE.LARGE} />
        <span>Neue Suche starten</span>
      </Card>
    </GridNavigationLayout>
  );
};
