import { ICONS, ICON_SIZE } from "app/assets/icons/icons";
import { CardContent } from "app/components/Card/Card";
import { ImageSearchArea } from "app/components/ImageSearchArea/ImageSearchArea";
import { Popover, PopoverList } from "app/components/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { useInterfaceWidth } from "app/hooks";
import { useUserSavedSearches } from "app/hooks/use-user-saved-searches.hook";
import { k, l } from "app/i18n";
import { TabButtonStyle, TabLayout } from "app/layouts/TabLayout/TabLayout";
import { execImageSearch, searchSlice } from "app/redux/slices/search.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { ROUTES } from "app/routes";
import { localizedTimeDistance } from "app/util/date.util";
import { appLogger } from "app/util/logger.util";
import { guessSavedSearchTitle } from "app/util/saved-search.util";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { SearchBy, SearchInput } from "app/views/SearchView/SearchInput/SearchInput";
import clsx from "clsx";
import hotkeys from "hotkeys-js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AppSearchBar.module.scss";

hotkeys("shift+7", function (event) {
  // Prevent the default refresh event under WINDOWS system
  event.preventDefault();

  const elements = document.getElementsByClassName("hotkey-search-input");
  if (elements[0]) {
    (elements[0] as HTMLInputElement).select();
  }

  // Returning false stops the event and prevents default browser events
  return false;
});

export const AppSearchBar = () => {
  const { current } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const [imageSearchOpen, setImageSearchOpen] = useState(false);
  const navigate = useNavigate();
  const { isSm, isLg } = useInterfaceWidth();
  const { items: userVisibleSavedSearches, activateSavedSearch } = useUserSavedSearches();
  const handleQuerySubmit = (query: string, searchBy: SearchBy) => {
    switch (searchBy) {
      case SearchBy.OWNER:
        dispatch(
          searchSlice.actions.startNewSearch({
            query: "",
            owner: query,
            representative: "",
            // XXX: Keep current state (but reset settings like limit, offset)
            filters: current.filters,
            sortBy: current.sortBy,
            sortOrder: current.sortOrder,
            queryType: current.queryType,
          }),
        );
        break;
      case SearchBy.REPRESENTATIVE:
        dispatch(
          searchSlice.actions.startNewSearch({
            query: "",
            owner: "",
            representative: query,
            // XXX: Keep current state (but reset settings like limit, offset)
            filters: current.filters,
            sortBy: current.sortBy,
            sortOrder: current.sortOrder,
            queryType: current.queryType,
          }),
        );
        break;
      default:
        dispatch(
          searchSlice.actions.startNewSearch({
            query: query,
            owner: "",
            representative: "",
            // XXX: Keep current state (but reset settings like limit, offset)
            filters: current.filters,
            sortBy: current.sortBy,
            sortOrder: current.sortOrder,
            queryType: current.queryType,
          }),
        );
        break;
    }
  };

  const handleImageSearch = async (blob: Blob) => {
    appLogger.log("handleImageSearch");
    trackEvent(AppEvent.IMAGE_SEARCH);
    navigate(ROUTES.SEARCH.path);
    setImageSearchOpen(false);
    setMenuOpen(false);
    await execImageSearch(blob, current)(dispatch);
  };

  const isSmallScreen = !isSm;

  const savedSearchContent = (
    <PopoverList
      items={userVisibleSavedSearches.map((i) => ({
        title: guessSavedSearchTitle(i),
        subtitle: localizedTimeDistance(i.createdAt),
        onClick: () => activateSavedSearch(i),
      }))}
    />
  );

  const imageSearchContent = (
    <CardContent>
      <ImageSearchArea onSearch={handleImageSearch} />
    </CardContent>
  );

  return (
    <>
      <div className={clsx(styles.root, "tutorial-search-bar")}>
        <SearchInput onImageSearch={handleImageSearch} onSubmit={handleQuerySubmit} />
        {isSmallScreen ? (
          <Popover
            open={menuOpen}
            placement="bottom"
            closeOnInteraction={false}
            offset={10}
            onOpen={() => {
              setMenuOpen(true);
            }}
            onClose={() => {
              setMenuOpen(false);
            }}
            buttonElement={() => (
              <button className={styles.imageSearchButton} title={"Menü"}>
                <ICONS.MENU size={ICON_SIZE.DEFAULT} />
              </button>
            )}
          >
            <PopoverContent>
              <TabLayout
                views={[
                  {
                    id: "saved-searches",
                    title: l(k.SAVED_SEARCHES),
                    children: savedSearchContent,
                    button: {
                      style: TabButtonStyle.PRIMARY,
                    },
                  },
                  {
                    id: "image-search",
                    title: l(k.IMAGE_SEARCH),
                    children: imageSearchContent,
                    button: {
                      style: TabButtonStyle.PRIMARY,
                    },
                  },
                ]}
              />
            </PopoverContent>
          </Popover>
        ) : (
          <>
            {isLg ? null : (
              <Popover
                placement="bottom"
                offset={10}
                buttonElement={() => (
                  <button className={styles.imageSearchButton} title={"Gespeicherte Suchen"}>
                    <ICONS.SAVED_SEARCHES size={ICON_SIZE.DEFAULT} />
                  </button>
                )}
              >
                <PopoverContent className={styles.savedSearchesPopoverContent}>
                  <div className={styles.savedSearchesPopoverTitle}>
                    <ICONS.SAVED_SEARCHES />
                    {l(k.SAVED_SEARCHES)}
                  </div>
                  {savedSearchContent}
                </PopoverContent>
              </Popover>
            )}
            <Popover
              open={imageSearchOpen}
              closeOnInteraction={false}
              placement="bottom"
              offset={10}
              onOpen={() => {
                setImageSearchOpen(true);
                trackEvent(AppEvent.OPEN_IMAGE_SEARCH);
              }}
              onClose={() => {
                setImageSearchOpen(false);
              }}
              buttonElement={() => (
                <button
                  className={clsx(styles.imageSearchButton, "tutorial-search-image-search")}
                  title={"Bildersuche"}
                >
                  <ICONS.CAMERA size={ICON_SIZE.DEFAULT} />
                </button>
              )}
            >
              <PopoverContent>
                <div className={styles.savedSearchesPopoverTitle}>
                  <ICONS.CAMERA />
                  {l(k.IMAGE_SEARCH)}
                </div>
                <ImageSearchArea onSearch={handleImageSearch} />
              </PopoverContent>
            </Popover>
          </>
        )}
      </div>
    </>
  );
};
