import { Monitoring } from "app/models";
import { toDate } from "./date.util";

export const mapMonitoring = (monitoring: Monitoring): Monitoring => ({
  ...monitoring,
  createdAt: toDate(monitoring.createdAt),
  updatedAt: toDate(monitoring.updatedAt),
  comments:
    monitoring.comments?.map((comment) => ({
      ...comment,
      createdAt: toDate(comment.createdAt),
      updatedAt: toDate(comment.updatedAt),
    })) || [],
  collisions: monitoring.collisions.map((collision) => ({
    ...collision,
    createdAt: toDate(collision.createdAt),
  })),

  // Set defaults
  flaggedTrademarks: monitoring.flaggedTrademarks || [],
});
