import { usePagination } from "app/hooks";
import clsx from "clsx";
import { isMobileOnly } from "react-device-detect";
import { Button, ButtonTheme } from "../Button/Button";
import styles from "./Pagination.module.scss";

type Props = {
  className?: string;
  pages: number;
  currentPage: number;
  onPage: (page: number) => unknown;
  onNext: () => unknown;
  onPrev: () => unknown;
};

export const Pagination = ({ pages, currentPage, onPage, onNext, onPrev, className }: Props) => {
  const pagination = usePagination({ count: pages, page: currentPage, siblingCount: isMobileOnly ? 1 : 3 });

  const handlePage = (page: number) => () => {
    onPage(page);
  };
  const handlePrevPage = () => {
    if (currentPage === 1) {
      return;
    }
    onPrev();
  };
  const handleNextPage = () => {
    if (currentPage === pages) {
      return;
    }
    onNext();
  };

  if (pages <= 1) {
    return null;
  }

  return (
    <div className={clsx(className, styles.root)}>
      {pagination.items.map((item, index) => {
        const key = `${item.page}-${item.type}-${index}`;
        switch (item.type) {
          case "next":
            return (
              <Button
                key={key}
                onClick={handleNextPage}
                theme={ButtonTheme.LINK}
                className={styles.nextButton}
                disabled={currentPage === pages}
              >
                Weiter
              </Button>
            );
          case "previous":
            return (
              <Button
                key={key}
                onClick={handlePrevPage}
                theme={ButtonTheme.LINK}
                className={styles.prevButton}
                disabled={currentPage === 1}
              >
                Zurück
              </Button>
            );
          case "page":
            return (
              <span
                key={key}
                className={clsx(styles.page, {
                  [styles.active]: item.page === currentPage,
                })}
                onClick={handlePage(item.page)}
              >
                {item.page}
              </span>
            );
          case "start-ellipsis":
          case "end-ellipsis":
            return <span key={key}>&hellip;</span>;
        }
        return <span key={key}>{item.type}</span>;
      })}
    </div>
  );
};
