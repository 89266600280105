import { AuthClient } from "app/api/auth.api";
import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { Card, CardContent } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { useAppForm, useAppSearchParams } from "app/hooks";
import { loginOrFail } from "app/redux/slices/auth.slice";
import { useAppDispatch } from "app/redux/store.hooks";
import { NO_AUTH_ROUTES } from "app/routes";
import { handleUnknownError, handleWarning } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import sharedStyles from "./../SharedNoAuthViewStyles.module.css";
import styles from "./SignupCard.module.css";

type FormData = {
  name: string;
  email: string;
  password: string;
};

const PAGE_NAME = "Signup";

export const SignupCard = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [{ ref, email: queryEmail }] = useAppSearchParams({
    ref: "",
    email: "",
  });

  const { register, registerEmailField, registerPasswordField, handleSubmit, errors, isDirty } = useAppForm<FormData>({
    defaultValues: {
      name: "",
      email: queryEmail || "",
      password: "",
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      setLoading(true);
      await AuthClient.signupWithCredentials(data.name, data.email, data.password);
      trackEvent(AppEvent.SIGNUP, {
        page: PAGE_NAME,
        ref,
      });
      await loginOrFail(dispatch)(data.email, data.password);
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        trackEvent(AppEvent.SIGNUP_OR_LOGIN_FAILED, {
          page: PAGE_NAME,
          ref,
          status: String(error.response && error.response.status) || "unknown",
        });
        if (error.response && error.response.status === 409) {
          handleWarning(
            <span>
              Diese E-Mail Adresse wird bereits verwendet. <Link to={NO_AUTH_ROUTES.LOGIN}>Zum Login</Link>
            </span>,
            error,
          );
          return;
        }
      }
      trackEvent(AppEvent.SIGNUP_OR_LOGIN_FAILED, {
        page: PAGE_NAME,
        ref,
        status: (error.response && error.response.status) || "unknown",
      });
      console.error("Error signing up", error);
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const nameHasErrors = Boolean(errors.name) && isDirty;
  const emailHasErrors = Boolean(errors.email) && isDirty;
  const passwordHasErrors = Boolean(errors.password) && isDirty;

  return (
    <Card className={sharedStyles.root}>
      <CardContent>
        <div className={sharedStyles.headline}>Jetzt kostenlos anmelden</div>
        <p className={sharedStyles.subheadline}>
          Bitte geben Sie Ihren Namen, Ihre E-Mail Adresse und Ihr Passwort ein, um sich anzumelden.
        </p>
        <form className={sharedStyles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...register("name", {
              minLength: 1,
              required: true,
            })}
            name="name"
            placeholder="Hanna Müller"
            valid={!nameHasErrors}
            label={nameHasErrors ? "Bitte geben Sie Ihren Namen ein" : "Name"}
          />
          <Input
            {...registerEmailField("email")}
            type="email"
            name="email"
            placeholder="hanna.mueller@beispiel.de"
            valid={!emailHasErrors}
            label={emailHasErrors ? "Bitte geben Sie eine vollständige E-Mail Adresse ein" : "E-Mail"}
          />
          <Input
            {...registerPasswordField("password")}
            type="password"
            name="password"
            placeholder="Wählen Sie ein sicheres Passwort"
            valid={!passwordHasErrors}
            label={passwordHasErrors ? "Das Passwort sollte mindestens 8 Stellen haben" : "Passwort"}
          />
          <div className={styles.legalBox}>
            Mit der Bestätigung des Formulars stimme ich den{" "}
            <a className={styles.inlineModalLink} href={APP_CONST.LINKS.AGB} target="_blank" rel="noreferrer noopener">
              Allgemeinen Geschäftsbedingungen
            </a>
            , sowie der{" "}
            <a
              className={styles.inlineModalLink}
              href={APP_CONST.LINKS.PRIVACY_POLICY}
              target="_blank"
              rel="noreferrer noopener"
            >
              Datenschutzerklärung
            </a>{" "}
            zu.
          </div>
          <Button type="submit" loading={loading} className={sharedStyles.button}>
            Jetzt kostenlos anmelden
          </Button>
        </form>
        <p className={sharedStyles.hint}>
          Sie haben bereits einen Account? <Link to={NO_AUTH_ROUTES.LOGIN}>Jetzt einloggen</Link>
        </p>
      </CardContent>
    </Card>
  );
};
