import clsx from "clsx";
import styles from "./Badge.module.css";

export type BadgeProps = {
  className?: string;
  children?: string;
  noBg?: boolean;
};

export const Badge = ({ className, noBg, children }: BadgeProps) => {
  return (
    <div
      className={clsx(styles.root, className, {
        [styles.noBg]: noBg,
      })}
    >
      {children}
    </div>
  );
};
