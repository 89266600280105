import { ICONS } from "app/assets/icons/icons";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import styles from "./ImagePicker.module.scss";

type Props = {
  onUpload: (image: File) => void;
  file?: File;
  url?: string;
  text?: string;
  className?: string;
};
export const ImagePicker = ({ onUpload, file, url, text, className }: Props) => {
  const onDrop = async (files: File[]) => {
    if (files.length > 0) {
      onUpload(files[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        className={clsx(
          styles.root,
          {
            [styles.active]: isDragActive,
          },
          className,
        )}
        title={text}
      >
        <input {...getInputProps()} accept="image/*" />
        {file ? (
          <img className={styles.image} src={URL.createObjectURL(file)} alt="" height="auto" width="auto" />
        ) : url ? (
          <img className={styles.image} src={url} alt="" height="auto" width="auto" />
        ) : (
          <div className={styles.content}>
            <ICONS.CAMERA size={24} />
            <p>{text}</p>
          </div>
        )}
      </div>
    </div>
  );
};
