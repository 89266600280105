import { useHasSomeUserFlag } from "app/hooks";
import { UserFlag } from "app/models";
import clsx from "clsx";
import { HTMLAttributes } from "react";
import styles from "./RenderIf.module.scss";

type Props = {
  padding?: boolean;
  center?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const RenderIfAdmin = ({ children, className, ...rest }: Props) => {
  return (
    <RequireUserFlag
      flags={[UserFlag.PLATFORM_ADMIN]}
      className={clsx({ [styles.isAdmin]: true }, className)}
      title={"Nur für Ben und Peter"}
      {...rest}
    >
      {children}
    </RequireUserFlag>
  );
};

export const RenderIfTestAccount = ({ children, className, ...rest }: Props) => {
  return (
    <RequireUserFlag
      flags={[UserFlag.PLATFORM_ADMIN, UserFlag.TEST_ACCOUNT]}
      className={clsx({ [styles.isTestAccount]: true }, className)}
      title={"Nur für Test Accounts"}
      {...rest}
    >
      {children}
    </RequireUserFlag>
  );
};

export const RenderIfBeta = ({ children, className, ...rest }: Props) => {
  return (
    <RequireUserFlag
      flags={[UserFlag.PLATFORM_ADMIN, UserFlag.TEST_ACCOUNT, UserFlag.BETA_FEATURES]}
      className={clsx({ [styles.isBeta]: true }, className)}
      {...rest}
    >
      {children}
    </RequireUserFlag>
  );
};

type RequireUserFlagProps = {
  flags?: UserFlag[];
  padding?: boolean;
  center?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const RequireUserFlag = ({ children, flags, className, padding, center, ...rest }: RequireUserFlagProps) => {
  const hasFlag = useHasSomeUserFlag(flags || []);

  if (!hasFlag) {
    return null;
  }

  return (
    <div
      className={clsx(
        {
          [styles.padding]: padding,
          [styles.center]: center,
        },
        className,
      )}
      {...rest}
    >
      {children}
    </div>
  );
};
