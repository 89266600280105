import { Modal, ModalContent } from "app/components/Modal/Modal";
import { ViennaClassDescription } from "app/components/ViennaClassDescription/ViennaClassDescription";
import { Trademark } from "app/models/trademark.model";
import { selectViennaClasses } from "app/redux/slices/vienna.slice";
import { useAppSelector } from "app/redux/store.hooks";
import styles from "./ViennaClassModal.module.css";
interface ViennaClassModalProps {
  open: boolean;
  onClose: () => void;
  trademark?: Trademark | null;
}

export const ViennaClassModal = ({ open, onClose, trademark }: ViennaClassModalProps) => {
  const classInfos = useAppSelector(selectViennaClasses(trademark?.viennaClasses || []));

  const title = (
    <>
      Wiener-Klassen der Marke <span className={styles.titleHighlight}>{trademark?.name || ""}</span>
    </>
  );

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <ModalContent>
        {classInfos.map((ci) => (
          <ViennaClassDescription key={ci.viennaId} viennaClass={ci} />
        ))}
      </ModalContent>
    </Modal>
  );
};
