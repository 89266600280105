import { Button, ButtonTheme } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { useModalToggle } from "app/hooks";
import { deleteWorkspace } from "app/redux";
import { useAppDispatch } from "app/redux/store.hooks";
import { handleUnknownError } from "app/util/error-handler";
import { ChangeEvent, useState } from "react";

export const WorkspaceCancellation = () => {
  const [confirmText, setConfirmText] = useState("");
  const [cancelOpen, openModal, closeModal] = useModalToggle();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handlePremiumCancel = async () => {
    try {
      setLoading(true);

      await dispatch(deleteWorkspace());

      closeModal();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  return (
    <div>
      <Button theme={ButtonTheme.LINK} onClick={openModal} disabled={loading}>
        Workspace löschen
      </Button>
      <Modal open={cancelOpen} onClose={closeModal} title="Workspace löschen">
        <ModalContent>
          <p>
            Sind Sie sicher, dass Sie Ihren Workspace löschen möchten? Um die Aktion zu bestätigen, geben Sie bitte das
            Wort{" "}
            <strong>
              <i>Löschen</i>
            </strong>{" "}
            ein.
          </p>
          <Input value={confirmText} onChange={handleInputChange} />
          <br />
        </ModalContent>
        <ModalActions>
          <Button
            theme={ButtonTheme.DANGER}
            onClick={handlePremiumCancel}
            loading={loading}
            disabled={confirmText !== "Löschen"}
          >
            Workspace löschen
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
