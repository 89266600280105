import { CardContainer } from "app/components/CardContainer/CardContainer";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { WorkspaceEmptyState } from "app/components/WorkspaceEmptyState/WorkspaceEmptyState";
import { fetchWorkspaceUsers } from "app/redux/slices/workspace.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { useEffect } from "react";
import styles from "./SettingsPageWorkspace.module.scss";
import { SettingsPageWorkspaceBranding } from "./SettingsPageWorkspaceBranding/SettingsPageWorkspaceBranding";
import { SettingsPageWorkspaceDebug } from "./SettingsPageWorkspaceDebug/SettingsPageWorkspaceDebug";
import { SettingsPageWorkspaceSettings } from "./SettingsPageWorkspaceSettings/SettingsPageWorkspaceSettings";
import { SettingsPageWorkspaceUsers } from "./SettingsPageWorkspaceUsers/SettingsPageWorkspaceUsers";

export const SettingsPageWorkspace = () => {
  const { workspace, loading: workspaceLoading } = useAppSelector((state) => state.workspace);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchWorkspaceUsers());
  }, [dispatch, workspace?.users]);

  if (workspaceLoading) {
    return <LoadingIndicator />;
  }

  if (!workspace) {
    return <WorkspaceEmptyState />;
  }

  return (
    <CardContainer className={styles.firstCard}>
      <SettingsPageWorkspaceSettings workspace={workspace} />
      <SettingsPageWorkspaceBranding workspace={workspace} />
      <SettingsPageWorkspaceUsers workspace={workspace} />
      <SettingsPageWorkspaceDebug />
    </CardContainer>
  );
};
