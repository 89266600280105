import { ICONS } from "app/assets/icons/icons";
import { Card, CardContentSection } from "app/components/Card/Card";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { EditableTextField } from "app/components/EditableTextField/EditableTextField";
import { PopoverListItem } from "app/components/Popover";
import { useIsAdmin } from "app/hooks";
import { Research } from "app/models";
import { useAppSelector } from "app/redux";
import { localizedTimeDistance } from "app/util/date.util";
import styles from "./ResearchHeader.module.scss";

type Props = {
  research: Research;
  onExport: () => unknown;
  // onActivateContext: () => unknown;
  onRename: () => unknown;
  onDelete: () => unknown;
  onDebug: () => unknown;
  onSaveNote: (note: string) => Promise<unknown>;
};

export const ResearchHeader = ({
  research,
  // onActivateContext,
  onExport,
  onRename,
  onDelete,
  onDebug,
  onSaveNote,
}: Props) => {
  const isAdmin = useIsAdmin();
  const researchUser = useAppSelector((state) =>
    state.auth.user?.userId === research.userId
      ? state.auth.user
      : state.workspace.users.find((u) => u.userId === research?.userId),
  );

  // const context = useActiveContext();

  let menuItems: PopoverListItem[] = [
    {
      Icon: ICONS.EDIT,
      title: "Umbenennen",
      onClick: onRename,
    },
    {
      title: "Debug",
      onClick: onDebug,
      Icon: ICONS.DEBUG,
      hidden: !isAdmin,
    },
    {
      Icon: ICONS.DELETE,
      title: `Löschen`,
      onClick: onDelete,
    },
  ];

  return (
    <CardContainer
      header={{
        title: research.name,
        subtitle: `Erstellt ${localizedTimeDistance(research.createdAt || new Date())} von ${
          researchUser?.name || "Unbekannt"
        }`,
        Icon: ICONS.RESEARCH,
        actions: menuItems,
      }}
    >
      <div className={styles.wrapper}>
        <Card className="tutorial-research-notes">
          <CardContentSection title={"Notizen für Mandanten"}>
            <EditableTextField text={research.note} onSave={onSaveNote} />
          </CardContentSection>
        </Card>
      </div>
      {/* <div style={{ display: "flex", marginTop: 16 }}>
        {research.trademarks && research.trademarks?.length === 0 ? (
          <Button onClick={onExport} style={{ marginRight: 8 }} theme={ButtonTheme.TRANSPARENT}>
            Recherchebericht generieren
          </Button>
        ) : null}
        {context?.research?.uid !== research.uid ? (
          <Button onClick={onActivateContext} theme={ButtonTheme.OUTLINE}>
            Rechereche fortsetzen
          </Button>
        ) : null}
      </div> */}
    </CardContainer>
  );
};
