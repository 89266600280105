import clsx from "clsx";
import styles from "./PopoverContent.module.scss";

export type PopoverContentProps = {
  children?: React.ReactNode;
  className?: string;
};

export const PopoverContent = ({ children, className }: PopoverContentProps) => {
  return <div className={clsx(styles.root, className)}>{children}</div>;
};
