import { ICONS } from "app/assets/icons/icons";
import { AppTextEditor } from "app/components/AppTextEditor/AppTextEditor";
import { Button, ButtonTheme } from "app/components/Button/Button";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { CardWithActions } from "app/components/CardWithActions/CardWithActions";
import { DisplayTypeSwitch } from "app/components/DisplayTypeSwitch/DisplayTypeSwitch";
import { LoadingIndicator } from "app/components/LoadingIndicator/LoadingIndicator";
import { Popover, PopoverList, PopoverListItem } from "app/components/Popover";
import {
  TrademarkActionConfig,
  TrademarkActionType,
} from "app/components/TrademarkCard/TrademarkActions/TrademarkActions";
import { TrademarkCard, TrademarkCardDisplayType } from "app/components/TrademarkCard/TrademarkCard";
import { TrademarkResultGroup } from "app/components/TrademarkResultGroup/TrademarkResultGroup";
import { TrademarkReference, TrademarkReferenceWithNote } from "app/models";
import { TrademarkComment } from "app/models/trademark-comment.model";
import { TrademarkWithNote } from "app/models/trademark.model";
import { useAppSelector } from "app/redux";
import { handleError } from "app/util/error-handler";
import { toTrademarkIdString, toTrademarkReference } from "app/util/trademark.util";
import { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDragHandleProps,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { toast } from "react-toastify";
import styles from "./EditableTrademarkList.module.scss";

type Props = {
  loading: boolean;
  loadingMessage: string;
  emptyState: React.ReactNode;
  title: string;
  trademarks: TrademarkWithNote[];
  onAddTrademarks: () => Promise<void>;
  onSaveSortOrder: (trademarkReferences: TrademarkReference[]) => Promise<void>;
  onRemoveTrademarks: (trademarkReferences: TrademarkReference[]) => Promise<void>;
  onTrademarkNoteUpdate: (trademarkReference: TrademarkReferenceWithNote) => Promise<void>;
};

export const EditableTrademarkList = ({
  loading,
  loadingMessage,
  emptyState,
  title,
  trademarks,
  onSaveSortOrder,
  onAddTrademarks,
  onRemoveTrademarks,
  onTrademarkNoteUpdate,
}: Props) => {
  const user = useAppSelector((state) => state.auth.user);
  const [displayType, setDisplayType] = useState(
    user?.interfaceDefaults?.trademarkDisplayType || TrademarkCardDisplayType.CARD,
  );

  // FIXME: Dragging is disabled if image grid is enabled
  // react-beautiful-dnd doesn't play well with grid layouts
  const draggingDisabled = displayType === TrademarkCardDisplayType.IMAGE;

  // XXX: We keep a copy of the sort order in component state to allow optimistic updates
  const [sortOrder, setSortOrder] = useState(trademarks.map(toTrademarkIdString));
  const sortedTrademarks = useMemo(
    () =>
      [...trademarks].sort(
        (a, b) =>
          sortOrder.findIndex((c) => c === toTrademarkIdString(a)) -
          sortOrder.findIndex((c) => c === toTrademarkIdString(b)),
      ),
    [sortOrder, trademarks],
  );

  // Sync sort order on e.g. websocket update
  useEffect(() => {
    setSortOrder(trademarks.map(toTrademarkIdString));
  }, [trademarks]);

  // Set new sort order on drop
  const onDragEnd = async (result: DropResult) => {
    const dragIndex = result.source.index;
    const dropIndex = result.destination?.index;

    // Nothing to do if nothing changed
    if (dropIndex === undefined || dragIndex === dropIndex) {
      return;
    }

    // Update local state for optimistic update
    const oldSortOrder = [...sortOrder];
    const droppedTrademark = oldSortOrder[dragIndex];
    const newSortOrder = [...sortOrder];
    newSortOrder.splice(dragIndex, 1);
    newSortOrder.splice(dropIndex, 0, droppedTrademark);
    setSortOrder(newSortOrder);

    try {
      // Update remote
      const sortedTrademarkReferences = [...trademarks]
        .sort(
          (a, b) =>
            newSortOrder.findIndex((i) => i === toTrademarkIdString(a)) -
            newSortOrder.findIndex((i) => i === toTrademarkIdString(b)),
        )
        .map(toTrademarkReference);

      await onSaveSortOrder(sortedTrademarkReferences);
    } catch (error: unknown) {
      // Rollback on error
      setSortOrder(oldSortOrder);

      handleError("Die Sortierung konnte nicht gespeichert werden.", error);
    }
  };

  const handleRemoveTrademark = (trademarkReference: TrademarkReference) => () =>
    onRemoveTrademarks([trademarkReference]);

  const handleNoteSave = (trademarkReference: TrademarkReference) => async (note: string) =>
    onTrademarkNoteUpdate({
      ...trademarkReference,
      note,
    });

  return (
    <CardContainer
      header={{
        title,
        Icon: ICONS.TRADEMARK,
      }}
    >
      {sortedTrademarks.length > 0 ? (
        <div className={styles.toolbar}>
          <DisplayTypeSwitch type={displayType} onChange={setDisplayType} />
        </div>
      ) : null}
      <div className={styles.content}>
        {loading ? (
          <LoadingIndicator message={loadingMessage} />
        ) : sortedTrademarks.length > 0 ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="trademark-list">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <TrademarkResultGroup
                      hideHeader={true}
                      title={""}
                      isGrid={displayType === TrademarkCardDisplayType.IMAGE}
                      items={sortedTrademarks}
                      renderItem={(trademark, i) => (
                        <Draggable
                          key={toTrademarkIdString(trademark)}
                          draggableId={toTrademarkIdString(trademark)}
                          index={i}
                          isDragDisabled={draggingDisabled}
                        >
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <TrademarkRenderer
                                comments={[]}
                                trademark={trademark}
                                displayType={displayType}
                                onRemoveTrademark={handleRemoveTrademark(toTrademarkReference(trademark))}
                                onNoteSave={handleNoteSave(toTrademarkReference(trademark))}
                                dragHandleProps={provided.dragHandleProps}
                              />
                            </div>
                          )}
                        </Draggable>
                      )}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button onClick={onAddTrademarks} theme={ButtonTheme.TRANSPARENT} className={styles.addButton}>
              Marken hinzufügen
            </Button>
          </>
        ) : (
          emptyState
        )}
      </div>
    </CardContainer>
  );
};

type TrademarkRendererProps = {
  trademark: TrademarkWithNote;
  comments: TrademarkComment[];
  displayType: TrademarkCardDisplayType;
  onRemoveTrademark: () => Promise<void>;
  onNoteSave: (note: string) => Promise<void>;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

const TrademarkRenderer = ({
  trademark,
  displayType,
  onRemoveTrademark,
  onNoteSave,
  dragHandleProps,
}: TrademarkRendererProps) => {
  const [commentsVisible, setCommentsVisible] = useState(false);

  const showEditor = trademark.note || commentsVisible;

  const showComments = () => {
    setCommentsVisible(true);
  };

  const handleRemoveTrademark = async () => {
    try {
      await onRemoveTrademark();
    } catch {
      toast.error(`Marke konnte nicht entfernt werden`);
    }
  };

  const handleNoteSave = async (note: string) => {
    try {
      await onNoteSave(note);
    } catch (error) {
      handleError("Notiz konnte nicht gespeichert werden", error);
    }
  };

  const handleNoteDelete = async () => {
    try {
      await onNoteSave("");
      setCommentsVisible(false);
    } catch (error) {
      handleError("Notiz konnte nicht gelöscht werden", error);
    }
  };

  const tmActions = new Map<TrademarkActionType, TrademarkActionConfig>([
    [
      TrademarkActionType.ADD_NOTE,
      {
        enabled: !trademark.note,
        onClick: showComments,
      },
    ],
    [
      TrademarkActionType.REMOVE,
      {
        enabled: true,
        onClick: handleRemoveTrademark,
      },
    ],
  ]);

  const menuItems: PopoverListItem[] = [
    {
      title: "Löschen",
      onClick: handleNoteDelete,
      Icon: ICONS.DELETE,
    },
  ];

  const noteActions = (
    <Popover>
      <PopoverList items={menuItems} />
    </Popover>
  );

  return (
    <>
      <TrademarkCard
        actions={tmActions}
        key={trademark.id}
        displayType={displayType}
        trademark={trademark}
        isDraggable
        dragHandleProps={dragHandleProps}
      />
      {showEditor ? (
        <CardWithActions className={styles.note} actions={noteActions}>
          <AppTextEditor defaultValue={trademark.note} onSave={handleNoteSave} defaultEditable={commentsVisible} />
        </CardWithActions>
      ) : null}
    </>
  );
};
