import { localizedAmount } from "app/util/currency.util";
import { localizedDate } from "app/util/date.util";
import clsx from "clsx";
import { Invoice } from "../../../../models/payment-information.model";
import styles from "./InvoiceList.module.scss";
interface InvoiceListProps {
  invoices: Invoice[];
}

export const InvoiceList = ({ invoices }: InvoiceListProps) => {
  return (
    <div className={styles.wrapper}>
      {invoices.map((invoice, index) => (
        <div
          className={clsx(styles.invoice, {
            [styles.invoiceBg]: index % 2 === 0,
          })}
          key={invoice.id}
        >
          <div className={styles.invoiceTitle}>
            <h4>{localizedDate(new Date(invoice.created))}</h4>
            <span>{localizedAmount(invoice.amount / 100)}</span>
          </div>
          <a href={invoice.downloadUrl} target="_blank" rel="noreferrer nofollow noopener">
            Download
          </a>
        </div>
      ))}
    </div>
  );
};
