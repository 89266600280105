import { Button, ButtonTheme } from "app/components/Button/Button";
import { Card, CardActions, CardContent, CardContentSection, CardTitle } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { useAppForm } from "app/hooks";
import { Partner } from "app/models";
import { updatePartner, useAppDispatch } from "app/redux";
import { handleError } from "app/util/error-handler";
import { AppEvent, trackEvent } from "app/util/tracking.util";
import { useState } from "react";

type Props = {
  partner: Partner;
};

export const PartnerContactSettings = ({ partner }: Props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const defaultFormValues = getDefaultFormData(partner);

  const { handleSubmit, register, isValid, isDirty, setValue, reset } = useAppForm<Partner>({
    defaultValues: defaultFormValues,
  });

  const handleResetForm = () => {
    reset(getDefaultFormData(partner));
  };

  const handleUpdate = async (data: Partner) => {
    if (!partner?.uid) {
      return;
    }
    if (!isValid || !isDirty) {
      return;
    }
    setLoading(true);
    try {
      trackEvent(AppEvent.UPDATE_PARTNER, data);
      await dispatch(updatePartner({ id: partner.uid, partner: data })).unwrap();
    } catch (error) {
      handleError("Partnerseite konnte nicht aktualisiert werden.", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTestData = async () => {
    await reset(getTestData(partner));
    setValue("email", "info@oktomark.de", { shouldDirty: true });
  };

  return (
    <Card item>
      <CardTitle
        title={`Kontaktdetails`}
        subtitle="Hinterlegen Sie Ihre offiziellen Kontaktdaten und verlinken Sie Ihre Social Media Profile"
      />
      <form onSubmit={handleSubmit(handleUpdate)}>
        <CardContent>
          <CardContentSection title="Anschrift" description="So können Kunden Sie erreichen.">
            <Input type="text" label="Name" placeholder="Beispiel Kanzlei" {...register("fullName")} />
            <Input type="address" label="Addresszeile 1" placeholder="Musterstraße 1" {...register("address1")} />
            <Input type="address" label="Addresszeile 2" placeholder="12345 Musterstadt" {...register("address2")} />
            <Input type="tel" label="Telefon" placeholder="+491523123247" {...register("phone")} />
            <Input type="email" label="E-Mail" placeholder="hanna@example.com" {...register("email")} />
            <Input type="url" label="Webseite" placeholder="https://example.com" {...register("website")} />
          </CardContentSection>
          <CardContentSection title={"Social Media"} description="Verlinken Sie Ihre Social Media Profile und Kanäle.">
            <Input type="url" label="Twitter" placeholder="https://twitter.com/..." {...register("twitter")} />
            <Input type="url" label="LinkedIn" placeholder="https://linkedin.com/..." {...register("linkedin")} />
            <Input type="url" label="Facebook" placeholder="https://facebook.com/..." {...register("facebook")} />
            <Input type="url" label="Instagram" placeholder="https://instagram.com/..." {...register("instagram")} />
            <Input type="url" label="YouTube" placeholder="https://youtube.com/..." {...register("youtube")} />
            <Input type="url" label="TikTok" placeholder="https://tiktok.com/..." {...register("tiktok")} />
          </CardContentSection>
        </CardContent>
        <CardActions>
          <Button type="submit" loading={loading} disabled={!isValid || !isDirty}>
            Kontaktdetails aktualieren
          </Button>
          <Button type="button" onClick={handleResetForm} theme={ButtonTheme.LINK} disabled={loading || !isDirty}>
            Abbrechen
          </Button>
          <RenderIfTestAccount>
            <Button type="button" onClick={handleTestData} theme={ButtonTheme.LINK} disabled={loading}>
              Vorausfüllen
            </Button>
          </RenderIfTestAccount>
        </CardActions>
      </form>
    </Card>
  );
};

function getDefaultFormData(partner: Partner) {
  return {
    fullName: partner.fullName,
    address1: partner.address1,
    address2: partner.address2,
    phone: partner.phone,
    email: partner.email,
    website: partner.website,

    youtube: partner.youtube,
    linkedin: partner.linkedin,
    twitter: partner.twitter,
    facebook: partner.facebook,
    instagram: partner.instagram,
    tiktok: partner.tiktok,
  };
}

function getTestData(partner: Partner) {
  return {
    ...getDefaultFormData(partner),
    fullName: partner.fullName || "Einsen und Nullen UG (haftungsbeschränkt)",
    address1: partner.address1 || "Mintropstraße 5",
    address2: partner.address2 || "40215 Düsseldorf",
    phone: partner.phone || "+4921194256980",
    email: partner.email || "test@example.com",
    website: partner.website || "https://oktomark.de/",
    linkedin: partner.linkedin || "https://www.linkedin.com/company/oktomark/",
    twitter: partner.twitter || "https://twitter.com/OktomarkDE",
  };
}
