import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllNiceClasses } from "app/api";
import { NiceClass } from "app/models";

type NiceClassMap = {
  [key: string]: NiceClass;
};

export interface NizzState {
  items: NiceClass[];
  map: NiceClassMap;
  loading: boolean;
}

const initialState: NizzState = {
  items: [],
  map: {},
  loading: false,
};

//
// Actions
// Just a stupid action creator wrapping API calls
//
// Example usage: dispatch(fetchSystemInfo())
//
export const fetchNizza = createAsyncThunk("nizza/fetch", () => fetchAllNiceClasses(2020, "de"));

//
// Reducer:
// State will be inferred from initial state
// Generates optimistic actions from above actions
//
const nizzaSlice = createSlice({
  name: "nizza",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fulfilled
      .addCase(fetchNizza.fulfilled, (state, action) => {
        state.items = action.payload;
        state.map = action.payload.reduce((prev: NiceClassMap, curr) => {
          prev[curr.classNumber] = curr;
          return prev;
        }, {});
        state.loading = false;
      })

      // Pending
      .addCase(fetchNizza.pending, (state) => {
        state.loading = true;
      })

      // Rejected
      .addCase(fetchNizza.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const nizzaReducer = nizzaSlice.reducer;
