import { getClient } from "app/api";
import { Context, InterfaceDefaults, Notifications, User, UserFlag } from "app/models/user.model";

export const sendFeedback = async (text: string, meta: object | null, token?: string | null) => {
  const client = await getClient();
  const requestOptions = token
    ? {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    : {};

  const { data } = await client.post(
    "/feedback",
    {
      text,
      meta,
    },
    requestOptions,
  );

  return data;
};

const client = getClient({ basePath: "/user" });

export const UserClient = {
  updateNotifications: async (notifications: Notifications) => {
    const { data } = await client.put<User>("/notifications", notifications);
    return data;
  },
  updateInterfaceDefaults: async (defaults: InterfaceDefaults) => {
    const { data } = await client.put<User>("/interfaceDefaults", defaults);
    return data;
  },
  updateContext: async (context: Context) => {
    const { data } = await client.put<User>("/context", context);
    return data;
  },
  clearContext: async () => {
    const { data } = await client.delete<User>("/context");
    return data;
  },
  removeFlag: async (flag: UserFlag) => {
    const { data } = await client.delete<User>(`/flags/${flag}`);
    return data;
  },
  addFlag: async (flag: UserFlag) => {
    const client = await getClient();
    const { data } = await client.put<User>(`/flags/${flag}`);
    return data;
  },
  update: async (user: Partial<Omit<User, "id">>) => {
    const client = await getClient();
    const { data } = await client.put<User>("/user", user);
    return data;
  },
  updatePhoto: async (blob: Blob) => {
    const formData = new FormData();
    formData.append("image", blob);
    const { data } = await client.put<User>("/photo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  },
  deletePhoto: async () => {
    const { data } = await client.delete<User>("/photo");
    return data;
  },
};
