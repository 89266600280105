export enum CommentVisibility {
  PUBLIC = "PUBLIC",
  PRIVATE = "PRIVATE",
  // TODO: Extend with additional visibilities like
  // WORKSPACE_PRIVATE = 'WORKSPACE_PRIVATE',
}

export interface TrademarkComment {
  uid: string;
  userId: string;
  comment: string;
  visibility: CommentVisibility;
  createdAt?: Date;
  updatedAt?: Date;
}
