import { Button, ButtonTheme } from "app/components/Button/Button";
import { Input } from "app/components/Input/Input";
import { Modal, ModalActions, ModalContent } from "app/components/Modal/Modal";
import { useModalToggle } from "app/hooks";
import { cancelPremium } from "app/redux/slices/payment.slice";
import { useAppDispatch, useAppSelector } from "app/redux/store.hooks";
import { handleUnknownError } from "app/util/error-handler";
import { ChangeEvent, useState } from "react";

export const PaymentCancellation = () => {
  const [confirmText, setConfirmText] = useState("");
  const [cancelOpen, openModal, closeModal] = useModalToggle();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const needsSubscription = useAppSelector((state) => state.payment.needsSubscription);

  const handlePremiumCancel = async () => {
    try {
      setLoading(true);

      await dispatch(cancelPremium());

      closeModal();
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmText(e.target.value);
  };

  return (
    <div>
      <Button theme={ButtonTheme.LINK} onClick={openModal} disabled={loading || needsSubscription}>
        Workspace Abonnement kündigen
      </Button>
      <Modal open={cancelOpen} onClose={closeModal} title="Workspace Abonnement kündigen">
        <ModalContent>
          <p>
            Sind Sie sicher, dass Sie das Abonnement für Ihren Workspace und Ihre Mitglieder kündigen möchten? Um Ihre
            Kündigung zu bestätigen, geben Sie bitte das Wort{" "}
            <strong>
              <i>Kündigen</i>
            </strong>{" "}
            ein.
          </p>
          <Input value={confirmText} onChange={handleInputChange} />
          <br />
        </ModalContent>
        <ModalActions>
          <Button
            theme={ButtonTheme.DANGER}
            onClick={handlePremiumCancel}
            loading={loading}
            disabled={confirmText !== "Kündigen"}
          >
            Workspace Abonnement kündigen
          </Button>
        </ModalActions>
      </Modal>
    </div>
  );
};
