import { Trademark } from "app/models/trademark.model";
import { searchSlice, useAppDispatch } from "app/redux";
import { ROUTES } from "app/routes";
import { useNavigate } from "react-router-dom";

export function useQuickSearch() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchByTrademarkOwner = (owner?: string) => () => {
    if (!owner) {
      return;
    }
    dispatch(
      searchSlice.actions.startNewSearch({
        owner: owner,
      }),
    );
    navigate(ROUTES.SEARCH.path);
  };
  const searchByTrademarkRepresentative = (representative?: string) => () => {
    if (!representative) {
      return;
    }
    dispatch(
      searchSlice.actions.startNewSearch({
        representative: representative,
      }),
    );
    navigate(ROUTES.SEARCH.path);
  };

  const searchByTrademarkName = (trademark: Trademark) => () => {
    navigate(ROUTES.SEARCH.path);
    dispatch(
      searchSlice.actions.startNewSearch({
        query: trademark.name,
      }),
    );
  };

  return {
    searchByTrademarkRepresentative,
    searchByTrademarkOwner,
    searchByTrademarkName,
  };
}
