import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast--dark {
    background: #121212;
    color: #fff;
  }
  .Toastify__toast--default {
    color: white;
    background: var(--theme-color-info);
  }

  .Toastify__toast--info {
    color: white;
    background: var(--theme-color-info);
  }
  .Toastify__toast--info .Toastify__progress-bar {
    background-color: #2196f3;
  }

  .Toastify__toast--success {
    color: white;
    background: var(--theme-color-positive);
  }

  .Toastify__toast--success .Toastify__progress-bar {
    background-color: #4caf50;
  }

  .Toastify__toast--warning {
    color: white;
    background: var(--theme-color-warning);
  }

  .Toastify__toast--warning .Toastify__progress-bar {
    background-color: #ff9800;
  }

  .Toastify__toast--error {
    color: white;
    background: var(--theme-color-negative);
  }

  .Toastify__toast--error .Toastify__progress-bar {
    background-color: #f44336;
  }

  .Toastify__close-button {
    fill: #ffffff;
  }

  .Toastify__close-button--default {
    color: #ffffff;
    opacity: 0.3;
  }

  .Toastify__close-button > svg {
    fill: #ffffff;
    height: 16px;
    width: 14px;
  }

  .Toastify__close-button:hover,
  .Toastify__close-button:focus {
    opacity: 1;
  }

  .Toastify__toast-body a {
    color: white;
    text-decoration: underline;
  }
`;
