import { Popover, PopoverList, PopoverListItem } from "app/components/Popover";
import clsx from "clsx";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";
import styles from "./CardContainer.module.scss";

interface Props {
  className?: string;
  children?: React.ReactNode;
  flex?: boolean;
  // item?: boolean;
  actionContent?: React.ReactNode;
  header?: {
    title: string;
    subtitle?: string;
    Icon?: IconType;
    link?: string;
    actions?: PopoverListItem[];
  };
}

export const CardContainer = ({ children, header, className, flex, actionContent }: Props) => {
  const titleContent = (
    <div className={styles.titleContent}>
      {header?.Icon ? (
        <span className={styles.titleIcon}>
          <header.Icon size={24} />
        </span>
      ) : null}
      <div className={styles.titleWrapper}>
        <span className={styles.title}>{header?.title}</span>
        {header?.subtitle && <span className={styles.subtitle}>{header.subtitle}</span>}
      </div>
    </div>
  );
  return (
    <div className={clsx(styles.root, className)}>
      {header ? (
        <div
          className={clsx(styles.headerWrapper, {
            [styles.headerOnly]: !Boolean(children),
          })}
        >
          <div>
            {header.link ? (
              <Link to={header.link} className={clsx(styles.header, styles.headerLink)}>
                {titleContent}
              </Link>
            ) : (
              <div className={styles.header}>{titleContent}</div>
            )}
          </div>
          <div className={styles.actionWrapper}>
            {actionContent ? actionContent : null}
            {header.actions ? (
              <Popover>
                <PopoverList items={header.actions} />
              </Popover>
            ) : null}
          </div>
        </div>
      ) : null}
      {children ? (
        <div
          className={clsx(styles.content, {
            [styles.flex]: flex,
          })}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
};
