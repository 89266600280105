import { ICONS } from "app/assets/icons/icons";
import { CardContainer } from "app/components/CardContainer/CardContainer";
import { SearchFilter } from "app/models";
import { searchSlice, useAppDispatch, useAppSelector } from "app/redux";
import { buildTmViewSearchUrl } from "app/util/tmview.util";
import { buildWipoSearchUrl } from "app/util/wipo.util";
import { SearchFilerChangeHandlers, SearchFilters } from "app/views/SearchView/SearchFilters/SearchFilters";
import { QuickActionButton } from "app/views/SearchView/SearchQuickActionBar/QuickActionButton/QuickActionButton";
import { useEffect, useState } from "react";
import styles from "./SearchQuickActionBar.module.scss";

export const SearchQuickActionBar = () => {
  const { current, result } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();

  const [tmViewUrl, setTmViewUrl] = useState<null | string>(null);
  const [wipoUrl, setWipoUrl] = useState<null | string>(null);

  useEffect(() => {
    setTmViewUrl(buildTmViewSearchUrl(current));
    setWipoUrl(buildWipoSearchUrl(current));
  }, [current]);

  const updateFilter = (diff: Partial<SearchFilter>) => {
    dispatch(
      searchSlice.actions.startNewSearch({
        ...current,
        filters: {
          ...current.filters,
          ...diff,
        },
      }),
    );
  };

  const clearFilter = () => {
    dispatch(
      searchSlice.actions.startNewSearch({
        ...current,
        filters: undefined,
      }),
    );
  };

  const searchFilterHandlers: SearchFilerChangeHandlers = {
    onClear: () => clearFilter(),
    onOfficeChange: (office) => updateFilter({ office }),
    onOwnerChange: (owner) => updateFilter({ owner }),
    onRepresentativeChange: (representative) => updateFilter({ representative }),
    onStatusChange: (status) => updateFilter({ status }),
    onTypeChange: (type) => updateFilter({ type }),
    onNiceChange: (niceClasses) => updateFilter({ niceClass: niceClasses }),
    onNiceOperatorChange: (niceClassOperator) => updateFilter({ niceClassOperator }),
    onViennaChange: (viennaClasses) => updateFilter({ viennaClass: viennaClasses }),
    onViennaOperatorChange: (viennaClassOperator) => updateFilter({ viennaClassOperator }),
  };

  return (
    <CardContainer className={styles.root}>
      <div className={styles.wrapper}>
        <SearchFilters queryResult={result} handlers={searchFilterHandlers} filters={current.filters} />
        {tmViewUrl ? (
          <a className={styles.link} href={tmViewUrl} target="_blank" rel="noreferrer noopener">
            <QuickActionButton title="TMview" Icon={ICONS.EXTERNAL_LINK} />
          </a>
        ) : null}
        {wipoUrl ? (
          <a className={styles.link} href={wipoUrl} target="_blank" rel="noreferrer noopener">
            <QuickActionButton title="WIPO" Icon={ICONS.EXTERNAL_LINK} />
          </a>
        ) : null}
      </div>
    </CardContainer>
  );
};
