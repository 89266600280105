import { isDevelopmentEnvironment } from "app/util/env.util";
import { vsprintf } from "sprintf-js";
import { getLanguage } from "./language-guess";

export interface Translation {
  [key: string]: string;
}

export const l = (translation: Translation, ...rest: string[]) => {
  return translate(getLanguage(), translation, ...rest);
};

export const translate = (language: string, translation: Translation, ...rest: string[]) => {
  try {
    return vsprintf(translation[language], rest);
  } catch (error) {
    console.error("Could not translate key", error);
    if (isDevelopmentEnvironment()) {
      throw error;
    }
    return "";
  }
};

export * from "./i18n.util";
export * from "./language-guess";
export { k } from "./translations";
