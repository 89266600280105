import { CardElement, Elements, IbanElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { PaymentClient } from "app/api";
import { Button } from "app/components/Button/Button";
import { RenderIfTestAccount } from "app/components/RenderIf/RenderIf";
import { UserFlag } from "app/models";
import { useAppSelector } from "app/redux/store.hooks";
import { handleUnknownError } from "app/util/error-handler";
import { stripeLiveInstance, stripeTestInstance } from "app/util/stripe.util";
import clsx from "clsx";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { FaCreditCard, FaMoneyCheck } from "react-icons/fa";
import { BillingInformation } from "../../../../models/payment-information.model";
import styles from "./PaymentMethodForm.module.css";

enum PaymentMethod {
  SEPA,
  CC,
}

const SEPA_COUNTRIES = [
  "AT",
  "BE",
  "CY",
  "DE",
  "EE",
  "ES",
  "ES",
  "FI",
  "FI",
  "FR",
  "GR",
  "IE",
  "IT",
  "LT",
  "LU",
  "LV",
  "MC",
  "MT",
  "NL",
  "PT",
  "PT",
  "PT",
  "SI",
  "SK",
  "SM",
];

interface Props {
  billingInformation: BillingInformation;
  onSubmit: (paymentMethodId: string) => Promise<void>;
  onChange: (valid: boolean) => void;
  valid: boolean;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  submitButtonText?: string;
}

const StripeForm = React.forwardRef<HTMLButtonElement, Props>(
  ({ billingInformation, valid, onChange, onSubmit, loading, setLoading, submitButtonText }, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isSepaCountry, setIsSepaCountry] = useState(SEPA_COUNTRIES.includes(billingInformation.address.country));
    const [paymentMethod, setPaymentMethod] = useState(isSepaCountry ? PaymentMethod.SEPA : PaymentMethod.CC);

    useEffect(() => {
      const sepaCountry = SEPA_COUNTRIES.includes(billingInformation.address.country);
      setIsSepaCountry(sepaCountry);

      if (!sepaCountry && paymentMethod === PaymentMethod.SEPA) {
        setPaymentMethod(PaymentMethod.CC);
      }
    }, [billingInformation.address.country, paymentMethod]);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        setLoading(true);
        const card = elements?.getElement(CardElement);
        const iban = elements?.getElement(IbanElement);

        const setupIntent = await PaymentClient.setupPayment(billingInformation);

        if (card) {
          const result = await stripe?.confirmCardSetup(setupIntent.clientSecret, {
            payment_method: {
              card,
              billing_details: {
                name: billingInformation.name,
                email: billingInformation.email,
                address: {
                  line1: billingInformation.address.line1,
                  line2: billingInformation.address.line2,
                  city: billingInformation.address.city,
                  state: billingInformation.address.state,
                  country: billingInformation.address.country,
                  postal_code: billingInformation.address.zip,
                },
              },
            },
          });
          if (result?.error) {
            throw result.error;
          }

          if (result?.setupIntent?.payment_method) {
            const method = result.setupIntent.payment_method;
            if (typeof method === "string") {
              await onSubmit(method);
            } else {
              await onSubmit(method.id);
            }
            card.clear();
          }
        }

        if (iban) {
          const result = await stripe?.confirmSepaDebitSetup(setupIntent.clientSecret, {
            payment_method: {
              sepa_debit: iban,
              billing_details: {
                name: billingInformation.name,
                email: billingInformation.email,
                address: {
                  line1: billingInformation.address.line1,
                  line2: billingInformation.address.line2,
                  city: billingInformation.address.city,
                  state: billingInformation.address.state,
                  country: billingInformation.address.country,
                  postal_code: billingInformation.address.zip,
                },
              },
            },
          });
          if (result?.error) {
            throw result.error;
          }

          if (result?.setupIntent?.payment_method) {
            const method = result.setupIntent.payment_method;
            if (typeof method === "string") {
              await onSubmit(method);
            } else {
              await onSubmit(method.id);
            }

            iban.clear();
          }
        }
      } catch (error) {
        handleUnknownError(error);
      } finally {
        setLoading(false);
      }
    };

    const onChangePaymentMethod = (p: PaymentMethod) => () => {
      onChange(false);
      setPaymentMethod(p);
    };

    const handleChange = ({ complete }: StripeElementChangeEvent) => {
      onChange(complete);
    };

    return (
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.switcher}>
          {isSepaCountry && (
            <div
              onClick={onChangePaymentMethod(PaymentMethod.SEPA)}
              className={clsx(styles.link, {
                [styles.active]: paymentMethod === PaymentMethod.SEPA,
              })}
            >
              <FaMoneyCheck className={styles.icon} />
              <span>SEPA-Lastschrift</span>
            </div>
          )}
          <div
            onClick={onChangePaymentMethod(PaymentMethod.CC)}
            className={clsx(styles.link, {
              [styles.active]: paymentMethod === PaymentMethod.CC,
            })}
          >
            <FaCreditCard className={styles.icon} />
            <span>Kreditkarte</span>
          </div>
        </div>

        <div className={styles.payment}>
          {paymentMethod === PaymentMethod.SEPA ? (
            <>
              <IbanElement
                options={{
                  supportedCountries: ["SEPA"],
                  classes: { base: styles.iban, focus: styles.focus },
                  disabled: loading,
                }}
                onChange={handleChange}
              />
              <RenderIfTestAccount padding={true}>
                Sie können diese IBAN benutzen:
                <br />
                DE89370400440532013000
              </RenderIfTestAccount>
              <p className={styles.hintText}>
                Ich ermächtige die Einsen und Nullen UG (haftungsbeschränkt), Zahlungen von meinem Konto mittels
                Lastschrift einzuziehen. Zugleich weise ich mein Kreditinstitut an, die von Einsen und Nullen UG
                (haftungsbeschränkt) auf mein Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht
                Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten
                dabei die mit meinem Kreditinstitut vereinbarten Bedingungen.
              </p>
            </>
          ) : (
            <>
              <CardElement
                options={{
                  classes: { base: styles.card, focus: styles.focus },
                  disabled: loading,
                }}
                onChange={handleChange}
              />
              <RenderIfTestAccount padding={true}>
                Sie können diese Kreditkarte benutzen:
                <br />
                4242 4242 4242 4242 (MM/YY: 04/24 CVC: 242 ZIP: 42424)
              </RenderIfTestAccount>
            </>
          )}
        </div>
        <div
          className={clsx(styles.button, {
            [styles.hidden]: !submitButtonText,
          })}
        >
          <Button type="submit" ref={ref} disabled={!valid} loading={loading}>
            {submitButtonText}
          </Button>
        </div>
      </form>
    );
  },
);

export const PaymentMethodForm = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const user = useAppSelector((state) => state.auth.user);

  const stripeInstance = useMemo(() => {
    return user?.flags?.includes(UserFlag.PAYMENT_TEST) ? stripeTestInstance : stripeLiveInstance;
  }, [user?.flags]);

  return (
    <Elements stripe={stripeInstance}>
      <StripeForm ref={ref} {...props} />
    </Elements>
  );
});
