import { TrademarkOffice, TrademarkStatus, TrademarkType } from "app/models/trademark.model";

export enum QueryType {
  ID = "ID",
  EXACT = "EXACT",
  CASE_INSENITIVE = "CASE_INSENITIVE",
  OPTIMIZED_EXACT = "OPTIMIZED_EXACT",
  OPTIMIZED_CASE_INSENSITIVE = "OPTIMIZED_CASE_INSENSITIVE",
  ANALYZED = "ANALIYZED",
  PHRASE = "PHRASE",
  PHRASE_WILDCARD = "PHRASE_WILDCARD",
  WILDCARD_PHRASE_WILDCARD = "WILDCARD_PHRASE_WILDCARD",
  FUZZY_AND = "FUZZY_AND",
  FUZZY = "FUZZY",
  OR = "OR",
  FUZZY_OR = "FUZZY_OR",
}

export const QUERY_SENSITIVITY = {
  EXACT: [QueryType.ID, QueryType.EXACT, QueryType.CASE_INSENITIVE],
  SIMILAR: [
    QueryType.ID,
    QueryType.EXACT,
    QueryType.CASE_INSENITIVE,
    QueryType.OPTIMIZED_EXACT,
    QueryType.OPTIMIZED_CASE_INSENSITIVE,
    QueryType.ANALYZED,
    QueryType.PHRASE,
    QueryType.PHRASE_WILDCARD,
  ],
  FUZZY: [
    QueryType.ID,
    QueryType.EXACT,
    QueryType.CASE_INSENITIVE,
    QueryType.OPTIMIZED_EXACT,
    QueryType.OPTIMIZED_CASE_INSENSITIVE,
    QueryType.ANALYZED,
    QueryType.PHRASE,
    QueryType.PHRASE_WILDCARD,
    QueryType.WILDCARD_PHRASE_WILDCARD,
    QueryType.FUZZY_AND,
    QueryType.FUZZY,
  ],
  RELATED: [
    QueryType.ID,
    QueryType.EXACT,
    QueryType.CASE_INSENITIVE,
    QueryType.OPTIMIZED_EXACT,
    QueryType.OPTIMIZED_CASE_INSENSITIVE,
    QueryType.ANALYZED,
    QueryType.PHRASE,
    QueryType.PHRASE_WILDCARD,
    QueryType.WILDCARD_PHRASE_WILDCARD,
    QueryType.FUZZY_AND,
    QueryType.FUZZY,
    QueryType.OR,
    QueryType.FUZZY_OR,
  ],
};

export enum SortBy {
  RELEVANCE = "RELEVANCE",
  NAME = "NAME",
  LAST_UPDATE_DATE = "LAST_UPDATE_DATE",
  FILED_DATE = "FILED_DATE",
  GRANTED_DATE = "GRANTED_DATE",
  EXPIRY_DATE = "EXPIRY_DATE",
  OPPOSITION_START_DATE = "OPPOSITION_START_DATE",
  OPPOSITION_END_DATE = "OPPOSITION_END_DATE",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Operator {
  OR = "OR",
  AND = "AND",
}

export type SearchFilter = {
  queryType: QueryType[];

  office: TrademarkOffice[];

  type: TrademarkType[];

  status: TrademarkStatus[];

  niceClass: string[];
  niceClassOperator: Operator;

  viennaClass: string[];
  viennaClassOperator: Operator;

  owner: string[];

  representative: string[];
};

export type SearchInputData = {
  query?: string;
  owner?: string;
  representative?: string;
  queryType?: QueryType[];
  limit?: number;
  offset?: number;
  filters?: Partial<SearchFilter>;
  sortBy?: SortBy;
  sortOrder?: SortOrder;
};

export interface SavedSearch extends Omit<SearchInputData, "limit" | "offset"> {
  uid: string;
  userId: string;
  name: string;
  updatedAt: Date;
  createdAt: Date;
  ignored: IgnoredTrademark[];
}

export interface IgnoredTrademark {
  trademarkId: string;
  trademarkOffice: TrademarkOffice;
}
