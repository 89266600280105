import { APP_CONST } from "app/assets/constants";
import { Button } from "app/components/Button/Button";
import { OneColumnLayout } from "app/layouts/OneColumnLayout/OneColumnLayout";
import { ROOT_PATH } from "app/routes";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NoAuthLayout.module.scss";

interface NoAuthLayoutProps {
  children: ReactNode;
  cta?: {
    text: string;
    action: () => any;
  };
}

export const NoAuthLayout = ({ children, cta }: NoAuthLayoutProps) => {
  const navigate = useNavigate();
  const handleAppNameClick = () => {
    navigate(ROOT_PATH);
  };
  return (
    <div className={styles.root}>
      <div className={styles.titleBar}>
        <a href={APP_CONST.LINKS.WEBSITE} target="_blank" rel="noreferrer noopener" className={styles.titleBarAppLink}>
          <div className={styles.titleBarAppName} onClick={handleAppNameClick}>
            <img height={40} className={styles.titleBarLogo} src={APP_CONST.IMAGES.OKTO} alt="Oktomark Logo" />
            <h1 className={styles.title}>Oktomark</h1>
          </div>
        </a>
        {cta ? (
          <Button shadow onClick={cta.action}>
            {cta.text}
          </Button>
        ) : null}
      </div>
      <div className={styles.content}>
        <OneColumnLayout>
          {children}
          <a href={APP_CONST.LINKS.WEBSITE} target="_blank" rel="noreferrer noopener" className={styles.bottomAppLink}>
            <div className={styles.titleBarAppName} onClick={handleAppNameClick}>
              <h1 className={styles.titleBottom}>Oktomark</h1>
            </div>
          </a>
        </OneColumnLayout>
      </div>
    </div>
  );
};
