// export type Translations = {
//   [key: string]: {
//     DE: string;
//     EN?: string;
//   };
// };

export const k = {
  PORTFOLIO: {
    DE: "Mandant",
    EN: "Client",
  },
  PORTFOLIOS: {
    DE: "Mandanten",
    EN: "Clients",
  },
  TRADEMARKS: {
    DE: "Marken",
    EN: "Trademarks",
  },
  REPRESENTATIVE: {
    DE: "Vertreter",
    EN: "Representative",
  },
  REPRESENTATIVES: {
    DE: "Vertreter",
    EN: "Representatives",
  },
  OWNER: {
    DE: "Inhaber",
    EN: "Owner",
  },
  STATISTICS: {
    DE: "Statistiken",
    EN: "Statistics",
  },
  UPDATES: {
    DE: "Updates",
    EN: "Updates",
  },
  VIEW: {
    DE: "Ansicht",
    EN: "View",
  },
  COLLECTION: {
    DE: "Sammlung",
    EN: "Collection",
  },
  COLLECTIONS: {
    DE: "Sammlungen",
    EN: "Collections",
  },
  DASHBOARD_PORTOFOLIO_EMPTY_STATE_TITLE: {
    DE: "Legen Sie jetzt Ihren ersten Mandanten an",
    EN: "Create your first client now",
  },
  CREATE_NEW_PORTFOLIO: {
    DE: "Neuen Mandanten anlegen",
    EN: "Create new client",
  },
  DASHBOARD_PORTOFOLIO_EMPTY_STATE_TEXT: {
    DE: "Mit Oktomark können Sie Recherchen und Marken ganz einfach einem Mandanten zuordenen. Organisieren Sie alle Arbeitsergebnisse und exportieren Sie diese mit wenigen Klicks.",
    EN: "With Oktomark, you can easily assign searches and trademarks to a client. Organize all work results and export them with a few clicks.",
  },
  MY_COLLECTIONS: {
    DE: "Meine Sammlungen",
    EN: "My collections",
  },
  MY_RESEARCHES: {
    DE: "Meine Recherchen",
    EN: "My researches",
  },
  MY_PORTFOLIOS: {
    DE: "Meine Mandanten",
    EN: "My clients",
  },
  SEARCHES: {
    DE: "Suchen",
    EN: "Searches",
  },
  RESEARCH: {
    DE: "Recherche",
    EN: "Research",
  },
  RESEARCHES: {
    DE: "Recherchen",
    EN: "Researches",
  },
  SEARCH_SIDEBAR_TITLE: {
    DE: "Filter",
    EN: "Filter",
  },
  LAST_SEARCHES: {
    DE: "Letzte Suchen",
    EN: "Last queries",
  },
  SAVED_SEARCH: {
    DE: "Gespeicherte Suche",
    EN: "Saved search",
  },
  SAVED_SEARCHES: {
    DE: "Gespeicherte Suchen",
    EN: "Saved searches",
  },
  IMAGE_SEARCH: {
    DE: "Bildersuche",
    EN: "Image search",
  },
  SETTINGS: {
    DE: "Einstellungen",
    EN: "Settings",
  },
  MONITORING: {
    DE: "Überwachung",
    EN: "Monitoring",
  },
  MONITORINGS: {
    DE: "Überwachungen",
    EN: "Monitorings",
  },
  MY_MONITORINGS: {
    DE: "Meine Überwachungen",
    EN: "My Monitorings",
  },
  COLLECTION_EMPTYSTATE_TEXT: {
    DE: "Sammlungen können Sie ganz individuell benutzen und so geht keine Marke mehr verloren. Klicken Sie an einer Marke einfach auf das Lesezeichen Symbol und fügen Sie die Marke zu einer Sammlung hinzu oder legen Sie einen neue Sammlung an.",
    EN: "With Collections you can remember as many brands as you want for later. To do this, click on the + symbol in the search or in updates and add the trademark to a list.",
  },
  PORTFOLIO_EMPTYSTATE_TEXT: {
    DE: "TODO",
    EN: "TODO",
  },
  PORTOFOLIO_RESEARCHES_EMPTY_STATE_TITLE: {
    DE: "Recherche starten",
    EN: "Start research",
  },
  PORTOFOLIO_RESEARCHES_EMPTY_STATE_TEXT: {
    DE: "Legen Sie jetzt die erste Recherche für Ihren Mandanten an und starten Sie die Suche.",
    EN: "Create the first search for your client now and add trademarks.",
  },
  SAVE_RESEARCH: {
    DE: "Recherche speichern",
    EN: "Save research",
  },
  SAVE_SEARCH: {
    DE: "Suche speichern",
    EN: "Save search",
  },
  SAVE_SEARCH_TO_RESEARCH: {
    DE: "Suche zu Recherche hinzufügen",
    EN: "Add search to research",
  },
  SAVE_NAMED_SEARCH: {
    DE: 'Suche "%s" speichern',
    EN: 'Save search "%s"',
  },
  LOADING_TRADEMARK_LIST_SUGGESTIONS: {
    DE: "Ähnliche Marken werden geladen",
    EN: "",
  },
  SIMILAR_TRADEMARKS_TITLE: {
    DE: "Ähnliche Marken",
    EN: "Similar trademarks",
  },
  SIMILAR_TRADEMARKS_SUBTITLE: {
    DE: "Diese Marken könnten interessant sein",
    EN: "",
  },
  TRADEMARK_MONITORING: {
    DE: "Überwachung",
    EN: "Watch",
  },
  TRADEMARK_MONITORINGS: {
    DE: "Überwachungen",
    EN: "Watches",
  },
  TRADEMARK_MONITORING_EMPTY_STATE_TITLE: {
    DE: "Jetzt die Markenüberwachung mit Oktomark ausprobieren!",
    EN: "Try trademark monitoring with Oktomark now!",
  },
  TRADEMARK_MONITORING_EMPTY_STATE_TEXT: {
    DE: "Um eine Marke zu überwachen, klicken Sie einfach in der Suche auf das Oktomark Symbol. Ihre überwachten Marken finden Sie anschließend hier.",
    EN: "To have a trademark monitored, click on the Oktomark icon in the search. You will then find your monitored marks here.",
  },

  TRADEMARK_SEARCH_EMPTY_STATE_TITLE: {
    DE: "Markenrecherche mit Oktomark",
    EN: "Trademark search with Oktomark",
  },
  TRADEMARK_SEARCH_EMPTY_STATE_TEXT: {
    DE: 'Der Oktomark Suchindex umfasst das Deutsche Patent- und Markenamt (DPMA) und das Amt der Europäischen Union für geistiges Eigentum (EUIPO). Probieren Sie es jetzt aus! Suchen Sie z. B. einfach mal nach der Marke "%s".',
    EN: 'The Oktomark search index includes the German Patent and Trademark Office (DPMA) and the European Union Intellectual Property Office (EUIPO). Try it now! For example search for "%s".',
  },
  TRADEMARK_SEARCH_NO_RESULT_STATE_TITLE: {
    DE: "Keine Ergebnisse gefunden",
    EN: "No results found",
  },
  TRADEMARK_SEARCH_NO_RESULT_STATE_TEXT: {
    DE: "Versuchen Sie eine andere Schreibweise oder andere Filter um ggf. mehr Marken zu finden.",
    EN: "Try a different spelling or other filters to find more tradmarks.",
  },
  VIENNA_CLASSIFICATION: {
    DE: "Wiener Klassifikation",
    EN: "Vienna classification",
  },
  VIENNA_CLASSIFICATION_DESCRIPTION: {
    DE: "Die Wiener Klassifikation ist eine international einheitliche Beschreibung der Bildbestandteile von Bild- und Wort-/Bildmarken. Die Oktomark Datenbank unfasst insgesamt %s Einträge bestehend aus Haupt- und Unterklassen. Beachten Sie, dass das europäische Markenamt eine erweiterte Fassung dieser Klassen verwendet. Diese sind entsprechend gekennzeichnet.",
    EN: "The Vienna Classification is an internationally standardized description of the image components of image and word/image marks. The Oktomark database contains a total of %s entries consisting of main classes and subclasses. Note that the European Trademark Office uses an extended version of these classes. These are marked accordingly.",
  },
  NICE_CLASSIFICATION: {
    DE: "Nizza Klassifikation",
    EN: "Vienna classification",
  },
  NICE_CLASSIFICATION_DESCRIPTION: {
    DE: "Die Nizza Klassifikation ist eine international anerkannte Einteilung von Waren und Dienstleistungen für die Eintragung einer Marke.",
    EN: "The Nice Classification is an internationally recognized classification of goods and services for the registration of a trademark.",
  },
  VIENNA_EUIPO_SPECIAL_HELP: {
    DE: "Diese Klasse existiert nur im EUIPO",
    EN: "This class exists only in the EUIPO",
  },
  CREATE_NEW_COLLECTION: {
    DE: "Neue Sammlung erstellen",
  },
  ADD_TO_COLLECTION: {
    DE: "Zu Sammlung hinzuzufügen",
  },
  ADD_COLLECTION: {
    DE: "Sammlung hinzuzufügen",
  },
  CREATE_NEW_COLLECTION_SUBTITLE: {
    DE: "Geben Sie der Sammlung einen Namen. Die Marke wird automatisch hinzugefügt.",
  },
  CREATE_NEW_RESEARCH: {
    DE: "Neue Recherche erstellen",
  },
  ADD_TO_RESEARCH: {
    DE: "Zu Recherche hinzuzufügen",
  },
  ADD_RESEARCH: {
    DE: "Recherche hinzuzufügen",
  },
  CREATE_NEW_RESEARCH_SUBTITLE: {
    DE: "Geben Sie der Recherche einen Namen. Die Marke wird automatisch hinzugefügt.",
  },
  ADD_TO_PORTFOLIO: {
    DE: "Zu Mandanten hinzufügen",
  },
  CREATE_NEW_PORTFOLIO_SUBTITLE: {
    DE: "Geben Sie dem Mandanten einen Namen. Die Marke wird automatisch hinzugefügt.",
  },
  ADD_MONITORING: {
    DE: "Überwachung hinzuzufügen",
  },
  OR: {
    DE: "ODER",
    EN: "OR",
  },
  AND: {
    DE: "UND",
    EN: "AND",
  },
};
