import { Button, ButtonTheme } from "app/components/Button/Button";
import { ButtonGroup } from "app/components/ButtonGroup/ButtonGroup";
import { k, l } from "app/i18n";
import { Operator } from "app/models";
import { useAppSelector } from "app/redux/store.hooks";
import clsx from "clsx";
import React from "react";
import styles from "./NizzaDialer.module.scss";

interface Props {
  className?: string;
  selected: string[];
  operator: Operator;
  onToggle: (id: string) => unknown;
  onOperatorChange: (operator: Operator) => unknown;
  onReset: () => void;
  available?: number[];
}

export const NizzaDialer = ({
  operator,
  className,
  selected,
  onToggle,
  onOperatorChange,
  onReset,
  available = [],
}: Props) => {
  const nizzaClasses = useAppSelector((state) => state.nizza.items);

  const handleSelect = (id: string) => () => {
    onToggle(id);
  };

  const handleOperatorChange = (o: Operator) => () => {
    onOperatorChange(o);
  };

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.buttons}>
        {nizzaClasses.map((i) => (
          <button
            type="button"
            disabled={operator === Operator.AND && available.length > 0 && !available.includes(i.classNumber)}
            onClick={handleSelect(String(i.classNumber))}
            className={clsx(
              styles.button,
              {
                [styles.selected]: selected.includes(String(i.classNumber)),
              },
              className,
            )}
            title={i.heading}
            key={i.classNumber}
          >
            {i.classNumber}
          </button>
        ))}
      </div>
      <ButtonGroup
        options={[
          {
            id: Operator.OR,
            active: operator === Operator.OR,
            onClick: handleOperatorChange(Operator.OR),
            title: l(k.OR),
          },
          {
            id: Operator.AND,
            active: operator === Operator.AND,
            onClick: handleOperatorChange(Operator.AND),
            title: l(k.AND),
          },
        ]}
      ></ButtonGroup>
      <Button theme={ButtonTheme.LINK} className={styles.resetButton} onClick={onReset} disabled={selected.length <= 0}>
        Alle löschen
      </Button>
    </div>
  );
};
