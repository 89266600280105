import { isValidEmail } from "app/util/validate.util";
import { FieldValues, Path, RegisterOptions, useForm, UseFormProps } from "react-hook-form";

export function useAppForm<T extends FieldValues>(formConfig?: UseFormProps<T>) {
  const {
    register,
    handleSubmit,
    formState: { isDirty, errors, isValid },
    setValue,
    reset,
    resetField,
    setFocus,
    control,
  } = useForm<T>({
    ...formConfig,
  });

  const registerPasswordField = (field: Path<T> = "password" as Path<T>, options: RegisterOptions<any, any> = {}) =>
    register(field, {
      required: {
        message: "Bitte geben Sie Ihr Passwort ein",
        value: true,
      },
      minLength: {
        message: "Ihr passwort sollte mindestens 8 Zeichen haben",
        value: 8,
      },
      ...options,
    });

  const registerEmailField = (field = "email" as Path<T>, options: RegisterOptions<any, any> = {}) =>
    register(field, {
      required: {
        message: "Bitte geben Sie Ihre E-Mail Adresse ein",
        value: true,
      },
      validate: (value) => isValidEmail(value as string),
      ...options,
    });

  return {
    register,
    registerPasswordField,
    registerEmailField,
    handleSubmit,
    isDirty,
    errors,
    isValid,
    setValue,
    reset,
    resetField,
    setFocus,
    control,
  };
}
