import { Badge } from "app/components/Badge/Badge";
import { Popover } from "app/components/Popover";
import { PopoverContent } from "app/components/Popover/PopoverContent/PopoverContent";
import { k, l } from "app/i18n";
import { ViennaClass, ViennaClassIdType } from "app/models/vienna-class.model";
import clsx from "clsx";
import styles from "./ViennaClassDescription.module.css";

interface ViennaClassDescriptionProps {
  className?: string;
  viennaClass: ViennaClass;
}

export const ViennaClassDescription = ({ className, viennaClass }: ViennaClassDescriptionProps) => {
  if (!viennaClass) {
    return null;
  }

  const { viennaId, text, notes, type } = viennaClass;

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.titleRow}>
        <b className={styles.id}>{viennaId}</b>
        {type === ViennaClassIdType.EUIPO_CUSTOM ? (
          <Popover
            hoverOnly={true}
            showOnHover={true}
            buttonElement={() => <Badge className={styles.badge}>EUIPO</Badge>}
          >
            <PopoverContent>{l(k.VIENNA_EUIPO_SPECIAL_HELP)}</PopoverContent>
          </Popover>
        ) : null}
      </div>
      <p className={styles.text}>{text}</p>
      <ul className={styles.list}>
        {notes.map((n, index) => (
          <li key={`${viennaId}_note_${index}`}>{n}</li>
        ))}
      </ul>
    </div>
  );
};
