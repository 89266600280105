import { getClient } from "app/api";
import { User } from "app/models";
import { PendingInvite, Workspace, WorkspaceBranding, WorkspaceContact } from "app/models/workspace.model";

const client = getClient({ basePath: "/workspace" });

export const WorkspaceClient = {
  fetch: async () => {
    const { data } = await client.get("/");
    return data;
  },
  create: async (name: string) => {
    const { data } = await client.post("/", {
      name,
    });
    return data;
  },
  update: async (name: string) => {
    const { data } = await client.put("/", {
      name,
    });
    return data;
  },
  delete: async () => {
    const { data } = await client.delete("/");
    return data;
  },
  fetchUsers: async () => {
    const { data } = await client.get<User[]>("/users");
    return data;
  },
  fetchPendingInvites: async () => {
    const { data } = await client.get<PendingInvite[]>("/pending-invites");
    return data;
  },
  acceptPendingInvite: async (id: string, secret: string) => {
    const { data } = await client.put<void>("/accept-invite", {
      workspaceId: id,
      secret,
    });
    return data;
  },
  rejectPendingInvite: async (id: string, secret: string) => {
    const { data } = await client.put<void>("/reject-invite", {
      workspaceId: id,
      secret,
    });
    return data;
  },
  removeUser: async (userId: string) => {
    const { data } = await client.delete<Workspace>(`/users/${userId}`);
    return data;
  },
  inviteUser: async (email: string) => {
    const { data } = await client.post("/invite", {
      email,
    });
    return data;
  },
  deleteInvite: async (uid: string) => {
    const { data } = await client.delete<Workspace>(`/invite/${uid}`);
    return data;
  },
  deleteCompanyLogo: async () => {
    const { data } = await client.delete<Workspace>("/branding/logo");
    return data;
  },
  updateBrandingLogo: async (blob: Blob) => {
    const formData = new FormData();
    formData.append("image", blob);
    const { data } = await client.put<Workspace>("/branding/logo", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  },
  updateContact: async (contact: WorkspaceContact) => {
    const { data } = await client.put<Workspace>("/contact", contact);
    return data;
  },
  updateBranding: async (branding: WorkspaceBranding) => {
    const { data } = await client.put<Workspace>("/branding", {
      primaryColor: branding.primaryColor,
      secondaryColor: branding.secondaryColor,
      pdfTitleStyle: branding.pdfTitleStyle,
    });
    return data;
  },
};
