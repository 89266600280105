import { Card, CardContent, CardContentSection } from "app/components/Card/Card";
import { ExternalLink } from "app/components/ExternalLink/ExternalLink";
import { Trademark } from "app/models/trademark.model";
import { isFutureDate, localizedDate, localizedTimeDistance } from "app/util/date.util";
import { linkByTradeMarkOffice } from "app/util/link.util";

interface TrademarkBaseDataCardProps {
  trademark: Trademark;
}

export const TrademarkBaseDataCard = ({ trademark }: TrademarkBaseDataCardProps) => {
  return (
    <Card>
      <CardContent>
        <CardContentSection title="Anmeldenummer">
          <ExternalLink
            title="Im Ursprungsamt anzeigen"
            text={trademark.id}
            href={linkByTradeMarkOffice(trademark.office, trademark.id)}
          />
        </CardContentSection>
        <CardContentSection title="Antragsdatum">{localizedDate(trademark.filed)}</CardContentSection>
        <CardContentSection title="Eintragungsdatum">{localizedDate(trademark.granted)}</CardContentSection>
        <CardContentSection title="Ablaufdatum">
          {localizedDate(trademark.expired)}
          {trademark.expired && isFutureDate(trademark.expired) ? (
            <small> ({localizedTimeDistance(trademark.expired)})</small>
          ) : (
            ""
          )}
        </CardContentSection>
        <CardContentSection title="Beginn der Widerspruchsfrist">
          {localizedDate(trademark.oppositionStart)}
        </CardContentSection>
        <CardContentSection title="Ende der Widerspruchsfrist">
          {localizedDate(trademark.oppositionEnd)}
          {trademark.oppositionEnd && isFutureDate(trademark.oppositionEnd) ? (
            <small> ({localizedTimeDistance(trademark.oppositionEnd)})</small>
          ) : (
            ""
          )}
        </CardContentSection>
      </CardContent>
    </Card>
  );
};
