import { AuthClient } from "app/api/auth.api";
import { Button } from "app/components/Button/Button";
import { Card, CardContent } from "app/components/Card/Card";
import { Input } from "app/components/Input/Input";
import { useAppForm, useAppSearchParams } from "app/hooks";
import { handleUnknownError } from "app/util/error-handler";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./../SharedNoAuthViewStyles.module.css";

type FormData = {
  password: string;
};

export const ResetPasswordCard = () => {
  const [{ secret }] = useAppSearchParams({
    secret: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { registerPasswordField, handleSubmit, errors, isDirty } = useAppForm<FormData>();

  const onSubmit = async (data: FormData) => {
    if (!secret || !data.password) {
      return;
    }

    try {
      setLoading(true);
      await AuthClient.resetPassword(secret, data.password);
      toast.success("Ihr Passwort wurde zurückgesetzt. Sie können sich nun mit Ihrem alten Passwort einloggen.", {
        autoClose: 10000,
      });
      navigate("/login");
    } catch (error) {
      handleUnknownError(error);
    } finally {
      setLoading(false);
    }
  };

  const valid = !Boolean(errors.password);
  const canSubmit = isDirty && valid;

  return (
    <Card className={styles.root}>
      <CardContent>
        <div className={styles.headline}>Passwort zurücksetzen</div>
        <p className={styles.subheadline}>Hier können Sie ein neues Passwort für Ihren Account wählen.</p>

        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            {...registerPasswordField()}
            type="password"
            name="password"
            label={errors.password ? errors.password.message : "Passwort"}
            className={styles.input}
            valid={valid}
          />

          <Button type="submit" loading={loading} className={styles.button} disabled={!canSubmit}>
            Passwort zurücksetzen
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};
