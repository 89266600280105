import { StateIndicator } from "app/components/TrademarkCard/StateIndicator/StateIndicator";
import { TrademarkOfficeBadge } from "app/components/TrademarkCard/TrademarkOfficeBadge/TrademarkOfficeBadge";
import { TypeIndicator } from "app/components/TrademarkCard/TypeIndicator/TypeIndicator";
import { Trademark } from "app/models/trademark.model";
import styles from "./TrademarkBadges.module.scss";

type TrademarkBadgesProps = {
  compact?: boolean;
  trademark: Trademark;
};

export const TrademarkBadges = ({ compact, trademark }: TrademarkBadgesProps) => {
  return (
    <div className={styles.badges}>
      <TrademarkOfficeBadge trademarkOffice={trademark.office} noBg={compact} />
      <TypeIndicator type={trademark.type} noBg={compact} />
      <StateIndicator trademark={trademark} noBg={compact} />
    </div>
  );
};
