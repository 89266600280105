export enum ScoreBoundary {
  ID = 100_000_000_000_000,
  EXACT = 10_000_000_000_000,
  CASE_INSENITIVE = 1_000_000_000_000,
  OPTIMIZED_EXACT = 10_000_000_000,
  OPTIMIZED_CASE_INSENSITIVE = 1_000_000_000,
  ANALYZED = 100_000_000,
  PHRASE = 10_000_000,
  PHRASE_WILDCARD = 1_000_000,
  WILDCARD_PHRASE_WILDCARD = 10_000,
  FUZZY_AND = 1_000,
  FUZZY = 100,
  OR = 10,
  FUZZY_OR = 1,
}

export enum ScoreCategory {
  ID = "ID",
  EXACT = "EXACT",
  SIMILAR = "SIMILAR",
  FUZZY = "FUZZY",
  RELATED = "RELATED",
}

export enum ScoreCategoryBoundary {
  ID = 100_000_000_000_000,
  EXACT = 100_000_000_000,
  SIMILAR = 100_000,
  FUZZY = 2000,
  RELATED = 0,
}
