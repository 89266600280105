import clsx from "clsx";
import { useEffect, useState } from "react";
import styles from "./TabLayout.module.scss";

type TabLayoutClassNames = {
  root?: string;
  header?: string;
  content?: string;
  button?: string;
};

interface TabLayoutProps {
  classNames?: TabLayoutClassNames;
  views?: TabViewConfig[];
  onChange?: (tabId: string) => unknown;
}

export type TabViewConfig = {
  id: string;
  title: string;
  children: React.ReactNode;
  button?: {
    style?: TabButtonStyle;
    extra?: string;
  };
};

type TabButtonProps = {
  onClick: () => unknown;
  extra?: string;
  isActive?: boolean;
  classNames?: TabLayoutClassNames;
  title: string;
  style?: TabButtonStyle;
};

export enum TabButtonStyle {
  DEFAULT = "DEFAULT",
  PRIMARY = "PRIMARY",
  DANGER = "DANGER",
  SECONDARY = "SECONDARY",
  GRAY = "GRAY",
}

export const TabButton = ({
  onClick,
  isActive,
  title,
  extra,
  classNames,
  style = TabButtonStyle.DEFAULT,
}: TabButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.button,
        {
          [styles.default]: style === TabButtonStyle.DEFAULT,
          [styles.primary]: style === TabButtonStyle.PRIMARY,
          [styles.secondary]: style === TabButtonStyle.SECONDARY,
          [styles.danger]: style === TabButtonStyle.DANGER,
          [styles.gray]: style === TabButtonStyle.GRAY,
          [styles.active]: isActive,
        },
        classNames?.button,
      )}
    >
      {title} {extra ? <span>{extra}</span> : null}
    </button>
  );
};

export const TabLayout = ({ classNames, views, onChange }: TabLayoutProps) => {
  const [tab, setTab] = useState<string | null>(null);

  const handleSetTab = (id: string) => () => {
    setTab(id);
    if (onChange) {
      onChange(id);
    }
  };

  useEffect(() => {
    if (views && tab === null) {
      const viewId = views.filter((v) => v.children).find(Boolean)?.id || null;
      setTab(viewId);
      if (onChange && viewId) {
        onChange(viewId);
      }
    }
  }, [tab, views, onChange]);

  if (!views) {
    return null;
  }

  const currentView = views.find((v) => v.id === tab)?.children || null;

  return (
    <div className={clsx(styles.root, classNames?.root)}>
      <div className={clsx(styles.header, classNames?.header)}>
        {views
          .filter((v) => v.children)
          .map((v) => (
            <TabButton
              onClick={handleSetTab(v.id)}
              key={v.id}
              title={v.title}
              extra={v.button?.extra}
              classNames={classNames}
              style={v.button?.style}
              isActive={v.id === tab}
            />
          ))}
      </div>
      <div className={clsx(styles.content, classNames?.content)}>{currentView}</div>
    </div>
  );
};
