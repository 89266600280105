import { k, l } from "app/i18n";
import clsx from "clsx";
import styles from "./SimilarTrademarksHeader.module.scss";

interface SimilarTrademarksHeaderProps {
  className?: string;
}

export const SimilarTrademarksHeader = ({ className }: SimilarTrademarksHeaderProps) => {
  return (
    <div className={clsx(styles.root, className)}>
      <h4>{l(k.SIMILAR_TRADEMARKS_TITLE)}</h4>
      <p>{l(k.SIMILAR_TRADEMARKS_SUBTITLE)}</p>
    </div>
  );
};
